import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useGetServiceQuery,
  useUpdateServiceMutation,
} from '@/features/services/redux/serviceAPI'
import { SaveServiceRequestInterface } from '@/features/services/redux/types'
import { Form } from '@/features/services/resources/_components/form'
import { ServiceCategoryList } from './categories/list'
import { ServiceConfigList } from '@/features/services/resources/views/edit/config/list'
import { ServiceNameEnum } from '@/features/posts/posts/redux/enums/service'
import { isFetchBaseQueryError } from '@/app/hooks'
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
} from '@/app/types'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'

export const ServiceEditScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['services'])
    const [updateService] =
      useUpdateServiceMutation()
    const navigate = useNavigate()
    const { id } = useParams<{
      id: string
    }>() as { id: string }
    const { data } = useGetServiceQuery(id)
    const [errors, setErrors] =
      useState<
        BackendValidationErrorInterface<SaveServiceRequestInterface>
      >()

    if (!data) return null

    const handleSubmit = async (
      data: SaveServiceRequestInterface
    ) => {
      try {
        await updateService({
          id,
          body: data,
        }).unwrap()
        toast.success(
          t('services:create.success')
        )
        navigate('/services')
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          const errorData =
            error as ErrorResponseInterface

          if (errorData.status === 422) {
            const errorData =
              error as ErrorValidationResponseInterface<SaveServiceRequestInterface>
            setErrors(errorData.data)
          }
        }
      }
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('services:edit.title')}
        </span>
        <Card>
          <Form
            errors={errors}
            data={data}
            onSubmit={handleSubmit}
          />
        </Card>
        <ServiceCategoryList serviceId={id} />
        {[
          ServiceNameEnum.MARK,
          ServiceNameEnum.POST_REFRESH,
        ].includes(data.system_name) && (
          <ServiceConfigList
            serviceId={id}
            serviceName={data.system_name}
          />
        )}
      </div>
    )
  }
