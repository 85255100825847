import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteArticleGalleryMutation,
  useGetArticleGalleriesQuery,
  useUpdateArticleGalleryMutation,
} from '@/features/articleGalleries/redux/articleGalleryAPI'
import { articleGalleriesModal } from '@/features/articleGalleries/resources/components/form'
import { ArticleGalleryInterface } from '@/features/articleGalleries/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { Query } from '@/utils/query'
import usePagination from '@/utils/hooks/usePagination'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'article_galleries',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination]
  )
  const { data: apiData } =
    useGetArticleGalleriesQuery(query.url())
  const [deleteArticleGallery] =
    useDeleteArticleGalleryMutation()
  const [updateArticleGallery] =
    useUpdateArticleGalleryMutation()

  const [data, setData] = useState<
    ArticleGalleryInterface[]
  >([])

  const onDelete = (id: number) => {
    deleteArticleGallery(id)
      .unwrap()
      .then(() =>
        toast.success(
          t('article_galleries:list.deleted')
        )
      )
  }

  const onEdit = (id: number) => {
    const row = data.find(
      (item) => item.id === id
    )

    articleGalleriesModal({
      title: t('article_galleries:edit.title'),
      data: row,
    }).then((data) =>
      updateArticleGallery({ data, id })
        .unwrap()
        .then(() =>
          toast.success(
            t('article_galleries:edit.success')
          )
        )
    )
  }

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    manualPagination: true,
    onPaginationChange: setPagination,
    state: {
      columnVisibility: visibility,
      pagination,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      table={table}
      pagination={apiData?.pagination}
    />
  )
}

const columnBuilder =
  createColumnHelper<ArticleGalleryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('title', {
    id: 'title',
    header: t('form:labels.title'),
    enableHiding: true,
  }),
  columnBuilder.accessor('description', {
    id: 'description',
    header: t('form:labels.description'),
    enableHiding: true,
  }),
  columnBuilder.accessor('status', {
    id: 'status',
    header: t('form:labels.status'),
    cell: ({ row }) =>
      t(
        `utils:article_gallery_statuses.${row.original.status}`
      ),
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.ARTICLE_GALLERY_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.ARTICLE_GALLERY_DESTROY,
          },
          onDetails: {
            action: `${row.original.id}/article-media`,
            permission:
              PermissionEnum.ARTICLE_GALLERY_SHOW,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
