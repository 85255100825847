import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import { ChangeCompanyRequestInterface } from '@/features/users/redux'
import { FormControl } from '@mui/base'
import { FormHelperText } from '@/components'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import _ from 'lodash'
import {
  UserCompanyProfileEnum,
  UserCompanyTypeEnum,
} from '@/features/users/redux/enums'
import { Input } from '@/features/components/inputs/input'

const Form = (): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const { control } =
    useFormContext<ChangeCompanyRequestInterface>()

  return (
    <Fragment>
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <Input
              type={'number'}
              label={t('form:labels.nip')}
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={'nip'}
        control={control}
      />
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl error={!!error}>
            <Select
              value={field.value}
              onChange={(_, value) =>
                field.onChange(value)
              }
              label={t(
                'form:labels.company_type'
              )}
            >
              {_.map(
                UserCompanyTypeEnum,
                (type) => (
                  <Option value={type} key={type}>
                    {t(`utils:company.${type}`)}
                  </Option>
                )
              )}
            </Select>
          </FormControl>
        )}
        name={'type'}
        control={control}
      />
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl error={!!error}>
            <Select
              value={field.value}
              onChange={(_, value) =>
                field.onChange(value)
              }
              label={t(
                'form:labels.company_primary_profile'
              )}
            >
              {_.map(
                UserCompanyProfileEnum,
                (profile) => (
                  <Option
                    value={profile}
                    key={profile}
                  >
                    {t(
                      `utils:company.${profile}`
                    )}
                  </Option>
                )
              )}
            </Select>
          </FormControl>
        )}
        name={'primary_profile'}
        control={control}
      />
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl error={!!error}>
            <Select
              value={field.value}
              onChange={(_, value) =>
                field.onChange(value)
              }
              label={t(
                'form:labels.company_secondary_profile'
              )}
            >
              {_.map(
                UserCompanyProfileEnum,
                (profile) => (
                  <Option
                    value={profile}
                    key={profile}
                  >
                    {t(
                      `utils:company.${profile}`
                    )}
                  </Option>
                )
              )}
            </Select>
          </FormControl>
        )}
        name={'secondary_profile'}
        control={control}
      />
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <Input
              type={'number'}
              min={0}
              label={t(
                'form:labels.farm_size_in_hectares'
              )}
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={'farm_size_in_hectares'}
        control={control}
      />
    </Fragment>
  )
}

export { Form }
