import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteFAQCategoryMutation,
  useGetFAQCategoriesQuery,
} from '@/features/faq/categories/redux/faqCategoryAPI'
import { FAQCategoryInterface } from '@/features/faq/categories/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'faq/categories',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination]
  )
  const { data: apiData } =
    useGetFAQCategoriesQuery(query.url())
  const [data, setData] = useState<
    FAQCategoryInterface[]
  >([])
  const [deleteCategory] =
    useDeleteFAQCategoryMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteCategory(id)
      .unwrap()
      .then(() =>
        toast.success(
          t('faq/categories:list.deleted')
        )
      )
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableHiding: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<FAQCategoryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('media_url', {
    id: 'media_url',
    header: t('form:labels.icon'),
    cell: ({ row }) =>
      row ? (
        <img
          src={row.original.media_url}
          alt={row.original.name}
          className={
            'h-[120px] w-[180px] object-cover'
          }
        />
      ) : null,
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/faq/categories/${row.original.id}/edit`,
            permission: PermissionEnum.FAQ_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.FAQ_DESTROY,
          },
          onDetails: {
            action: `/faq/categories/${row.original.id}`,
            permission: PermissionEnum.FAQ_SHOW,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
