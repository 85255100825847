import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Table } from './partials'
import { Search } from '@/features/components/inputs/Search'
import { useSearch } from '@/utils/hooks/useSearch'
import { Transition } from 'react-transition-group'
import {
  ColumnVisibility,
  FilterBuilder,
  Filters,
  useColumnVisibility,
  useFilters,
} from '@/components'

export const InternalQuotationsResultListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'form',
      'internal_quotations_results',
    ])
    const { query, setQueryParams } = useSearch({
      columnsForSearch: [
        'internalQuotation.name',
        'acceptUser.name',
      ],
    })
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()
    const { ref, TriggerFilters, showFilters } =
      useFilters()

    return (
      <div
        className={'flex flex-col gap-y-4 p-4'}
      >
        <Transition
          nodeRef={ref}
          unmountOnExit={true}
          in={showFilters}
          timeout={200}
          classNames={'transition-height'}
        >
          <Filters
            filterBuilder={builder}
            onSubmit={setQueryParams}
          />
        </Transition>
        <div
          className={
            'flex flex-col lg:flex-row lg:justify-between gap-y-4 lg:items-center'
          }
        >
          <div>
            <TriggerFilters />
          </div>
          <div className={'flex gap-2'}>
            <Search onChange={setQueryParams} />
            <ColumnVisibility
              columns={
                visibilityColumnsWithLabels
              }
              toggleColumn={handleToggleColumn}
            />
          </div>
        </div>

        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t(
                  'internal_quotations_results:list.title'
                )}
              </span>
            </div>
            <Table
              query={query}
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }

const builder = new FilterBuilder().addFilter({
  field: 'accepted',
  component: 'select',
  props: {
    placeholder: 'form:placeholders.select',
    label: 'form:labels.accepted',
  },
  optionsProps: {
    translate: true,
    options: [
      {
        value: '1',
        label: 'form:labels.show_accepted',
      },
      {
        value: '0',
        label: 'form:labels.show_unaccepted',
      },
    ],
  },
})
