import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useDeletePostServiceMutation,
  useGetPostServicesQuery,
  useUpdatePostServiceMutation,
} from '@/features/posts/posts/redux/postAPI'
import { PostServiceInterface } from '@/features/posts/posts/redux/types'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { PermissionEnum } from '@/features/permissions/redux/types'
import Carbon from '@/utils/carbon'
import { toast } from 'react-toastify'
import { postServiceForm } from '../form'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
} from '@/components'
import { TFunction } from 'i18next'

type Props = {
  postId: number | string
}

export const Table: React.FC<Props> = ({
  postId,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'posts/posts',
  ])
  const { data = [] } =
    useGetPostServicesQuery(postId)
  const [updatePostService] =
    useUpdatePostServiceMutation()
  const [deleteService] =
    useDeletePostServiceMutation()

  const handleEdit = (id: number) => {
    const service = data.find(
      (service) => service.id === id
    )

    if (!service) return

    postServiceForm({
      data: service,
    }).then((data) => {
      updatePostService({
        id: service.id,
        postId,
        body: data,
      })
        .unwrap()
        .then(() =>
          toast.success(
            t(
              'posts/posts:edit.services.edit_success'
            )
          )
        )
    })
  }

  const handleDelete = (id: number) => {
    deleteService({
      id,
      postId,
    })
      .unwrap()
      .then(() =>
        toast.success(
          t(
            'posts/posts:edit.services.delete_success'
          )
        )
      )
  }

  const table = useReactTable({
    columns: columns(t, handleDelete, handleEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
  })

  return (
    <div className={'flex flex-col gap-y-2'}>
      <BaseTable table={table} />
    </div>
  )
}

const columnBuilder =
  createColumnHelper<PostServiceInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('system_name', {
    id: 'system_name',
    header: t('form:labels.system_name'),
    cell: ({ row }) =>
      t(
        `utils:services.${row.original.system_name}`
      ),
  }),
  columnBuilder.accessor('quantity', {
    id: 'quantity',
    header: t('form:labels.quantity'),
  }),
  columnBuilder.accessor('value', {
    id: 'value',
    header: t('form:labels.value'),
  }),
  columnBuilder.accessor('end_at', {
    id: 'end_at',
    header: t('form:labels.end_at'),
    cell: ({ row }) =>
      row.original.end_at
        ? new Carbon()
            .parse(row.original.end_at)
            .toDateTimeLocal()
        : '-',
  }),
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.created_at'),
    cell: ({ row }) =>
      row.original.end_at
        ? new Carbon()
            .parse(row.original.created_at)
            .toDateTimeLocal()
        : '-',
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission: PermissionEnum.POST_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.POST_DESTROY,
          },
        })}
      />
    ),
  }),
]
