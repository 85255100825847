import { Tooltip as RTooltip } from 'react-tooltip'
import React, { FC } from 'react'
import { TooltipInterface } from './Tooltip.types'

export const Tooltip: FC<TooltipInterface> = ({
  place,
  id,
  content,
  as: Component,
  children,
  ...rest
}): React.ReactNode => {
  return (
    <React.Fragment>
      <Component
        data-tooltip-id={id}
        data-tooltip-content={content}
        {...rest}
      >
        {children}
      </Component>
      <RTooltip place={place} id={id} />
    </React.Fragment>
  )
}
