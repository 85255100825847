import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveArticleRequestInterface } from '@/features/articles/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { ArticleStatusEnum } from '@/features/articles/redux/enums/ArticleStatusEnum'
import _ from 'lodash'
import Carbon from '@/utils/carbon'

export default class SaveArticleValidation
  implements
    ValidationInterface<SaveArticleRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveArticleRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      article_category_id: yup
        .number()
        .nullable(t('validation:required')),
      category_id: yup
        .number()
        .nullable(t('validation:required')),
      status: yup
        .mixed<ArticleStatusEnum>()
        .required(t('validation:required'))
        .oneOf(Object.values(ArticleStatusEnum)),
      promoted: yup
        .boolean()
        .required(t('validation:required')),
      important: yup
        .boolean()
        .required(t('validation:required')),
      article_of_day: yup
        .boolean()
        .required(t('validation:required')),
      title: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t('validation:max', { max: 255 })
        ),
      lead: yup
        .string()
        .nullable()
        .max(
          1024,
          t('validation:max', { max: 1024 })
        ),
      content: yup
        .string()
        .required(t('validation:required'))
        .min(20, t('validation:min', { min: 20 }))
        .max(
          65535,
          t('validation:max', { max: 65535 })
        ),
      seo_description: yup.string().nullable(),
      seo_keywords: yup.string().nullable(),
      seo_canonical_url: yup
        .string()
        .url(t('validation:field_must_be_url'))
        .nullable(),
      og_title: yup.string().nullable(),
      og_description: yup.string().nullable(),
      og_image: yup
        .mixed<File>()
        .nullable()
        .test(
          'file-extension',
          t('validation:file_extension', {
            allowedExtensions:
              'jpg, png, jpeg, svg',
          }),
          function (value) {
            if (_.isNil(value)) return true

            const allowedExtensions = [
              'jpg',
              'png',
              'jpeg',
              'svg',
            ]
            const fileExtension = value.name
              .split('.')
              .pop()

            return allowedExtensions.includes(
              fileExtension || ''
            )
          }
        ),
      published_at: yup
        .string()
        .nullable()
        .when('status', {
          is: (status: ArticleStatusEnum) =>
            status ===
            ArticleStatusEnum.PUBLISHED,
          then: (yup) =>
            yup.required(
              t('validation:required')
            ),
        })
        .transform((value) => {
          return value
            ? new Carbon(value).toISOString()
            : value
        }),
      subcategory: yup.string().nullable(),
      main_image: yup
        .object()
        .shape({
          file: yup.mixed<File>().nullable(),
          watermark: yup.boolean().required(),
          title: yup
            .string()
            .nullable()
            .max(
              255,
              t(
                'validation:field_must_be_less_than',
                { max: 255 }
              )
            ),
          description: yup
            .string()
            .max(
              255,
              t(
                'validation:field_must_be_less_than',
                { max: 255 }
              )
            )
            .nullable(),
        })
        .required(),
      article_gallery_id: yup.number().nullable(),
    })
  }

  defaultValues(): SaveArticleRequestInterface {
    return {
      title: '',
      lead: '',
      content: '',
      status: ArticleStatusEnum.CREATED,
      promoted: false,
      important: false,
      article_of_day: false,
      seo_canonical_url: '',
      seo_description: '',
      seo_keywords: '',
      og_title: '',
      og_description: '',
      published_at: '',
      subcategory: '',
      main_image: {
        title: '',
        watermark: false,
        description: '',
        file: null,
      },
    }
  }
}
