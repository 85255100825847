import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { ButtonLink } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './_components/table'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

export const PackageListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['packages'])
    const { hasPermission } = usePermissions()
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    return (
      <div
        className={'flex flex-col p-8 gap-y-4'}
      >
        <div
          className={
            'flex flex-col gap-2 justify-between lg:flex-row'
          }
        >
          {hasPermission(
            PermissionEnum.PACKAGE_SAVE
          ) && (
            <ButtonLink
              variant={'contained'}
              className={
                'flex items-center gap-x-2'
              }
              to={'/packages/create'}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={'text-white'}
              />
              <span>
                {t('packages:list.create')}
              </span>
            </ButtonLink>
          )}
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <Card margin noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t('packages:list.title')}
              </span>
            </div>
            <Table
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
