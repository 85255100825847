import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { ButtonLink } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './_components/table'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

type Props = {
  categoryId: string
  categoryName: string
}

export const FAQCategoryQuestionsList: React.FC<
  Props
> = ({
  categoryId,
  categoryName,
}): React.ReactNode => {
  const { t } = useTranslation(['faq/questions'])
  const { hasPermission } = usePermissions()
  const {
    handleToggleColumn,
    handleSetAvailableColumns,
    visibilityColumnsWithLabels,
    visibilityColumns,
  } = useColumnVisibility()

  return (
    <div className={'flex flex-col gap-y-4'}>
      <div
        className={
          'flex flex-col gap-2 lg:justify-between lg:flex-row'
        }
      >
        {hasPermission(
          PermissionEnum.FAQ_SAVE
        ) && (
          <ButtonLink
            variant={'contained'}
            className={
              'flex items-center gap-x-2'
            }
            to={`/faq/categories/${categoryId}/questions/create`}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className={'text-white'}
            />
            <span>
              {t('faq/questions:list.create')}
            </span>
          </ButtonLink>
        )}
        <ColumnVisibility
          columns={visibilityColumnsWithLabels}
          toggleColumn={(id: string) =>
            handleToggleColumn(id)
          }
        />
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t('faq/questions:list.title', {
                category: categoryName,
              })}
            </span>
          </div>
          <Table
            categoryId={categoryId}
            visibility={visibilityColumns}
            setAvailableColumns={
              handleSetAvailableColumns
            }
          />
        </div>
      </Card>
    </div>
  )
}
