import knwAPI from '@/utils/api/igrit'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'
import {
  GetRoleResponseInterface,
  GetRolesResponseInterface,
  SaveRoleRequestInterface,
} from '@/features/roles/redux/types'

export const {
  useGetRolesQuery,
  useStoreRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useLazyGetRolesQuery,
} = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<
      GetRolesResponseInterface['data'],
      string
    >({
      query: (query) => `/admin/roles?${query}`,
      transformResponse: (
        response: GetRolesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Role', result),
    }),
    getRole: builder.query<
      GetRoleResponseInterface['data'],
      number | string
    >({
      query: (id) => `/admin/roles/${id}`,
      transformResponse: (
        response: GetRoleResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('Role', result),
    }),
    storeRole: builder.mutation<
      void,
      SaveRoleRequestInterface
    >({
      query: (body) => ({
        url: '/admin/roles',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Role'],
    }),
    updateRole: builder.mutation<
      void,
      {
        id: number | string
        body: SaveRoleRequestInterface
      }
    >({
      query: ({ id, body }) => ({
        url: `/admin/roles/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Role'],
    }),
    deleteRole: builder.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Role'],
    }),
  }),
})
