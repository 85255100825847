import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
} from '@/components'
import { Query } from '@/utils/query'
import usePagination from '@/utils/hooks/usePagination'
import {
  AdressesInterface,
  useDeleteAdressMutation,
  useGetAdressesQuery,
  useUpdateAdressMutation,
} from '@/features/users/redux'
import { AdressModal } from '@/features/users/resources/views/edit/partials'

type Props = {
  query: Query
  userId: string | number
}
export const Table: React.FC<Props> = ({
  query: baseQuery,
  userId,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'users',
    'utils',
  ])

  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => baseQuery.page(pagination.pageIndex),
    [pagination, baseQuery]
  )

  const { data: apiData } = useGetAdressesQuery({
    query: query.url(),
    id: userId,
  })

  const [deleteAdress] = useDeleteAdressMutation()
  const [updateAdress] = useUpdateAdressMutation()
  const [data, setData] = useState<
    AdressesInterface[]
  >([])

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = async (id: number) => {
    try {
      await deleteAdress({
        userId: userId,
        adressId: id,
      }).unwrap()
      toast.success(
        t('users:adresses.list.deleted')
      )
    } catch (error) {
      // empty block
    }
  }

  const onEdit = async (id: number) => {
    const row = data.find(
      (item) => item.id === id
    )

    try {
      const data = await AdressModal({
        title: t(
          'users:adresses.list:edit.title'
        ),
        data: row,
      })
      await updateAdress({
        body: data,
        userId: userId,
        adressId: id,
      }).unwrap()
      toast.success(
        t('users:adresses.list:edit.success')
      )
    } catch (error) {
      // empty block
    }
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination,
    },
  })

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<AdressesInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
  }),
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
  }),
  columnBuilder.accessor('street', {
    id: 'street',
    header: t('form:labels.street'),
  }),
  columnBuilder.accessor('country', {
    id: 'country',
    header: t('form:labels.country'),
  }),
  columnBuilder.accessor('state', {
    id: 'state',
    header: t('form:labels.state'),
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.USER_ADDRESS_DESTROY,
            },
            onEdit: {
              action: () =>
                onEdit(row.original.id),
              permission:
                PermissionEnum.USER_ADDRESS_SAVE,
            },
          }),
        ]}
      />
    ),
  }),
]
