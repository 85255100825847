import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteInternalQuotationPartnerMutation,
  useGetInternalQuotationsPartnersQuery,
  useToggleQuotationPartnerStatusMutation,
  useUpdateInternalQuotationPartnerMutation,
} from '@/features/internalQuotationsPartners/redux/internalQuotationsPartnersAPI'
import { InternalQuotationPartnerInterface } from '@/features/internalQuotationsPartners/redux/types'
import { internalQuotationsPartnersModal } from '@/features/internalQuotationsPartners/resources/components/form/Form'
import { serialize } from 'object-to-formdata'
import { InternalQuotationsPartnersStatusEnum } from '@/features/internalQuotationsPartners/redux/enums/InternalQuotationsPartnersStatusEnum'
import Carbon from '@/utils/carbon'
import {
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import {
  Query,
  SortingState,
} from '@/utils/query'

type Props = {
  query: Query
} & VisibleColumnsInterface
export const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'internal_quotations_partners',
    'utils',
  ])
  const [sorting, setSorting] =
    useState<SortingState>([])
  const query = useMemo(
    () => baseQuery.sortBySortState(sorting),
    [baseQuery]
  )

  const { data = [] } =
    useGetInternalQuotationsPartnersQuery(
      query.url()
    )
  const [deleteInternalQuotationPartner] =
    useDeleteInternalQuotationPartnerMutation()
  const [updateInternalQuotationPartner] =
    useUpdateInternalQuotationPartnerMutation()
  const [toggleQuotationPartnerStatus] =
    useToggleQuotationPartnerStatusMutation()

  const onDelete = async (id: number) => {
    try {
      await deleteInternalQuotationPartner(
        id
      ).unwrap()
      toast.success(
        t(
          'internal_quotations_partners:list.deleted'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const onEdit = async (id: number) => {
    const row = data.find(
      (item) => item.id === id
    )

    try {
      const data =
        await internalQuotationsPartnersModal({
          title: t(
            'internal_quotations_partners:edit.title'
          ),
          data: row,
        })
      const formData = serialize(data, {
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
        indices: true,
      })
      formData.append('_method', 'PUT')
      await updateInternalQuotationPartner({
        data: formData,
        id,
      }).unwrap()
      toast.success(
        t(
          'internal_quotations_partners:edit.success'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const onToggle = async (id: number) => {
    try {
      await toggleQuotationPartnerStatus(id)
      toast.success(
        t(
          'internal_quotations_partners:toggle.success'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const table = useReactTable({
    columns: columns(
      t,
      onDelete,
      onEdit,
      onToggle
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: true,
    manualSorting: true,
    enableHiding: true,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<InternalQuotationPartnerInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onToggle: (id: number) => void
) => [
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.created_at'),
    cell: ({ row }) =>
      new Carbon(
        row.original.created_at
      ).toDateTimeLocal(),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('updated_at', {
    id: 'updated_at',
    header: t('form:labels.updated_at'),
    cell: ({ row }) =>
      new Carbon(
        row.original.updated_at
      ).toDateTimeLocal(),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('website', {
    id: 'website',
    header: t('form:labels.url'),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'image',
    header: t('form:labels.image'),
    cell: ({ row }) => {
      return (
        <img
          src={row.original.logo_url}
          alt={''}
          className={'max-w-[100px]'}
        />
      )
    },
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('status', {
    id: 'status',
    header: t('form:labels.status'),
    cell: ({ row }) =>
      t(
        `utils:internal_quotations_partners_statuses.${row.original.status}`
      ),
    enableSorting: true,
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATIONS_PARTNER_DESTROY,
            },
            onEdit: {
              action: () =>
                onEdit(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATIONS_PARTNER_SAVE,
            },
          }),
          {
            icon: faToggleOn,
            onClick: () =>
              onToggle(row.original.id),
            buttonClassName: 'bg-green-500',
            permission:
              PermissionEnum.INTERNAL_QUOTATIONS_PARTNER_SAVE,
            condition:
              row.original.status ===
              InternalQuotationsPartnersStatusEnum.ACTIVE,
            tooltip:
              'internal_quotations_partners:tooltips.deactivate',
          },
          {
            icon: faToggleOff,
            onClick: () =>
              onToggle(row.original.id),
            buttonClassName: 'bg-red-400',
            permission:
              PermissionEnum.INTERNAL_QUOTATIONS_PARTNER_SAVE,
            condition:
              row.original.status !==
              InternalQuotationsPartnersStatusEnum.ACTIVE,
            tooltip:
              'internal_quotations_partners:tooltips.activate',
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
