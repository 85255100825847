import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import _ from 'lodash'
import { SavePackageCategoryRequestInterface } from '@/features/packages/redux/types'

export default class SavePackageCategoryValidation
  implements
    ValidationInterface<SavePackageCategoryRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SavePackageCategoryRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      category_id: yup
        .number()
        .required(t('validation:required'))
        .notOneOf([0], t('validation:required')),
      price: yup
        .number()
        .required(t('validation:required'))
        .min(0, t('validation:min', { min: 0 })),
      points: yup
        .number()
        .nullable(t('validation:required'))
        .min(0, t('validation:min', { min: 0 })),
      discounted_price: yup
        .number()
        .nullable(t('validation:required'))
        .min(0, t('validation:min', { min: 0 }))
        .lessThan(
          yup.ref('price'),
          t('validation:less_than', {
            field: t(
              'form:labels.price'
            ).toLowerCase(),
          })
        )
        .transform(
          (value: number | null | string) =>
            _.isNaN(value) ? null : Number(value)
        ),
      discounted_points: yup
        .number()
        .nullable(t('validation:required'))
        .min(0, t('validation:min', { min: 0 }))
        .when('points', {
          is: (value: number | null | string) =>
            !_.isNaN(value) && !_.isNull(value),
          then: (schema) =>
            schema.lessThan(
              yup.ref('points'),
              t('validation:less_than', {
                field: t(
                  'form:labels.points'
                ).toLowerCase(),
              })
            ),
        })
        .transform(
          (value: number | null | string) =>
            _.isNaN(value) ? null : Number(value)
        ),
    })
  }

  defaultValues(): SavePackageCategoryRequestInterface {
    return {
      category_id: 0,
      price: 0,
      discounted_points: '',
      points: '',
      discounted_price: '',
    }
  }
}
