import {
  AdsInterface,
  UpdateAdsValidationInterface,
} from '@/features/ads/ads/redux'
import { UseFormSetValue } from 'react-hook-form'
import Carbon from '@/utils/carbon'

export const getPrefixForGeneralForm = (
  baseExists: boolean
) => (baseExists ? 'base' : '')

export const associateValues = (
  ad: AdsInterface,
  setValue: UseFormSetValue<UpdateAdsValidationInterface>
) => {
  setValue('name', ad.name)
  setValue('generate_form', true)
  setValue('slot_id', ad.slot_id)
  setValue('advertiser_id', ad.advertiser_id)

  if (ad.slot) {
    setValue('slot_name', ad.slot.name)
    setValue('slot_type', ad.slot.type)
  }

  setValue('is_target_blank', ad.is_target_blank)
  setValue('is_active', ad.is_active)
  setValue(
    'is_anonymous_only',
    ad.is_anonymous_only
  )
  setValue(
    'started_at',
    new Carbon(ad.started_at).format('yyyy-MM-dd')
  )
  setValue(
    'ended_at',
    new Carbon(ad.ended_at).format('yyyy-MM-dd')
  )
  setValue(
    'desktop_pixel_url',
    ad.desktop_pixel_url
  )
  setValue(
    'mobile_pixel_url',
    ad.mobile_pixel_url
  )
  setValue(
    'mobile_pixel_url',
    ad.mobile_pixel_url
  )
  setValue(
    'total_display_limit',
    ad.total_display_limit
  )
  setValue(
    'daily_display_limit',
    ad.daily_display_limit
  )
  setValue('capping_limit', ad.capping_limit)
  setValue(
    'capping_days_period',
    ad.capping_days_period
  )
  setValue(
    'desktop_click_url',
    ad.desktop_click_url
  )
  setValue(
    'mobile_click_url',
    ad.mobile_click_url
  )

  if (ad.view_ids.length) {
    setValue('view_ids', ad.view_ids)
  }

  if (ad.categories.length) {
    setValue('categories', ad.categories)
  }
}
