import { FC, ReactNode } from 'react'
import {
  SearchType,
  Select2OptionInterface,
} from '@/app/types'
import { useAsyncSelect } from '@/utils/hooks/useAsyncSelectV2'
import { useLazySearchAttributeQuery } from '@/features/posts/attributes/redux/attributeAPI'
import { AttributeInterface } from '@/features/posts/attributes/redux/types'
import { AsyncSelect } from '../AsyncSelect'
import {
  MultiValue,
  SingleValue,
} from 'react-select'

const SearchAttribute: FC<SearchType> = ({
  onChange,
  isMulti,
  defaultValue,
}): ReactNode => {
  const [getAttributes] =
    useLazySearchAttributeQuery()
  const {
    handleFetch,
    setSelectedValue,
    selectedValue,
  } = useAsyncSelect<AttributeInterface, 'array'>(
    getAttributes,
    'array',
    'name',
    'id',
    'name',
    defaultValue
  )

  const handleChange = (
    value:
      | MultiValue<Select2OptionInterface>
      | SingleValue<Select2OptionInterface>
  ) => {
    if (isMulti) {
      onChange(
        value as MultiValue<Select2OptionInterface>
      )
      setSelectedValue(
        value as Select2OptionInterface[]
      )
    } else {
      onChange(
        value as SingleValue<Select2OptionInterface>
      )
      setSelectedValue(
        value as Select2OptionInterface
      )
    }
  }

  return (
    <AsyncSelect
      defaultValue={selectedValue}
      loadOptions={handleFetch}
      isMulti={isMulti}
      onChange={handleChange}
    />
  )
}

export { SearchAttribute }
