import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { UpdateUserRequestInterface } from '@/features/users/redux'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'

class BaseUserValidation
  implements
    ValidationInterface<UpdateUserRequestInterface>
{
  defaultValues(): UpdateUserRequestInterface {
    return {
      name: '',
      email: '',
      phone_prefix: '48',
      phone_number: '',
      is_active: false,
      is_blocked: false,
      agreements: [],
    }
  }

  rules(
    t: TFunction
  ): ObjectSchema<
    UpdateUserRequestInterface,
    AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t('validation:max', { max: 255 })
        )
        .transform((value) => value.trim()),
      email: yup
        .string()
        .required(t('validation:required'))
        .email(t('validation:email_invalid'))
        .max(
          255,
          t('validation:max', { max: 255 })
        )
        .transform((value) => value.trim()),
      phone_prefix: yup
        .string()
        .nullable()
        .transform((value) =>
          value ? value.trim() : null
        )
        .test(
          'phone-prefix-required',
          t('validation:required'),
          (value, { parent }) =>
            !(parent.phone_number && !value)
        ),
      phone_number: yup
        .string()
        .nullable()
        .transform((value) =>
          value ? value.trim() : null
        )
        .test(
          'phone-number-required',
          t('validation:required'),
          (value, { parent }) =>
            !(parent.phone_prefix && !value)
        ),
      is_active: yup
        .boolean()
        .required(t('validation:required')),
      is_blocked: yup
        .boolean()
        .required(t('validation:required')),
      agreements: yup
        .array()
        .of(
          yup.object().shape({
            id: yup
              .number()
              .required(t('validation:required')),
            accepted: yup
              .boolean()
              .required(t('validation:required')),
          })
        )
        .required(),
    })
  }
}

export { BaseUserValidation }
