import React, { useEffect } from 'react'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'

import {
  ExternalQuotationInterface,
  SaveExternalQuotationRequestInterface,
  useUpdateExternalQuotationMutation,
} from '@/features/externalQuotations/redux'
import SaveExternalQuotationValidation from '../validation/SaveExternalQuotationValidation'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { usePermissions } from '@/utils/hooks/usePermission'

type Props = {
  body: ExternalQuotationInterface
  t: TFunction
  permission: PermissionEnum
}

const NameForm: React.FC<Props> = ({
  body,
  t,
  permission,
}): React.ReactNode => {
  const { schema, defaultValues } = useValidation(
    new SaveExternalQuotationValidation(),
    t
  )
  const [updateExternalQuotation, { isLoading }] =
    useUpdateExternalQuotationMutation()
  const { hasPermission } = usePermissions()

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<SaveExternalQuotationRequestInterface>(
    {
      resolver: yupResolver(schema),
      defaultValues,
    }
  )

  const _handleNameChange = async (
    data: SaveExternalQuotationRequestInterface
  ) => {
    try {
      await updateExternalQuotation({
        id: body.id,
        data: {
          is_active: data.is_active,
          name: data.name,
        },
      }).unwrap()
      toast.success(
        t('external_quotations:edit.success')
      )
    } catch (error) {
      //empty block
    }
  }
  const handleNameChange = _.debounce(
    _handleNameChange,
    1500
  )

  useEffect(() => {
    setValue('name', body.name)
    setValue('is_active', body.is_active)
  }, [body])

  useEffect(() => {
    const subscription = watch(() => {
      if (Object.keys(errors).length) {
        return
      }

      handleSubmit(handleNameChange)()
    })
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  if (
    permission === undefined ||
    hasPermission(permission)
  )
    return (
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <Input
              endAdornment={
                isLoading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                  />
                )
              }
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={'name'}
        control={control}
      />
    )

  return (
    <React.Fragment>{body.name}</React.Fragment>
  )
}

export { NameForm }
