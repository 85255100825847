import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useGetCategoryTreeQuery } from '@/features/posts/categories/redux/categoryAPI'
import TreeView, {
  INode,
  ITreeViewOnNodeSelectProps,
} from 'react-accessible-treeview'
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils'
import clsx from 'clsx'
import { CheckboxIcon } from './partials'
import { CategoryTreeTypes } from './CategoryTree.types'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons'

const CategoryTree: React.FC<
  CategoryTreeTypes
> = ({
  onChange,
  selectedIds,
}): React.ReactNode => {
  const { t } = useTranslation()
  const {
    data: apiData,
    isLoading,
    isFetching,
  } = useGetCategoryTreeQuery()
  const [expandedIds, setExapndedIds] = useState<
    (number | string)[]
  >([])
  const [data, setData] = useState<
    INode<IFlatMetadata>[]
  >([
    {
      id: 'main-parent',
      name: '',
      parent: null,
      children: [0],
    },
    {
      id: 0,
      name: t('form:labels.select_all'),
      parent: 'main-parent',
      children: [],
    },
  ])

  const isReady = useMemo(() => {
    const availableNodeIds = data.map(
      (item) => item.id
    )

    return (
      selectedIds.every((id) =>
        availableNodeIds.includes(id)
      ) &&
      !isFetching &&
      !isLoading
    )
  }, [selectedIds, data])

  useEffect(() => {
    if (selectedIds.length) {
      setExapndedIds([...selectedIds, ...[0]])
    }
  }, [selectedIds])

  useEffect(() => {
    if (apiData) {
      const base = apiData.map((item) => {
        return {
          id: item.id,
          name: item.name,
          parent: 0,
          children: apiData
            .filter(
              (child) =>
                child.parent_id === item.id
            )
            .sort((a, b) =>
              a.name < b.name ? -1 : 1
            )
            .map((child) => child.id),
        }
      })

      setData([
        {
          id: 0,
          name: t('form:labels.select_all'),
          parent: 'main-parent',
          children: apiData
            .filter(
              (item) => item.parent_id === null
            )
            .map((item) => item.id),
        },
        {
          id: 'main-parent',
          name: '',
          parent: null,
          children: [0],
        },
        ...base,
      ])
    }
  }, [apiData])

  const onSelect = (
    props: ITreeViewOnNodeSelectProps
  ) => {
    if (!props.treeState) return
    const selectedIds = Array.from(
      props.treeState.selectedIds
    ).map((id) =>
      typeof id === 'number' ? +id : id
    )
    onChange(
      selectedIds.filter(
        (id) => typeof id === 'number' && id !== 0
      ) as number[]
    )
    setExapndedIds((prev) => [
      ...prev,
      ...selectedIds,
      ...[0],
    ])
  }

  return (
    <div className={'flex flex-col'}>
      {!isReady ? (
        <div>Loading...</div>
      ) : (
        <TreeView
          data={data}
          multiSelect
          propagateSelectUpwards
          propagateSelect
          expandedIds={expandedIds}
          selectedIds={selectedIds}
          togglableSelect
          onSelect={onSelect}
          nodeRenderer={({
            element,
            getNodeProps,
            level,
            handleExpand,
            handleSelect,
            isHalfSelected,
            isSelected,
            isBranch,
            isExpanded,
          }) => (
            <div
              {...getNodeProps({
                onClick: handleExpand,
              })}
              style={{
                paddingLeft: `${
                  (level - 1) * 20
                }px`,
              }}
              autoFocus={false}
              className={clsx(
                `flex items-center gap-x-2`,
                {
                  'cursor-pointer':
                    element.children.length,
                }
              )}
            >
              {isBranch && (
                <span
                  onClick={(e) => {
                    handleExpand(e)
                    e.stopPropagation()
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      isExpanded
                        ? faCaretUp
                        : faCaretDown
                    }
                  />
                </span>
              )}
              <CheckboxIcon
                onClick={(e) => {
                  handleSelect(e)
                  e.stopPropagation()
                }}
                variant={
                  isHalfSelected
                    ? 'some'
                    : isSelected
                    ? 'all'
                    : 'none'
                }
              />
              <span>{element.name}</span>
            </div>
          )}
        />
      )}
    </div>
  )
}

export { CategoryTree }
