import React, { useEffect } from 'react'
import { FormInterface } from '@/app/types'
import {
  FAQCategoryInterface,
  SaveFAQCategoryRequestInterface,
} from '@/features/faq/categories/redux/types'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SaveFAQCategoryValidation from '@/features/faq/categories/resources/_components/form/validations/saveFAQCategoryValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Label } from '@/features/components/inputs/label'
import { Button } from '@/features/components/buttons/button'

export const Form: React.FC<
  FormInterface<
    SaveFAQCategoryRequestInterface,
    FAQCategoryInterface
  >
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveFAQCategoryValidation(),
    t
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SaveFAQCategoryRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
    }
  }, [data])

  return (
    <div className={'flex flex-col'}>
      <form
        className={'flex flex-col gap-y-6'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={
            'grid lg:grid-cols-5 gap-4 place-content-center'
          }
        >
          <Controller
            render={({ field }) => (
              <FormControl
                {...field}
                error={!!errors.name}
              >
                <Input
                  name={field.name}
                  label={t('form:labels.name')}
                  error={!!errors.name}
                />
                <FormHelperText
                  message={errors.name?.message}
                />
              </FormControl>
            )}
            name={'name'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <div
                className={
                  'flex flex-col gap-y-1'
                }
              >
                <Label
                  label={t('form:labels.icon')}
                />
                <input
                  type={'file'}
                  accept={'image/*'}
                  name={field.name}
                  onChange={(e) => {
                    e.target.files
                      ? field.onChange(
                          e.target.files[0]
                        )
                      : field.onChange(null)
                  }}
                />
                <FormHelperText
                  error={!!errors.icon}
                  message={errors.icon?.message}
                />
              </div>
            )}
            name={'icon'}
            control={control}
          />
        </div>
        <div className={'flex pt-6'}>
          <Button
            variant={'contained'}
            type={'submit'}
          >
            {t('form:buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  )
}
