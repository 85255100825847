import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { UserInterface } from '@/features/users/redux/types'
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from '@/features/users/redux/userAPI'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'users',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => baseQuery.page(pagination.pageIndex),
    [pagination, baseQuery]
  )
  const { data: apiData } = useGetUsersQuery(
    query.url()
  )
  const [data, setData] = useState<
    UserInterface[]
  >([])
  const [deleteUser] = useDeleteUserMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = async (id: number) => {
    try {
      await deleteUser(id).unwrap()
      toast.success(t('users:list.deleted'))
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    manualPagination: true,
    enableHiding: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<UserInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('email', {
    id: 'email',
    header: t('form:labels.email'),
    enableHiding: true,
  }),
  columnBuilder.accessor(
    'phone_number.phone_number',
    {
      id: 'phone_number',
      header: t('form:labels.phone_number'),
      enableHiding: true,
    }
  ),
  columnBuilder.accessor('is_premium', {
    id: 'is_premium',
    header: t('form:labels.is_premium'),
    cell: ({ row }) =>
      row.original.is_premium
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('is_active', {
    id: 'is_active',
    header: t('form:labels.is_active'),
    cell: ({ row }) =>
      row.original.is_active
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('is_blocked', {
    id: 'is_blocked',
    header: t('form:labels.is_blocked'),
    cell: ({ row }) =>
      row.original.is_blocked
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor(
    'failed_login_attempts_count',
    {
      id: 'failed_login_attempts_count',
      header: t(
        'form:labels.failed_login_attempts_count'
      ),
      enableHiding: true,
    }
  ),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/users/${row.original.id}/edit`,
            permission: PermissionEnum.USER_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.USER_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
