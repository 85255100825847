import {
  InternalQuotationCategoryInterface,
  SaveInternalQuotationCategoryInterface,
} from '@/features/internalQuotationCategories/redux/types'

export const toRequestObject = (
  row: InternalQuotationCategoryInterface
): SaveInternalQuotationCategoryInterface => ({
  description: row.description,
  order: row.order,
  name: row.name,
  category_id: row.category_id,
  price_from: row.price_from,
  price_to: row.price_to,
  unit: row.unit.name,
  is_active: row.is_active,
  conversion_unit_from: row.conversion_unit_from,
})
