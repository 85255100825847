import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { SaveAttributePropsInterface } from '@/features/posts/attributes/redux/types'
import {
  useGetAttributeQuery,
  useUpdateAttributeMutation,
} from '@/features/posts/attributes/redux/attributeAPI'
import { Form } from '@/features/posts/attributes/resources/components/form'

export const PostAttributesEditScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'posts/attributes',
    ])
    const [updateAttribute] =
      useUpdateAttributeMutation()
    const navigate = useNavigate()
    const { id } = useParams<{
      id: string
    }>() as { id: string }
    const { data } = useGetAttributeQuery(id)

    const handleSubmit = (
      data: SaveAttributePropsInterface
    ) => {
      updateAttribute({
        id,
        body: data,
      })
        .unwrap()
        .then(() => {
          toast.success(
            t('posts/attributes:edit.success')
          )
          navigate('/posts/attributes')
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('posts/attributes:edit.title')}
        </span>
        <Card>
          <Form
            data={data}
            onSubmit={handleSubmit}
          />
        </Card>
      </div>
    )
  }
