import { FC } from 'react'
import { LabelTypeInterface } from './Label.types'
import clsx from 'clsx'

const Label: FC<LabelTypeInterface> = ({
  label,
  className,
  ...rest
}) => {
  const classNames = clsx(
    'font-s text-gray-700 my-2',
    className
  )

  return (
    <label className={classNames} {...rest}>
      {label}
    </label>
  )
}

export { Label }
