import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useGetFAQCategoryQuery,
  useUpdateFAQCategoryMutation,
} from '@/features/faq/categories/redux/faqCategoryAPI'
import { SaveFAQCategoryRequestInterface } from '@/features/faq/categories/redux/types'
import { buildForm } from '@/features/faq/categories/resources/_components/form/helper'
import { Form } from '@/features/faq/categories/resources/_components/form'

export const FAQCategoriesEditScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'faq/categories',
    ])
    const { id } = useParams<{ id: string }>()
    const { data } = useGetFAQCategoryQuery(id!)
    const [updateCategory] =
      useUpdateFAQCategoryMutation()
    const navigate = useNavigate()

    const handleSubmit = (
      data: SaveFAQCategoryRequestInterface
    ) => {
      const formData = buildForm(data)
      formData.append('_method', 'PUT')

      updateCategory({ body: formData, id: id! })
        .unwrap()
        .then(() => {
          toast.success(
            t('faq/categories:edit.success')
          )
          navigate('/faq/categories')
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('faq/categories:edit.title')}
        </span>
        <Card>
          <Form
            data={data}
            onSubmit={handleSubmit}
          />
        </Card>
      </div>
    )
  }
