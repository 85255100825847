import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Button } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './_components/table'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { useStoreArticleCategoryMutation } from '@/features/articleCategories/redux/articleCategoryAPI'
import { toast } from 'react-toastify'
import { articleCategoryFormModal } from '@/features/articleCategories/resources/components/form'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

export const ArticleCategoryListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'article_categories',
    ])
    const { hasPermission } = usePermissions()
    const [storeArticleCategory] =
      useStoreArticleCategoryMutation()
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    const handleAddClick = () => {
      articleCategoryFormModal({
        title: t(
          'article_categories:create.title'
        ),
      }).then((result) => {
        result.subcategories =
          result.subcategories.map(
            (subcategory: string) => ({
              name: subcategory,
            })
          )
        return storeArticleCategory(result)
          .unwrap()
          .then(() =>
            toast.success(
              t(
                'article_categories:create.success'
              )
            )
          )
      })
    }

    return (
      <div
        className={'flex flex-col p-8 gap-y-4'}
      >
        <div
          className={
            'flex gap-4 flex-col lg:justify-between lg:flex-row'
          }
        >
          {hasPermission(
            PermissionEnum.ARTICLE_CATEGORY_SAVE
          ) && (
            <div>
              <Button
                variant={'contained'}
                className={
                  'flex items-center gap-x-2'
                }
                onClick={handleAddClick}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={'text-white'}
                />
                <span>
                  {t(
                    'article_categories:list.create'
                  )}
                </span>
              </Button>
            </div>
          )}
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t(
                  'article_categories:list.title'
                )}
              </span>
            </div>
            <Table
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
