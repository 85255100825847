import React, {
  FC,
  ReactNode,
  useEffect,
} from 'react'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import {
  SettingInterface,
  useUpdateSettingsMutation,
} from '@/features/settings'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import useValidation from '@/utils/hooks/useValidation'
import { UpdateSettingValidation } from './rules'
import { yupResolver } from '@hookform/resolvers/yup'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { Input } from '@/features/components/inputs/input'
import { FormControl } from '@mui/base'
import { match, Pattern } from 'ts-pattern'
import { FormHelperText } from '@/components'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Button } from '@/features/components/buttons/button'
import { toast } from 'react-toastify'

const Form: FC<
  {
    data: SettingInterface
  } & InstanceProps<void>
> = ({
  data,
  isOpen,
  onResolve,
  onReject,
}): ReactNode => {
  const { t } = useTranslation([
    'settings',
    'form',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new UpdateSettingValidation(),
    t
  )
  const { handleSubmit, reset, control } =
    useForm<typeof defaultValues>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  const [updateSetting] =
    useUpdateSettingsMutation()

  useEffect(() => {
    reset({
      value: data.value,
      description: data.description,
    })
  }, [data])

  const onSubmit = async (
    formData: typeof defaultValues
  ) => {
    try {
      await updateSetting({
        id: data.id,
        data: formData,
      }).unwrap()
      toast.success(t('settings:edit.success'))
      onResolve()
    } catch (error) {}
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={t('settings:edit.title')}
          onClose={onReject}
        />
        <form
          className={'flex flex-col pt-4 gap-4'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            readOnly
            defaultValue={t(
              `utils:settings.keys.${data.key}`
            )}
            label={t('form:labels.name')}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => {
              return match(data.data_type)
                .with(
                  Pattern.union(
                    'string',
                    'integer'
                  ),
                  (type) => (
                    <FormControl
                      {...field}
                      error={!!error}
                    >
                      <Input
                        type={
                          type === 'string'
                            ? 'text'
                            : 'number'
                        }
                        label={t(
                          'form:labels.value'
                        )}
                      />
                      <FormHelperText
                        message={error?.message}
                      />
                    </FormControl>
                  )
                )
                .with('bool', () => (
                  <Checkbox
                    checked={!!field.value}
                    label={t('form:labels.value')}
                    onChange={field.onChange}
                  />
                ))
                .otherwise(() => <div></div>)
            }}
            name={'value'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.description'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'description'}
            control={control}
          />
          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const editSettingModal = create(Form)
