import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import Form from '@/features/packages/resources/components/form'
import { SavePackageRequestInterface } from '@/features/packages/redux/types'
import {
  useGetPackageQuery,
  useUpdatePackageMutation,
} from '@/features/packages/redux/packageAPI'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import { PackageCategoryList } from '@/features/packages/resources/views/edit/categories/list'

export const PackageEditScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['packages'])
    const [updatePackage] =
      useUpdatePackageMutation()
    const navigate = useNavigate()
    const { id } = useParams<{
      id: string
    }>() as { id: string }
    const { data } = useGetPackageQuery(id)

    if (!data) return null

    const handleSubmit = (
      data: SavePackageRequestInterface
    ) => {
      const formData = serialize(data, {
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
        indices: true,
      })

      formData.append('_method', 'PUT')

      updatePackage({ id, data: formData })
        .unwrap()
        .then(() => {
          toast.success(t('edit.success'))
          navigate('/packages')
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('packages:edit.title')}
        </span>
        <Card>
          <Form
            data={data}
            onSubmit={handleSubmit}
          />
        </Card>
        <PackageCategoryList packageId={id} />
      </div>
    )
  }
