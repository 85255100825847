import React, { useMemo } from 'react'
import { useAppSelector } from '@/app/hooks'
import { getIsAuthenticated } from '@/features/auth/redux/authSlice'
import { AppLayout } from '@/features/components/layout/app/layout'
import {
  matchRoutes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom'
import { PrivateRouterPropsInterface } from '@/utils/routes/privateRoutes/types'

export const PrivateRoutes: React.FC<
  PrivateRouterPropsInterface
> = ({ routes }): React.ReactNode => {
  const isAuthenticated = useAppSelector(
    getIsAuthenticated
  )
  const { permissions } = useAppSelector(
    (state) => state.auth
  )
  const { pathname } = useLocation()
  const match = matchRoutes(routes, pathname)
  const isAllowed = useMemo(() => {
    if (!match) return false

    const { route } = match[0]

    return (
      route.permissions.length === 0 ||
      permissions.some((p) =>
        route.permissions.includes(p)
      )
    )
  }, [match, permissions])

  if (!isAuthenticated)
    return <Navigate to='/login' />

  return isAuthenticated && isAllowed ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to='/' />
  )
}
