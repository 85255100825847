import {
  FiltersTypes,
  FilterValueType,
  SetFilterValue,
} from './Filters.types'
import React, {
  FC,
  useCallback,
  useRef,
  useState,
} from 'react'
import _, { isEmpty } from 'lodash'
import { match, P, Pattern } from 'ts-pattern'
import { Input } from '@/features/components/inputs/input'
import { Select2OptionInterface } from '@/app/types'
import {
  isSelect2OptionInterface,
  isSelect2OptionInterfaceArray,
} from '@/utils/helpers/matchers'
import Select, { MultiValue } from 'react-select'
import Card from '@/features/components/cards'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Label } from '@/components'

const Filters: FC<FiltersTypes> = ({
  filterBuilder,
  onSubmit,
  containerProps,
  filtersProps,
  children,
}): React.ReactNode => {
  const { t } = useTranslation(['form'])
  const [filters, setFilters] =
    useState<FilterValueType>({})

  const handleSetFilters = (
    key: string,
    value: SetFilterValue
  ) => {
    if (
      (typeof value === 'string' &&
        isEmpty(value)) ||
      _.isNil(value)
    ) {
      const _filters = filters
      delete _filters[key]
      setFilters(_filters)
      debounceRequest(_filters)
      return
    } else {
      const resolveValue = match(value)
        .with(P.number, (value) => value)
        .with(P.string, (value) => value)
        .with(
          Pattern.when(
            (
              value
            ): value is Select2OptionInterface =>
              isSelect2OptionInterface(value)
          ),
          (value) => value.value
        )
        .with(
          Pattern.when(
            (
              value
            ): value is MultiValue<Select2OptionInterface> =>
              isSelect2OptionInterfaceArray(value)
          ),
          (value) =>
            value.map((v) => v.value).join(',')
        )
        .with(P.boolean, (value) =>
          value ? 1 : 0
        )
        .exhaustive()

      const _filters = {
        ...filters,
        [key]: resolveValue,
      }

      setFilters(_filters)
      debounceRequest(_filters)
    }
  }

  const debounceSubmit = _.debounce(
    (values: typeof filters) => onSubmit(values),
    2000
  )

  const debounceRequest = useCallback(
    (values: typeof filters) =>
      debounceSubmit(values),
    []
  )

  return (
    <Card>
      <div
        className={'flex flex-col'}
        {...containerProps}
      >
        <div
          className={'grid lg:grid-cols-5 gap-4'}
          {...filtersProps}
        >
          {filterBuilder
            .getFilters()
            .map((filter, index) => {
              const placeholder = filter.props
                ?.placeholder
                ? t(filter.props.placeholder)
                : ''
              const label = filter.props?.label
                ? t(filter.props.label)
                : ''

              return match(filter.component)
                .with('input', () => (
                  <Input
                    key={index}
                    placeholder={placeholder}
                    label={label}
                    type={'search'}
                    onChange={(e) =>
                      handleSetFilters(
                        filter.field,
                        e.currentTarget.value
                      )
                    }
                  />
                ))
                .with('number', () => {
                  const [value, setValue] =
                    useState<string>('')

                  return (
                    <Input
                      key={index}
                      value={value}
                      type={'number'}
                      placeholder={placeholder}
                      label={label}
                      endAdornment={
                        value.length ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => {
                              setValue('')
                              handleSetFilters(
                                filter.field,
                                ''
                              )
                            }}
                          />
                        ) : (
                          ''
                        )
                      }
                      onChange={(e) => {
                        setValue(
                          e.currentTarget.value
                        )
                        handleSetFilters(
                          filter.field,
                          e.currentTarget.value
                        )
                      }}
                    />
                  )
                })
                .with('date', () => {
                  const [value, setValue] =
                    useState<string>('')
                  const ref =
                    useRef<HTMLInputElement>(null)

                  return (
                    <Input
                      value={value}
                      inputRef={ref}
                      key={index}
                      type={'date'}
                      placeholder={placeholder}
                      onFocus={() =>
                        ref.current?.showPicker()
                      }
                      label={label}
                      endAdornment={
                        value.length ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => {
                              setValue('')
                              handleSetFilters(
                                filter.field,
                                ''
                              )
                            }}
                          />
                        ) : (
                          ''
                        )
                      }
                      onChange={(e) => {
                        setValue(
                          e.currentTarget.value
                        )
                        handleSetFilters(
                          filter.field,
                          e.currentTarget.value
                        )
                      }}
                    />
                  )
                })
                .with('select', () => (
                  <div
                    key={index}
                    className={'flex flex-col'}
                  >
                    <Label
                      className={
                        'mt-0 font-semibold'
                      }
                      label={label}
                    />
                    <Select
                      isClearable
                      options={filter.optionsProps?.options.map(
                        (option) => ({
                          value: option.value,
                          label: filter
                            .optionsProps
                            ?.translate
                            ? t(option.label)
                            : option.label,
                        })
                      )}
                      placeholder={placeholder}
                      onChange={(value) =>
                        handleSetFilters(
                          filter.field,
                          value ? value.value : ''
                        )
                      }
                      classNames={{
                        control: () =>
                          '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent !min-h-[40px]',
                        menuList: () =>
                          '!max-h-[200px]',
                      }}
                    />
                  </div>
                ))
                .with('custom', () => {
                  const CustomComponent =
                    filter.customComponent

                  if (!CustomComponent)
                    throw new Error(
                      'Custom component is not provided'
                    )

                  return (
                    <CustomComponent
                      key={index}
                      label={label}
                      placeholder={placeholder}
                      onChange={(value) =>
                        handleSetFilters(
                          filter.field,
                          value
                        )
                      }
                    />
                  )
                })
                .otherwise(() => null)
            })}
        </div>
        {children}
      </div>
    </Card>
  )
}

export { Filters }
