import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { GeneralInfoValidationInterface } from '@/features/ads/ads/redux'
import Carbon from '@/utils/carbon'
import { isNil } from 'lodash'

class GeneralInfoValidation
  implements
    ValidationInterface<GeneralInfoValidationInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    GeneralInfoValidationInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      advertiser_id: yup
        .number()
        .required(t('validation:required'))
        .notOneOf([0], t('validation:required')),
      started_at: yup
        .string()
        .required(t('validation:required'))
        .test(
          'is-greater-than',
          function (value) {
            const { ended_at } = this.parent

            if (
              isNil(ended_at) ||
              isNil(value) ||
              !value.length ||
              !ended_at.length
            ) {
              return true
            }

            return new Date(value) <
              new Date(ended_at)
              ? true
              : this.createError({
                  message: t(
                    'validation:field_must_be_less_than',
                    {
                      max: new Carbon()
                        .parse(ended_at)
                        .format(
                          'dd.MM.yyyy HH:mm'
                        ),
                    }
                  ),
                  path: 'started_at',
                })
          }
        )
        .transform((value) =>
          value.length
            ? new Carbon()
                .parse(value)
                .toISOString()
            : value
        ),
      ended_at: yup
        .string()
        .required(t('validation:required'))
        .test('is-less-than', function (value) {
          const { started_at } = this.parent

          if (
            isNil(started_at) ||
            isNil(value) ||
            !started_at.length ||
            !value.length
          ) {
            return true
          }

          return new Date(value) >
            new Date(started_at)
            ? true
            : this.createError({
                message: t(
                  'validation:field_must_be_greater_than',
                  {
                    min: new Carbon()
                      .parse(started_at)
                      .format('dd.MM.yyyy HH:mm'),
                  }
                ),
                path: 'ended_at',
              })
        })
        .transform((value: string) =>
          value.length
            ? new Carbon()
                .parse(value)
                .toISOString()
            : value
        ),
      name: yup
        .string()
        .required(t('validation:required')),
      is_anonymous_only: yup.boolean().required(),
      desktop_click_url: yup
        .string()
        .required(t('validation:required')),
      mobile_click_url: yup.string().nullable(),
    })
  }

  defaultValues(): GeneralInfoValidationInterface {
    return {
      advertiser_id: 0,
      started_at: '',
      ended_at: '',
      name: '',
      is_anonymous_only: false,
      desktop_click_url: '',
      mobile_click_url: '',
    }
  }
}

export { GeneralInfoValidation }
