import {
  AdressesInterface,
  SaveAdressRequestInterface,
} from '@/features/users/redux'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import React, { useEffect } from 'react'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import {
  Controller,
  FormProvider,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useValidation from '@/utils/hooks/useValidation'
import { useTranslation } from 'react-i18next'
import SaveAdressValidation from '../rules/SaveAdressValidation'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/components'
import { Button } from '@/features/components/buttons/button'
import { LocationForm } from './LocationForm'
import { Checkbox } from '@/features/components/inputs/checkbox'

type Props = {
  data?: AdressesInterface
  title: string
} & InstanceProps<
  SaveAdressRequestInterface,
  unknown
>
const Form: React.FC<Props> = ({
  onResolve,
  onReject,
  data,
  isOpen,
  title,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveAdressValidation(),
    t
  )
  const methods =
    useForm<SaveAdressRequestInterface>({
      resolver: yupResolver(schema),
      defaultValues,
    })

  useEffect(() => {
    if (data) {
      const { setValue } = methods
      setValue('name', data.name)
      setValue('is_default', data.is_default)
      setValue('city', data.city)
      setValue('country', data.country)
      setValue('house_number', data.house_number)
      setValue('local_number', data.local_number)
      setValue('location', data.location)
      setValue('postal_code', data.postal_code)
      setValue('state', data.state)
      setValue('street', data.street)
    }
  }, [data])

  return (
    <Modal open={isOpen} onClose={() => onReject}>
      <Modal.Content size={'w-[800px]'}>
        <div className={'flex flex-col gap-y-6'}>
          <ModalTitle
            title={title}
            onClose={onReject}
          />
          <FormProvider {...methods}>
            <form
              className={'flex flex-col gap-4'}
              onSubmit={methods.handleSubmit(
                onResolve
              )}
            >
              <div
                className={
                  'grid xl:grid-cols-3 gap-4 items-center'
                }
              >
                <Controller
                  control={methods.control}
                  render={({
                    field,
                    fieldState: { error },
                  }) => (
                    <FormControl
                      {...field}
                      error={!!error}
                    >
                      <Input
                        label={t(
                          'form:labels.name'
                        )}
                      />
                      <FormHelperText
                        message={error?.message}
                      />
                    </FormControl>
                  )}
                  name={'name'}
                />
                <Controller
                  control={methods.control}
                  render={({
                    field,
                    fieldState: { error },
                  }) => (
                    <FormControl
                      {...field}
                      error={!!error}
                    >
                      <Input
                        label={t(
                          'form:labels.country'
                        )}
                      />
                      <FormHelperText
                        message={error?.message}
                      />
                    </FormControl>
                  )}
                  name={'country'}
                />
                <Controller
                  control={methods.control}
                  render={({
                    field,
                    fieldState: { error },
                  }) => (
                    <FormControl
                      {...field}
                      error={!!error}
                    >
                      <Input
                        label={t(
                          'form:labels.state'
                        )}
                      />
                      <FormHelperText
                        message={error?.message}
                      />
                    </FormControl>
                  )}
                  name={'state'}
                />
                <Controller
                  control={methods.control}
                  render={({
                    field,
                    fieldState: { error },
                  }) => (
                    <FormControl
                      {...field}
                      error={!!error}
                    >
                      <Input
                        label={t(
                          'form:labels.postal_code'
                        )}
                      />
                      <FormHelperText
                        message={error?.message}
                      />
                    </FormControl>
                  )}
                  name={'postal_code'}
                />
                <Controller
                  control={methods.control}
                  render={({
                    field,
                    fieldState: { error },
                  }) => (
                    <FormControl
                      {...field}
                      error={!!error}
                    >
                      <Input
                        label={t(
                          'form:labels.street'
                        )}
                      />
                      <FormHelperText
                        message={error?.message}
                      />
                    </FormControl>
                  )}
                  name={'street'}
                />
                <Controller
                  control={methods.control}
                  render={({
                    field,
                    fieldState: { error },
                  }) => (
                    <FormControl
                      {...field}
                      error={!!error}
                    >
                      <Input
                        label={t(
                          'form:labels.house_number'
                        )}
                      />
                      <FormHelperText
                        message={error?.message}
                      />
                    </FormControl>
                  )}
                  name={'house_number'}
                />

                <Controller
                  control={methods.control}
                  render={({
                    field,
                    fieldState: { error },
                  }) => (
                    <FormControl
                      {...field}
                      error={!!error}
                    >
                      <Input
                        type={'number'}
                        label={t(
                          'form:labels.local_number'
                        )}
                      />
                      <FormHelperText
                        message={error?.message}
                      />
                    </FormControl>
                  )}
                  name={'local_number'}
                />
              </div>
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                  >
                    <Checkbox
                      onChange={field.onChange}
                      checked={field.value}
                      label={t(
                        'form:labels.is_default'
                      )}
                    />
                  </FormControl>
                )}
                name={'is_default'}
                control={methods.control}
              />
              <LocationForm />

              <div
                className={'flex gap-x-4 pt-4'}
              >
                <Button
                  variant={'contained'}
                  type={'submit'}
                  className={'w-full'}
                >
                  {t('form:buttons.save')}
                </Button>
                <Button
                  variant={'outlined'}
                  onClick={() => onReject()}
                  className={'w-full'}
                >
                  {t('form:buttons.cancel')}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const AdressModal = create(Form)
