import { useTranslation } from 'react-i18next'
import React, {
  FC,
  useEffect,
  useMemo,
} from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { PermissionEnum } from '@/features/permissions/redux/types'
import {
  SettingInterface,
  useGetSettingsQuery,
} from '@/features/settings'
import { match } from 'ts-pattern'
import { editSettingModal } from '@/features/settings/resources/views/edit'

const Table: FC<VisibleColumnsInterface> = ({
  visibility,
  setAvailableColumns,
}) => {
  const { t } = useTranslation([
    'form',
    'utils',
    'settings',
  ])
  const { data = [] } = useGetSettingsQuery()

  const handleEdit = async (id: number) => {
    const settingData = data.find(
      (setting) => setting.id === id
    )

    if (!settingData) return

    try {
      await editSettingModal({
        data: settingData,
      })
    } catch (error) {}
  }

  const table = useReactTable({
    columns: useMemo(
      () => columns(t, handleEdit),
      [t, columns, data]
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableHiding: true,
    manualPagination: true,
    state: {
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<SettingInterface>()

const columns = (
  t: TFunction,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('key', {
    id: 'name',
    header: t('form:labels.name'),
    cell: ({ row }) =>
      t(
        `utils:settings.keys.${row.original.key}`
      ),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('data_type', {
    id: 'slug',
    header: t('form:labels.data_type'),
    cell: ({ row }) =>
      t(
        `utils:settings.data_types.${row.original.data_type}`
      ),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('value', {
    id: 'value',
    header: t('form:labels.value'),
    cell: ({ row }) =>
      match(row.original.data_type)
        .with('bool', () =>
          row.original.value
            ? t('form:labels.yes')
            : t('form:labels.no')
        )
        .otherwise(() => row.original.value),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.SETTING_SAVE,
          },
        })}
      />
    ),
  }),
]

export { Table }
