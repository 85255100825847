import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StoreUserRequestInterface,
  useStoreUserMutation,
} from '@/features/users/redux'
import { Form } from './partials'
import { isFetchBaseQueryError } from '@/app/hooks'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const UserCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(['users'])
  const [storeUser] = useStoreUserMutation()
  const [errors, setErrors] =
    useState<
      BackendValidationErrorInterface<StoreUserRequestInterface>
    >()
  const navigateTo = useNavigate()

  const handleSubmit = async (
    data: StoreUserRequestInterface
  ) => {
    try {
      await storeUser(data).unwrap()
      toast.success(t('users:create.success'))
      navigateTo('/users')
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 422) {
          setErrors(
            error.data as BackendValidationErrorInterface<StoreUserRequestInterface>
          )
        }
      }
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('users:create.title')}
      </span>
      <Form
        errors={errors}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export { UserCreateScreen }
