import {
  QuotationResultDetailsInterface,
  SaveQuotationResultDetailsRequestInterface,
} from '@/features/internalQuotationsResultsDetails/redux/types'

export const toRequestObject = (
  row: QuotationResultDetailsInterface
): SaveQuotationResultDetailsRequestInterface => ({
  price: row.final_price,
})
