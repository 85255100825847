import igritAPI from '@/utils/api/igrit'
import {
  GetCategoriesResponseInterface,
  GetCategoryResponseInterface,
  GetTreeCategoryResponseInterface,
  SearchCategoriesResponseInterface,
} from '@/features/posts/categories/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

const categoryAPI = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<
      GetCategoriesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/posts/categories?${query}`,
      transformResponse: (
        response: GetCategoriesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Category', result),
    }),
    getCategory: build.query<
      GetCategoryResponseInterface['data'],
      string | number
    >({
      query: (id) =>
        `/admin/posts/categories/${id}`,
      transformResponse: (
        response: GetCategoryResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('Category', result),
    }),
    searchCategories: build.query<
      SearchCategoriesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/posts/categories/search?${query}`,
      transformResponse: (
        response: SearchCategoriesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) =>
                  ({
                    type: 'Category',
                    id,
                  } as const)
              ),
              { type: 'Category', id: 'SEARCH' },
            ]
          : [{ type: 'Category', id: 'SEARCH' }],
    }),
    deleteCategory: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/posts/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        {
          type: 'Category',
          id: 'LIST',
        },
      ],
    }),
    storeCategory: build.mutation<void, FormData>(
      {
        query: (body) => ({
          url: '/admin/posts/categories',
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        invalidatesTags: [
          {
            type: 'Category',
            id: 'LIST',
          },
          {
            type: 'Category',
            id: 'SEARCH',
          },
        ],
      }
    ),
    updateCategory: build.mutation<
      void,
      { id: string | number; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/admin/posts/categories/${id}`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: (
        result,
        error,
        { id }
      ) => [
        { type: 'Category', id },
        {
          type: 'Category',
          id: 'LIST',
        },
        {
          type: 'Category',
          id: 'SEARCH',
        },
      ],
    }),
    getCategoryTree: build.query<
      GetTreeCategoryResponseInterface['data'],
      void
    >({
      query: () => '/admin/posts/categories/tree',
      transformResponse: (
        response: GetTreeCategoryResponseInterface
      ) => response.data,
      providesTags: ['CategoryTree'],
    }),
  }),
})

export const {
  useLazySearchCategoriesQuery,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useStoreCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryQuery,
  useLazyGetCategoryQuery,
  useGetCategoryTreeQuery,
} = categoryAPI
