import React from 'react'
import 'ckeditor5-custom-build/build/ckeditor'
import { UploadAdapter } from '@/app/ckeditor/plugins/uploadAdapter'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { GalleryEnum } from '@/app/enums/galleryEnum'
import BaseEditor from 'ckeditor5-custom-build/build/ckeditor'

type Props = {
  onChange: (value: string) => void
  data: string
  galleryType: GalleryEnum
}

export const Editor: React.FC<Props> = ({
  onChange,
  data,
  galleryType,
}): React.ReactNode => {
  return (
    <CKEditor
      data={data}
      // @ts-ignore
      editor={BaseEditor}
      onReady={(editor) => {
        editor.plugins.get(
          'FileRepository'
          // @ts-ignore
        ).createUploadAdapter = (loader) => {
          return new UploadAdapter(
            loader,
            galleryType
          )
        }
      }}
      onChange={(event, value) =>
        // @ts-ignore
        onChange(value.getData())
      }
    />
  )
}
