import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { UpdateAdsValidationInterface } from '@/features/ads/ads/redux'
import {
  BaseAdsValidation,
  GeneralInfoValidation,
} from '@/features/ads/ads/resources/rules'

class UpdateAdsValidation
  implements
    ValidationInterface<UpdateAdsValidationInterface>
{
  private baseValidation = new BaseAdsValidation()
  private generalInfoValidation =
    new GeneralInfoValidation()

  rules(
    t: TFunction
  ): yup.ObjectSchema<
    UpdateAdsValidationInterface,
    yup.AnyObject
  > {
    return yup
      .object()
      .shape({
        desktop_image: yup
          .mixed<File>()
          .nullable()
          .test(
            'file-size',
            t('validation:max_file_size', {
              size: '1024 KB',
            }),
            (value) => {
              if (value) {
                return value.size <= 1024 * 1024
              }

              return true
            }
          ),
        mobile_image: yup
          .mixed<File>()
          .nullable()
          .test(
            'file-size',
            t('validation:max_file_size', {
              size: '1024 KB',
            }),
            (value) => {
              if (value) {
                return value.size <= 1024 * 1024
              }

              return true
            }
          ),
      })
      .concat(this.baseValidation.rules(t))
      .concat(this.generalInfoValidation.rules(t))
  }

  defaultValues(): UpdateAdsValidationInterface {
    return {
      ...this.baseValidation.defaultValues(),
      ...this.generalInfoValidation.defaultValues(),
    }
  }
}

export { UpdateAdsValidation }
