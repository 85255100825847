import igritAPI from '@/utils/api/igrit'
import {
  GetFAQCategoriesResponseInterface,
  GetFAQCategoryResponseInterface,
} from './types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetFAQCategoriesQuery,
  useDeleteFAQCategoryMutation,
  useStoreFAQCategoryMutation,
  useUpdateFAQCategoryMutation,
  useGetFAQCategoryQuery,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getFAQCategories: build.query<
      GetFAQCategoriesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/faq-categories?${query}`,
      transformResponse: (
        response: GetFAQCategoriesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('FAQ_CATEGORY', result),
    }),
    getFAQCategory: build.query<
      GetFAQCategoryResponseInterface['data'],
      string | number
    >({
      query: (id) =>
        `/admin/faq-categories/${id}`,
      transformResponse: (
        response: GetFAQCategoryResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'FAQ_CATEGORY',
          result
        ),
    }),
    storeFAQCategory: build.mutation<
      void,
      FormData
    >({
      query: (body) => ({
        url: '/admin/faq-categories',
        body,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: [
        { type: 'FAQ_CATEGORY', id: 'LIST' },
      ],
    }),
    updateFAQCategory: build.mutation<
      void,
      { id: number | string; body: FormData }
    >({
      query: ({ body, id }) => ({
        url: `/admin/faq-categories/${id}`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: 'FAQ_CATEGORY', id: 'LIST' },
        { type: 'FAQ_CATEGORY', id },
      ],
    }),
    deleteFAQCategory: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        method: 'DELETE',
        url: `/admin/faq-categories/${id}`,
      }),
      invalidatesTags: [
        { type: 'FAQ_CATEGORY', id: 'LIST' },
      ],
    }),
  }),
})
