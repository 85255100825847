import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { PaginationState } from '@/utils/hooks/usePagination/types'
import Select, {
  Option,
} from '@/features/components/inputs/select'

const availableRecords = [15, 30, 50, 100]

const RecordDropdown: FC<{
  setPagination: Dispatch<
    SetStateAction<PaginationState>
  >
}> = ({ setPagination }) => {
  const [size, setSize] = useState(15)

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageSize: size,
    }))
  }, [size])

  return (
    <div className={'flex justify-end'}>
      <div className={'w-[80px] mx-4 py-2'}>
        <Select
          value={size}
          onChange={(_, value) => {
            setSize(value as number)
          }}
          buttonClassNames={
            '!min-w-fit !py-1 !px-4'
          }
        >
          {availableRecords.map((value, key) => (
            <Option value={value} key={key}>
              {value}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export { RecordDropdown }
