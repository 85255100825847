import {
  FC,
  ReactNode,
  useEffect,
  useMemo,
} from 'react'
import {
  FormInterface,
  Select2OptionInterface,
} from '@/app/types'
import {
  explodeValue,
  StoreMassAttributeCategoryValidationInterface,
  useLazyCheckAttributeCategoryRelationQuery,
} from '@/features/posts/attributeCategories/redux'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import { StoreMassAttributeCategoryValidation } from '../../rules'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CategoryTree,
  CreatableSelect,
  FormHelperText,
  Label,
  SearchAttribute,
} from '@/components'
import Card from '@/features/components/cards'
import { FormControl } from '@mui/base'
import { SingleValue } from 'react-select'
import { AttributeTypeEnum } from '@/features/posts/attributes/redux/enums/attributeType'
import { useSearchAttributeQuery } from '@/features/posts/attributes/redux/attributeAPI'
import { Query } from '@/utils/query'
import { match, Pattern } from 'ts-pattern'
import { useLazySearchCategoriesQuery } from '@/features/posts/categories/redux/categoryAPI'
import { toast } from 'react-toastify'
import { Button } from '@/features/components/buttons/button'

const ns = 'posts/attribute_categories'

const Form: FC<
  FormInterface<StoreMassAttributeCategoryValidationInterface>
> = ({ onSubmit }): ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    ns,
  ])
  const { schema, defaultValues } = useValidation(
    new StoreMassAttributeCategoryValidation(),
    t
  )
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<StoreMassAttributeCategoryValidationInterface>(
    {
      defaultValues,
      resolver: yupResolver(schema),
    }
  )
  const { data: attributes = [] } =
    useSearchAttributeQuery(new Query().url())
  const watchValues = watch('values')
  const watchType = watch('attribute_type')
  const [getCategories] =
    useLazySearchCategoriesQuery()
  const selectedCategoryIds = useMemo(
    () => watchValues.map((v) => v.category_id),
    [watchValues]
  )
  const watchAttributeId = watch('attribute_id')
  const [checkRelation] =
    useLazyCheckAttributeCategoryRelationQuery()

  useEffect(() => {
    if (
      watchValues.length &&
      watchAttributeId !== 0
    ) {
      checkRelation({
        attribute_id: watchAttributeId,
        category_ids: selectedCategoryIds,
      })
        .unwrap()
        .then((response) => {
          const existingRelations =
            response.filter((r) => r.exists)

          if (existingRelations.length) {
            const categoryNames = watchValues
              .filter((v) =>
                existingRelations.some(
                  (r) =>
                    r.category_id ===
                    v.category_id
                )
              )
              .map((v) => v.category_name)

            toast.error(
              t(
                `${ns}:mass_create.errors.existing_relations`,
                {
                  categoryNames:
                    categoryNames.join(', '),
                }
              )
            )
          }
        })
    }
  }, [watchAttributeId, selectedCategoryIds])

  const handleChangeCategory = async (
    selectedIds: number[]
  ) => {
    if (selectedIds.length === 0) return

    try {
      const categories = await getCategories(
        new Query()
          .where('id', selectedIds.join(','))
          .url()
      ).unwrap()

      setValue(
        'values',
        categories.map((category) => ({
          category_id: category.id,
          category_name: category.name,
          value: [],
        }))
      )
    } catch (error) {
      // error
    }
  }

  const handleChangeAttribute = (
    option: SingleValue<Select2OptionInterface>
  ) => {
    if (!option) return

    const attribute = attributes.find(
      (attribute) =>
        attribute.id === Number(option.value)
    )

    if (attribute) {
      setValue('values', [])
      setValue('attribute_type', attribute.type)
      setValue('attribute_id', attribute.id)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={'flex flex-col gap-y-6'}
    >
      <div className={'flex'}>
        <Button
          variant={'contained'}
          type={'submit'}
        >
          {t('form:buttons.save')}
        </Button>
      </div>
      <div
        className={'grid lg:grid-cols-3 gap-8'}
      >
        <div
          className={
            'lg:col-span-2 flex flex-col gap-y-2'
          }
        >
          <Card>
            {match(watchType)
              .with(
                Pattern.union(
                  AttributeTypeEnum.SELECT,
                  AttributeTypeEnum.MULTI_SELECT
                ),
                () => (
                  <div
                    className={
                      'flex flex-col gap-y-6'
                    }
                  >
                    {watchValues.map(
                      (value, index) => (
                        <Controller
                          key={index}
                          render={({
                            field,
                            fieldState,
                          }) => (
                            <FormControl
                              className={
                                'flex flex-col'
                              }
                              error={
                                !!fieldState.error
                              }
                            >
                              <Label
                                label={`${t(
                                  'form:labels.values'
                                )} - ${
                                  value.category_name
                                }`}
                              />
                              <CreatableSelect
                                value={field.value?.map(
                                  (v) => ({
                                    label: v,
                                    value: v,
                                  })
                                )}
                                isMulti={true}
                                onChange={(
                                  value
                                ) =>
                                  field.onChange(
                                    explodeValue(
                                      value
                                    )
                                  )
                                }
                              />
                              <FormHelperText
                                message={
                                  fieldState.error
                                    ?.message
                                }
                              />
                            </FormControl>
                          )}
                          control={control}
                          name={`values.${index}.value`}
                        />
                      )
                    )}
                  </div>
                )
              )
              .with(
                AttributeTypeEnum.CERTIFICATE,
                () => (
                  <Controller
                    render={({
                      field,
                      fieldState: { error },
                    }) => (
                      <FormControl
                        className={
                          'flex flex-col'
                        }
                        error={!!error}
                      >
                        <Label
                          label={t(
                            'form:labels.image'
                          )}
                        />
                        <input
                          type={'file'}
                          onChange={(e) =>
                            e.currentTarget.files
                              ? field.onChange(
                                  e.currentTarget
                                    .files[0]
                                )
                              : null
                          }
                        />
                      </FormControl>
                    )}
                    name={'image'}
                    control={control}
                  />
                )
              )
              .otherwise(() => null)}
          </Card>
          <div className={'flex'}>
            <Button
              variant={'contained'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
        </div>
        <Card>
          <div
            className={'flex flex-col gap-y-6'}
          >
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  className={'flex flex-col'}
                  error={!!error}
                >
                  <Label
                    label={t(
                      'form:labels.attribute'
                    )}
                  />
                  <SearchAttribute
                    onChange={
                      handleChangeAttribute
                    }
                    isMulti={false}
                    defaultValue={field.value}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'attribute_id'}
              control={control}
            />
            <CategoryTree
              selectedIds={selectedCategoryIds}
              onChange={handleChangeCategory}
            />
          </div>
        </Card>
      </div>
    </form>
  )
}

export { Form }
