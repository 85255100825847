import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useGetFAQCategoryQuestionQuery,
  useUpdateFAQCategoryQuestionMutation,
} from '@/features/faq/questions/redux/faqCategoryQuestionAPI'
import { SaveFAQCategoryQuestionRequestInterface } from '@/features/faq/questions/redux/types'
import { Form } from '@/features/faq/questions/resources/_components/form'

export const FAQCategoryQuestionsEditScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'faq/questions',
    ])
    const { category_id, id } = useParams<{
      category_id: string
      id: string
    }>() as {
      category_id: string
      id: string
    }
    const [updateQuestion] =
      useUpdateFAQCategoryQuestionMutation()
    const { data } =
      useGetFAQCategoryQuestionQuery(id)
    const navigate = useNavigate()

    const handleSubmit = (
      data: SaveFAQCategoryQuestionRequestInterface
    ) => {
      updateQuestion({ id, body: data })
        .unwrap()
        .then(() => {
          toast.success(
            t('faq/questions:edit.success')
          )
          navigate(
            `/faq/categories/${category_id}`
          )
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('faq/questions:edit.title')}
        </span>
        <Card>
          <Form
            data={data}
            params={{ category_id }}
            onSubmit={handleSubmit}
          />
        </Card>
      </div>
    )
  }
