import React, { useEffect } from 'react'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import { toRequestObject } from './helper'
import { match } from 'ts-pattern'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { usePermissions } from '@/utils/hooks/usePermission'
import {
  PostSearchInterface,
  UpdatePostDataRequest,
} from '@/features/posts/posts/redux/types'
import { useUpdatePostMutation } from '@/features/posts/posts/redux/postAPI'
import { SearchCategory } from '@/features/components/inputs/asyncSelect/searchCategory'
import { SingleValue } from 'react-select'
import { Select2OptionInterface } from '@/app/types'
import { confirmModal } from '@/features/components/modals/confirm'
import { UpdatePostValidation } from '@/features/posts/posts/resources/views/edit/_components'

type Props = {
  body: PostSearchInterface
  t: TFunction
  fieldName: keyof UpdatePostDataRequest
  postId: string | number
  permission: PermissionEnum
  noPermissionValue: string
}

const Form: React.FC<Props> = ({
  body,
  t,
  fieldName,
  postId,
  permission,
  noPermissionValue,
}): React.ReactNode => {
  const { schema, defaultValues } = useValidation(
    new UpdatePostValidation(),
    t
  )
  const { hasPermission } = usePermissions()
  const [updatePost, { isLoading }] =
    useUpdatePostMutation()

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UpdatePostDataRequest>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const _handleExecutionChange = async (
    data: UpdatePostDataRequest
  ) => {
    try {
      await updatePost({
        id: postId,
        body: {
          ...data,
        },
      }).unwrap()
      toast.success(t('posts/posts:edit.success'))
    } catch (error) {
      //empty block
    }
  }
  const handleExecutionChange = _.debounce(
    _handleExecutionChange,
    1500
  )

  useEffect(() => {
    reset(toRequestObject(body))
  }, [body])

  useEffect(() => {
    const subscription = watch(() => {
      if (Object.keys(errors).length) {
        return
      }

      handleSubmit(handleExecutionChange)()
    })
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  const handleCategoryChange = (
    value: SingleValue<Select2OptionInterface>
  ): void => {
    if (value) {
      confirmModal({
        message: t(
          'posts/posts:edit.basic.category_change.description'
        ),
        type: 'warning',
      }).then((confirmed) => {
        if (confirmed) {
          setValue(
            'category_id',
            parseInt(value.value)
          )
          setValue('attributes', [])
        }
      })
    }
  }

  if (
    permission === undefined ||
    hasPermission(permission)
  )
    return (
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            {match(fieldName)
              .with('price', () => (
                <div className={'w-20'}>
                  <Input
                    type={'number'}
                    endAdornment={
                      isLoading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                        />
                      )
                    }
                  />
                </div>
              ))
              .with('title', () => (
                <div className={'w-24'}>
                  <Input
                    multiline={true}
                    hideContent={true}
                    rows={2}
                    endAdornment={
                      isLoading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                        />
                      )
                    }
                  />
                </div>
              ))
              .otherwise(() => (
                <div className={'w-56 lg:w-64'}>
                  <SearchCategory
                    onChange={
                      handleCategoryChange
                    }
                    isMulti={false}
                    defaultValue={{
                      label: body.category.name,
                      value:
                        body.category.id.toString(),
                    }}
                  />
                </div>
              ))}
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={fieldName}
        control={control}
      />
    )

  return <span>{`${noPermissionValue}`}</span>
}

export { Form }
