import React, { useEffect, useState } from 'react'
import {
  AdvertiserInterface,
  SaveAdvertiserRequestInterface,
} from '@/features/ads/advertisers/redux/types'
import { useTranslation } from 'react-i18next'
import { SaveAdvertiserValidation } from './rules'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Label } from '@/features/components/inputs/label'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import Modal from '@/features/components/modals/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@/features/components/buttons/button'
import { Select2OptionInterface } from '@/app/types'
import { SearchUser } from '@/features/components/inputs/asyncSelect/searchUser'
import { SingleValue } from 'react-select'

type Props = {
  title: string
  data?: AdvertiserInterface
} & InstanceProps<SaveAdvertiserRequestInterface>

const Form: React.FC<Props> = ({
  data,
  title,
  isOpen,
  onResolve,
  onReject,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveAdvertiserValidation(),
    t
  )
  const { control, handleSubmit, setValue } =
    useForm<SaveAdvertiserRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })
  const [selectedUser, setSelectedUser] =
    useState<Select2OptionInterface | null>(null)
  const [selectedManager, setSelectedManager] =
    useState<Select2OptionInterface | null>(null)

  useEffect(() => {
    if (data) {
      setValue('user_id', data.user_id)
      setValue('manager_id', data.manager_id)
      setValue('nip', data.nip)
      setValue('company_name', data.company_name)
      setValue(
        'company_address',
        data.company_address
      )
      setValue(
        'contact_person',
        data.contact_person
      )
      setValue(
        'contact_phone',
        data.contact_phone
      )
      setValue(
        'contact_email',
        data.contact_email
      )

      if (data.user) {
        setSelectedUser({
          label: data.user.name,
          value: data.user.id.toString(),
        })
      }

      if (data.manager) {
        setSelectedManager({
          label: data.manager.name,
          value: data.manager.id.toString(),
        })
      }
    }
  }, [data])

  const handleChangeUser = (
    value: SingleValue<Select2OptionInterface>
  ) => {
    setSelectedUser(value)
    setValue(
      'user_id',
      value ? Number(value.value) : null
    )
  }

  const handleChangeManager = (
    value: SingleValue<Select2OptionInterface>
  ) => {
    setSelectedManager(value)
    setValue(
      'manager_id',
      value ? Number(value.value) : null
    )
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content size={'lg:w-[800px]'}>
        <div
          className={
            'flex w-full items-center justify-between'
          }
        >
          <span
            className={'text-lg font-semibold'}
          >
            {title}
          </span>
          <FontAwesomeIcon
            onClick={() => onReject()}
            icon={faTimes}
            className={'cursor-pointer'}
          />
        </div>
        <form
          onSubmit={handleSubmit(onResolve)}
          className={'flex flex-col gap-4 pt-4'}
        >
          <Controller
            render={() => (
              <div className={'flex flex-col'}>
                <Label
                  label={t('form:labels.user')}
                />
                <SearchUser
                  onChange={handleChangeUser}
                  defaultValue={selectedUser}
                  isMulti={false}
                />
              </div>
            )}
            name={'user_id'}
            control={control}
          />
          <Controller
            render={() => (
              <div className={'flex flex-col'}>
                <Label
                  label={t('form:labels.manager')}
                />
                <SearchUser
                  onChange={handleChangeManager}
                  defaultValue={selectedManager}
                  isMulti={false}
                />
              </div>
            )}
            name={'manager_id'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  type={'number'}
                  label={t('form:labels.nip')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'nip'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.company_name'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'company_name'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.company_address'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'company_address'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.contact_person'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'contact_person'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.contact_phone'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'contact_phone'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.contact_email'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'contact_email'}
            control={control}
          />
          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const advertiserFormModal = create(Form)
