import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveAdvertiserRequestInterface } from '@/features/ads/advertisers/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'

class SaveAdvertiserValidation
  implements
    ValidationInterface<SaveAdvertiserRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveAdvertiserRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      user_id: yup.number().nullable(),
      manager_id: yup.number().nullable(),
      nip: yup
        .string()
        .nullable()
        .length(
          10,
          t('validation:equal', { length: 10 })
        ),
      company_name: yup
        .string()
        .required(t('validation:required')),
      company_address: yup
        .string()
        .required(t('validation:required')),
      contact_person: yup
        .string()
        .required(t('validation:required')),
      contact_phone: yup.string().nullable(),
      contact_email: yup
        .string()
        .nullable()
        .email(t('validation:email_invalid')),
    })
  }

  defaultValues(): SaveAdvertiserRequestInterface {
    return {
      company_name: '',
      company_address: '',
      contact_person: '',
      nip: '',
      contact_phone: '',
      contact_email: '',
    }
  }
}

export { SaveAdvertiserValidation }
