import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo } from 'react'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Button } from '@/features/components/buttons/button'
import UpdateArticleMediaGalleryValidation from './vallidations/UpdateArticleMediaGalleryValidation'
import {
  ArticleMediaInterface,
  UpdateArticleMediaGalleryInterface,
} from '@/features/articleMediaGalleries/redux/types'

type Props = {
  data?: ArticleMediaInterface
  title: string
} & InstanceProps<UpdateArticleMediaGalleryInterface>

const Form: React.FC<Props> = ({
  data,
  isOpen,
  onReject,
  onResolve,
  title,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new UpdateArticleMediaGalleryValidation(),
    t
  )
  const { control, setValue, handleSubmit } =
    useForm<UpdateArticleMediaGalleryInterface>({
      resolver: yupResolver(schema),
      defaultValues: defaultValues,
    })

  useEffect(() => {
    if (data) {
      setValue('title', data.title)
      setValue('description', data.description)
      setValue('order', data.order)
    }
  }, [data])

  const imgSrc = useMemo(
    () =>
      data?.article_media_files.find(
        (article) =>
          article.collection_name === 'default'
      )?.url,
    [data]
  )

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={title}
          onClose={onReject}
        />
        {data && (
          <img
            src={imgSrc}
            alt={''}
            className={'max-h-[350px] pt-4'}
          />
        )}
        <form
          onSubmit={handleSubmit(onResolve)}
          className={'flex flex-col gap-y-2 pt-8'}
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t('form:labels.title')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'title'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.description'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'description'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  type={'number'}
                  min={0}
                  label={t('form:labels.order')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'order'}
            control={control}
          />
          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const editArticleMediaGalleryModal =
  create(Form)
