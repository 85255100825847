import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { Query } from '@/utils/query'
import usePagination from '@/utils/hooks/usePagination'
import { IssueInterface } from '@/features/issues/redux/types'
import {
  useDeleteIssueMutation,
  useGetIssuesQuery,
} from '@/features/issues/redux/issueAPI'
import { issueDetailsModal } from '@/features/issues/resources/views/details'
import { issueEditModal } from '@/features/issues/resources/views/edit'
import { toast } from 'react-toastify'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { TFunction } from 'i18next'
import pluralize from 'pluralize'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'issues',
  ])
  const [{ pageIndex, pageSize }, setPagination] =
    usePagination()
  const [data, setData] = useState<
    IssueInterface[]
  >([])
  const [deleteIssue] = useDeleteIssueMutation()
  const query = useMemo(
    () => baseQuery.page(pageIndex),
    [pageIndex, baseQuery]
  )
  const { data: apiData } = useGetIssuesQuery(
    query.url()
  )

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = (id: number) => {
    deleteIssue(id)
      .unwrap()
      .then(() =>
        toast.success(t('issues:delete.success'))
      )
  }

  const handleEdit = (id: number) => {
    void issueEditModal({
      issueId: id,
    })
  }

  const handleDetails = (id: number) => {
    void issueDetailsModal({
      issueId: id,
    })
  }

  const table = useReactTable({
    columns: columns(
      t,
      handleDetails,
      handleEdit,
      handleDelete
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<IssueInterface>()

const columns = (
  t: TFunction,
  onDetails: (id: number) => void,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableHiding: true,
  }),
  columnBuilder.accessor('signature', {
    id: 'signature',
    header: t('form:labels.issue_signature'),
    enableHiding: true,
  }),
  columnBuilder.accessor('issueable_type', {
    id: 'issueable_type',
    header: t('form:labels.model'),
    cell: ({ row }) => (
      <a
        className={'text-blue-500'}
        href={`${pluralize(
          row.original.issueable_type
        )}/${row.original.issueable_id}/edit`}
      >
        {t(
          `utils:models.${row.original.issueable_type}`
        )}
        #{row.original.issueable_id}
      </a>
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    cell: ({ row }) => (
      <span>
        {t(`issues:types.${row.original.type}`)}
      </span>
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('status', {
    id: 'status',
    header: t('form:labels.status'),
    cell: ({ row }) =>
      t(`issues:statuses.${row.original.status}`),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission: PermissionEnum.ISSUE_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.ISSUE_DESTROY,
          },
          onDetails: {
            action: () =>
              onDetails(row.original.id),
            permission: PermissionEnum.ISSUE_SHOW,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
