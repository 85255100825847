import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { FormControl } from '@mui/base'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Input } from '@/features/components/inputs/input'
import { Label } from '@/features/components/inputs/label'
import { SlotFormTypes } from './SlotForm.types'
import { Select2OptionInterface } from '@/app/types'
import Select, { MultiValue } from 'react-select'
import { Collapse } from '@/components'
import { useAdsViews } from '../hooks/useAdsViews'
import { useAdsSlots } from '../hooks/useAdsSlots'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { useSlotFormHelper } from '../hooks/useSlotFormHelper'
import { AdsSlotTypeEnum } from '@/features/ads/slots/redux'
import { CategoriesForm } from '../partials'

const SlotForm: React.FC<SlotFormTypes> = ({
  slotName,
  slotId,
  index,
  slotType,
}): React.ReactNode => {
  const { t } = useTranslation(['form'])
  const {
    prefix,
    control,
    selectedViewIds,
    handleChangeViewIds,
    categories,
    initiateAssignCategories,
  } = useSlotFormHelper(index)
  const { slots } = useAdsSlots()
  const {
    handleViewsChange: onViewsChange,
    viewsForCategoryBundles,
    availableViews,
    selectedViews,
    categoriesWithViews,
  } = useAdsViews(slots, slotId, selectedViewIds)

  useEffect(() => {
    initiateAssignCategories(
      viewsForCategoryBundles.map(
        (view) => view.id
      )
    )
  }, [viewsForCategoryBundles])

  const handleViewsChange = (
    options: MultiValue<Select2OptionInterface>
  ) => {
    onViewsChange(options)
    handleChangeViewIds(
      options.map((option) => +option.value)
    )
  }

  return (
    <Collapse
      title={
        <Controller
          render={({ field }) => (
            <Checkbox
              label={slotName}
              checked={field.value}
              onChange={field.onChange}
            />
          )}
          name={`${prefix}generate_form`}
          control={control}
        />
      }
    >
      <div
        className={
          'grid lg:grid-cols-2 gap-16 p-4'
        }
      >
        <div className={'flex flex-col gap-4'}>
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl error={!!error}>
                <Checkbox
                  label={t(
                    'form:labels.is_target_blank'
                  )}
                  checked={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
            )}
            name={`${prefix}is_target_blank`}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl error={!!error}>
                <Checkbox
                  label={t(
                    'form:labels.is_active'
                  )}
                  checked={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
            )}
            name={`${prefix}is_active`}
            control={control}
          />
          <div
            className={
              'grid lg:grid-cols-2 gap-4'
            }
          >
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  className={'lg:col-span-2'}
                  {...field}
                  error={!!error}
                >
                  <Input
                    type={'url'}
                    label={t(
                      'form:labels.desktop_pixel_url'
                    )}
                  />
                </FormControl>
              )}
              name={`${prefix}desktop_pixel_url`}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  className={'lg:col-span-2'}
                  {...field}
                  error={!!error}
                >
                  <Input
                    type={'url'}
                    label={t(
                      'form:labels.mobile_pixel_url'
                    )}
                  />
                </FormControl>
              )}
              name={`${prefix}mobile_pixel_url`}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    type={'number'}
                    min={0}
                    label={t(
                      'form:labels.total_display_limit'
                    )}
                  />
                </FormControl>
              )}
              name={`${prefix}total_display_limit`}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    type={'number'}
                    min={0}
                    label={t(
                      'form:labels.daily_display_limit'
                    )}
                  />
                </FormControl>
              )}
              name={`${prefix}daily_display_limit`}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    type={'number'}
                    min={0}
                    label={t(
                      'form:labels.capping_limit'
                    )}
                  />
                </FormControl>
              )}
              name={`${prefix}capping_limit`}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    type={'number'}
                    min={0}
                    label={t(
                      'form:labels.capping_days_period'
                    )}
                  />
                </FormControl>
              )}
              name={`${prefix}capping_days_period`}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  className={
                    'lg:col-span-2 flex flex-col'
                  }
                  {...field}
                  error={!!error}
                >
                  <Label
                    label={t('form:labels.views')}
                  />
                  <Select
                    isMulti
                    onChange={handleViewsChange}
                    options={availableViews}
                    value={selectedViews}
                    classNames={{
                      control: () =>
                        '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent',
                    }}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={`${prefix}view_ids`}
              control={control}
            />
          </div>
        </div>
        <div
          className={
            'grid lg:grid-cols-2 gap-6 w-full'
          }
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl error={!!error}>
                <Label
                  label={t(
                    'form:labels.desktop_image'
                  )}
                />
                <input
                  type={'file'}
                  accept={'image/*'}
                  className={
                    '!max-w-[290px] lg:!max-w-full'
                  }
                  onChange={(e) =>
                    e.target.files
                      ? field.onChange(
                          e.target.files[0]
                        )
                      : field.onChange(null)
                  }
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={`${prefix}desktop_image`}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl error={!!error}>
                <Label
                  label={t(
                    'form:labels.mobile_image'
                  )}
                />
                <input
                  type={'file'}
                  className={
                    '!max-w-[290px] lg:!max-w-full'
                  }
                  accept={'image/*'}
                  onChange={(e) =>
                    e.target.files
                      ? field.onChange(
                          e.target.files[0]
                        )
                      : field.onChange(null)
                  }
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={`${prefix}mobile_image`}
            control={control}
          />
          {slotType ===
            AdsSlotTypeEnum.TOP_BANNER_1 && (
            <Fragment>
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl error={!!error}>
                    <Label
                      label={t(
                        'form:labels.background_image'
                      )}
                    />
                    <input
                      type={'file'}
                      className={
                        '!max-w-[290px] lg:!max-w-full'
                      }
                      accept={'image/*'}
                      onChange={(e) =>
                        e.target.files
                          ? field.onChange(
                              e.target.files[0]
                            )
                          : field.onChange(null)
                      }
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={`${prefix}background_image`}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl error={!!error}>
                    <Label
                      label={t(
                        'form:labels.desktop_full_size_image'
                      )}
                    />
                    <input
                      type={'file'}
                      className={
                        '!max-w-[290px] lg:!max-w-full'
                      }
                      accept={'image/*'}
                      onChange={(e) =>
                        e.target.files
                          ? field.onChange(
                              e.target.files[0]
                            )
                          : field.onChange(null)
                      }
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={`${prefix}desktop_full_screen_image`}
                control={control}
              />
            </Fragment>
          )}
          {categoriesWithViews(categories).map(
            (category, index) => (
              <CategoriesForm
                key={index}
                prefix={prefix}
                index={index}
                viewName={
                  category.view?.name ?? ''
                }
                initialSelectedCategories={
                  category.category_ids
                }
              />
            )
          )}
        </div>
      </div>
    </Collapse>
  )
}

export { SlotForm }
