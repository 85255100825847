import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { CategoryInterface } from '@/features/posts/categories/redux/types'

type Props = {
  data: Omit<CategoryInterface, 'tree'>
  type:
    | 'details'
    | 'parent_details'
    | 'additional_category'
}

export const Details: React.FC<Props> = ({
  data,
  type,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'posts/categories',
  ])
  const title = useRef<string>(
    t(`posts/categories:details.${type}`)
  ).current

  return (
    <Card>
      <div className={'flex flex-col gap-y-6'}>
        <span className={'text-lg font-medium'}>
          {title}
        </span>
        <div className={'flex flex-col gap-y-2'}>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.name')}
            </span>
            <span>{data.name}</span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.seo_keywords')}
            </span>
            <span>{data.seo_keywords}</span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.seo_description')}
            </span>
            <span>{data.seo_description}</span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.icon')}
            </span>
            <img
              src={data.icon_url || ''}
              alt={data.name}
              className={'size-4'}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}
