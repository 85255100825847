import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { StoreUserRequestInterface } from '@/features/users/redux'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'
import {
  BaseUserValidation,
  ChangePasswordValidation,
} from '@/features/users/resources/rules'

class StoreUserValidation
  implements
    ValidationInterface<StoreUserRequestInterface>
{
  private baseUserValidation =
    new BaseUserValidation()
  private changePasswordValidation =
    new ChangePasswordValidation()

  defaultValues(): StoreUserRequestInterface {
    return {
      ...this.changePasswordValidation.defaultValues(),
      ...this.baseUserValidation.defaultValues(),
    }
  }

  rules(
    t: TFunction
  ): ObjectSchema<
    StoreUserRequestInterface,
    AnyObject
  > {
    return yup
      .object()
      .concat(
        this.changePasswordValidation.rules(t)
      )
      .concat(this.baseUserValidation.rules(t))
  }
}

export { StoreUserValidation }
