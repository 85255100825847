import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useStoreRoleMutation } from '@/features/roles/redux/roleAPI'
import { SaveRoleRequestInterface } from '@/features/roles/redux/types'
import { Form } from '@/features/roles/resources/_components/form'

const RolesCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(['roles'])
  const [storeRole] = useStoreRoleMutation()
  const navigate = useNavigate()

  const handleSubmit = (
    data: SaveRoleRequestInterface
  ) => {
    storeRole(data)
      .unwrap()
      .then(() => {
        toast.success(t('roles:create.success'))
        navigate('/roles')
      })
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('roles:create.title')}
      </span>
      <Card margin={true}>
        <Form onSubmit={handleSubmit} />
      </Card>
    </div>
  )
}

export { RolesCreateScreen }
