import React, { Fragment, useEffect } from 'react'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import { toRequestObject } from './helper'
import { match } from 'ts-pattern'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { usePermissions } from '@/utils/hooks/usePermission'
import SaveQuotationsResultsDetailValidation from './validations/SaveQuotationsResultsDetailValidation'
import { useUpdateInternalQuotationResultDetailMutation } from '@/features/internalQuotationsResultsDetails/redux/internalQuotationsResultsDetailsApi'
import {
  QuotationResultDetailsInterface,
  SaveQuotationResultDetailsRequestInterface,
} from '@/features/internalQuotationsResultsDetails/redux/types'

type Props = {
  body: QuotationResultDetailsInterface
  t: TFunction
  fieldName: keyof SaveQuotationResultDetailsRequestInterface
  permission: PermissionEnum
  quotationId: string | number
}

const Form: React.FC<Props> = ({
  body,
  t,
  fieldName,
  permission,
  quotationId,
}): React.ReactNode => {
  const { schema, defaultValues } = useValidation(
    new SaveQuotationsResultsDetailValidation(),
    t
  )
  const { hasPermission } = usePermissions()
  const [
    updateInternalQuotationResultDetail,
    { isLoading },
  ] =
    useUpdateInternalQuotationResultDetailMutation()

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SaveQuotationResultDetailsRequestInterface>(
    {
      resolver: yupResolver(schema),
      defaultValues,
    }
  )

  const _handleFieldValueChange = async (
    data: SaveQuotationResultDetailsRequestInterface
  ) => {
    try {
      await updateInternalQuotationResultDetail({
        resultId: body.id,
        quotationId: quotationId,
        data: {
          ...data,
        },
      }).unwrap()
      toast.success(
        t(
          'internal_quotations_results_details:edit.success'
        )
      )
    } catch (error) {
      //empty block
    }
  }
  const handleFieldValueChange = _.debounce(
    _handleFieldValueChange,
    1500
  )

  useEffect(() => {
    reset(toRequestObject(body))
  }, [body])

  useEffect(() => {
    const subscription = watch(() => {
      if (Object.keys(errors).length) {
        return
      }

      handleSubmit(handleFieldValueChange)()
    })
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  if (
    permission === undefined ||
    hasPermission(permission)
  )
    return (
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <Input
              type={match(fieldName)
                .with('price', () => 'number')
                .otherwise(() => 'text')}
              endAdornment={
                isLoading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                  />
                )
              }
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={fieldName}
        control={control}
      />
    )

  return (
    <Fragment>{`${body['final_price']}`}</Fragment>
  )
}

export { Form }
