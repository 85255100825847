import { AbstractAsyncSelectStrategy } from './AbstractAsyncSelectStrategy'
import {
  PaginatedResponseInterface,
  Select2OptionInterface,
} from '@/app/types'
import { isArray } from 'lodash'
import {
  HandleFetchResponse,
  NonNullableDefaultValue,
} from '../useAsyncSelect.types'

class PaginationAsyncSelectStrategy<
  T extends object,
  TMultiple extends boolean = false
> extends AbstractAsyncSelectStrategy<
  T,
  TMultiple
> {
  handleFetch = async (
    searchValue: string,
    prevOptions: unknown,
    additional?: {
      page: number | undefined
    }
  ): Promise<
    HandleFetchResponse<'paginated'>
  > => {
    let page = 1

    const { label, value, search } =
      this.columnFields

    try {
      if (additional && additional.page) {
        page = additional.page
      }

      const response: PaginatedResponseInterface<T> =
        await this.fetchFunction(
          this.prepareQuery()
            .where(search, searchValue)
            .page(page)
            .url()
        ).unwrap()

      return {
        options: response.data.map((item) => ({
          label: item[label],
          value: item[value],
        })) as Select2OptionInterface[],
        hasMore:
          response.pagination.total_pages > page,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      }
    }
  }

  handleGetDefaultValue = async (
    defaultValue: NonNullableDefaultValue
  ) => {
    const { label, value } = this.columnFields

    try {
      const query = this.prepareQuery().where(
        this.columnFields.value.toString(),
        isArray(defaultValue)
          ? defaultValue.join(',')
          : defaultValue
      )

      const response: PaginatedResponseInterface<T> =
        await this.fetchFunction(
          query.url()
        ).unwrap()

      if (this.isMulti) {
        this.setValue(
          response.data.map((item) => ({
            label: item[label] as string,
            value: item[value] as string,
          }))
        )
      } else {
        const item = response.data[0]

        if (!item) return

        this.setValue({
          label: item[label] as string,
          value: item[value] as string,
        })
      }
    } catch (error) {
      return
    }
  }

  getProperties(): Record<
    string,
    string | Function
  > {
    return {
      control: () =>
        '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent !min-h-[42px]',
      menuList: () => '!max-h-[200px]',
    }
  }
}

export { PaginationAsyncSelectStrategy }
