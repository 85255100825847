import { PermissionEnum } from '@/features/permissions/redux/types'
import { useCallback } from 'react'
import { useAppSelector } from '@/app/hooks'

export const usePermissions = () => {
  const { permissions } = useAppSelector(
    (state) => state.auth
  )

  const hasPermission = useCallback(
    (permission: PermissionEnum) => {
      return permissions.includes(permission)
    },
    [permissions]
  )

  return {
    hasPermission,
  }
}
