import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  InvoiceFilter,
  InvoiceStatistics,
  Table,
} from './partials'
import Card from '@/features/components/cards'
import { useSearch } from '@/utils/hooks/useSearch'
import { Search } from '@/features/components/inputs/Search'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

export const InvoiceListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['invoices'])
    const { setQueryParams, query } = useSearch({
      columnsForSearch: [
        'user.name',
        'signature',
        'buyer->nip',
      ],
    })
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    return (
      <div
        className={'flex flex-col p-8 gap-y-4'}
      >
        <Card>
          <div
            className={
              'grid lg:grid-cols-4 gap-8'
            }
          >
            <div className={'col-span-3'}>
              <InvoiceFilter
                onSubmit={(filters) =>
                  setQueryParams(filters)
                }
              />
            </div>
            <div>
              <InvoiceStatistics query={query} />
            </div>
          </div>
        </Card>
        <div
          className={'flex gap-2 lg:justify-end'}
        >
          <Search onChange={setQueryParams} />
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t('invoices:list.title')}
              </span>
            </div>
            <Table
              query={query}
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
