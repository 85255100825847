import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from './partials'
import {
  StoreMassAttributeCategoryRequestInterface,
  StoreMassAttributeCategoryValidationInterface,
  useStoreBatchAttributeCategoriesMutation,
} from '@/features/posts/attributeCategories/redux'
import _, { omit } from 'lodash'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { isFetchBaseQueryError } from '@/app/hooks'
import { ValidationErrorInterface } from '@/app/types'

const ns = 'posts/attribute_categories'

const PostAttributeCategoryMassCreate =
  (): React.ReactNode => {
    const { t } = useTranslation(['form', ns])
    const [storeBatch] =
      useStoreBatchAttributeCategoriesMutation()
    const navigate = useNavigate()

    const handleSubmit = async (
      data: StoreMassAttributeCategoryValidationInterface
    ) => {
      const transformedData: StoreMassAttributeCategoryRequestInterface =
        {
          ...omit(data, ['attribute_type']),
          values: data.values.map((value) =>
            omit(value, ['category_name'])
          ),
        }

      try {
        await storeBatch(
          serialize(transformedData, {
            indices: true,
            nullsAsUndefineds: true,
            booleansAsIntegers: true,
          })
        ).unwrap()

        toast.success(
          t(`${ns}:mass_create.success`)
        )
        navigate('/posts/attribute-categories')
      } catch (error) {
        if (
          isFetchBaseQueryError(error) &&
          error.status === 422
        ) {
          const errors =
            error.data as ValidationErrorInterface

          toast.error(() => (
            <ul
              className={'flex flex-col gap-y-1'}
            >
              {_.map(
                errors.errors,
                (error, key) => (
                  <li key={key}>
                    {error.join(', ')}
                  </li>
                )
              )}
            </ul>
          ))
        }
      }
    }

    return (
      <div
        className={'flex flex-col gap-y-8 p-8'}
      >
        <h1 className={'text-2xl font-semibold'}>
          {t(`${ns}:mass_create.title`)}
        </h1>
        <Form onSubmit={handleSubmit} />
      </div>
    )
  }

export { PostAttributeCategoryMassCreate }
