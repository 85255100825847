import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { BaseAdsValidationInterface } from '@/features/ads/ads/redux'
import _ from 'lodash'
import { AdsSlotTypeEnum } from '@/features/ads/slots/redux'

type RequestInterface = Omit<
  BaseAdsValidationInterface,
  'desktop_image' | 'mobile_image'
>

class BaseAdsValidation
  implements
    ValidationInterface<RequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    RequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      slot_id: yup
        .number()
        .required(t('validation:required')),
      is_target_blank: yup.boolean().required(),
      is_active: yup.boolean().required(),
      background_image: yup
        .mixed<File>()
        .nullable()
        .test(
          'file-size',
          t('validation:max_file_size', {
            size: '1024 KB',
          }),
          (value) => {
            if (value) {
              return value.size <= 1024 * 1024
            }

            return true
          }
        ),
      desktop_pixel_url: yup.string().nullable(),
      desktop_full_screen_image: yup
        .mixed<File>()
        .nullable()
        .test(
          'file-size',
          t('validation:max_file_size', {
            size: '1024 KB',
          }),
          (value) => {
            if (value) {
              return value.size <= 1024 * 1024
            }

            return true
          }
        ),
      mobile_pixel_url: yup.string().nullable(),
      total_display_limit: yup
        .number()
        .nullable()
        .transform((value: string | number) =>
          _.isNaN(value) ? null : value
        ),
      daily_display_limit: yup
        .number()
        .nullable()
        .transform((value: string | number) =>
          _.isNaN(value) ? null : value
        ),
      capping_limit: yup
        .number()
        .nullable()
        .transform((value: string | number) =>
          _.isNaN(value) ? null : value
        ),
      capping_days_period: yup
        .number()
        .nullable()
        .transform((value: string | number) =>
          _.isNaN(value) ? null : value
        ),
      view_ids: yup
        .array()
        .of(yup.number().required())
        .required(),
      categories: yup
        .array()
        .of(
          yup.object().shape({
            category_ids: yup
              .array()
              .of(yup.number().required())
              .required(),
            view_id: yup.number().required(),
          })
        )
        .required(),
      slot_name: yup
        .string()
        .required()
        .transform((value) => value.trim()),
      slot_type: yup
        .mixed<AdsSlotTypeEnum>()
        .required(),
      generate_form: yup.boolean().required(),
    })
  }

  defaultValues(): RequestInterface {
    return {
      slot_id: 0,
      is_target_blank: false,
      mobile_pixel_url: '',
      desktop_pixel_url: '',
      is_active: false,
      view_ids: [],
      categories: [],
      generate_form: false,
      slot_name: '',
      slot_type: AdsSlotTypeEnum.TOP_BANNER_1,
      daily_display_limit: '',
      capping_days_period: '',
      capping_limit: '',
      total_display_limit: '',
    }
  }
}

export { BaseAdsValidation }
