import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ChangePasswordInterface } from '@/features/auth/redux/types'
import { useChangePasswordMutation } from '@/features/auth/redux/authAPI'
import { toast } from 'react-toastify'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'
import { isFetchBaseQueryError } from '@/app/hooks'
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
} from '@/app/types'
import Card from '@/features/components/cards'
import { Form } from './partials/Form'

const ChangePasswordScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['auth'])
    const [changePassword] =
      useChangePasswordMutation()
    const navigate = useNavigate()
    const [errors, setErrors] =
      useState<
        BackendValidationErrorInterface<ChangePasswordInterface>
      >()

    const onSubmit = async (
      data: ChangePasswordInterface
    ) => {
      try {
        await changePassword(data).unwrap()
        toast.success(
          t('auth:change_password.success')
        )
        navigate('/')
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          const errorData =
            error as ErrorResponseInterface

          if (errorData.status === 422) {
            const errorData =
              error as ErrorValidationResponseInterface<ChangePasswordInterface>
            setErrors(errorData.data)
          }
        }
      }
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('auth:change_password.title')}
        </span>
        <Card>
          <div
            className={'flex flex-col gap-y-4'}
          >
            <div className={'pt-8'}>
              <Form
                errors={errors}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }
export { ChangePasswordScreen }
