import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveCensorshipRequestInterface } from '@/features/censorship/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'

export default class SaveCensorshipValidation
  implements
    ValidationInterface<SaveCensorshipRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveCensorshipRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      word: yup
        .string()
        .required(t('validation:required')),
      description: yup.string(),
      is_enabled_in_comments: yup
        .boolean()
        .required()
        .default(false),
      with_more_accurate_checking: yup
        .boolean()
        .required()
        .default(false),
    })
  }

  defaultValues(): SaveCensorshipRequestInterface {
    return {
      word: '',
      description: '',
      is_enabled_in_comments: false,
      with_more_accurate_checking: false,
    }
  }
}
