import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveSurveyRequestInterface } from '@/features/surveys/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import Carbon from '@/utils/carbon'

export default class SaveSurveyValidation
  implements
    ValidationInterface<SaveSurveyRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveSurveyRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      question: yup
        .string()
        .required(t('validation.required')),
      started_at: yup
        .string()
        .required(t('validation:required'))
        .test(
          'is-greater-than',
          function (value) {
            const { ended_at } = this.parent
            if (ended_at === undefined) {
              return true
            }

            return new Date(value) <
              new Date(ended_at)
              ? true
              : this.createError({
                  message: t(
                    'validation:field_must_be_greater_than',
                    {
                      min: new Carbon()
                        .parse(ended_at)
                        .format(
                          'dd.MM.yyyy HH:mm'
                        ),
                    }
                  ),
                  path: 'started_at',
                })
          }
        )
        .transform((value) =>
          value
            ? new Carbon()
                .parse(value)
                .toISOString()
            : value
        ),
      ended_at: yup
        .string()
        .required(t('validation:required'))
        .test('is-less-than', function (value) {
          const { started_at } = this.parent
          if (started_at === undefined) {
            return true
          }

          return new Date(value) >
            new Date(started_at)
            ? true
            : this.createError({
                message: t(
                  'validation:field_must_be_less_than',
                  {
                    max: new Carbon()
                      .parse(started_at)
                      .format('dd.MM.yyyy HH:mm'),
                  }
                ),
                path: 'ended_at',
              })
        })
        .transform((value) =>
          value
            ? new Carbon()
                .parse(value)
                .toISOString()
            : value
        ),
    })
  }

  defaultValues(): SaveSurveyRequestInterface {
    return {
      question: '',
      started_at: '',
      ended_at: '',
    }
  }
}
