import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import React from 'react'
import { Table } from './partials'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const SettingListPage = () => {
  const { t } = useTranslation(['settings'])
  const {
    handleToggleColumn,
    visibilityColumns,
    visibilityColumnsWithLabels,
    handleSetAvailableColumns,
  } = useColumnVisibility()

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <div className={'flex justify-end'}>
        <div className={'flex gap-1'}>
          <ColumnVisibility
            toggleColumn={handleToggleColumn}
            columns={visibilityColumnsWithLabels}
          />
        </div>
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t('settings:list.title')}
            </span>
          </div>
          <Table
            setAvailableColumns={
              handleSetAvailableColumns
            }
            visibility={visibilityColumns}
          />
        </div>
      </Card>
    </div>
  )
}

export { SettingListPage }
