import { Select2OptionInterface } from '@/app/types'
import { isMatching, P } from 'ts-pattern'

export const isSelect2OptionInterface = (
  value: any
): value is Select2OptionInterface => {
  const pattern = {
    label: P.string,
    value: P.union(P.string, P.number),
  }

  return isMatching(pattern, value)
}

export const isSelect2OptionInterfaceArray = (
  value: any
): value is Select2OptionInterface[] => {
  return (
    Array.isArray(value) &&
    value.every(isSelect2OptionInterface)
  )
}
