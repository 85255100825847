import igritAPI from '@/utils/api/igrit'

import {
  setArrayCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'
import {
  GetInternalQuotationsPartnerInterface,
  GetInternalQuotationsPartnersInterface,
  InternalQuotationPartnerInterface,
} from '@/features/internalQuotationsPartners/redux/types'

export const {
  useDeleteInternalQuotationPartnerMutation,
  useGetInternalQuotationPartnerQuery,
  useGetInternalQuotationsPartnersQuery,
  useUpdateInternalQuotationPartnerMutation,
  useStoreInternalQuotationPartnerMutation,
  useToggleQuotationPartnerStatusMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getInternalQuotationPartner: build.query<
      InternalQuotationPartnerInterface,
      string | number
    >({
      query: (id) =>
        `/admin/internal-quotation-partners/${id}`,
      transformResponse: (
        response: GetInternalQuotationsPartnerInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'InternalQuotationPartner',
          result
        ),
    }),

    getInternalQuotationsPartners: build.query<
      InternalQuotationPartnerInterface[],
      string
    >({
      query: (query) =>
        `/admin/internal-quotation-partners?${query}`,
      transformResponse: (
        response: GetInternalQuotationsPartnersInterface
      ) => response.data,
      providesTags: (result) =>
        setArrayCache(
          'InternalQuotationPartner',
          result
        ),
    }),

    storeInternalQuotationPartner: build.mutation<
      void,
      FormData
    >({
      query: (data) => ({
        url: '/admin/internal-quotation-partners',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: [
        'InternalQuotationPartner',
      ],
    }),

    updateInternalQuotationPartner:
      build.mutation<
        void,
        { data: FormData; id: string | number }
      >({
        query: ({ data, id }) => ({
          url: `/admin/internal-quotation-partners/${id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        invalidatesTags: [
          'InternalQuotationPartner',
        ],
      }),

    deleteInternalQuotationPartner:
      build.mutation<void, string | number>({
        query: (id) => ({
          url: `admin/internal-quotation-partners/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          'InternalQuotationPartner',
        ],
      }),
    toggleQuotationPartnerStatus: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/internal-quotation-partners/${id}/toggle-status`,
        method: 'POST',
      }),
      invalidatesTags: [
        'InternalQuotationPartner',
      ],
    }),
  }),
})
