import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { ButtonLink } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  AttributeCategoryFilter,
  Table,
} from './components'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { useSearch } from '@/utils/hooks/useSearch'
import { Search } from '@/features/components/inputs/Search'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const ns = 'posts/attribute_categories'

export const PostAttributeCategoryListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([ns])
    const { hasPermission } = usePermissions()
    const { query, setQueryParams } = useSearch({
      columnsForSearch: [
        'attribute.name',
        'category.name',
      ],
    })
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    return (
      <div
        className={'flex flex-col p-8 gap-y-4'}
      >
        <div
          className={
            'flex flex-col gap-2 justify-between md:flex-row'
          }
        >
          {hasPermission(
            PermissionEnum.ATTRIBUTE_CATEGORY_SAVE
          ) && (
            <div className={'flex gap-2'}>
              <ButtonLink
                variant={'contained'}
                className={
                  'flex items-center gap-x-2'
                }
                to={
                  '/posts/attribute-categories/create'
                }
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={'text-white'}
                />
                <span>
                  {t(`${ns}:list.create`)}
                </span>
              </ButtonLink>
              <ButtonLink
                variant={'contained'}
                className={
                  'flex items-center gap-x-2'
                }
                to={
                  '/posts/attribute-categories/batch/create'
                }
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={'text-white'}
                />
                <span>
                  {t(`${ns}:list.create_mass`)}
                </span>
              </ButtonLink>
            </div>
          )}
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <AttributeCategoryFilter
          onSubmit={(values) =>
            setQueryParams({ ...values })
          }
        />
        <Search onChange={setQueryParams} />
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>{t(`${ns}:list.title`)}</span>
            </div>
            <Table
              query={query}
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
