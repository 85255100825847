import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Button } from '@/features/components/buttons/button'
import {
  InternalQuotationInterface,
  SaveInternalQuotationInterface,
} from '@/features/internalQuotations/redux/types'
import SaveInternalQuotationValidation from '../validations/SaveInternalQuotationValidation'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Label } from '@/features/components/inputs/label'
import { SearchCategory } from '@/features/components/inputs/asyncSelect/searchCategory'
import Carbon from '@/utils/carbon'
import _ from 'lodash'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'

type Props = {
  data?: InternalQuotationInterface
  onSubmit: (
    data: SaveInternalQuotationInterface
  ) => Promise<boolean>
  backendErrors?: BackendValidationErrorInterface<SaveInternalQuotationInterface>
}
const Form: React.FC<Props> = ({
  onSubmit,
  data,
  backendErrors,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const {
    schema,
    defaultValues,
    resolveValidationErrors,
  } = useValidation(
    new SaveInternalQuotationValidation(),
    t
  )
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SaveInternalQuotationInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (data) {
      setValue('is_active', data.is_active)
      setValue('name', data.name)
      setValue('order', data.order)
      setValue(
        'execution_interval_days',
        data.execution_interval_days
      )
      setValue(
        'start_at',
        new Carbon(
          data.start_at
        ).toInputWithDateTime()
      )

      if (data.category) {
        setValue('category_id', data.category_id)
      }
    }
  }, [data])

  useEffect(() => {
    if (backendErrors) {
      const err = resolveValidationErrors(
        backendErrors
      )

      if (err) {
        _.forEach(err, (value, key) => {
          setError(
            key as keyof SaveInternalQuotationInterface,
            {
              type: 'manual',
              message: value,
            }
          )
        })
      }
    }
  }, [backendErrors])

  return (
    <form
      className={'flex flex-col gap-4'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className={'grid lg:grid-cols-3 gap-4'}
      >
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.name')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`name`}
        />
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                min={0}
                label={t(
                  'form:labels.execution_interval_days'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`execution_interval_days`}
        />
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                min={0}
                label={t('form:labels.order')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`order`}
        />
        <Controller
          render={({ field }) => (
            <FormControl
              {...field}
              error={!!errors[field.name]}
            >
              <Input
                type={'datetime-local'}
                label={t(
                  'form:labels.started_at'
                )}
              />
              <FormHelperText
                message={
                  errors[field.name]?.message
                }
              />
            </FormControl>
          )}
          name={'start_at'}
          control={control}
        />
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Label
                label={t('form:labels.category')}
              />
              <SearchCategory
                defaultValue={field.value}
                onChange={(option) =>
                  field.onChange(option?.value)
                }
                isMulti={false}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'category_id'}
        />
      </div>
      <div className={'flex flex-col gap-y-1'}>
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                label={t('form:labels.is_active')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`is_active`}
        />
      </div>
      <div className={'flex'}>
        <Button
          variant={'contained'}
          type={'submit'}
        >
          {t('form:buttons.save')}
        </Button>
      </div>
    </form>
  )
}

export { Form }
