import { Query } from '@/utils/query'
import {
  HandleFetchResponse,
  NonNullableDefaultValue,
  ResponseType,
} from '../useAsyncSelect.types'
import { Dispatch, SetStateAction } from 'react'
import { Select2OptionInterface } from '@/app/types'

abstract class AbstractAsyncSelectStrategy<
  T extends object,
  TMultiple extends boolean = false
> {
  constructor(
    public fetchFunction: Function,
    public columnFields: {
      label: keyof T
      value: keyof T
      search: keyof T & string
    },
    public isMulti: TMultiple,
    public setValue: Dispatch<
      SetStateAction<
        | Select2OptionInterface
        | Select2OptionInterface[]
        | null
      >
    >,
    public query?: Query
  ) {}

  abstract handleFetch(
    search: string,
    prevOptions?: unknown,
    additional?: {
      page: number | undefined
    }
  ): Promise<HandleFetchResponse<ResponseType>>

  abstract handleGetDefaultValue(
    defaultValue: NonNullableDefaultValue
  ): Promise<void>

  abstract getProperties(): Record<
    string,
    string | Function
  >

  prepareQuery(): Query {
    const query = new Query()

    if (this.query) {
      query.params(this.query.filters)
    }

    return query
  }
}

export { AbstractAsyncSelectStrategy }
