import React, { Fragment, useEffect } from 'react'
import { Select2OptionInterface } from '@/app/types'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import { FormControl } from '@mui/base'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { LatLngTuple } from 'leaflet'
import AsyncSelect from '@/features/components/inputs/asyncSelect/asyncSelect'
import _ from 'lodash'
import { SingleValue } from 'react-select'
import { useLazySearchCityQuery } from '@/utils/api/nominatim'
import { SaveAdressRequestInterface } from '@/features/users/redux'

export const LocationForm =
  (): React.ReactNode => {
    const { t } = useTranslation(['posts/posts'])
    const [searchCities, { data: cities = [] }] =
      useLazySearchCityQuery()
    const { control, watch, setValue } =
      useFormContext<SaveAdressRequestInterface>()

    const watchLocation = watch('location')

    const handleChangeLocation = (
      coordinates: LatLngTuple
    ) => {
      setValue('location', {
        lat: coordinates[0],
        long: coordinates[1],
      })
    }

    const _handleSearchLocation = (
      value: string,
      callback: (
        options: Select2OptionInterface[]
      ) => void
    ) => {
      searchCities(value)
        .unwrap()
        .then((response) =>
          callback(
            response.map((city) => ({
              label: `${city.address.country}, ${city.address.city}, ${city.address.state}`,
              value: city.place_id.toString(),
            }))
          )
        )
    }

    const handleSearchLocation = _.debounce(
      _handleSearchLocation,
      500
    )

    const handleSelectLocation = (
      option: SingleValue<Select2OptionInterface>
    ) => {
      if (!option) return

      const placeId = Number(option.value)
      const selectedCity = cities.find(
        (city) => city.place_id === placeId
      )

      if (selectedCity) {
        setValue('location', {
          lat: selectedCity.lat,
          long: selectedCity.lon,
        })
        setValue('city', option.label)
      }
    }

    return (
      <Fragment>
        <span className={'font-medium'}>
          {t('posts/posts:edit.address.title')}
        </span>
        <div className={'flex flex-col gap-y-4'}>
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                className={'z-[9999]'}
                {...field}
                error={!!error}
              >
                <AsyncSelect
                  onChange={handleSelectLocation}
                  cacheOptions
                  loadOptions={
                    handleSearchLocation
                  }
                  value={{
                    label: field.value,
                    value: field.value,
                  }}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'city'}
            control={control}
          />
          <MapContainer
            center={[
              watchLocation?.lat,
              watchLocation?.long,
            ]}
            zoom={13}
            scrollWheelZoom={false}
            className={'min-h-[300px]'}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <LocationMarker
              onChange={handleChangeLocation}
              coordinates={[
                watchLocation?.lat,
                watchLocation?.long,
              ]}
            />
          </MapContainer>
        </div>
      </Fragment>
    )
  }

type LocationProps = {
  coordinates: LatLngTuple
  onChange: (coordinates: LatLngTuple) => void
}

const LocationMarker: React.FC<LocationProps> = ({
  coordinates,
  onChange,
}): React.ReactNode => {
  const map = useMapEvents({
    click(e) {
      onChange([e.latlng.lat, e.latlng.lng])
    },
  })

  useEffect(() => {
    map.panTo(coordinates)
  }, [coordinates, map])

  return (
    <Marker position={coordinates}>
      <Popup>You are here</Popup>
    </Marker>
  )
}
