import { ArticleCategoryInterface } from '@/features/articleCategories/redux/types'
import { v4 as uuid } from 'uuid'

export const mergeCategories = (
  categories: ArticleCategoryInterface[],
  enableFilters = false
) => {
  const mergeCategory = (
    category: ArticleCategoryInterface
  ) => {
    const merged = {
      name: category.name,
      id: uuid(),
      article_category_id: category.id,
      subcategory: '',
    }

    if (category.subcategories) {
      return category.subcategories.map(
        (subcategory) => ({
          ...merged,
          name: `${merged.name} > ${subcategory.name}`,
          subcategory: subcategory.name,
          id: uuid(),
        })
      )
    }
    return [merged]
  }

  // if enableFilters === true return all active categories
  // if enableFilters === false return all active and inactive categories
  const filterActiveCategories = (
    category: ArticleCategoryInterface
  ) => (enableFilters ? category.active : true)

  return categories
    .filter(filterActiveCategories)
    .flatMap((category) =>
      mergeCategory(category)
    )
}

export type MergedCategoriesType = ReturnType<
  typeof mergeCategories
>
