import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Button } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './_components/table'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { useStoreCensorshipMutation } from '@/features/censorship/redux/censorshipAPI'
import { censorshipFormModal } from '@/features/censorship/resources/_components/form'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { SaveCensorshipRequestInterface } from '@/features/censorship/redux/types'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

export const CensorshipListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['censorship'])
    const { hasPermission } = usePermissions()
    const [storeCensorship] =
      useStoreCensorshipMutation()
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    const handleAddQuestion = () => {
      censorshipFormModal({
        title: t('censorship:create.title'),
      }).then((data) =>
        storeCensorship(
          _.omitBy(
            data,
            _.isEmpty
          ) as SaveCensorshipRequestInterface
        )
          .unwrap()
          .then(() =>
            toast.success(
              t('censorship:create.success')
            )
          )
      )
    }

    return (
      <div
        className={'flex flex-col gap-y-4 p-4'}
      >
        <div
          className={
            'flex flex-col gap-2 lg:justify-between lg:flex-row'
          }
        >
          {hasPermission(
            PermissionEnum.CENSORSHIP_SAVE
          ) && (
            <div>
              <Button
                variant={'contained'}
                className={
                  'flex items-center gap-x-2'
                }
                onClick={handleAddQuestion}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={'text-white'}
                />
                <span>
                  {t('censorship:list.create')}
                </span>
              </Button>
            </div>
          )}
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t('censorship:list.title')}
              </span>
            </div>
            <Table
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
