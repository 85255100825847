import { useState } from 'react'
import { Select2Option } from './useSelectValue.types'

export const useSelectValue = <
  TMultiple = boolean
>(
  initialValue: Select2Option<TMultiple> = null
) => {
  return useState<Select2Option<TMultiple>>(
    initialValue
  )
}
