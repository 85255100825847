import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveQuotationResultDetailsRequestInterface } from '@/features/internalQuotationsResultsDetails/redux/types'

export default class SaveQuotationsResultsDetailValidation
  implements
    ValidationInterface<SaveQuotationResultDetailsRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveQuotationResultDetailsRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      price: yup.number().nullable(),
    })
  }

  defaultValues(): SaveQuotationResultDetailsRequestInterface {
    return {
      price: 0,
    }
  }
}
