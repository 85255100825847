import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import {
  ExternalQuotationInterface,
  useGetExternalQuotationsQuery,
  useUpdateExternalQuotationMutation,
} from '@/features/externalQuotations/redux'
import { NameForm } from './forms'
import {
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import Carbon from '@/utils/carbon'
import { SwitcherIcon } from '../partials/SwitcherIcon'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'

type Props = {
  query: Query
} & VisibleColumnsInterface

const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'external_quotations',
    'utils',
    'validation',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => baseQuery.page(pagination.pageIndex),
    [pagination, baseQuery]
  )

  const { data: apiData } =
    useGetExternalQuotationsQuery(query.url())
  const [data, setData] = useState<
    ExternalQuotationInterface[]
  >([])

  const [updateExternalQuotation] =
    useUpdateExternalQuotationMutation()

  const toggleActive = async (
    row: ExternalQuotationInterface
  ) => {
    try {
      await updateExternalQuotation({
        id: row.id,
        data: {
          name: row.name,
          is_active: !row.is_active,
        },
      }).unwrap()
      toast.success(
        t('external_quotations:toggle.success')
      )
    } catch (error) {
      //   emptyblock
    }
  }

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t, toggleActive),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    manualPagination: true,
    enableHiding: true,
    state: {
      columnVisibility: visibility,
      pagination,
    },
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<ExternalQuotationInterface>()

const columns = (
  t: TFunction,
  toggleActive: (
    row: ExternalQuotationInterface
  ) => Promise<void>
) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'name',
    header: t('form:labels.name'),
    cell: ({ row }) => (
      <NameForm
        permission={
          PermissionEnum.EXTERNAL_QUOTATION_SOURCE_SAVE
        }
        t={t}
        body={row.original}
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('group_code', {
    id: 'group_code',
    header: t('form:labels.group_code'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'is_active',
    header: t('form:labels.is_active'),
    cell: ({ row }) => (
      <SwitcherIcon
        onClick={(row) => toggleActive(row)}
        row={row.original}
        isActive={row.original.is_active}
        permission={
          PermissionEnum.EXTERNAL_QUOTATION_SOURCE_SAVE
        }
        tooltip={
          row.original.is_active
            ? 'external_quotations:tooltips.active'
            : 'external_quotations:tooltips.deactive'
        }
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.created_at'),
    cell: ({ row }) =>
      new Carbon(
        row.original.created_at
      ).toDateTimeLocal(),
    enableHiding: true,
  }),
]

export { Table }
