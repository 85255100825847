import Compresssor from 'compressorjs'
import Compressor from 'compressorjs'

const defaultCompressorOptions: Omit<
  Compressor.Options,
  'success' | 'error'
> = {
  quality: 0.9,
  maxWidth: 1920,
  maxHeight: 1080,
}

export const compressImage = (
  file: File,
  options: Omit<
    Compressor.Options,
    'success' | 'error'
  > = defaultCompressorOptions
): Promise<File | Blob> => {
  return new Promise((resolve, reject) => {
    new Compresssor(file, {
      ...options,
      success(result) {
        resolve(result)
      },
      error(err) {
        reject(err)
      },
    })
  })
}
