import igritAPI from '@/utils/api/igrit'
import {
  GetProtectionResponseInterface,
  GetProtectionsResponseInterface,
} from '@/features/protections/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetProtectionsQuery,
  useGetProtectionQuery,
  useStoreProtectionMutation,
  useUpdateProtectionMutation,
  useDeleteProtectionMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getProtections: build.query<
      GetProtectionsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/protections?${query}`,
      transformResponse: (
        response: GetProtectionsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Protection', result),
    }),
    getProtection: build.query<
      GetProtectionResponseInterface['data'],
      string | number
    >({
      query: (id) => `/admin/protections/${id}`,
      transformResponse: (
        response: GetProtectionResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'Protection',
          result
        ),
    }),
    storeProtection: build.mutation<
      void,
      FormData
    >({
      query: (data) => ({
        url: `/admin/protections`,
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['Protection'],
    }),
    updateProtection: build.mutation<
      void,
      {
        id: number | string
        data: FormData
      }
    >({
      query: ({ id, data }) => ({
        url: `/admin/protections/${id}`,
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['Protection'],
    }),
    deleteProtection: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/protections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Protection'],
    }),
  }),
})
