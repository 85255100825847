import igritAPI from '@/utils/api/igrit'
import {
  GetInternalQuotationInterface,
  GetInternalQuotationsInterface,
  InternalQuotationInterface,
  SaveInternalQuotationInterface,
} from '@/features/internalQuotations/redux/types'
import { setArrayCache, setSingleObjectCache } from '@/utils/api/rtkHelper'
import _ from 'lodash'

export const {
  useDeleteInternalQuotationMutation,
  useGetInternalQuotationQuery,
  useGetInternalQuotationsQuery,
  useStoreInternalQuotationMutation,
  useUpdateInternalQuotationMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getInternalQuotations: build.query<
      GetInternalQuotationsInterface['data'],
      string
    >({
      query: (query) => `/admin/internal-quotations?${query}`,
      transformResponse: (response: GetInternalQuotationsInterface) =>
        response.data,
      providesTags: (result) => setArrayCache('InternalQuotation', result),
    }),

    getInternalQuotation: build.query<
      InternalQuotationInterface,
      string | number
    >({
      query: (id) => `admin/internal-quotations/${id}`,
      transformResponse: (response: GetInternalQuotationInterface) => {
        const data = response.data
        const internalQuotation: InternalQuotationInterface = {
          ...data,
          categories: [
            _.omit(data.category, 'children'),
            ...data.category.children,
          ],
        }

        return internalQuotation
      },
      providesTags: (result) =>
        setSingleObjectCache('InternalQuotation', result),
    }),

    storeInternalQuotation: build.mutation<
      void,
      SaveInternalQuotationInterface
    >({
      query: (data) => ({
        url: '/admin/internal-quotations',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['InternalQuotation'],
    }),

    updateInternalQuotation: build.mutation<
      void,
      { data: SaveInternalQuotationInterface; id: string | number }
    >({
      query: ({ data, id }) => ({
        url: `/admin/internal-quotations/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['InternalQuotation'],
    }),

    deleteInternalQuotation: build.mutation<void, string | number>({
      query: (id) => ({
        url: `/admin/internal-quotations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InternalQuotation'],
    }),
  }),
})
