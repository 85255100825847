import { forwardRef } from 'react'
import clsx from 'clsx'
import { ModalTitleProps } from './ModalTitle.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const ModalTitle = forwardRef<
  HTMLDivElement,
  ModalTitleProps
>((props, ref) => {
  const {
    className,
    children,
    title,
    onClose,
    ...rest
  } = props

  return (
    <div
      className={clsx(
        'flex w-full items-center justify-between',
        className
      )}
      ref={ref}
      {...rest}
    >
      <span className={'text-lg font-semibold'}>
        {title}
      </span>
      <FontAwesomeIcon
        icon={faTimes}
        className={'cursor-pointer'}
        onClick={onClose}
      />
    </div>
  )
})

ModalTitle.displayName = 'ModalTitle'

export default ModalTitle
