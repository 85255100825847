import React, { useEffect, useState } from 'react'
import { Select2OptionInterface } from '@/app/types'
import {
  PostInterface,
  UpdatePostDataRequest,
} from '@/features/posts/posts/redux/types'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  UseFormReturn,
} from 'react-hook-form'
import { associateValues } from './helper'
import _ from 'lodash'
import { SingleValue } from 'react-select'
import Card from '@/features/components/cards'
import { Label } from '@/features/components/inputs/label'
import Carbon from '@/utils/carbon'
import { FormControl } from '@mui/base'
import { Checkbox } from '@/features/components/inputs/checkbox'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { PostTypeEnum } from '@/features/posts/posts/redux/enums/type'
import { SearchCategory } from '@/features/components/inputs/asyncSelect/searchCategory'
import { Input } from '@/features/components/inputs/input'
import { useLazyGetCategoryQuery } from '@/features/posts/categories/redux/categoryAPI'
import { UnitEnum } from '@/app/enums/unitEnum'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { confirmModal } from '@/features/components/modals/confirm'
import { PostStatusEnum } from '@/features/posts/posts/redux/enums/status'

type Props = {
  data?: PostInterface
  form: UseFormReturn<UpdatePostDataRequest>
}

export const BasicDataForm: React.FC<Props> = ({
  data,
  form: {
    setValue,
    watch,
    formState: { errors },
    control,
  },
}): React.ReactNode => {
  const { t } = useTranslation(['posts/posts'])
  const [availableUnits, setAvailableUnits] =
    useState<UnitEnum[]>([])
  const [getCategory] = useLazyGetCategoryQuery()

  const watchCategoryId = watch('category_id')

  useEffect(() => {
    if (data) {
      associateValues(data, setValue)
    }
  }, [data, setValue])

  useEffect(() => {
    if (watchCategoryId !== 0) {
      getCategory(watchCategoryId)
        .unwrap()
        .then((response) =>
          setAvailableUnits(response.units)
        )
    }
  }, [getCategory, watchCategoryId])

  if (!data) {
    return <span></span>
  }

  const handleCategoryChange = (
    value: SingleValue<Select2OptionInterface>
  ): void => {
    if (value) {
      confirmModal({
        message: t(
          'posts/posts:edit.basic.category_change.description'
        ),
        type: 'warning',
      }).then((confirmed) => {
        if (confirmed) {
          setValue(
            'category_id',
            parseInt(value.value)
          )
          setValue('attributes', [])
        }
      })
    }
  }

  return (
    <Card>
      <span className={'font-medium'}>
        {t('posts/posts:edit.basic.title')}
      </span>
      <div
        className={'flex flex-col gap-y-4 pt-6'}
      >
        <div
          className={'grid lg:grid-cols-3 gap-4'}
        >
          <div className={'flex flex-col'}>
            <Label
              label={t('form:labels.created_at')}
            />
            <span>
              {new Carbon()
                .parse(data.created_at)
                .format('dd.MM.yyyy HH:mm')}
            </span>
          </div>
          <div className={'flex flex-col'}>
            <Label
              label={t(
                'form:labels.refreshed_at'
              )}
            />
            <span>
              {data.refreshed_at
                ? new Carbon()
                    .parse(data.refreshed_at)
                    .format('dd.MM.yyyy HH:mm')
                : '-'}
            </span>
          </div>
          <div className={'flex flex-col'}>
            <Label
              label={t('form:labels.archived_at')}
            />
            <span>
              {data.archived_at
                ? new Carbon()
                    .parse(data.archived_at)
                    .format('dd.MM.yyyy HH:mm')
                : '-'}
            </span>
          </div>
          <div
            className={'flex flex-col gap-y-1'}
          >
            <Controller
              render={({ field }) => (
                <FormControl
                  error={!!errors.email_visible}
                >
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t(
                      'form:labels.email_visible'
                    )}
                  />
                </FormControl>
              )}
              name={'email_visible'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl
                  error={!!errors.phone_visible}
                >
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t(
                      'form:labels.phone_visible'
                    )}
                  />
                </FormControl>
              )}
              name={'phone_visible'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl
                  error={
                    !!errors.whatsapp_available
                  }
                >
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t(
                      'form:labels.whatsapp_available'
                    )}
                  />
                </FormControl>
              )}
              name={'whatsapp_available'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl
                  error={
                    !!errors.messages_enabled
                  }
                >
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t(
                      'form:labels.messages_enabled'
                    )}
                  />
                </FormControl>
              )}
              name={'messages_enabled'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl
                  error={
                    !!errors.is_price_negotiable
                  }
                >
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t(
                      'form:labels.is_price_negotiable'
                    )}
                  />
                </FormControl>
              )}
              name={'is_price_negotiable'}
              control={control}
            />
          </div>
          <div
            className={'flex flex-col gap-y-3'}
          >
            <div className={'flex flex-col'}>
              <Label
                label={t(
                  'form:labels.count_of_views'
                )}
              />
              <span>{data.views}</span>
            </div>
            <div className={'flex flex-col'}>
              <Label
                label={t(
                  'form:labels.count_of_phone_views'
                )}
              />
              <span>{data.phone_views}</span>
            </div>
            <Controller
              render={({ field }) => (
                <FormControl
                  error={!!errors.type}
                >
                  <Select
                    value={field.value}
                    buttonClassNames={
                      '!min-w-fit'
                    }
                    onChange={(_, value) =>
                      field.onChange(value)
                    }
                    label={t(
                      'form:labels.status'
                    )}
                  >
                    {_.map(
                      PostStatusEnum,
                      (value, key) => (
                        <Option
                          value={value}
                          key={key}
                        >
                          {t(
                            `utils:posts.statuses.${value}`
                          )}
                        </Option>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
              name={'status'}
              control={control}
            />
          </div>
          <div
            className={'flex flex-col gap-y-3'}
          >
            <div className={'flex flex-col'}>
              <Label
                label={t(
                  'form:labels.count_of_email_views'
                )}
              />
              <span>{data.email_views}</span>
            </div>
            <div className={'flex flex-col'}>
              <Label
                label={t(
                  'form:labels.count_of_likes'
                )}
              />
              <span>{data.likes_count}</span>
            </div>
          </div>
        </div>
        <div
          className={
            'grid lg:grid-cols-2 2xl:grid-cols-3 gap-4'
          }
        >
          <Controller
            render={({ field }) => (
              <FormControl error={!!errors.type}>
                <Select
                  value={field.value}
                  buttonClassNames={'!min-w-fit'}
                  onChange={(_, value) =>
                    field.onChange(value)
                  }
                  label={t('form:labels.type')}
                >
                  {_.map(
                    PostTypeEnum,
                    (value, key) => (
                      <Option
                        value={value}
                        key={key}
                      >
                        {t(
                          `utils:post_types.${value}`
                        )}
                      </Option>
                    )
                  )}
                </Select>
              </FormControl>
            )}
            name={'type'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl
                {...field}
                error={!!errors.price}
              >
                <Input
                  type={'number'}
                  label={t('form:labels.price')}
                  step={'0.01'}
                  name={field.name}
                />
              </FormControl>
            )}
            name={'price'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl error={!!errors.unit}>
                <Select
                  buttonClassNames={'!min-w-fit'}
                  value={field.value}
                  onChange={(_, value) =>
                    field.onChange(value)
                  }
                  label={t('form:labels.unit')}
                  placeholder={t(
                    'form:labels.select_unit'
                  )}
                >
                  {_.map(
                    availableUnits,
                    (value, key) => (
                      <Option
                        value={value}
                        key={key}
                      >
                        {t(
                          `utils:units.${value}`
                        )}
                      </Option>
                    )
                  )}
                </Select>
              </FormControl>
            )}
            name={'unit'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl
                {...field}
                error={!!errors.quantity}
              >
                <Input
                  type={'number'}
                  label={t(
                    'form:labels.quantity'
                  )}
                  step={'0.01'}
                  name={field.name}
                />
              </FormControl>
            )}
            name={'quantity'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <FormControl
                error={!!errors.quantity_unit}
              >
                <Select
                  buttonClassNames={'!min-w-fit'}
                  value={field.value}
                  onChange={(_, value) =>
                    field.onChange(value)
                  }
                  label={t(
                    'form:labels.quantity_unit'
                  )}
                  placeholder={t(
                    'form:labels.select_unit'
                  )}
                >
                  {_.map(
                    availableUnits,
                    (value, key) => (
                      <Option
                        value={value}
                        key={key}
                      >
                        {t(
                          `utils:units.${value}`
                        )}
                      </Option>
                    )
                  )}
                </Select>
              </FormControl>
            )}
            name={'quantity_unit'}
            control={control}
          />
          <div
            className={
              'flex flex-col gap-y-2 lg:col-span-2 2xl:col-span-3'
            }
          >
            <div className={'flex flex-col'}>
              <Label
                label={t('form:labels.category')}
              />
              <span className={'text-sm'}>
                {data.category_tree
                  .flatMap(
                    (category) => category.name
                  )
                  .join(' > ')}
              </span>
            </div>
            <Controller
              render={({ field }) => (
                <SearchCategory
                  onChange={handleCategoryChange}
                  isMulti={false}
                  defaultValue={field.value}
                />
              )}
              name={'category_id'}
              control={control}
            />
          </div>
        </div>
        <div className={'flex flex-col gap-y-4'}>
          <Controller
            render={({ field }) => (
              <FormControl
                {...field}
                error={!!errors.title}
              >
                <Input
                  label={t('form:labels.title')}
                  fullWidth
                  name={field.name}
                />
                <FormHelperText
                  message={errors.title?.message}
                />
              </FormControl>
            )}
            name={'title'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <div
                className={
                  'flex flex-col max-w-[350px] lg:max-w-full'
                }
              >
                <Input
                  multiline
                  rows={5}
                  label={t(
                    'form:labels.description'
                  )}
                  {...field}
                />
              </div>
            )}
            name={'content'}
            control={control}
          />
        </div>
      </div>
    </Card>
  )
}
