import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { StoreCampaignValidationInterface } from '@/features/ads/ads/redux'
import {
  BaseAdsValidation,
  GeneralInfoValidation,
} from '@/features/ads/ads/resources/rules'

class StoreCampaignValidation
  implements
    ValidationInterface<StoreCampaignValidationInterface>
{
  private baseValidation = new BaseAdsValidation()
  private generalInfoValidation =
    new GeneralInfoValidation()

  rules(
    t: TFunction
  ): yup.ObjectSchema<
    StoreCampaignValidationInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      advertisements: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              desktop_image: yup
                .mixed<File>()
                .when('generate_form', {
                  is: (value: boolean) => value,
                  then: (schema) =>
                    schema.required(
                      t('validation:required')
                    ),
                })
                .test(
                  'file-size',
                  t('validation:max_file_size', {
                    size: '1024 KB',
                  }),
                  (value) => {
                    if (value) {
                      return (
                        value.size <= 1024 * 1024
                      )
                    }

                    return true
                  }
                )
                .nullable(),
              mobile_image: yup
                .mixed<File>()
                .when('generate_form', {
                  is: (value: boolean) => value,
                  then: (schema) =>
                    schema.required(
                      t('validation:required')
                    ),
                })
                .test(
                  'file-size',
                  t('validation:max_file_size', {
                    size: '1024 KB',
                  }),
                  (value) => {
                    if (value) {
                      return (
                        value.size <= 1024 * 1024
                      )
                    }

                    return true
                  }
                )
                .nullable(),
            })
            .concat(this.baseValidation.rules(t))
            .required()
        )
        .required(),
      base: this.generalInfoValidation
        .rules(t)
        .required(),
    })
  }

  defaultValues(): StoreCampaignValidationInterface {
    return {
      advertisements: [],
      base: this.generalInfoValidation.defaultValues(),
    }
  }
}

export { StoreCampaignValidation }
