import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Table } from '@/features/posts/posts/resources/views/list/components'
import { useSearch } from '@/utils/hooks/useSearch'
import { Search } from '@/features/components/inputs/Search'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const PostsForUser = (): React.ReactNode => {
  const { t } = useTranslation(['posts/posts'])

  const { query: baseQuery, setQueryParams } =
    useSearch({
      columnsForSearch: [
        'title',
        'content',
        'phoneNumber.phone_number',
        'user.email',
      ],
    })
  const {
    handleToggleColumn,
    handleSetAvailableColumns,
    visibilityColumnsWithLabels,
    visibilityColumns,
  } = useColumnVisibility()

  const query = useMemo(() => {
    return baseQuery.where('user_id', 5)
  }, [baseQuery])

  return (
    <div className={'flex flex-col gap-y-4'}>

      <div className={'flex justify-between'}>
        <Search onChange={setQueryParams} />
        <ColumnVisibility
          columns={visibilityColumnsWithLabels}
          toggleColumn={handleToggleColumn}
        />
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t('posts/posts:list.title')}
            </span>
          </div>
          <Table
            visibility={visibilityColumns}
            setAvailableColumns={
              handleSetAvailableColumns
            }
            query={query}
            showSelection={false}
          />
        </div>
      </Card>
    </div>
  )
}

export { PostsForUser }
