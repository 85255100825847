import igritAPI from '@/utils/api/igrit'
import {
  ArticleInterface,
  ArticleMainImageType,
  GetArticleResponseInterface,
  GetArticlesResponseInterface,
} from '@/features/articles/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useDeleteArticleMutation,
  useGetArticleQuery,
  useGetArticlesQuery,
  useStoreArticleMutation,
  useUpdateArticleMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getArticles: build.query<
      GetArticlesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/articles?${query}`,
      transformResponse: (
        response: GetArticlesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Article', result),
    }),
    getArticle: build.query<
      ArticleInterface,
      string | number
    >({
      query: (id) => `/admin/articles/${id}`,
      transformResponse: (
        response: GetArticleResponseInterface
      ) => {
        const { article_media, ...rest } =
          response.data
        const media = article_media.find(
          (media) => media.type === 'main_image'
        )

        const mainImage: ArticleMainImageType = {
          watermark: false,
          title: '',
          description: '',
        }

        if (media) {
          Object.assign(mainImage, {
            watermark: media.has_watermark,
            title: media.title,
            description: media.description,
          })
        }

        return {
          ...rest,
          article_media: article_media,
          main_image: mainImage,
        }
      },
      providesTags: (result) =>
        setSingleObjectCache('Article', result),
    }),
    storeArticle: build.mutation<void, FormData>({
      query: (data) => ({
        url: '/admin/articles',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['Article'],
    }),
    updateArticle: build.mutation<
      void,
      {
        articleId: string | number
        data: FormData
      }
    >({
      query: ({ data, articleId }) => ({
        url: `/admin/articles/${articleId}`,
        method: 'PUT',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['Article'],
    }),
    deleteArticle: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/articles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Article'],
    }),
  }),
})
