import { useSearchAdsViewsQuery } from '@/features/ads/views/redux'
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Select2OptionInterface } from '@/app/types'
import { AdsSlotInterface } from '@/features/ads/slots/redux'
import { MultiValue } from 'react-select'
import { BaseAdsValidationInterface } from '@/features/ads/ads/redux'

export const useAdsViews = (
  slots: AdsSlotInterface[],
  slotId: number,
  selectedViewIds: number[]
) => {
  const { data = [] } = useSearchAdsViewsQuery()
  const [availableViews, setAvailableViews] =
    useState<Select2OptionInterface[]>([])
  const [selectedViews, setSelectedViews] =
    useState<Select2OptionInterface[]>([])

  useEffect(() => {
    const slot = slots.find(
      (slot) => slot.id === slotId
    )

    if (!slot) return

    setAvailableViews(
      slot.views?.map((view) => ({
        label: view.name,
        value: view.id.toString(),
      })) || []
    )
  }, [slots, slotId])

  useEffect(() => {
    if (selectedViewIds) {
      setSelectedViews(
        availableViews.filter((view) =>
          selectedViewIds.includes(+view.value)
        )
      )
    }
  }, [availableViews])

  const findView = useCallback(
    (id: number) =>
      data.find((view) => view.id === id),
    [data]
  )

  const handleViewsChange = useCallback(
    (
      options: MultiValue<Select2OptionInterface>
    ) => {
      setSelectedViews(
        options as Select2OptionInterface[]
      )
    },
    []
  )

  const viewsForCategoryBundles = useMemo(
    () =>
      data
        .filter((view) =>
          selectedViewIds.includes(view.id)
        )
        .filter(
          (view) => view.is_category_required
        ),
    [data, selectedViewIds]
  )

  const categoriesWithViews = useCallback(
    (
      categories: BaseAdsValidationInterface['categories']
    ) =>
      categories.map((category) => ({
        ...category,
        view: findView(category.view_id),
      })),
    [findView]
  )

  return {
    views: data,
    findView,
    availableViews,
    selectedViews,
    handleViewsChange,
    viewsForCategoryBundles,
    categoriesWithViews,
  }
}
