import igritAPI from '@/utils/api/igrit'
import {
  GetInternalQuotationCategoriesResponseInterface,
  GetInternalQuotationCategoryResponseInterface,
  SaveInternalQuotationCategoryInterface,
} from '@/features/internalQuotationCategories/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useDeleteInternalQuotationCategoryMutation,
  useGetInternalQuotationCategoriesQuery,
  useGetInternalQuotationCategoryQuery,
  useStoreInternalQuotationCategoryMutation,
  useUpdateInternalQuotationCategoryMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getInternalQuotationCategories: build.query<
      GetInternalQuotationCategoriesResponseInterface['data'],
      { id: string | number; query: string }
    >({
      query: ({ query, id }) =>
        `/admin/internal-quotations/${id}/internal-quotations-categories?${query}`,
      transformResponse: (
        response: GetInternalQuotationCategoriesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache(
          'InternalQuotationCategory',
          result
        ),
    }),

    getInternalQuotationCategory: build.query<
      GetInternalQuotationCategoryResponseInterface['data'],
      {
        quotationId: string | number
        categoryId: string | number
      }
    >({
      query: ({ quotationId, categoryId }) =>
        `/admin/internal-quotations/${quotationId}/internal-quotations-categories/${categoryId}`,
      transformResponse: (
        response: GetInternalQuotationCategoryResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'InternalQuotationCategory',
          result
        ),
    }),

    storeInternalQuotationCategory:
      build.mutation<
        void,
        {
          data: SaveInternalQuotationCategoryInterface
          id: string | number
        }
      >({
        query: ({ data, id }) => ({
          url: `/admin/internal-quotations/${id}/internal-quotations-categories`,
          method: 'POST',
          body: data,
        }),
        invalidatesTags: [
          'InternalQuotationCategory',
        ],
      }),

    updateInternalQuotationCategory:
      build.mutation<
        void,
        {
          data: SaveInternalQuotationCategoryInterface
          quotationId: string | number
          categoryId: string | number
        }
      >({
        query: ({
          data,
          quotationId,
          categoryId,
        }) => ({
          url: `/admin/internal-quotations/${quotationId}/internal-quotations-categories/${categoryId}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [
          'InternalQuotationCategory',
        ],
      }),

    deleteInternalQuotationCategory:
      build.mutation<
        void,
        {
          internalQuotationId: string | number
          internalQuotationCategoryId:
            | string
            | number
        }
      >({
        query: ({
          internalQuotationId,
          internalQuotationCategoryId,
        }) => ({
          url: `/admin/internal-quotations/${internalQuotationId}/internal-quotations-categories/${internalQuotationCategoryId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          'InternalQuotationCategory',
        ],
      }),
  }),
})
