import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import {
  Button,
  ButtonLink,
} from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons'
import { Table } from './partials'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { useSearch } from '@/utils/hooks/useSearch'
import { Search } from '@/features/components/inputs/Search'
import { SearchAdvertiser } from '@/features/ads/ads/resources/components'
import {
  ColumnVisibility,
  FilterBuilder,
  Filters,
  useColumnVisibility,
  useFilters,
} from '@/components'
import { Label } from '@/features/components/inputs/label'
import { AdsSlotInterface } from '@/features/ads/slots/redux'
import { useAdsSlots } from '@/features/ads/ads/resources/components/Form/hooks/useAdsSlots'
import { Transition } from 'react-transition-group'
import { useDeleteBatchAdsMutation } from '@/features/ads/ads/redux'
import { toast } from 'react-toastify'

export const AdsListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'ads',
      'utils',
      'form',
    ])
    const [rowSelection, setRowSelection] =
      useState<number[]>([])
    const { hasPermission } = usePermissions()
    const { query, setQueryParams } = useSearch({
      columnsForSearch: [
        'name',
        'advertiser.company_name',
      ],
    })
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()
    const { slots } = useAdsSlots()
    const filterBuilder = useMemo(
      () => builder(slots),
      [slots]
    )
    const { ref, TriggerFilters, showFilters } =
      useFilters()
    const [deleteBatch] =
      useDeleteBatchAdsMutation()
    const [refetch, setRefetch] = useState(false)
    const handleDeleteAds = async () => {
      try {
        await deleteBatch(rowSelection).unwrap()
        toast.success(t('ads:list.deleted'))
        window.location.reload()
      } catch (error) {
        // empty block
      }
    }

    return (
      <div
        className={'flex flex-col gap-y-4 p-4'}
      >
        <div
          className={
            'flex flex-col gap-4 lg:flex-row-reverse'
          }
        >
          {hasPermission(
            PermissionEnum.ADVERTISEMENT_SAVE
          ) && (
            <ButtonLink
              variant={'contained'}
              className={
                'flex items-center gap-x-2'
              }
              to={'/ads/create'}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={'text-white'}
              />
              <span>{t('ads:list.create')}</span>
            </ButtonLink>
          )}
        </div>
        <Transition
          nodeRef={ref}
          unmountOnExit={true}
          in={showFilters}
          timeout={200}
          classNames={'transition-height'}
        >
          <Filters
            filterBuilder={filterBuilder}
            onSubmit={setQueryParams}
          />
        </Transition>
        <div
          className={
            'flex flex-col gap-4 lg:flex-row lg:justify-between lg:items-center'
          }
        >
          <div className={'flex gap-4'}>
            <TriggerFilters />
            <Button
              variant={'contained'}
              onClick={handleDeleteAds}
              className={'bg-red-500'}
            >
              {t('form:buttons.delete')}
            </Button>
          </div>
          <div className={'flex gap-2'}>
            <Search onChange={setQueryParams} />
            <Button
              variant={'contained'}
              onClick={() => setRefetch(true)}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
            <ColumnVisibility
              columns={
                visibilityColumnsWithLabels
              }
              toggleColumn={handleToggleColumn}
            />
          </div>
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>{t('ads:list.title')}</span>
            </div>
            <Table
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
              onRefetched={setRefetch}
              shouldRefetch={refetch}
              onRowSelectionChange={
                setRowSelection
              }
              query={query}
            />
          </div>
        </Card>
      </div>
    )
  }

const builder = (slots: AdsSlotInterface[]) =>
  new FilterBuilder()
    .addFilter({
      field: 'advertiser_id',
      component: 'custom',
      props: {
        label: 'form:labels.advertiser',
      },
      customComponent: ({ onChange, label }) => (
        <div className={'flex flex-col'}>
          {label && <Label label={label} />}
          <SearchAdvertiser
            isMulti={false}
            onChange={onChange}
          />
        </div>
      ),
    })
    .addFilter({
      field: 'slot_id',
      component: 'select',
      props: {
        placeholder:
          'form:placeholders.select_slot',
        label: 'form:labels.slot_name',
      },
      optionsProps: {
        translate: false,
        options: slots.map((slot) => ({
          value: slot.id,
          label: slot.name,
        })),
      },
    })
    .addFilter({
      field: 'is_active',
      component: 'select',
      props: {
        placeholder:
          'form:placeholders.select_status',
        label: 'form:labels.status',
      },
      optionsProps: {
        translate: true,
        options: [
          {
            value: '1',
            label: 'form:labels.active',
          },
          {
            value: '0',
            label: 'form:labels.inactive',
          },
        ],
      },
    })
    .addFilter({
      field: 'clicks_count_min',
      component: 'number',
      props: {
        label: 'form:labels.clicks_count_min',
      },
    })
    .addFilter({
      field: 'clicks_count_max',
      component: 'number',
      props: {
        label: 'form:labels.clicks_count_max',
      },
    })
    .addFilter({
      field: 'views_count_min',
      component: 'number',
      props: {
        label: 'form:labels.views_count_min',
      },
    })
    .addFilter({
      field: 'views_count_max',
      component: 'number',
      props: {
        label: 'form:labels.views_count_max',
      },
    })
    .addFilter({
      field: 'ctr_min',
      component: 'number',
      props: {
        label: 'form:labels.ctr_min',
      },
    })
    .addFilter({
      field: 'ctr_max',
      component: 'number',
      props: {
        label: 'form:labels.ctr_max',
      },
    })
