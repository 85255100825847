import { StoreImageRequestInterface } from '@/features/posts/posts/redux/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import StoreImageValidation from './validations/storeImageValidation'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDropzone } from 'react-dropzone'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import Card from '@/features/components/cards'
import { Button } from '@/features/components/buttons/button'

type Props = {
  onSubmit: (
    data: StoreImageRequestInterface
  ) => Promise<boolean>
}

export const Form: React.FC<Props> = ({
  onSubmit,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
  ])
  const { schema, defaultValues } = useValidation(
    new StoreImageValidation(),
    t
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<StoreImageRequestInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const watchImage = watch('image')

  const dropzone = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024,
    onDropAccepted: (files) =>
      setValue('image', files[0]),
  })

  const handleFormSubmit = (
    data: StoreImageRequestInterface
  ) => {
    onSubmit(data).then((success) => {
      if (success) reset(defaultValues)
    })
  }

  return (
    <Card className={'flex flex-col gap-y-4'}>
      <span className={'font-medium'}>
        {t(
          'posts/posts:edit.images.create.title'
        )}
      </span>
      <div className={'flex flex-col'}>
        <div className={'w-full'}>
          <div
            {...dropzone.getRootProps({
              className: 'dropzone',
            })}
          >
            <input
              {...dropzone.getInputProps()}
            />
            <p>
              {t('utils:dropzone.select_files')}
            </p>
          </div>
        </div>
        <FormHelperText
          message={errors.image?.message}
          error={!!errors.image}
        />
      </div>
      <div className={'grid lg:grid-cols-4'}>
        <Controller
          render={({ field }) => (
            <FormControl
              {...field}
              error={!!errors[field.name]}
            >
              <Input
                type={'number'}
                label={t('form:labels.order')}
              />
              <FormHelperText
                message={
                  errors[field.name]?.message
                }
              />
            </FormControl>
          )}
          name={'order'}
          control={control}
        />
        {watchImage && (
          <div
            className={'col-start-3 col-end-4'}
          >
            <img
              src={URL.createObjectURL(
                watchImage
              )}
              className={'max-w-16'}
              alt={'image'}
            />
          </div>
        )}
      </div>
      <div className={'flex pt-4'}>
        <Button
          variant={'contained'}
          onClick={handleSubmit(handleFormSubmit)}
        >
          {t('form:buttons.save')}
        </Button>
      </div>
    </Card>
  )
}
