import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteBundleCategoryMutation,
  useGetBundleCategoriesQuery,
} from '@/features/categoryBundles/redux/categoryBundleAPI'
import { BundleCategoryInterface } from '@/features/categoryBundles/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'bundle_categories',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination.pageIndex]
  )
  const { data: apiData } =
    useGetBundleCategoriesQuery(query.url())
  const [data, setData] = useState<
    BundleCategoryInterface[]
  >([])
  const [deleteBundleCategory] =
    useDeleteBundleCategoryMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteBundleCategory(id)
      .unwrap()
      .then(() =>
        toast.success(
          t('bundle_categories:list.deleted')
        )
      )
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<BundleCategoryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('is_active', {
    id: 'is_active',
    header: t('form:labels.is_active'),
    cell: ({ row }) =>
      row.original.is_active
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/bundle-categories/${row.original.id}/edit`,
            permission:
              PermissionEnum.CATEGORY_BUNDLE_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.CATEGORY_BUNDLE_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
