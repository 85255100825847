import React, { useEffect } from 'react'
import { FormInterface } from '@/app/types'
import {
  PageInterface,
  SavePageRequestInterface,
} from '@/features/pages/redux/types'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SavePageValidation from '@/features/pages/resources/_components/form/validations/savePageValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Label } from '@/features/components/inputs/label'
import { Editor } from '@/features/components/inputs/editor'
import { GalleryEnum } from '@/app/enums/galleryEnum'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Button } from '@/features/components/buttons/button'

export const Form: React.FC<
  FormInterface<
    SavePageRequestInterface,
    PageInterface
  >
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
  ])
  const { schema, defaultValues } = useValidation(
    new SavePageValidation(),
    t
  )
  const { control, handleSubmit, setValue } =
    useForm<SavePageRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      _.forEach(
        _.omitBy(data, 'id'),
        (value, key) => {
          setValue(
            key as keyof SavePageRequestInterface,
            value
          )
        }
      )
    }
  }, [data])

  return (
    <form
      className={'flex flex-col gap-y-4'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className={'grid lg:grid-cols-4 gap-4'}
      >
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.title')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'title'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.slug')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'slug'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                min={0}
                label={t(
                  'form:labels.menu_order'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'menu_order'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                min={0}
                label={t(
                  'form:labels.footer_order'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'footer_order'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.seo_title')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'seo_title'}
          control={control}
        />
        <div className={'lg:col-span-2'}>
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.seo_description'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'seo_description'}
            control={control}
          />
        </div>
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.seo_tags')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'seo_tags'}
          control={control}
        />
        <div className={'lg:col-span-2'}>
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.og_description'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'og_description'}
            control={control}
          />
        </div>
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.og_title')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'og_title'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <div className={'flex flex-col'}>
              <Label
                label={t('form:labels.og_image')}
              />
              <input
                type={'file'}
                accept={'image/*'}
                onChange={(e) => {
                  e.target.files
                    ? field.onChange(
                        e.target.files[0]
                      )
                    : field.onChange(null)
                }}
              />
              <FormHelperText
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name={'og_image'}
          control={control}
        />
        <div className={'lg:col-span-4'}>
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <div className={'flex flex-col'}>
                <Label
                  label={t('form:labels.content')}
                />
                <Editor
                  onChange={field.onChange}
                  data={field.value}
                  galleryType={GalleryEnum.PAGE}
                />
                <FormHelperText
                  error={!!error}
                  message={error?.message}
                />
              </div>
            )}
            name={'content'}
            control={control}
          />
        </div>
      </div>
      <div className={'flex flex-col gap-y-1'}>
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Checkbox
                label={t('form:labels.is_active')}
                checked={field.value}
                onChange={field.onChange}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'is_active'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Checkbox
                label={t('form:labels.in_menu')}
                checked={field.value}
                onChange={field.onChange}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'in_menu'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Checkbox
                label={t('form:labels.in_footer')}
                checked={field.value}
                onChange={field.onChange}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'in_footer'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Checkbox
                label={t(
                  'form:labels.seo_no_follow'
                )}
                checked={field.value}
                onChange={field.onChange}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'seo_no_follow'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Checkbox
                label={t(
                  'form:labels.seo_no_index'
                )}
                checked={field.value}
                onChange={field.onChange}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'seo_no_index'}
          control={control}
        />
      </div>
      <div className={'flex pt-4'}>
        <Button
          variant={'contained'}
          type={'submit'}
        >
          {t('form:buttons.save')}
        </Button>
      </div>
    </form>
  )
}
