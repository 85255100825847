import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveInternalQuotationsPartnerInterface } from '@/features/internalQuotationsPartners/redux/types'
import { InternalQuotationsPartnersStatusEnum } from '@/features/internalQuotationsPartners/redux/enums/InternalQuotationsPartnersStatusEnum'
import _ from 'lodash'

export default class SaveInternalQuotationPartnerValidation
  implements
    ValidationInterface<SaveInternalQuotationsPartnerInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveInternalQuotationsPartnerInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t(
            'validation:field_must_be_less_than',
            { max: 255 }
          )
        ),
      status: yup
        .mixed<InternalQuotationsPartnersStatusEnum>()
        .oneOf(
          Object.values(
            InternalQuotationsPartnersStatusEnum
          )
        )
        .required(t('validation:required')),
      logo: yup
        .object()
        .shape({
          file: yup
            .mixed<File>()
            .nullable()
            .test(
              'file-extension',
              t('validation:file_extension', {
                allowedExtensions: 'svg',
              }),
              function (value) {
                if (_.isNil(value)) return true

                const allowedExtensions = ['svg']
                const fileExtension = value.name
                  .split('.')
                  .pop()

                return allowedExtensions.includes(
                  fileExtension || ''
                )
              }
            ),
          remove: yup.boolean().required(),
        })
        .required(),
      website: yup
        .string()
        .url(t('validation:field_must_be_url'))
        .nullable()
        .max(
          255,
          t(
            'validation:field_must_be_less_than',
            { max: 255 }
          )
        ),
    })
  }

  defaultValues(): SaveInternalQuotationsPartnerInterface {
    return {
      name: '',
      status:
        InternalQuotationsPartnersStatusEnum.INACTIVE,
      logo: {
        remove: false,
        file: null,
      },
      website: null,
    }
  }
}
