import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Button } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from '@/features/blacklist/resources/views/list/components/table'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import { useStoreBlackListRowMutation } from '@/features/blacklist/redux/blacklistAPI'
import { blackListFormModal } from '@/features/blacklist/resources/components/form'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

export const BlackListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['blacklist'])
    const { hasPermission } = usePermissions()
    const [storeBlackListRow] =
      useStoreBlackListRowMutation()
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    const handleAddBlacklist = () => {
      blackListFormModal({
        title: t('blacklist:create.title'),
      }).then((data) =>
        storeBlackListRow(data)
          .unwrap()
          .then(() =>
            toast.success(
              t('blacklist:create.success')
            )
          )
      )
    }

    return (
      <div
        className={'flex flex-col gap-y-4 p-4'}
      >
        <div
          className={
            'flex gap-4 flex-col lg:justify-between lg:flex-row'
          }
        >
          {hasPermission(
            PermissionEnum.BLACKLIST_SAVE
          ) && (
            <div>
              <Button
                variant={'contained'}
                className={
                  'flex items-center gap-x-2'
                }
                onClick={handleAddBlacklist}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={'text-white'}
                />
                <span>
                  {t('blacklist:list.create')}
                </span>
              </Button>
            </div>
          )}
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t('blacklist:list.title')}
              </span>
            </div>
            <Table
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
