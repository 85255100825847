import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StoreCampaignValidation } from './rules/StoreCampaignValidation'
import useValidation from '@/utils/hooks/useValidation'
import {
  FormProvider,
  useForm,
} from 'react-hook-form'
import {
  StoreCampaignRequestInterface,
  StoreCampaignValidationInterface,
  useStoreCampaignMutation,
} from '@/features/ads/ads/redux'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  GeneralInfoForm,
  redirectOrCreateModal,
  SlotForm,
} from '@/features/ads/ads/resources/components'
import { useSearchAdsSlotsQuery } from '@/features/ads/slots/redux'
import { Query } from '@/utils/query'
import { Button } from '@/features/components/buttons/button'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { serialize } from 'object-to-formdata'
import { match } from 'ts-pattern'
import { useNavigate } from 'react-router-dom'

const slotsQuery = new Query()
  .includes('views')
  .url()

const AdsCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation([
    'ads',
    'form',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new StoreCampaignValidation(),
    t
  )
  const methods =
    useForm<StoreCampaignValidationInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })
  const { data: adsSlots = [] } =
    useSearchAdsSlotsQuery(slotsQuery)
  const [storeCampaign] =
    useStoreCampaignMutation()
  const navigateTo = useNavigate()

  const watchAdvertisements = methods.watch(
    'advertisements'
  )

  useEffect(() => {
    methods.setValue(
      'advertisements',
      adsSlots.map(
        (slot) => ({
          slot_id: slot.id,
          slot_name: slot.name,
          slot_type: slot.type,
          view_ids:
            slot.views?.map((view) => view.id) ||
            [],
          categories: [],
          is_active: true,
          is_target_blank: true,
          generate_form: false,
          capping_days_period: '',
          capping_limit: '',
          daily_display_limit: '',
          total_display_limit: '',
          mobile_pixel_url: '',
          desktop_pixel_url: '',
        }),
        { shouldValidate: false }
      )
    )
  }, [adsSlots])

  const onSubmit = async (
    data: StoreCampaignValidationInterface
  ) => {
    const advertisementsForStore =
      data.advertisements.filter(
        (ad) => ad.generate_form
      )

    if (advertisementsForStore.length === 0) {
      toast.error(
        t('ads:create.error.no_advertisements')
      )
      return
    }

    const transformedData: StoreCampaignRequestInterface =
      {
        advertisements:
          advertisementsForStore.map((ad) => {
            const filteredData = _.omit(ad, [
              'generate_form',
              'slot_name',
              'slot_type',
            ])

            return {
              ...filteredData,
              ...data.base,
              mobile_click_url: data.base
                .mobile_click_url
                ? data.base.mobile_click_url
                : data.base.desktop_click_url,
              categories: ad.categories.map(
                (category) => ({
                  ...category,
                  category_ids:
                    category.category_ids.join(
                      ','
                    ),
                })
              ),
              view_ids: ad.view_ids.join(','),
            }
          }),
      }

    try {
      await storeCampaign(
        serialize(transformedData, {
          indices: true,
          booleansAsIntegers: true,
          nullsAsUndefineds: true,
        })
      ).unwrap()

      toast.success(t('ads:create.success'))

      const whereRedirect: 'list' | 'create' =
        await redirectOrCreateModal()

      match(whereRedirect)
        .with('list', () => navigateTo('/ads'))
        .with('create', () =>
          window.location.reload()
        )
        .exhaustive()
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col p-8'}>
      <FormProvider {...methods}>
        <form
          className={'flex flex-col gap-y-2'}
          onSubmit={methods.handleSubmit(
            onSubmit
          )}
        >
          <div className={'py-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
          <GeneralInfoForm
            title={t('ads:create.title')}
          />
          {watchAdvertisements.map(
            (slot, index) => (
              <SlotForm
                key={index}
                index={index}
                slotId={slot.slot_id}
                slotType={slot.slot_type}
                slotName={slot.slot_name}
              />
            )
          )}
          <div className={'py-4 mb-12 lg:mb-0'}>
            <Button
              variant={'contained'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export { AdsCreateScreen }
