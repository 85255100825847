import React, { FC, useEffect } from 'react'
import { FormInterface } from '@/app/types'
import { StoreUserRequestInterface } from '@/features/users/redux'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import { StoreUserValidation } from '../rules'
import {
  FormProvider,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Card from '@/features/components/cards'
import {
  BaseForm,
  PasswordForm,
} from '@/features/users/resources/components'
import { Button } from '@/features/components/buttons/button'
import _ from 'lodash'

const Form: FC<
  FormInterface<StoreUserRequestInterface>
> = ({ onSubmit, errors }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
  ])
  const {
    schema,
    defaultValues,
    resolveValidationErrors,
  } = useValidation(new StoreUserValidation(), t)
  const methods =
    useForm<StoreUserRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (errors) {
      const err = resolveValidationErrors(errors)

      _.forEach(err, (value, key) => {
        methods.setError(
          key as keyof StoreUserRequestInterface,
          {
            type: 'manual',
            message: value,
          }
        )
      })
    }
  }, [errors])

  return (
    <Card>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(
            onSubmit
          )}
          className={'flex flex-col gap-y-6'}
        >
          <BaseForm
            containerClassName={
              'lg:grid !grid-cols-4 gap-4'
            }
          >
            <PasswordForm />
          </BaseForm>
          <div className={'flex pt-6'}>
            <Button
              variant={'contained'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  )
}

export { Form }
