import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import {
  UpdateUserRequestInterface,
  UserInterface,
} from '@/features/users/redux'
import { FormControl } from '@mui/base'
import {
  FormHelperText,
  TextInput,
} from '@/components'
import { Checkbox } from '@/features/components/inputs/checkbox'
import clsx from 'clsx'
import { Agreements } from './partials'

const BaseForm: FC<{
  children?: React.ReactNode
  containerClassName?: string
  data?: UserInterface
}> = ({
  children,
  containerClassName,
  data,
}): React.ReactNode => {
  const { t } = useTranslation(['form'])
  const { control } =
    useFormContext<UpdateUserRequestInterface>()

  return (
    <div
      className={clsx(
        'flex flex-col gap-4',
        containerClassName
      )}
    >
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <TextInput
              label={t('form:labels.name')}
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={'name'}
        control={control}
      />
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <TextInput
              type={'email'}
              label={t('form:labels.email')}
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={'email'}
        control={control}
      />
      <div
        className={'grid lg:grid-cols-3 gap-4'}
      >
        <div
          className={
            'grid grid-cols-3 gap-4 lg:col-span-3 w-full'
          }
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <TextInput
                  label={t('form:labels.prefix')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'phone_prefix'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                className={'col-span-2'}
                error={!!error}
              >
                <TextInput
                  label={t(
                    'form:labels.phone_number'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'phone_number'}
            control={control}
          />
        </div>
      </div>
      {children}
      <div
        className={
          'col-span-4 flex flex-col gap-y-2'
        }
      >
        <Controller
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              onChange={field.onChange}
              label={t(
                'form:labels.email_confirmed'
              )}
            />
          )}
          name={'is_active'}
          control={control}
        />
        <Controller
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              onChange={field.onChange}
              label={t('form:labels.is_blocked')}
            />
          )}
          name={'is_blocked'}
          control={control}
        />
        <Agreements data={data} />
      </div>
    </div>
  )
}

export { BaseForm }
