import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonLink,
} from '@/features/components/buttons/button'
import { blockModal } from '@/features/posts/posts/resources/views/edit/_components/modals/blockModal'
import clsx from 'clsx'
import { useSendPostPublishedMailMutation } from '@/features/posts/posts/redux/postAPI'
import { toast } from 'react-toastify'
import { PostStatusEnum } from '@/features/posts/posts/redux/enums/status'

export const ButtonStack: React.FC<{
  blocked: boolean
  id: string | number
  policeReportUrl: string
  status: PostStatusEnum
}> = ({
  blocked,
  id,
  policeReportUrl,
  status,
}): React.ReactNode => {
  const { t } = useTranslation(['posts/posts'])
  const [sendMail] =
    useSendPostPublishedMailMutation()

  const handleSendMessage = () => {
    sendMail(id)
      .unwrap()
      .then(() =>
        toast.success(
          t(
            'posts/posts:edit.send_message.success'
          )
        )
      )
  }

  const handleBlock = () => {
    void blockModal({
      isBlocked: blocked,
      postId: id,
    })
  }

  return (
    <div
      className={
        'flex flex-col lg:flex-row gap-4 lg:items-center'
      }
    >
      <Button
        variant={'contained'}
        className={clsx({
          '!ripple-bg-red-600': !blocked,
          '!ripple-bg-green-600': blocked,
        })}
        onClick={handleBlock}
      >
        {t(
          `posts/posts:edit.button_stack.${
            blocked ? 'unblock' : 'block'
          }`
        )}
      </Button>
      <ButtonLink
        fullWidth
        variant={'contained'}
        className={'!ripple-bg-red-600'}
        target={'_blank'}
        to={policeReportUrl}
      >
        {t(
          'posts/posts:edit.button_stack.download_report'
        )}
      </ButtonLink>
      {status === PostStatusEnum.PUBLISHED && (
        <Button
          className={'col-span-2'}
          variant={'contained'}
          onClick={handleSendMessage}
        >
          {t(
            'posts/posts:edit.button_stack.send_message'
          )}
        </Button>
      )}
    </div>
  )
}
