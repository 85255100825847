import {
  useCallback,
  useRef,
  useState,
} from 'react'
import { Button as BaseButton } from '@/features/components/buttons/button'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

const useFilters = () => {
  const [showFilters, setShowFilters] =
    useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation(['utils'])
  const [searchParams, setSearchParams] =
    useSearchParams()

  const handleClick = useCallback(() => {
    if (showFilters) {
      setSearchParams({})
    }

    setShowFilters(!showFilters)
  }, [showFilters, setSearchParams])

  const TriggerFilters = () => (
    <BaseButton
      variant={'contained'}
      onClick={handleClick}
    >
      {!showFilters
        ? t('utils:filters.show')
        : t('utils:filters.hide')}
    </BaseButton>
  )

  return { TriggerFilters, ref, showFilters }
}

export { useFilters }
