import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  RowSelectionState,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import {
  AdsInterface,
  useDeleteAdsMutation,
  useGetAdsListQuery,
  useResetAdsStatisticsMutation,
  useUpdateAdsStatusMutation,
} from '@/features/ads/ads/redux'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { match, Pattern } from 'ts-pattern'
import Carbon from '@/utils/carbon'
import {
  faSync,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons'

type Props = {
  query: Query
  onRowSelectionChange: (
    selectedIds: number[]
  ) => void
  shouldRefetch: boolean
  onRefetched: Dispatch<SetStateAction<boolean>>
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  onRowSelectionChange,
  shouldRefetch,
  onRefetched,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'ads',
    'utils',
  ])
  const [rowSelection, setRowSelection] =
    useState<Record<number, boolean>>({})
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      baseQuery
        .page(pagination.pageIndex)
        .includes('slot'),
    [pagination, baseQuery]
  )
  const { data: apiData, refetch } =
    useGetAdsListQuery(query.url())
  const [data, setData] = useState<
    AdsInterface[]
  >([])
  const [deleteAds] = useDeleteAdsMutation()
  const [resetStatistics] =
    useResetAdsStatisticsMutation()
  const [updateStatus] =
    useUpdateAdsStatusMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  useEffect(() => {
    if (shouldRefetch) {
      void refetch()
        .unwrap()
        .then(() => {
          onRefetched(false)
        })
    }
  }, [shouldRefetch])

  const handleRowSelectionChange = useCallback(
    (keys: Updater<RowSelectionState>) => {
      setRowSelection(keys)

      const selectedIds = Object.keys(keys).map(
        (key) => data[Number(key)].id
      )
      onRowSelectionChange(selectedIds)
    },
    []
  )

  const onDelete = async (id: number) => {
    try {
      await deleteAds(id).unwrap()
      toast.success(t('ads:list.deleted'))
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  const onResetStatistics = async (
    id: number
  ) => {
    try {
      await resetStatistics(id).unwrap()
      toast.success(
        t('ads:list.reset_statistics')
      )
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  const onChangeStatus = async (id: number) => {
    const ads = data.find((ad) => ad.id === id)

    if (!ads) {
      return
    }

    try {
      await updateStatus({
        id,
        status: !ads.is_active,
      }).unwrap()
      toast.success(t('ads:list.status_updated'))
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  const table = useReactTable({
    columns: columns(
      t,
      onDelete,
      onResetStatistics,
      onChangeStatus
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableMultiRowSelection: true,
    manualPagination: true,
    enableHiding: true,
    state: {
      pagination,
      rowSelection,
      columnVisibility: visibility,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange:
      handleRowSelectionChange,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<AdsInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onResetStatistics: (id: number) => void,
  onUpdateStatus: (id: number) => void
) => [
  columnBuilder.display({
    id: 'select',
    header: () => <span>#</span>,
    enableSorting: false,
    meta: {
      cellClassName: 'text-center',
      columnClassName: 'text-center',
    },
    maxSize: 50,
    cell: ({ row }) => (
      <input
        type={'checkbox'}
        className={
          'w-[16px] h-[16px] accent-primary'
        }
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
        disabled={!row.getCanSelect()}
      />
    ),
    enableHiding: false,
  }),
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'image',
    header: t('form:labels.image'),
    cell: ({ row }) => (
      <img
        src={row.original.desktop_image_url}
        alt={''}
        className={
          'h-[120px] w-[180px] object-cover'
        }
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('slot.name', {
    id: 'slot_name',
    header: t('form:labels.slot_name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('is_active', {
    id: 'is_active',
    header: t('form:labels.status'),
    cell: ({ row }) =>
      match([
        row.original.is_active,
        row.original.started_at,
        row.original.ended_at,
      ])
        .with(
          Pattern.when(
            ([status, startedAt]) =>
              status &&
              new Carbon(startedAt).gt(new Date())
          ),
          () => t('form:labels.scheduled')
        )
        .with(
          Pattern.when(
            ([status, startedAt, endedAt]) =>
              status &&
              new Carbon(endedAt).lt(new Date())
          ),
          () => t('form:labels.finished')
        )
        .with(
          [true, Pattern.string, Pattern.string],
          () => t('form:labels.active')
        )
        .with(
          [false, Pattern.string, Pattern.string],
          () => t('form:labels.inactive')
        )
        .exhaustive(),
    enableHiding: true,
  }),
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.display({
    header: t('form:labels.count_of_click'),
    id: 'count_of_click',
    cell: ({ row }) => (
      <div className={'flex flex-col gap-y-1'}>
        <span>
          {t('form:labels.desktop')}:{' '}
          {row.original.desktop_clicks_count}
        </span>
        <span>
          {t('form:labels.mobile')}:{' '}
          {row.original.mobile_clicks_count}
        </span>
        <span>
          {t('form:labels.total')}:{' '}
          {row.original.total_clicks_count}
        </span>
      </div>
    ),
    enableHiding: true,
  }),
  columnBuilder.display({
    header: t('form:labels.count_of_views'),
    id: 'count_of_view',
    cell: ({ row }) => (
      <div className={'flex flex-col gap-y-1'}>
        <span>
          {t('form:labels.desktop')}:{' '}
          {row.original.desktop_views_count}
        </span>
        <span>
          {t('form:labels.mobile')}:{' '}
          {row.original.mobile_views_count}
        </span>
        <span>
          {t('form:labels.total')}:{' '}
          {row.original.total_views_count}
        </span>
      </div>
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('total_ctr', {
    id: 'total_ctr',
    header: t('form:labels.total_ctr'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onEdit: {
              action: `/ads/${row.original.id}/edit`,
              permission:
                PermissionEnum.ADVERTISEMENT_SAVE,
            },
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.ADVERTISEMENT_DESTROY,
            },
          }),
          {
            icon: row.original.is_active
              ? faToggleOn
              : faToggleOff,
            onClick: () =>
              onUpdateStatus(row.original.id),
            permission:
              PermissionEnum.ADVERTISEMENT_SAVE,
            buttonClassName: row.original
              .is_active
              ? 'bg-green-500'
              : 'bg-red-400',
            tooltip: row.original.is_active
              ? 'ads:list.tooltips.deactivate'
              : 'ads:list.tooltips.activate',
          },
          {
            icon: faSync,
            onClick: () =>
              onResetStatistics(row.original.id),
            permission:
              PermissionEnum.ADVERTISEMENT_SAVE,
            buttonClassName: 'bg-yellow-400',
            tooltip:
              'ads:list.tooltips.reset_statistics',
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
