import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ChangeCompanyRequestInterface } from '@/features/users/redux'
import {
  UserCompanyProfileEnum,
  UserCompanyTypeEnum,
} from '@/features/users/redux/enums'

export default class ChangeCompanyValidation
  implements
    ValidationInterface<ChangeCompanyRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    ChangeCompanyRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      nip: yup
        .string()
        .nullable()
        .matches(
          /^\d{10}$/,
          t(
            'validation:field_must_be_integer_equal',
            { length: 10 }
          )
        )
        .transform((value) =>
          value === null || value.trim() === ''
            ? null
            : value
        ),

      farm_size_in_hectares: yup
        .number()
        .nullable(),
      primary_profile: yup
        .mixed<UserCompanyProfileEnum>()
        .oneOf(
          Object.values(UserCompanyProfileEnum)
        )
        .nullable(),
      secondary_profile: yup
        .mixed<UserCompanyProfileEnum>()
        .oneOf(
          Object.values(UserCompanyProfileEnum)
        )
        .nullable(),
      type: yup
        .mixed<UserCompanyTypeEnum>()
        .oneOf(Object.values(UserCompanyTypeEnum))
        .nullable(),
    })
  }

  defaultValues(): ChangeCompanyRequestInterface {
    return {
      nip: '',
      farm_size_in_hectares: null,
      primary_profile: null,
      secondary_profile: null,
      type: null,
    }
  }
}
