import React, { useEffect } from 'react'
import {
  PostInterface,
  UpdateAttributeInterface,
  UpdatePostDataRequest,
} from '@/features/posts/posts/redux/types'
import { useTranslation } from 'react-i18next'
import {
  useFieldArray,
  UseFormReturn,
} from 'react-hook-form'
import Card from '@/features/components/cards'
import { Button } from '@/features/components/buttons/button'
import { associateAttributeFormModal } from '@/features/posts/posts/resources/views/edit/_components/form/base/_components/associateAttributeForm'
import { TFunction } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { AttributeTypeEnum } from '@/features/posts/attributes/redux/enums/attributeType'

type Props = {
  data?: PostInterface
  form: UseFormReturn<UpdatePostDataRequest>
}
export const AttributesForm: React.FC<Props> = ({
  data,
  form: { control, setValue },
}): React.ReactNode => {
  const { t } = useTranslation([
    'posts/posts',
    'form',
  ])
  const { fields, remove, append, update } =
    useFieldArray({
      control,
      name: 'attributes',
    })

  const handleEdit = (id: string) => {
    const attribute = fields.find(
      (field) => field.id === id
    )

    if (!attribute || !data) return

    associateAttributeFormModal({
      categoryId: data.category.id,
      data: attribute,
    }).then(
      (result: UpdateAttributeInterface) => {
        const index = fields.findIndex(
          (field) => field.id === id
        )

        if (index === -1) return

        update(index, result)
      }
    )
  }

  useEffect(() => {
    if (data) {
      setValue(
        'attributes',
        data.attributes.map((attribute) => ({
          attribute_category_id:
            attribute.original_attribute_value
              .attribute_id,
          value:
            attribute.original_attribute_value
              .value,
          value_ids:
            attribute.original_attribute_value
              .value_ids,
          attribute_id:
            attribute.original_attribute_value
              .attribute_id,
          value_id:
            attribute.original_attribute_value
              .value_id,
          type: attribute.type,
          name: attribute.original_attribute_value
            .name,
          human_value: attribute.value,
        }))
      )
    }
  }, [data])

  const handleAddAttribute = () => {
    if (data) {
      associateAttributeFormModal({
        categoryId: data.category.id,
      }).then(
        (result: UpdateAttributeInterface) => {
          const isAttributeAlreadyAdded =
            fields.find(
              (field) =>
                field.attribute_category_id ===
                result.attribute_category_id
            )

          if (isAttributeAlreadyAdded) {
            toast.warning(
              t(
                'posts/posts:edit.attributes.already_added'
              )
            )
            return
          }

          append(result)
        }
      )
    }
  }

  return (
    <Card className={'flex flex-col gap-y-4'}>
      <div
        className={'flex justify-between gap-y-6'}
      >
        <span className={'font-medium'}>
          {t('posts/posts:edit.attributes.title')}
        </span>
        <Button
          onClick={handleAddAttribute}
          variant={'contained'}
          size={'xsmall'}
        >
          {t(
            'posts/posts:edit.attributes.add_button'
          )}
        </Button>
      </div>
      <div className={'flex flex-col gap-y-2'}>
        <table className={'w-full'}>
          <thead>
            <tr>
              <td className={'font-medium'}>
                {t('form:labels.attribute_name')}
              </td>
              <td className={'font-medium'}>
                {t('form:labels.value')}
              </td>
              <td className={'font-medium'}>
                {t('form:labels.type')}
              </td>
              <td className={'font-medium'}>
                {t('form:labels.actions')}
              </td>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => (
              <tr key={field.id}>
                <td>{field.name}</td>
                <td>
                  {resolveValue(
                    field.human_value,
                    t,
                    field.type
                  )}
                </td>
                <td>
                  {t(
                    `utils:attribute_types.${field.type}`
                  )}
                </td>
                <td
                  className={
                    'flex items-center gap-x-2'
                  }
                >
                  <button
                    type={'button'}
                    onClick={() => remove(index)}
                  >
                    <FontAwesomeIcon
                      className={'text-red-600'}
                      icon={faTrash}
                    />
                  </button>
                  {field.type !==
                    AttributeTypeEnum.CERTIFICATE && (
                    <button
                      type={'button'}
                      onClick={() =>
                        handleEdit(field.id)
                      }
                    >
                      <FontAwesomeIcon
                        className={
                          'text-blue-600'
                        }
                        icon={faEdit}
                      />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  )
}

/**
 * Resolve value to be displayed
 * @param value
 * @param t
 * @param type
 */
const resolveValue = (
  value: UpdateAttributeInterface['value'],
  t: TFunction,
  type: AttributeTypeEnum
): string | number | React.ReactNode => {
  switch (type) {
    case AttributeTypeEnum.CERTIFICATE:
      return (
        <img
          src={value as string}
          alt={'-'}
          className={'max-h-8'}
        />
      )
    case AttributeTypeEnum.RANGE:
      const rangeValue = value as {
        from: number
        to: number
      }
      return `${rangeValue?.from} - ${rangeValue?.to}`
    case AttributeTypeEnum.BOOL:
      return value
        ? t('form:labels.yes')
        : t('form:labels.no')
    default:
      return value as string | number
  }
}
