import { Table } from '@tanstack/react-table'
import { useCallback, useState } from 'react'
import { VisibleColumnInterface } from '@/components'

export const useColumnVisibility = () => {
  const [
    visibilityColumnsWithLabels,
    setVisibilityColumnsWithLabels,
  ] = useState<VisibleColumnInterface[]>([])

  const [
    visibilityColumns,
    setVisibilityColumns,
  ] = useState<Record<string, boolean>>({})

  const handleSetAvailableColumns = useCallback(
    <T>(table: Table<T>) => {
      const columns: VisibleColumnInterface[] =
        table
          .getVisibleFlatColumns()
          .filter(
            (column) =>
              column.columnDef.enableHiding
          )
          .map((column) => ({
            id: column.id,
            header: column.columnDef
              .header as string,
            visible: true,
          }))

      const colsWithoutLabels: Record<
        string,
        boolean
      > = {}

      columns.forEach((column) => {
        Object.assign(colsWithoutLabels, {
          [column.id]: true,
        })
      })

      setVisibilityColumns(colsWithoutLabels)
      setVisibilityColumnsWithLabels(columns)
    },
    []
  )

  const handleToggleColumn = useCallback(
    (columnId: string) => {
      setVisibilityColumns((prev) => ({
        ...prev,
        [columnId]: !prev[columnId],
      }))

      setVisibilityColumnsWithLabels(
        (prevState) =>
          prevState.map((column) => {
            if (column.id === columnId) {
              return {
                ...column,
                visible: !column.visible,
              }
            }
            return column
          })
      )
    },
    []
  )

  return {
    visibilityColumnsWithLabels,
    visibilityColumns,
    handleSetAvailableColumns,
    handleToggleColumn,
  }
}
