import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { BaseTable } from '@/components'
import usePagination from '@/utils/hooks/usePagination'
import {
  useGetUserLogsQuery,
  UserLogsInterface,
} from '@/features/users/redux'
import Carbon from '@/utils/carbon'
import { Query } from '@/utils/query'

type Props = {
  userId: string | number
}
export const Table: React.FC<Props> = ({
  userId,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'users',
    'utils',
  ])

  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination]
  )

  const { data: apiData } = useGetUserLogsQuery({
    query: query.url(),
    userId: userId,
  })
  const [data, setData] = useState<
    UserLogsInterface[]
  >([])

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination,
    },
  })

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<UserLogsInterface>()

const columns = (t: TFunction) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
  }),
  columnBuilder.accessor('ip', {
    id: 'ip',
    header: t('form:labels.ip'),
  }),
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.created_at'),
    cell: ({ row }) =>
      new Carbon(
        row.original.created_at
      ).toDateTimeLocal(),
  }),
  columnBuilder.accessor('success', {
    id: 'success',
    header: t('form:labels.success'),
    cell: ({ row }) =>
      row.original.success
        ? t('form:labels.yes')
        : t('form:labels.no'),
  }),
]
