export enum PermissionEnum {
  ADMIN = 'admin',
  CATEGORY_DESTROY = 'categories.destroy',
  CATEGORY_SAVE = 'categories.save',
  CATEGORY_SHOW = 'categories.show',
  ATTRIBUTE_DESTROY = 'attributes.destroy',
  ATTRIBUTE_SAVE = 'attributes.save',
  ATTRIBUTE_SHOW = 'attributes.show',
  FAQ_SHOW = 'faq.show',
  FAQ_SAVE = 'faq.save',
  FAQ_DESTROY = 'faq.destroy',
  ROLE_SAVE = 'roles.save',
  ROLE_SHOW = 'roles.show',
  ROLE_DESTROY = 'roles.destroy',
  POST_SAVE = 'posts.save',
  POST_SHOW = 'posts.show',
  POST_DESTROY = 'posts.destroy',
  CENSORSHIP_SAVE = 'censorship.save',
  CENSORSHIP_DESTROY = 'censorship.destroy',
  CENSORSHIP_SHOW = 'censorship.show',
  SURVEY_SAVE = 'surveys.save',
  SURVEY_DESTROY = 'surveys.destroy',
  SURVEY_SHOW = 'surveys.show',
  ATTRIBUTE_CATEGORY_DESTROY = 'attribute-categories.destroy',
  ATTRIBUTE_CATEGORY_SAVE = 'attribute-categories.save',
  ATTRIBUTE_CATEGORY_SHOW = 'attribute-categories.show',
  INVOICE_SHOW = 'invoices.show',
  INVOICE_SAVE = 'invoices.save',
  INVOICE_DESTROY = 'invoices.destroy',
  ISSUE_SHOW = 'issues.show',
  ISSUE_SAVE = 'issues.save',
  ISSUE_DESTROY = 'issues.destroy',
  PAGE_SHOW = 'pages.show',
  PAGE_SAVE = 'pages.save',
  PAGE_DESTROY = 'pages.destroy',
  ADVERTISER_SHOW = 'advertisers.show',
  ADVERTISER_SAVE = 'advertisers.save',
  ADVERTISER_DESTROY = 'advertisers.destroy',
  SERVICE_SHOW = 'services.show',
  SERVICE_SAVE = 'services.save',
  SERVICE_DESTROY = 'services.destroy',
  PACKAGE_SHOW = 'packages.show',
  PACKAGE_SAVE = 'packages.save',
  PACKAGE_DESTROY = 'packages.destroy',
  BLACKLIST_SHOW = 'blacklist.show',
  BLACKLIST_SAVE = 'blacklist.save',
  BLACKLIST_DESTROY = 'blacklist.destroy',
  ARTICLE_GALLERY_SHOW = 'article-gallery.show',
  ARTICLE_GALLERY_SAVE = 'article-gallery.save',
  ARTICLE_GALLERY_DESTROY = 'article-gallery.destroy',
  VIEW_SHOW = 'views.show',
  VIEW_SAVE = 'views.save',
  VIEW_DESTROY = 'views.destroy',
  SLOT_SHOW = 'slots.show',
  SLOT_SAVE = 'slots.save',
  SLOT_DESTROY = 'slots.destroy',
  CATEGORY_BUNDLE_SHOW = 'category-bundles.show',
  CATEGORY_BUNDLE_SAVE = 'category-bundles.save',
  CATEGORY_BUNDLE_DESTROY = 'category-bundles.destroy',
  ARTICLE_CATEGORY_SHOW = 'article-categories.show',
  ARTICLE_CATEGORY_SAVE = 'article-categories.save',
  ARTICLE_CATEGORY_DESTROY = 'article-categories.destroy',
  ARTICLE_SHOW = 'articles.show',
  ARTICLE_SAVE = 'articles.save',
  ARTICLE_DESTROY = 'articles.destroy',
  ADVERTISEMENT_SHOW = 'advertisements.show',
  ADVERTISEMENT_SAVE = 'advertisements.save',
  ADVERTISEMENT_DESTROY = 'advertisements.destroy',
  INTERNAL_QUOTATIONS_PARTNER_SHOW = 'internal-quotations-partners.show',
  INTERNAL_QUOTATIONS_PARTNER_SAVE = 'internal-quotations-partners.save',
  INTERNAL_QUOTATIONS_PARTNER_DESTROY = 'internal-quotations-partners.destroy',
  ARTICLE_COMMENT_SHOW = 'article-comments.show',
  ARTICLE_COMMENT_SAVE = 'article-comments.save',
  ARTICLE_COMMENT_DESTROY = 'article-comments.destroy',
  PROTECTION_SHOW = 'protections.show',
  PROTECTION_SAVE = 'protections.save',
  PROTECTION_DESTROY = 'protections.destroy',
  USER_SHOW = 'users.show',
  USER_SAVE = 'users.save',
  USER_DESTROY = 'users.destroy',
  USER_ADDRESS_SHOW = 'user-addresses.show',
  USER_ADDRESS_SAVE = 'user-addresses.save',
  USER_ADDRESS_DESTROY = 'user-addresses.destroy',
  EXTERNAL_QUOTATION_SOURCE_SHOW = 'external-quotations-source.show',
  EXTERNAL_QUOTATION_SOURCE_SAVE = 'external-quotations-source.save',
  INTERNAL_QUOTATION_SHOW = 'internal-quotations.show',
  INTERNAL_QUOTATION_SAVE = 'internal-quotations.save',
  INTERNAL_QUOTATION_DESTROY = 'internal-quotations.destroy',
  INTERNAL_QUOTATION_API_SHOW = 'internal-quotation-apis.show',
  INTERNAL_QUOTATION_API_SAVE = 'internal-quotation-apis.save',
  INTERNAL_QUOTATION_API_DESTROY = 'internal-quotation-apis.destroy',
  AGREEMENT_SHOW = 'agreements.show',
  AGREEMENT_SAVE = 'agreements.save',
  AGREEMENT_DESTROY = 'agreements.destroy',
  SETTING_SAVE = 'settings.save',
  SETTING_SHOW = 'settings.show',
  ACCOUNTING_REPORT_SHOW = 'accounting-reports.show',
  ACCOUNTING_REPORT_SAVE = 'accounting-reports.save',
  ACCOUNTING_REPORT_DESTROY = 'accounting-reports.destroy',
}
