import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import { ChangePasswordRequestInterface } from '@/features/users/redux'
import { FormControl } from '@mui/base'
import {
  FormHelperText,
  TextInput,
} from '@/components'

const PasswordForm = (): React.ReactNode => {
  const { t } = useTranslation(['form'])
  const { control } =
    useFormContext<ChangePasswordRequestInterface>()

  return (
    <Fragment>
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <TextInput
              type={'password'}
              label={t('form:labels.password')}
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={'password'}
        control={control}
      />
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <TextInput
              type={'password'}
              label={t(
                'form:labels.password_confirmation'
              )}
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={'password_confirmation'}
        control={control}
      />
    </Fragment>
  )
}

export { PasswordForm }
