import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import Modal from '@/features/components/modals/modal'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Button } from '@/features/components/buttons/button'
import SaveInternalQuotationPartnerValidation from './validations/SaveInternalQuotationPartnerValidation'
import ModalTitle from '@/features/components/modals/ModalTitle'
import {
  InternalQuotationPartnerInterface,
  SaveInternalQuotationsPartnerInterface,
} from '@/features/internalQuotationsPartners/redux/types'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import _ from 'lodash'
import { InternalQuotationsPartnersStatusEnum } from '@/features/internalQuotationsPartners/redux/enums/InternalQuotationsPartnersStatusEnum'
import { Label } from '@/features/components/inputs/label'
import { Checkbox } from '@/features/components/inputs/checkbox'

type Props = {
  data?: InternalQuotationPartnerInterface
  title: string
} & InstanceProps<
  SaveInternalQuotationsPartnerInterface,
  unknown
>

const Form: React.FC<Props> = ({
  data,
  isOpen,
  onReject,
  onResolve,
  title,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveInternalQuotationPartnerValidation(),
    t
  )

  const { control, setValue, handleSubmit } =
    useForm<SaveInternalQuotationsPartnerInterface>(
      {
        resolver: yupResolver(schema),
        defaultValues,
      }
    )

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('status', data.status)
      setValue('website', data.website)
    }
  }, [data])

  return (
    <Modal open={isOpen} onClose={() => onReject}>
      <Modal.Content size={'w-[500px]'}>
        <div className={'flex flex-col gap-y-6'}>
          <ModalTitle
            title={title}
            onClose={onReject}
          />
          <form
            className={'flex flex-col gap-y-8'}
            onSubmit={handleSubmit(onResolve)}
          >
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    label={t('form:labels.name')}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'name'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    label={t('form:labels.url')}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'website'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl error={!!error}>
                  <Select
                    value={field.value}
                    onChange={(_, value) =>
                      field.onChange(value)
                    }
                    label={t(
                      'form:labels.status'
                    )}
                  >
                    {_.map(
                      InternalQuotationsPartnersStatusEnum,
                      (value, index) => (
                        <Option
                          value={value}
                          key={index}
                        >
                          {t(
                            `utils:internal_quotations_partners_statuses.${value}`
                          )}
                        </Option>
                      )
                    )}
                  </Select>
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'status'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  className={
                    'flex flex-col gap-y-1'
                  }
                  error={!!error}
                >
                  <Label
                    label={t('form:labels.image')}
                  />
                  <input
                    type={'file'}
                    accept={'image/svg+xml'}
                    onChange={(event) => {
                      event.target.files
                        ? field.onChange(
                            event.target.files[0]
                          )
                        : field.onChange(null)
                    }}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'logo.file'}
              control={control}
            />
            {data && (
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl error={!!error}>
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      label={t(
                        'form:labels.remove_img'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'logo.remove'}
                control={control}
              />
            )}

            <div className={'flex gap-x-4 pt-4'}>
              <Button
                variant={'contained'}
                type={'submit'}
                className={'w-full'}
              >
                {t('form:buttons.save')}
              </Button>
              <Button
                variant={'outlined'}
                onClick={() => onReject()}
                className={'w-full'}
              >
                {t('form:buttons.cancel')}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const internalQuotationsPartnersModal =
  create(Form)
