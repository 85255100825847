import React from 'react'
import { ChildSidebarMenuItemInterface } from '@/utils/routes/privateRoutes/types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'

type Props = {
  submenu: ChildSidebarMenuItemInterface[]
  title: string
  open: boolean
  closeSidebar: () => void
}

export const ChildrenSidebar: React.FC<Props> = ({
  submenu,
  title,
  open,
  closeSidebar,
}): React.ReactNode => {
  const { t } = useTranslation(['layout'])

  return (
    <div
      className={clsx(
        {
          'border-r border-neutral-600 w-[312px]':
            open,
          'w-0': !open,
        },
        'fixed z-[9999] h-full overflow-hidden whitespace-nowrap bg-white pb-6 pt-4 text-m duration-500 ease-in-out lg:translate-x-[80px]'
      )}
    >
      {title && (
        <div
          className={
            'whitespace-nowrap px-7 text-l font-semibold'
          }
        >
          {t(title)}
        </div>
      )}
      <ul
        className={
          'flex flex-col gap-y-1 pt-[44px]'
        }
      >
        {submenu.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            onClick={closeSidebar}
            className={({ isActive }) =>
              clsx(
                {
                  'bg-[#F3F9FE] text-primary-600':
                    isActive,
                  'ml-[3px]': !isActive,
                },
                'overflow-hidden py-3 text-m'
              )
            }
            end
          >
            <li
              key={index}
              className={
                'flex flex-row items-center gap-x-3 px-7'
              }
            >
              <span className={'text-m'}>
                {t(item.title)}
              </span>
            </li>
          </NavLink>
        ))}
      </ul>
    </div>
  )
}
