import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

const url = import.meta.env
  .VITE_ELASTIC_SEARCH_API_URL

const elasticSearchAPI = createApi({
  tagTypes: ['ElasticSearchPost'],
  reducerPath: 'elasticSearchAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
  }),
  endpoints: (builder) => ({}),
})

export default elasticSearchAPI
