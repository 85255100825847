import { useTranslation } from 'react-i18next'
import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  AgreementInterface,
  useDeleteAgreementMutation,
  useGetPaginatedAgreementsQuery,
} from '@/features/agreements'
import { Query } from '@/utils/query'
import usePagination from '@/utils/hooks/usePagination'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import { agreementEditModal } from '../../edit'

const Table: FC<
  { baseQuery: Query } & VisibleColumnsInterface
> = ({
  baseQuery,
  visibility,
  setAvailableColumns,
}) => {
  const { t } = useTranslation([
    'form',
    'utils',
    'agreements',
  ])
  const [data, setData] = useState<
    AgreementInterface[]
  >([])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => baseQuery.page(pagination.pageIndex),
    [pagination.pageIndex, baseQuery]
  )
  const { data: apiData } =
    useGetPaginatedAgreementsQuery(query.url())
  const [deleteAgreement] =
    useDeleteAgreementMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const handleDelete = async (id: number) => {
    try {
      await deleteAgreement(id).unwrap()
      toast.success(t('agreements:list.deleted'))
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  const handleEdit = async (id: number) => {
    try {
      await agreementEditModal({
        id,
      })
    } catch (error) {
      // empty block
    }
  }

  const table = useReactTable({
    columns: useMemo(
      () => columns(t, handleDelete, handleEdit),
      [t, columns]
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    onPaginationChange: setPagination,
    enableHiding: true,
    manualPagination: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
      setPagination={setPagination}
    />
  )
}

const columnBuilder =
  createColumnHelper<AgreementInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('required', {
    id: 'required',
    header: t('form:labels.required'),
    enableSorting: false,
    cell: ({ row }) =>
      row.original.required
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('visible', {
    id: 'visible',
    header: t('form:labels.visible'),
    enableSorting: false,
    cell: ({ row }) =>
      row.original.visible
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('text_link', {
    id: 'text_link',
    header: t('form:labels.text_link'),
    enableSorting: false,
    cell: ({ row }) =>
      row.original.text_link &&
      row.original.file_url ? (
        <a
          href={row.original.file_url}
          target='_blank'
          rel='noreferrer'
        >
          {row.original.text_link}
        </a>
      ) : (
        '-'
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('slug', {
    id: 'slug',
    header: t('form:labels.slug'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.AGREEMENT_DESTROY,
          },
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.AGREEMENT_SAVE,
          },
        })}
      />
    ),
  }),
]

export { Table }
