import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '@/app/hooks'
import { LogInPropsInterface } from '@/features/auth/redux/types'
import { FormControl } from '@mui/base'
import {
  loginMutation,
  logoutRequestMutation,
  useLazyGetMeQuery,
} from '@/features/auth/redux/authAPI'
import { toast } from 'react-toastify'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Input } from '@/features/components/inputs/input'
import { Button } from '@/features/components/buttons/button'
import LoginValidation from './validations/loginValidation'
import useValidation from '@/utils/hooks/useValidation'
import { useNavigate } from 'react-router-dom'
import { PermissionEnum } from '@/features/permissions/redux/types'
import igritAPI from '@/utils/api/igrit'
import elasticSearchAPI from '@/utils/api/elasticSearch'
import nominatimAPI from '@/utils/api/nominatim'

export const LoginScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'auth',
      'form',
      'validation',
    ])
    const { schema, defaultValues } =
      useValidation(new LoginValidation(), t)
    const dispatch = useAppDispatch()
    const [getMe] = useLazyGetMeQuery()
    const navigate = useNavigate()

    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues,
      resolver: yupResolver(schema),
    })

    const handleLogout = () => {
      dispatch(logoutRequestMutation())
        .unwrap()
        .finally(() => {
          dispatch(igritAPI.util?.resetApiState())
          dispatch(
            elasticSearchAPI.util?.resetApiState()
          )
          dispatch(
            nominatimAPI.util?.resetApiState()
          )
          navigate('/login')
        })
    }

    const onSubmit = async (
      data: LogInPropsInterface
    ) => {
      try {
        const loginResponse = await dispatch(
          loginMutation(data)
        ).unwrap()
        const userData = await getMe().unwrap()

        const doesUserHaveAdminPermission =
          userData.permissions.includes(
            PermissionEnum.ADMIN
          )

        if (!doesUserHaveAdminPermission) {
          toast.error(
            t('auth:login.no_permission')
          )
          handleLogout()
          return
        }

        navigate('/')
        toast.success(t('auth:login.success'))
      } catch (error) {
        toast.error(t('auth:login.error'))
      }
    }
    return (
      <Fragment>
        <div className={'flex flex-col gap-y-6'}>
          <Controller
            name={'email'}
            control={control}
            render={({ field }) => (
              <FormControl
                {...field}
                error={!!errors.email}
              >
                <Input
                  label={t('form:labels.email')}
                  name={field.name}
                  type={'email'}
                  placeholder={t(
                    'form:placeholders.email'
                  )}
                />
                <FormHelperText
                  message={errors.email?.message}
                />
              </FormControl>
            )}
          />
          <Controller
            name={'password'}
            control={control}
            render={({ field }) => (
              <FormControl
                {...field}
                error={!!errors.password}
              >
                <Input
                  label={t(
                    'form:labels.password'
                  )}
                  type={'password'}
                  name={field.name}
                  placeholder={t(
                    'form:placeholders.password'
                  )}
                />
                <FormHelperText
                  message={
                    errors.password?.message
                  }
                />
              </FormControl>
            )}
          />
          <Button
            variant={'contained'}
            onClick={handleSubmit(onSubmit)}
          >
            {t('auth:login.button')}
          </Button>
        </div>
      </Fragment>
    )
  }
