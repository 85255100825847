import igritAPI from '@/utils/api/igrit'
import {
  GetAdsSlotResponseInterface,
  GetAdsSlotsResponseInterface,
  SaveAdsSlotRequestInterface,
  SearchAdsSlotsResponseInterface,
} from '@/features/ads/slots/redux/types'
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetAdsSlotsQuery,
  useGetAdsSlotQuery,
  useStoreAdsSlotMutation,
  useUpdateAdsSlotMutation,
  useDeleteAdsSlotMutation,
  useSearchAdsSlotsQuery,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getAdsSlots: build.query<
      GetAdsSlotsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/ads/slots?${query}`,
      transformResponse: (
        response: GetAdsSlotsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('AdsSlots', result),
    }),
    getAdsSlot: build.query<
      GetAdsSlotResponseInterface['data'],
      number | string
    >({
      query: (id) => `/ads/slots/${id}`,
      transformResponse: (
        response: GetAdsSlotResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('AdsSlots', result),
    }),
    storeAdsSlot: build.mutation<
      void,
      SaveAdsSlotRequestInterface
    >({
      query: (data) => ({
        url: '/admin/ads/slots',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AdsSlots'],
    }),
    updateAdsSlot: build.mutation<
      void,
      {
        data: SaveAdsSlotRequestInterface
        id: number | string
      }
    >({
      query: ({ data, id }) => ({
        url: `/admin/ads/slots/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AdsSlots'],
    }),
    deleteAdsSlot: build.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/ads/slots/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdsSlots'],
    }),
    searchAdsSlots: build.query<
      SearchAdsSlotsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/ads/slots/search?${query}`,
      transformResponse: (
        response: SearchAdsSlotsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setArrayCache('AdsSlots', result),
    }),
  }),
})
