import React, { useEffect } from 'react'
import { Select2OptionInterface } from '@/app/types'
import {
  PostInterface,
  UpdatePostDataRequest,
} from '@/features/posts/posts/redux/types'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import {
  Controller,
  UseFormReturn,
} from 'react-hook-form'
import { FormControl } from '@mui/base'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { LatLngTuple } from 'leaflet'
import AsyncSelect from '@/features/components/inputs/asyncSelect/asyncSelect'
import _ from 'lodash'
import { SingleValue } from 'react-select'
import { useLazySearchCityQuery } from '@/utils/api/nominatim'

type Props = {
  data?: PostInterface
  form: UseFormReturn<UpdatePostDataRequest>
}

export const LocationForm: React.FC<Props> = ({
  data,
  form: {
    control,
    setValue,
    watch,
    formState: { errors },
  },
}): React.ReactNode => {
  const { t } = useTranslation(['posts/posts'])
  const [searchCities, { data: cities = [] }] =
    useLazySearchCityQuery()
  const watchLocation = watch('location')

  useEffect(() => {
    if (data) {
      setValue('location', {
        lat: data.location.lat,
        long: data.location.long,
      })
      setValue(
        'location_name',
        data.location_name
      )
    }
  }, [data])

  if (!data) {
    return <span></span>
  }

  const handleChangeLocation = (
    coordinates: LatLngTuple
  ) => {
    setValue('location', {
      lat: coordinates[0],
      long: coordinates[1],
    })
  }

  const _handleSearchLocation = (
    value: string,
    callback: (
      options: Select2OptionInterface[]
    ) => void
  ) => {
    searchCities(value)
      .unwrap()
      .then((response) =>
        callback(
          response.map((city) => ({
            label: `${city.address.country}, ${city.address.city}, ${city.address.state}`,
            value: city.place_id.toString(),
          }))
        )
      )
  }

  const handleSearchLocation = _.debounce(
    _handleSearchLocation,
    500
  )

  const handleSelectLocation = (
    option: SingleValue<Select2OptionInterface>
  ) => {
    if (!option) return

    const placeId = Number(option.value)
    const selectedCity = cities.find(
      (city) => city.place_id === placeId
    )

    if (selectedCity) {
      setValue('location', {
        lat: selectedCity.lat,
        long: selectedCity.lon,
      })
      setValue('location_name', option.label)
    }
  }

  return (
    <Card className={'flex flex-col gap-6'}>
      <span className={'font-medium'}>
        {t('posts/posts:edit.address.title')}
      </span>
      <div className={'flex flex-col gap-y-4'}>
        <Controller
          render={({ field }) => (
            <FormControl
              className={'z-[9999]'}
              {...field}
              error={!!errors.location_name}
            >
              <AsyncSelect
                onChange={handleSelectLocation}
                cacheOptions
                loadOptions={handleSearchLocation}
                value={{
                  label: field.value,
                  value: field.value,
                }}
              />
              <FormHelperText
                message={
                  errors.location_name?.message
                }
              />
            </FormControl>
          )}
          name={'location_name'}
          control={control}
        />
        <MapContainer
          center={[
            watchLocation?.lat,
            watchLocation?.long,
          ]}
          zoom={13}
          scrollWheelZoom={false}
          className={'min-h-[300px]'}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <LocationMarker
            onChange={handleChangeLocation}
            coordinates={[
              watchLocation?.lat,
              watchLocation?.long,
            ]}
          />
        </MapContainer>
      </div>
    </Card>
  )
}

type LocationProps = {
  coordinates: LatLngTuple
  onChange: (coordinates: LatLngTuple) => void
}

const LocationMarker: React.FC<LocationProps> = ({
  coordinates,
  onChange,
}): React.ReactNode => {
  const map = useMapEvents({
    click(e) {
      onChange([e.latlng.lat, e.latlng.lng])
    },
  })

  useEffect(() => {
    map.panTo(coordinates)
  }, [coordinates, map])

  return (
    <Marker position={coordinates}>
      <Popup>You are here</Popup>
    </Marker>
  )
}
