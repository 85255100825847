import igritAPI from '@/utils/api/igrit'
import {
  GetQuotationResultDetailsResponseInterface,
  SaveQuotationResultDetailsRequestInterface,
} from '@/features/internalQuotationsResultsDetails/redux/types'
import { setArrayCache } from '@/utils/api/rtkHelper'

export const {
  useGetInternalQuotationResultsDetailsQuery,
  useUpdateInternalQuotationResultDetailMutation,
  useDeleteInternalQuotationResultDetailMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getInternalQuotationResultsDetails:
      build.query<
        GetQuotationResultDetailsResponseInterface['data'],
        { id: string | number; query: string }
      >({
        query: ({ id, query }) =>
          `/admin/internal-quotation-results/${id}/internal-quotation-result-details?${query}`,
        transformResponse: (
          response: GetQuotationResultDetailsResponseInterface
        ) => response.data,
        providesTags: (result) =>
          setArrayCache(
            'InternalQuotationResultDetail',
            result
          ),
      }),
    updateInternalQuotationResultDetail:
      build.mutation<
        void,
        {
          resultId: string | number
          quotationId: string | number
          data: SaveQuotationResultDetailsRequestInterface
        }
      >({
        query: ({
          data,
          quotationId,
          resultId,
        }) => ({
          url: `/admin/internal-quotation-results/${quotationId}/internal-quotation-result-details/${resultId}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [
          'InternalQuotationResultDetail',
        ],
      }),
    deleteInternalQuotationResultDetail:
      build.mutation<
        void,
        {
          resultId: string | number
          quotationId: string | number
        }
      >({
        query: ({ quotationId, resultId }) => ({
          url: `/admin/internal-quotation-results/${quotationId}/internal-quotation-result-details/${resultId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          'InternalQuotationResultDetail',
        ],
      }),
  }),
})
