import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import { Query } from '@/utils/query'
import {
  useDeleteInternalQuotationCategoryMutation,
  useGetInternalQuotationCategoriesQuery,
} from '@/features/internalQuotationCategories/redux/internalQuotationCategoriesAPI'
import { InternalQuotationCategoryInterface } from '@/features/internalQuotationCategories/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { PermissionEnum } from '@/features/permissions/redux/types'
import usePagination from '@/utils/hooks/usePagination'
import { RealtimeForm } from '@/features/internalQuotationCategories/resources/components/form'

type Props = {
  quotationId: string | number
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  quotationId,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'internal_quotations_categories',
    'utils',
    'validation',
  ])
  const [pagination, setPagination] =
    usePagination()

  const query = useMemo(
    () =>
      baseQuery
        .includes(
          'category',
          'unit',
          'parentCategory'
        )
        .page(pagination.pageIndex)
        .url(),
    [baseQuery, pagination]
  )

  const { data: apiData } =
    useGetInternalQuotationCategoriesQuery({
      id: quotationId,
      query,
    })
  const [deleteInternalQuotationCategory] =
    useDeleteInternalQuotationCategoryMutation()
  const [data, setData] = useState<
    InternalQuotationCategoryInterface[]
  >([])

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = (id: number) => {
    deleteInternalQuotationCategory({
      internalQuotationId: quotationId,
      internalQuotationCategoryId: id,
    })
      .unwrap()
      .then(() =>
        toast.success(
          t(
            'internal_quotations_categories:list.deleted'
          )
        )
      )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, quotationId),
    getCoreRowModel: getCoreRowModel(),
    data,
    onPaginationChange: setPagination,
    manualPagination: true,
    enableSorting: false,
    state: {
      columnVisibility: visibility,
      pagination,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<InternalQuotationCategoryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  quotationId: string | number
) => [
  columnBuilder.display({
    id: 'order',
    header: t('form:labels.order'),
    cell: ({ row }) => (
      <RealtimeForm
        body={row.original}
        t={t}
        fieldName={'order'}
        quotationId={quotationId}
        permission={
          PermissionEnum.INTERNAL_QUOTATION_SAVE
        }
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'name',
    header: t('form:labels.name'),
    cell: ({ row }) => (
      <RealtimeForm
        body={row.original}
        t={t}
        fieldName={'name'}
        quotationId={quotationId}
        permission={
          PermissionEnum.INTERNAL_QUOTATION_SAVE
        }
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'parent_category.name',
    header: t(
      'form:labels.internal_category_name'
    ),
    cell: ({ row }) =>
      row.original.parent_category.name
        ? row.original.parent_category.name
        : '',
    enableHiding: true,
  }),
  columnBuilder.accessor('category.name', {
    id: 'category.name',
    header: t('form:labels.subcategories'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'price_from',
    header: t('form:labels.price_from'),
    cell: ({ row }) => (
      <RealtimeForm
        body={row.original}
        t={t}
        fieldName={'price_from'}
        quotationId={quotationId}
        permission={
          PermissionEnum.INTERNAL_QUOTATION_SAVE
        }
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('price_to', {
    id: 'price_to',
    header: t('form:labels.price_to'),
    cell: ({ row }) => (
      <RealtimeForm
        body={row.original}
        t={t}
        fieldName={'price_to'}
        quotationId={quotationId}
        permission={
          PermissionEnum.INTERNAL_QUOTATION_SAVE
        }
      />
    ),
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATION_DESTROY,
            },
            onEdit: {
              action: `/internal-quotations/${quotationId}/categories/${row.original.id}`,
              permission:
                PermissionEnum.INTERNAL_QUOTATION_SAVE,
            },
          }),
        ]}
      />
    ),
    enableHiding: true,
  }),
]
