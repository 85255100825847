import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import usePagination from '@/utils/hooks/usePagination'
import {
  Query,
  SortingState,
} from '@/utils/query'
import {
  useDeleteArticleMutation,
  useGetArticlesQuery,
} from '@/features/articles/redux/articlesAPI'
import Carbon from '@/utils/carbon'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import {
  ArticleInterface,
  ArticleStatusEnum,
} from '@/features/articles/redux'
import { PublishedBadge } from './PublishedBadge'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

type Props = {
  query: Query
} & VisibleColumnsInterface
export const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'articles',
  ])
  const [pagination, setPagination] =
    usePagination()
  const [sorting, setSorting] =
    useState<SortingState>([])
  const query = useMemo(
    () =>
      baseQuery
        .includes('articleMedia')
        .page(pagination.pageIndex)
        .sortBySortState(sorting),
    [pagination, sorting, baseQuery]
  )
  const { data: apiData } = useGetArticlesQuery(
    query.url()
  )
  const [data, setData] = useState<
    ArticleInterface[]
  >([])

  const [deleteArticle] =
    useDeleteArticleMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteArticle(id)
      .unwrap()
      .then(() =>
        toast.success(t('articles:list.deleted'))
      )
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    manualSorting: true,
    enableSorting: true,
    onSortingChange: setSorting,
    manualPagination: true,
    enableHiding: true,
    state: {
      pagination,
      sorting,
      columnVisibility: visibility,
    },
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      table={table}
      pagination={apiData?.pagination}
    />
  )
}

const columnBuilder =
  createColumnHelper<ArticleInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.created_at'),
    cell: ({ row }) => {
      if (row.original.created_at)
        return new Carbon()
          .parse(row.original.created_at)
          .format('dd.MM.yyyy HH:mm')
    },
    enableSorting: true,
  }),
  columnBuilder.display({
    id: 'image',
    header: t('form:labels.image'),
    cell: ({ row }) => {
      if (row.original.article_media.length) {
        const mainImage =
          row.original.article_media[0].article_media_files.find(
            (media) =>
              media.collection_name === 'default'
          )

        if (mainImage)
          return (
            <img
              src={mainImage.url}
              alt={''}
              className={
                'h-[120px] w-[180px] object-cover'
              }
            />
          )
      }
    },
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('title', {
    id: 'title',
    header: t('form:labels.title'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('lead', {
    id: 'lead',
    header: t('form:labels.lead'),
    cell: ({ row }) => {
      return (
        <p
          className={
            'truncate overflow-hidden max-w-[150px] max-h-32'
          }
        >
          {row.original.lead}
        </p>
      )
    },
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('views', {
    id: 'views',
    header: t('form:labels.count_of_views'),
    enableSorting: true,
    enableHiding: true,
  }),

  columnBuilder.accessor('status', {
    id: 'status',
    header: t('form:labels.published'),
    cell: ({ row }) => {
      if (row.original.published_at) {
        const currentDate = new Carbon().getDate()
        const publishedAt = new Carbon(
          row.original.published_at
        )

        // IF published at is greater than current date or
        // if status is equal === published
        if (
          publishedAt.gt(currentDate) &&
          row.original.status ===
            ArticleStatusEnum.PUBLISHED
        ) {
          return (
            <PublishedBadge
              date={publishedAt.toLocaleString()}
            />
          )
        }
      }

      return row.original.status ===
        ArticleStatusEnum.PUBLISHED
        ? t('form:labels.yes')
        : t('form:labels.no')
    },
    enableSorting: true,
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onEdit: {
              action: `/articles/${row.original.id}/edit`,
              permission:
                PermissionEnum.ARTICLE_SAVE,
            },
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.ARTICLE_DESTROY,
            },
          }),
          {
            icon: faSearch,
            onClick: () =>
              window.open(
                `${
                  import.meta.env
                    .VITE_IGRIT_FRONTEND_URL
                }/article/${row.original.id}`,
                '_blank'
              ),
            buttonClassName: 'bg-blue-500',
            permission:
              PermissionEnum.ARTICLE_SHOW,
            tooltip:
              'articles:tooltips.show_in_portal',
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
