import { useCallback, useMemo } from 'react'
import { useNavigate as useNativeUseNavigate } from 'react-router-dom'

export const useNavigate = () => {
  const nativeNavigateTo = useNativeUseNavigate()
  const openInBlank = useMemo(() => {
    const openInBlank = localStorage.getItem(
      'open_in_blank'
    )

    if (openInBlank) {
      return Boolean(Number(openInBlank))
    }
  }, [localStorage])

  const shouldShowModal = useMemo(
    () => openInBlank === undefined,
    [openInBlank]
  )

  const handleStoreOpenInBlank = useCallback(
    (value: boolean) => {
      localStorage.setItem(
        'open_in_blank',
        String(value ? 1 : 0)
      )
    },
    []
  )

  const navigateTo = useCallback(
    (url: string) => {
      if (openInBlank) {
        window.open(url, '_blank')
      } else {
        nativeNavigateTo(url)
      }
    },
    [nativeNavigateTo, openInBlank]
  )

  return {
    navigateTo,
    shouldShowModal,
    handleStoreOpenInBlank,
  }
}
