import React, { useEffect } from 'react'
import {
  CensorshipInterface,
  SaveCensorshipRequestInterface,
} from '@/features/censorship/redux/types'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SaveCensorshipValidation from '@/features/censorship/resources/_components/form/validations/saveCensorshipValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import Modal from '@/features/components/modals/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Button } from '@/features/components/buttons/button'

type Props = {
  data?: CensorshipInterface
  title: string
} & InstanceProps<
  SaveCensorshipRequestInterface,
  unknown
>

const Form: React.FC<Props> = ({
  data,
  isOpen,
  onReject,
  onResolve,
  title,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveCensorshipValidation(),
    t
  )

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<SaveCensorshipRequestInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (data) {
      setValue('word', data.word)
      setValue('description', data.description)
      setValue(
        'with_more_accurate_checking',
        data.with_more_accurate_checking
      )
      setValue(
        'is_enabled_in_comments',
        data.is_enabled_in_comments
      )
    }
  }, [data])

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content size={'w-[500px]'}>
        <div className={'flex flex-col gap-y-6'}>
          <div
            className={
              'flex w-full items-center justify-between'
            }
          >
            <span
              className={'text-lg font-semibold'}
            >
              {title}
            </span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={'cursor-pointer'}
            />
          </div>
          <form
            className={'flex flex-col gap-y-2'}
            onSubmit={handleSubmit(onResolve)}
          >
            <Controller
              render={({ field }) => (
                <FormControl
                  {...field}
                  error={!!errors[field.name]}
                >
                  <Input
                    label={t('form:labels.word')}
                  />
                  <FormHelperText
                    message={
                      errors[field.name]?.message
                    }
                  />
                </FormControl>
              )}
              name={'word'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl
                  {...field}
                  error={!!errors[field.name]}
                >
                  <Input
                    multiline
                    rows={5}
                    label={t(
                      'form:labels.description'
                    )}
                  />
                  <FormHelperText
                    message={
                      errors[field.name]?.message
                    }
                  />
                </FormControl>
              )}
              name={'description'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl
                  error={!!errors[field.name]}
                >
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t(
                      'form:labels.with_more_accurate_checking'
                    )}
                  />
                </FormControl>
              )}
              name={'with_more_accurate_checking'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl
                  error={!!errors[field.name]}
                >
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t(
                      'form:labels.is_enabled_in_comments'
                    )}
                  />
                </FormControl>
              )}
              name={'is_enabled_in_comments'}
              control={control}
            />
            <div className={'pt-4'}>
              <Button
                variant={'contained'}
                type={'submit'}
              >
                {t('form:buttons.save')}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const censorshipFormModal = create(Form)
