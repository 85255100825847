import igritAPI from '@/utils/api/igrit'
import {
  GetAccountingReportsResponseInterface,
  StoreAccountingReportRequestInterface,
} from './types'
import { setPaginatedCache } from '@/utils/api/rtkHelper'

export const {
  useGetAccountingReportsQuery,
  useStoreAccountingReportMutation,
  useDeleteAccountingReportMutation,
  useMarkAsSentAccountingReportMutation,
  useRefreshAccountingReportMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getAccountingReports: build.query<
      GetAccountingReportsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/accounting-reports?${query}`,
      transformResponse: (
        response: GetAccountingReportsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache(
          'AccountingReport',
          result
        ),
    }),
    storeAccountingReport: build.mutation<
      void,
      StoreAccountingReportRequestInterface
    >({
      query: (data) => ({
        url: '/admin/accounting-reports',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AccountingReport'],
    }),
    deleteAccountingReport: build.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/accounting-reports/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AccountingReport'],
    }),
    markAsSentAccountingReport: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/accounting-reports/${id}/mark-as-sent`,
        method: 'POST',
      }),
      invalidatesTags: ['AccountingReport'],
    }),
    refreshAccountingReport: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/accounting-reports/${id}/refresh`,
        method: 'POST',
      }),
      invalidatesTags: ['AccountingReport'],
    }),
  }),
})
