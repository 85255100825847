import React from 'react'
import { SearchProps } from './Search.types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Input } from '../input'
import { useSearchParams } from 'react-router-dom'

const Search: React.FC<SearchProps> = ({
  onChange,
}): React.ReactNode => {
  const { t } = useTranslation(['form'])
  const [searchParams, setSearchParams] =
    useSearchParams()

  const _handleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({ search: e.target.value.trim() })
  }

  const handleChange = _.debounce(
    _handleChange,
    500
  )

  return (
    <div className={'flex lg:justify-end'}>
      <Input
        defaultValue={
          searchParams.get('search') || ''
        }
        containerClassName={'!w-auto'}
        onChange={handleChange}
        className={'w-fit lg:w-[300px]'}
        placeholder={t(
          'form:placeholders.search'
        )}
        type={'search'}
      />
    </div>
  )
}

export { Search }
