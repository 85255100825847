import { Query } from '@/utils/query'
import { useSearchAdsSlotsQuery } from '@/features/ads/slots/redux'

const slotApiParams = new Query()
  .includes('views')
  .url()

export const useAdsSlots = () => {
  const { data: slots = [] } =
    useSearchAdsSlotsQuery(slotApiParams)

  return {
    slots,
  }
}
