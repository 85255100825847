import { flexRender } from '@tanstack/react-table'
import { Pagination } from '../Pagination'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownWideShort,
  faArrowUpWideShort,
} from '@fortawesome/free-solid-svg-icons'
import { BaseTableTypes } from './BaseTable.types'
import { ReactNode, useEffect } from 'react'
import { RecordDropdown } from '../RecordDropdown'
import { useSearchParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Label } from '@/components'

const BaseTable = <T extends object>({
  table,
  pagination,
  setPagination,
}: BaseTableTypes<T>): ReactNode => {
  const [searchParams, setSearchParams] =
    useSearchParams()
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  const handleChangePage = (page: number) => {
    searchParams.set('page', page.toString())
    setSearchParams(searchParams)
    table.setPageIndex(page)
  }

  useEffect(() => {
    if (searchParams.get('page')) {
      table.setPageIndex(
        parseInt(
          searchParams.get('page') as string
        )
      )
    }
  }, [searchParams.get('page')])

  return (
    <div className={'flex flex-col'}>
      {setPagination && (
        <RecordDropdown
          setPagination={setPagination}
        />
      )}
      {pagination && (
        <div
          className={
            'px-8 py-6 overflow-auto w-full'
          }
        >
          <Pagination
            onPageChange={handleChangePage}
            totalPages={pagination.total_pages}
            forcePage={
              searchParams.get('page')
                ? parseInt(
                    searchParams.get(
                      'page'
                    ) as string
                  ) - 1
                : 0
            }
          />
        </div>
      )}
      <div className={'w-full overflow-x-auto'}>
        <table
          className={
            'table-auto w-full text-left'
          }
        >
          {isDesktop && (
            <thead>
              {table
                .getHeaderGroups()
                .map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className={
                      'border-b border-b-neutral-600'
                    }
                  >
                    {headerGroup.headers.map(
                      (header) => {
                        return (
                          <th
                            className={clsx(
                              'bg-neutral-100 px-[12px] py-[6px] text-xs font-[500] text-gray-50'
                            )}
                            key={header.id}
                            style={{
                              width:
                                header.getSize(),
                              minWidth:
                                header.column
                                  .columnDef
                                  .minSize,
                              maxWidth:
                                header.column
                                  .columnDef
                                  .maxSize,
                            }}
                            colSpan={
                              header.colSpan
                            }
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                className={clsx({
                                  'cursor-pointer flex gap-x-2 items-center select-none':
                                    header.column.getCanSort(),
                                })}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column
                                    .columnDef
                                    .header,
                                  header.getContext()
                                )}
                                {{
                                  asc: (
                                    <FontAwesomeIcon
                                      icon={
                                        faArrowUpWideShort
                                      }
                                    />
                                  ),
                                  desc: (
                                    <FontAwesomeIcon
                                      icon={
                                        faArrowDownWideShort
                                      }
                                    />
                                  ),
                                }[
                                  header.column.getIsSorted() as string
                                ] ?? null}
                              </div>
                            )}
                          </th>
                        )
                      }
                    )}
                  </tr>
                ))}
            </thead>
          )}
          <tbody
            className={
              'text-xs font-medium text-gray-700'
            }
          >
            {table
              .getRowModel()
              .rows.map((row) => {
                return (
                  <tr
                    key={row.id}
                    className={
                      'border-b border-b-neutral-600 h-full'
                    }
                  >
                    {isDesktop ? (
                      row
                        .getVisibleCells()
                        .map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className={clsx(
                                'px-[12px] py-[6px] text-[12px] text-gray-700'
                              )}
                            >
                              {flexRender(
                                cell.column
                                  .columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          )
                        })
                    ) : (
                      <td
                        className={
                          'border-b border-b-neutral-600 h-full flex flex-col gap-4 p-4'
                        }
                      >
                        {row
                          .getVisibleCells()
                          .map((cell) => (
                            <div
                              key={cell.id}
                              className={
                                'flex flex-col gap-2 flex-wrap'
                              }
                            >
                              {typeof cell.column
                                .columnDef
                                .header ===
                                'string' && (
                                <Label
                                  className={
                                    'font-bold'
                                  }
                                  label={cell.column.columnDef.header.toString()}
                                />
                              )}
                              <div>
                                {flexRender(
                                  cell.column
                                    .columnDef
                                    .cell,
                                  cell.getContext()
                                )}
                              </div>
                            </div>
                          ))}
                      </td>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>
        {pagination && (
          <div
            className={
              'p-4 lg:px-8 lg:py-6 mb-12 lg:mb-0'
            }
          >
            <Pagination
              onPageChange={handleChangePage}
              totalPages={pagination.total_pages}
              forcePage={
                searchParams.get('page')
                  ? parseInt(
                      searchParams.get(
                        'page'
                      ) as string
                    ) - 1
                  : 0
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

export { BaseTable }
