import React, { FC, ReactNode } from 'react'
import {
  SearchType,
  Select2OptionInterface,
} from '@/app/types'
import {
  MultiValue,
  SingleValue,
} from 'react-select'
import { useLazyGetArticleGalleriesQuery } from '@/features/articleGalleries/redux/articleGalleryAPI'
import { ArticleGalleryInterface } from '@/features/articleGalleries/redux/types'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useAsyncSelect } from '@/utils/hooks/useAsyncSelectV2'
import { useTranslation } from 'react-i18next'

const SearchArticleGallery: FC<SearchType> = ({
  onChange,
  isMulti,
  defaultValue,
}): ReactNode => {
  const { t } = useTranslation(['form'])
  const [getArticleGalleries] =
    useLazyGetArticleGalleriesQuery()
  const {
    handleFetch,
    selectedValue,
    setSelectedValue,
    selectProperties,
  } = useAsyncSelect<
    ArticleGalleryInterface,
    'paginated'
  >(
    getArticleGalleries,
    'paginated',
    'title',
    'id',
    'title',
    defaultValue
  )

  const handleChange = (
    value:
      | MultiValue<Select2OptionInterface>
      | SingleValue<Select2OptionInterface>
  ) => {
    if (isMulti) {
      onChange(
        value as MultiValue<Select2OptionInterface>
      )
      setSelectedValue(
        value as Select2OptionInterface[]
      )
    } else {
      onChange(
        value as SingleValue<Select2OptionInterface>
      )
      setSelectedValue(
        value as Select2OptionInterface
      )
    }
  }

  return (
    <AsyncPaginate
      loadOptions={handleFetch}
      onChange={handleChange}
      value={selectedValue}
      placeholder={t(
        'form:placeholders.search_article_gallery'
      )}
      isMulti={isMulti}
      debounceTimeout={500}
      classNames={selectProperties}
    />
  )
}

export { SearchArticleGallery }
