import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteServiceMutation,
  useGetServicesQuery,
} from '@/features/services/redux/serviceAPI'
import { ServiceInterface } from '@/features/services/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination]
  )
  const { data: apiData } = useGetServicesQuery(
    query.url()
  )
  const [data, setData] = useState<
    ServiceInterface[]
  >([])
  const [deleteService] =
    useDeleteServiceMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteService(id)
      .unwrap()
      .then(() =>
        toast.success(t('services:list.deleted'))
      )
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    state: {
      columnVisibility: visibility,
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<ServiceInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('system_name', {
    id: 'system_name',
    header: t('form:labels.system_name'),
    cell: ({ row }) => (
      <span>
        {t(
          `utils:services.${row.original.system_name}`
        )}
      </span>
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('price', {
    id: 'price',
    header: t('form:labels.price'),
    cell: ({ row }) =>
      Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
      }).format(row.original.price),
    enableHiding: true,
  }),
  columnBuilder.accessor('points', {
    id: 'points',
    header: t('form:labels.points'),
    enableHiding: true,
  }),
  columnBuilder.accessor('quantity', {
    id: 'quantity',
    header: t('form:labels.quantity'),
    enableHiding: true,
  }),
  columnBuilder.accessor('discounted_price', {
    id: 'discounted_price',
    header: t('form:labels.discounted_price'),
    cell: ({ row }) =>
      row.original.discounted_price
        ? Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'PLN',
          }).format(row.original.price)
        : '-',
    enableHiding: true,
  }),
  columnBuilder.accessor('discounted_points', {
    id: 'discounted_points',
    header: t('form:labels.discounted_points'),
    enableHiding: true,
  }),
  columnBuilder.accessor('for_auth', {
    id: 'for_auth',
    header: t('form:labels.for_auth'),
    cell: ({ row }) =>
      row.original.for_auth
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('standalone', {
    id: 'standalone',
    header: t('form:labels.standalone'),
    cell: ({ row }) =>
      row.original.standalone
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/services/${row.original.id}/edit`,
            permission:
              PermissionEnum.SERVICE_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.SERVICE_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
