import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import Card from '@/features/components/cards'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'
import { isFetchBaseQueryError } from '@/app/hooks'
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
} from '@/app/types'
import { toast } from 'react-toastify'
import { SaveInternalQuotationCategoryInterface } from '@/features/internalQuotationCategories/redux/types'
import { useStoreInternalQuotationCategoryMutation } from '@/features/internalQuotationCategories/redux/internalQuotationCategoriesAPI'
import { Form } from '../../components/form'

const CreateInternalQuotationCategoriesScreen =
  (): React.ReactNode => {
    const [storeInternalQuotationCategory] =
      useStoreInternalQuotationCategoryMutation()
    const { t } = useTranslation([
      'internal_quotations',
    ])
    const navigate = useNavigate()
    const [errors, setErrors] =
      useState<
        BackendValidationErrorInterface<SaveInternalQuotationCategoryInterface>
      >()
    const { id } = useParams() as { id: string }

    const onSubmit = async (
      data: SaveInternalQuotationCategoryInterface
    ) => {
      try {
        await storeInternalQuotationCategory({
          data,
          id,
        }).unwrap()
        navigate(
          `/internal-quotations/${id}/categories`
        )
        toast.success(
          t(
            'internal_quotations_categories:create.success'
          )
        )
        return true
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          const errorData =
            error as ErrorResponseInterface

          if (errorData.status === 422) {
            const errorData =
              error as ErrorValidationResponseInterface<SaveInternalQuotationCategoryInterface>
            setErrors(errorData.data)
          }
        }
        return false
      }
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t(
            'internal_quotations_categories:create.title'
          )}
        </span>
        <Card>
          <Form
            errors={errors}
            onSubmit={onSubmit}
          />
        </Card>
      </div>
    )
  }

export { CreateInternalQuotationCategoriesScreen }
