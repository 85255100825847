import React, { HTMLProps } from 'react'
import { FormControl } from '@mui/base'
import clsx from 'clsx'

type Props = HTMLProps<HTMLInputElement> & {
  label?: string
}

export const Checkbox = React.forwardRef(
  (
    props: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { label, className, value, ...rest } =
      props
    const classes = clsx(
      className,
      'w-[16px] h-[16px] accent-primary'
    )

    return (
      <React.Fragment>
        <FormControl
          value={value}
          className={'flex flex-row items-center'}
        >
          <input
            type={'checkbox'}
            ref={ref}
            className={classes}
            {...rest}
          />
          {label && (
            <span className={'px-2'}>
              {label}
            </span>
          )}
        </FormControl>
      </React.Fragment>
    )
  }
)
