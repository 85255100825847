import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from '@/features/categoryBundles/resources/components'
import Card from '@/features/components/cards'
import { useStoreBundleCategoryMutation } from '@/features/categoryBundles/redux/categoryBundleAPI'
import { SaveBundleCategoryRequestInterface } from '@/features/categoryBundles/redux/types'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const CreateCategoryBundleScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'bundle_categories',
      'utils',
    ])
    const [storeBundleCategory] =
      useStoreBundleCategoryMutation()
    const navigate = useNavigate()

    const handleSubmit = async (
      data: SaveBundleCategoryRequestInterface
    ) => {
      try {
        await storeBundleCategory(data).unwrap()
        toast.success(
          t('bundle_categories:create.success')
        )
        navigate('/bundle-categories')
      } catch (error) {
        toast.error(
          t('utils:errors.something_went_wrong')
        )
      }
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('bundle_categories:create.title')}
        </span>
        <Card margin>
          <Form onSubmit={handleSubmit} />
        </Card>
      </div>
    )
  }

export { CreateCategoryBundleScreen }
