import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/features/components/buttons/button'

const Error404 = (): React.ReactNode => {
  const { t } = useTranslation(['utils'])
  const navigate = useNavigate()

  return (
    <div
      className={
        'w-full h-screen m-0 flex items-center justify-center bg-white'
      }
    >
      <div
        className={'flex flex-col items-center'}
      >
        <h1
          className={
            'text-5xl font-bold text-primary'
          }
        >
          404
        </h1>
        <span
          className={'text-2xl font-semibold'}
        >
          {t('errors.404')}
        </span>
        <Button
          className={'mt-4'}
          variant={'contained'}
          onClick={() => {
            navigate('/')
          }}
        >
          {t('utils:back_to_home')}
        </Button>
      </div>
    </div>
  )
}

export { Error404 }
