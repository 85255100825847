import React, {
  lazy,
  Suspense,
  useState,
} from 'react'
import { Trans } from 'react-i18next'
import { useGetBundleCategoriesQuery } from '@/features/categoryBundles/redux/categoryBundleAPI'
import { CategoriesFormTypes } from './CategoriesForm.types'
import { useFormContext } from 'react-hook-form'
import {
  StoreCampaignValidationInterface,
  UpdateAdsValidationInterface,
} from '@/features/ads/ads/redux'

const Tree = lazy(() =>
  import(
    '@/components/CategoryTree/CategoryTree'
  ).then((module) => ({
    default: module.CategoryTree,
  }))
)

const CategoriesForm: React.FC<
  CategoriesFormTypes
> = ({
  initialSelectedCategories,
  prefix,
  index,
  viewName,
}): React.ReactNode => {
  const { data } = useGetBundleCategoriesQuery(
    '?page=1&include=categories'
  )
  const { setValue } = useFormContext<
    | StoreCampaignValidationInterface
    | UpdateAdsValidationInterface
  >()
  const [
    selectedCategories,
    setSelectedCategories,
  ] = useState<number[]>(
    initialSelectedCategories
  )

  const handleSelectByCategoryBundle = (
    id: number
  ) => {
    const categoryBundle = data?.data.find(
      (item) => item.id === id
    )

    const categoryIds =
      categoryBundle?.category_ids || []

    setSelectedCategories(categoryIds)
  }

  return (
    <div className={'flex flex-col gap-1'}>
      <span>
        <Trans
          i18nKey={
            'ads:utils.categories_for_view'
          }
          values={{
            view: viewName,
          }}
          components={{
            strong: (
              <span className={'font-semibold'} />
            ),
          }}
        />
      </span>
      <div className={'flex flex-wrap gap-4'}>
        {data?.data.map((bundleCategory) => (
          <button
            type={'button'}
            key={bundleCategory.id}
            onClick={() =>
              handleSelectByCategoryBundle(
                bundleCategory.id
              )
            }
            className={
              'px-4 py-1 text-sm ripple-bg-primary-600 rounded text-white'
            }
          >
            {bundleCategory.name}
          </button>
        ))}
      </div>
      <div className={'pt-4'}>
        <Suspense fallback={'Loading...'}>
          <Tree
            onChange={(ids) =>
              setValue(
                `${prefix}categories.${index}.category_ids`,
                ids
              )
            }
            selectedIds={selectedCategories}
          />
        </Suspense>
      </div>
    </div>
  )
}

export { CategoriesForm }
