import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import * as yup from 'yup'
import { SaveArticleGalleryInterface } from '@/features/articleGalleries/redux/types'
import { TFunction } from 'i18next'
import { ArticleGalleryStatusEnum } from '@/features/articleGalleries/redux/enums/ArticleGalleryStatusEnum'

export default class SaveArticleGalleryValidation
  implements
    ValidationInterface<SaveArticleGalleryInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveArticleGalleryInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      title: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t(
            'validation:field_must_be_less_than',
            { max: 255 }
          )
        ),
      description: yup
        .string()
        .max(
          255,
          t(
            'validation:field_must_be_less_than',
            { max: 255 }
          )
        )
        .nullable(),
      status: yup
        .mixed<ArticleGalleryStatusEnum>()
        .required(t('validation:required'))
        .oneOf(
          Object.values(ArticleGalleryStatusEnum)
        ),
    })
  }

  defaultValues(): SaveArticleGalleryInterface {
    return {
      title: '',
      description: '',
      status: ArticleGalleryStatusEnum.ACTIVE,
    }
  }
}
