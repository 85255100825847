import { InvoiceInterface } from '@/features/invoices/redux/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import Carbon from '@/utils/carbon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

type Props = {
  data: InvoiceInterface
}

export const InvoiceBasicDetails: React.FC<
  Props
> = ({ data }): React.ReactNode => {
  const { t } = useTranslation([
    'invoices',
    'form',
    'utils',
  ])

  return (
    <Card>
      <div className={'flex flex-col gap-y-6'}>
        <span className={'text-lg font-medium'}>
          {t(
            'invoices:details.basic_information'
          )}
        </span>
        <div className={'flex flex-col gap-y-2'}>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.id')}
            </span>
            <span>{data.id}</span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.invoice_signature')}
            </span>
            <span>{data.signature}</span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.currency')}
            </span>
            <span>{data.currency}</span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.type')}
            </span>
            <span>
              {t(
                `utils:invoice_types.${data.type}`
              )}
            </span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.issue_date')}
            </span>
            <span>
              {new Carbon(data.issue_date).format(
                'dd.MM.yyyy'
              )}
            </span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.sale_date')}
            </span>
            <span>
              {new Carbon(data.sale_date).format(
                'dd.MM.yyyy'
              )}
            </span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.gross_value')}
            </span>
            <span>
              {Intl.NumberFormat('pl-PL', {
                style: 'currency',
                currency: data.currency,
              }).format(data.gross_value)}
            </span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.link')}
            </span>
            <a
              href={data.url}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                className={'text-red-600'}
              />
            </a>
          </div>
        </div>
      </div>
    </Card>
  )
}
