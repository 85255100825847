import { PostTypeEnum } from '@/features/posts/posts/redux/enums/type'
import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export const TypeBadge: React.FC<{
  type: PostTypeEnum
}> = ({ type }): React.ReactNode => {
  const { t } = useTranslation(['utils'])

  const BaseBadge = ({
    classes,
    text,
  }: {
    classes: string
    text: string
  }) => (
    <span
      className={clsx(
        'py-1 px-2 rounded text-xs uppercase shadow',
        classes
      )}
    >
      {text}
    </span>
  )

  switch (type) {
    case PostTypeEnum.BUY:
      return (
        <BaseBadge
          classes={'bg-green-600 text-white'}
          text={t('utils:post_types.buy')}
        />
      )
    case PostTypeEnum.SELL:
      return (
        <BaseBadge
          classes={'bg-red-600 text-white'}
          text={t('utils:post_types.sell')}
        />
      )
    case PostTypeEnum.JOB:
      return (
        <BaseBadge
          classes={'bg-blue-600 text-white'}
          text={t('utils:post_types.job')}
        />
      )
    case PostTypeEnum.RENT:
      return (
        <BaseBadge
          classes={'bg-yellow-600 text-white'}
          text={t('utils:post_types.rent')}
        />
      )
    case PostTypeEnum.SERVICES:
      return (
        <BaseBadge
          classes={'bg-purple-600 text-white'}
          text={t('utils:post_types.services')}
        />
      )
    case PostTypeEnum.OTHER:
      return (
        <BaseBadge
          classes={'bg-gray-600 text-white'}
          text={t('utils:post_types.other')}
        />
      )
  }
}
