import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Query } from '@/utils/query'
import Card from '@/features/components/cards'
import { Table } from '@/features/issues/resources/_components/table'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

type Props = {
  id: number | string
}

export const PostIssueList: React.FC<Props> = ({
  id,
}): React.ReactNode => {
  const { t } = useTranslation('posts/posts')
  const query = useRef(
    new Query()
      .where('issueable_type', 'post')
      .where('issueable_id', id)
  ).current
  const {
    handleToggleColumn,
    handleSetAvailableColumns,
    visibilityColumnsWithLabels,
    visibilityColumns,
  } = useColumnVisibility()

  return (
    <Card margin noPadding>
      <div
        className={'flex flex-col p-4 gap-y-4'}
      >
        <ColumnVisibility
          columns={visibilityColumnsWithLabels}
          toggleColumn={handleToggleColumn}
        />
        <div
          className={
            'flex justify-between items-center'
          }
        >
          <span className={'font-medium'}>
            {t('posts/posts:edit.issues.title')}
          </span>
        </div>
      </div>
      <Table
        visibility={visibilityColumns}
        setAvailableColumns={
          handleSetAvailableColumns
        }
        query={query}
      />
    </Card>
  )
}
