import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveExternalQuotationRequestInterface } from '@/features/externalQuotations/redux'

export default class SaveExternalQuotationValidation
  implements
    ValidationInterface<SaveExternalQuotationRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveExternalQuotationRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t(
            'validation:field_must_be_less_than',
            { max: 255 }
          )
        ),
      is_active: yup
        .boolean()
        .required(t('validation:required')),
    })
  }

  defaultValues(): SaveExternalQuotationRequestInterface {
    return {
      name: '',
      is_active: false,
    }
  }
}
