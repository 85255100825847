import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'
import { Transition } from 'react-transition-group'

type Props = {
  children: React.ReactNode | string
  title: string | React.ReactNode
  className?: string
  containerClassName?: string
}

const Collapse: React.FC<Props> = ({
  children,
  title,
  className,
  containerClassName,
}): React.ReactNode => {
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div
      className={clsx(
        containerClassName,
        'flex flex-col bg-white rounded-t'
      )}
    >
      <button
        type={'button'}
        className={
          'flex bg-green-600 text-white py-2 px-4 rounded rounded-b-none cursor-pointer items-center justify-between gap-x-[48px]'
        }
        onClick={() => setOpen(!open)}
      >
        <div className={'font-semibold'}>
          {title}
        </div>
        <div
          className={clsx(
            'flex size-[40px] items-center justify-center rounded-md'
          )}
        >
          {!open ? (
            <FontAwesomeIcon
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon icon={faChevronUp} />
          )}
        </div>
      </button>
      <Transition
        nodeRef={ref}
        in={open}
        timeout={200}
      >
        {(state) => (
          <div
            className={clsx(
              className,
              {
                '!max-h-0': !open,
                'h-auto': open,
              },
              'h-auto border border-neutral-600 border-t-0'
            )}
            style={{
              transition:
                'max-height 0.2s ease-in-out',
              ...(state !== 'entered' && {
                overflow: 'hidden',
              }),
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </div>
  )
}

export { Collapse }
