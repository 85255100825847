import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveQuotationApplicationsRequestInterface } from '@/features/internalQuotationsApplications/redux/types'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import {
  QuotationApplicationStatusEnum,
  QuotationApplicationTypeEnum,
} from '@/features/internalQuotationsApplications/redux/enums'

export default class SaveQuotationApplicationValidation
  implements
    ValidationInterface<SaveQuotationApplicationsRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveQuotationApplicationsRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      domain: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t(
            'validation:field_must_be_less_than',
            { max: 255 }
          )
        ),
      status: yup
        .mixed<QuotationApplicationStatusEnum>()
        .oneOf(
          Object.values(
            QuotationApplicationStatusEnum
          )
        )
        .required(t('validation:required')),
      type: yup
        .mixed<QuotationApplicationTypeEnum>()
        .oneOf(
          Object.values(
            QuotationApplicationTypeEnum
          )
        )
        .required(t('validation:required')),
    })
  }

  defaultValues(): SaveQuotationApplicationsRequestInterface {
    return {
      domain: '',
      status:
        QuotationApplicationStatusEnum.ACTIVE,
      type: QuotationApplicationTypeEnum.API,
    }
  }
}
