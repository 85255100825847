import React from 'react'
import Card from '@/features/components/cards'
import { Table } from './table'
import { useTranslation } from 'react-i18next'

type Props = {
  userId: string | number
}
const UserLogs: React.FC<Props> = ({
  userId,
}): React.ReactNode => {
  const { t } = useTranslation(['users'])
  return (
    <div className={'flex flex-col gap-y-4'}>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t('users:logs.list.title')}
            </span>
          </div>
          <Table userId={userId} />
        </div>
      </Card>
    </div>
  )
}

export { UserLogs }
