import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormProvider,
  useForm,
} from 'react-hook-form'
import useValidation from '@/utils/hooks/useValidation'
import { yupResolver } from '@hookform/resolvers/yup'
import Card from '@/features/components/cards'
import { Form } from './partials'
import { Button } from '@/features/components/buttons/button'
import { toast } from 'react-toastify'
import {
  ChangeCompanyRequestInterface,
  UserInterface,
  useUpdateCompanyMutation,
} from '@/features/users/redux'
import ChangeCompanyValidation from './rules/ChangeCompanyValidation'

const ChangeCompanyForm: FC<{
  data?: UserInterface
  userId: number | string
}> = ({ userId, data }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'users',
  ])
  const [updateCompany] =
    useUpdateCompanyMutation()
  const { schema, defaultValues } = useValidation(
    new ChangeCompanyValidation(),
    t
  )
  const methods =
    useForm<ChangeCompanyRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data?.company) {
      const { setValue } = methods

      if (data.company.nip) {
        setValue('nip', data.company.nip)
      }

      if (data.company.type) {
        setValue('type', data.company.type)
      }

      if (data.company.primary_profile) {
        setValue(
          'primary_profile',
          data.company.primary_profile
        )
      }

      if (data.company.secondary_profile) {
        setValue(
          'secondary_profile',
          data.company.secondary_profile
        )
      }

      if (data.company.farm_size_in_hectares) {
        setValue(
          'farm_size_in_hectares',
          data.company.farm_size_in_hectares
        )
      }
    }
  }, [data])

  const onSubmit = async (
    data: ChangeCompanyRequestInterface
  ) => {
    try {
      await updateCompany({
        data: data,
        userId: userId,
      }).unwrap()
      toast.success(
        t('users:form.change_company.success')
      )
    } catch (error) {
      // empty block
    }
  }

  return (
    <Card>
      <span className={'font-semibold text-lg'}>
        {t('users:form.change_company.title')}
      </span>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(
            onSubmit
          )}
          className={'flex flex-col gap-4 pt-4'}
        >
          <div
            className={
              'grid lg:grid-cols-2 items-center gap-4'
            }
          >
            <Form />
          </div>
          <div className={'flex pt-8'}>
            <Button
              variant={'contained'}
              size={'small'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  )
}

export { ChangeCompanyForm }
