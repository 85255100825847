import { FC, ReactNode, useState } from 'react'
import {
  ClickAwayListener,
  Dropdown,
  Menu,
  MenuButton,
} from '@mui/base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTableCells } from '@fortawesome/free-solid-svg-icons'
import { Checkbox } from '@/features/components/inputs/checkbox'
import clsx from 'clsx'
import { ColumnVisibilityInterface } from '@/components'

const ColumnVisibility: FC<
  ColumnVisibilityInterface
> = ({ columns, toggleColumn }): ReactNode => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dropdown
      onOpenChange={() => setIsOpen(!isOpen)}
      open={isOpen}
    >
      <div>
        <MenuButton
          className={clsx(
            'py-[10px] px-[16px] rounded-lg box-shadow cursor-pointer',
            'disabled:opacity-50 disabled:cursor-not-allowed text-sm',
            'ripple-bg-primary-600 text-white'
          )}
        >
          <FontAwesomeIcon icon={faTableCells} />
        </MenuButton>
        <ClickAwayListener
          onClickAway={() => setIsOpen(false)}
        >
          <Menu
            slotProps={{
              listbox: {
                className:
                  'bg-white border border-neutral-200 p-2 flex flex-col mr-14',
              },
            }}
          >
            {columns.map((column) => (
              <button
                className={
                  'cursor-pointer hover:bg-neutral-100 p-1'
                }
                key={column.id}
                onClick={() => {
                  toggleColumn(column.id)
                }}
              >
                <Checkbox
                  readOnly
                  checked={column.visible}
                  label={column.header}
                />
              </button>
            ))}
          </Menu>
        </ClickAwayListener>
      </div>
    </Dropdown>
  )
}

export { ColumnVisibility }
