import {
  InvoiceItemInterface,
  SaveInvoiceItemRequestInterface,
} from '@/features/invoices/redux/types'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SaveInvoiceItemValidation from '@/features/invoices/items/resources/_components/form/validations/saveInvoiceItemValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Modal from '@/features/components/modals/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Button } from '@/features/components/buttons/button'

type Props = {
  data?: InvoiceItemInterface
  title: string
} & InstanceProps<
  SaveInvoiceItemRequestInterface,
  void
>

const InvoiceItemForm: React.FC<Props> = ({
  data,
  title,
  onReject,
  onResolve,
  isOpen,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'invoices',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveInvoiceItemValidation(),
    t
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<SaveInvoiceItemRequestInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const watchNetPrice = watch('net_price')
  const watchQuantity = watch('quantity')
  const watchTaxRate = watch('tax_rate')

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('quantity', data.quantity)
      setValue('net_price', data.net_price)
      setValue('tax_rate', data.tax_rate)
    }
  }, [data])

  useEffect(() => {
    if (
      watchQuantity &&
      watchNetPrice &&
      watchTaxRate
    ) {
      setValue(
        'net_value',
        Number(watchQuantity) *
          Number(watchNetPrice)
      )
      setValue(
        'gross_value',
        (
          Number(watchQuantity) *
          Number(watchNetPrice) *
          (1 + Number(watchTaxRate) / 100)
        ).toFixed(2)
      )
      setValue(
        'tax_value',
        (
          (Number(watchQuantity) *
            Number(watchNetPrice) *
            Number(watchTaxRate)) /
          100
        ).toFixed(2)
      )
      setValue(
        'gross_price',
        (
          Number(watchNetPrice) *
          (1 + Number(watchTaxRate) / 100)
        ).toFixed(2)
      )
    }
  }, [
    setValue,
    watchNetPrice,
    watchQuantity,
    watchTaxRate,
  ])

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content size={'2xl:w-1/2 lg:w-full'}>
        <div
          className={
            'flex flex-col gap-y-6 overflow-auto max-h-[80vh] lg:max-h-full'
          }
        >
          <div
            className={
              'flex w-full items-center justify-between'
            }
          >
            <span
              className={'text-lg font-semibold'}
            >
              {title}
            </span>
            <FontAwesomeIcon
              onClick={() => onReject()}
              icon={faTimes}
              className={'cursor-pointer'}
            />
          </div>
          <form
            onSubmit={handleSubmit(onResolve)}
            className={'flex flex-col gap-y-4'}
          >
            <div
              className={
                'grid lg:grid-cols-4 gap-4'
              }
            >
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={
                      !!_.get(errors, field.name)
                    }
                  >
                    <Input
                      label={t(
                        'form:labels.name'
                      )}
                    />
                    <FormHelperText
                      message={
                        _.get(errors, field.name)
                          ?.message
                      }
                    />
                  </FormControl>
                )}
                name={'name'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={
                      !!_.get(errors, field.name)
                    }
                  >
                    <Input
                      type={'number'}
                      min={0}
                      label={t(
                        'form:labels.quantity'
                      )}
                    />
                    <FormHelperText
                      message={
                        _.get(errors, field.name)
                          ?.message
                      }
                    />
                  </FormControl>
                )}
                name={'quantity'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={
                      !!_.get(errors, field.name)
                    }
                  >
                    <Input
                      type={'number'}
                      step={'0.01'}
                      min={0}
                      label={t(
                        'form:labels.net_price'
                      )}
                    />
                    <FormHelperText
                      message={
                        _.get(errors, field.name)
                          ?.message
                      }
                    />
                  </FormControl>
                )}
                name={'net_price'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={
                      !!_.get(errors, field.name)
                    }
                  >
                    <Input
                      type={'number'}
                      min={0}
                      label={t(
                        'form:labels.tax_rate'
                      )}
                    />
                    <FormHelperText
                      message={
                        _.get(errors, field.name)
                          ?.message
                      }
                    />
                  </FormControl>
                )}
                name={'tax_rate'}
                control={control}
              />
            </div>
            <div className={'py-2'}>
              <span className={'font-semibold'}>
                {t('invoices:items.calculation')}
              </span>
            </div>
            <div
              className={
                'grid lg:grid-cols-4 gap-4'
              }
            >
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={
                      !!_.get(errors, field.name)
                    }
                  >
                    <Input
                      type={'number'}
                      min={0}
                      readOnly
                      label={t(
                        'form:labels.tax_value'
                      )}
                    />
                    <FormHelperText
                      message={
                        _.get(errors, field.name)
                          ?.message
                      }
                    />
                  </FormControl>
                )}
                name={'tax_value'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={
                      !!_.get(errors, field.name)
                    }
                  >
                    <Input
                      type={'number'}
                      min={0}
                      readOnly
                      label={t(
                        'form:labels.net_value'
                      )}
                    />
                    <FormHelperText
                      message={
                        _.get(errors, field.name)
                          ?.message
                      }
                    />
                  </FormControl>
                )}
                name={'net_value'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={
                      !!_.get(errors, field.name)
                    }
                  >
                    <Input
                      type={'number'}
                      min={0}
                      readOnly
                      label={t(
                        'form:labels.gross_price'
                      )}
                    />
                    <FormHelperText
                      message={
                        _.get(errors, field.name)
                          ?.message
                      }
                    />
                  </FormControl>
                )}
                name={'gross_price'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={
                      !!_.get(errors, field.name)
                    }
                  >
                    <Input
                      type={'number'}
                      min={0}
                      readOnly
                      label={t(
                        'form:labels.gross_value'
                      )}
                    />
                    <FormHelperText
                      message={
                        _.get(errors, field.name)
                          ?.message
                      }
                    />
                  </FormControl>
                )}
                name={'gross_value'}
                control={control}
              />
            </div>
            <div className={'flex gap-x-4 pt-4'}>
              <Button
                variant={'contained'}
                type={'submit'}
                className={'w-full'}
              >
                {t('form:buttons.save')}
              </Button>
              <Button
                variant={'outlined'}
                onClick={() => onReject()}
                className={'w-full'}
              >
                {t('form:buttons.cancel')}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const invoiceItemFormModal = create(
  InvoiceItemForm
)
