import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { useNavigate } from 'react-router-dom'
import Form from '@/features/packages/resources/components/form'
import { SavePackageRequestInterface } from '@/features/packages/redux/types'
import { useStorePackageMutation } from '@/features/packages/redux/packageAPI'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'

export const PackageCreateScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['packages'])
    const [storePackage] =
      useStorePackageMutation()
    const navigate = useNavigate()

    const handleSubmit = (
      data: SavePackageRequestInterface
    ) => {
      const formData = serialize(data, {
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
        indices: true,
      })

      storePackage(formData)
        .unwrap()
        .then(() => {
          toast.success(t('create.success'))
          navigate('/packages')
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('packages:edit.title')}
        </span>
        <Card margin>
          <Form onSubmit={handleSubmit} />
        </Card>
      </div>
    )
  }
