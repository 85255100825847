import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import usePagination from '@/utils/hooks/usePagination'
import { toast } from 'react-toastify'
import { Query } from '@/utils/query'
import { InternalQuotationResultInterface } from '@/features/internalQuotationsResults/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import Carbon from '@/utils/carbon'
import {
  useDeleteInternalQuotationResultMutation,
  useGetInternalQuotationResultsQuery,
  useUpdateInternalQuotationResultMutation,
} from '@/features/internalQuotationsResults/redux/internalQuotationsResultsAPI'
import { internalQuotationsResultModal } from '@/features/internalQuotationsResults/resources/components/form'
import {
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'internal_quotations_results',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      baseQuery
        .page(pagination.pageIndex)
        .includes('acceptUser'),
    [pagination, baseQuery]
  )
  const { data: apiData } =
    useGetInternalQuotationResultsQuery(
      query.url()
    )
  const [data, setData] = useState<
    InternalQuotationResultInterface[]
  >([])

  const [deleteInternalQuotationResult] =
    useDeleteInternalQuotationResultMutation()
  const [updateInternalQuotationResult] =
    useUpdateInternalQuotationResultMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = async (id: number) => {
    try {
      await deleteInternalQuotationResult(
        id
      ).unwrap()
      toast.success(
        t(
          'internal_quotations_results:list.deleted'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const onEdit = async (id: number) => {
    const row = data.find(
      (item) => item.id === id
    )
    try {
      const data =
        await internalQuotationsResultModal({
          title: t(
            'internal_quotations_results:edit.title'
          ),
          data: row,
        })
      await updateInternalQuotationResult({
        data: data,
        id,
      }).unwrap()
      toast.success(
        t(
          'internal_quotations_results:edit.success'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const onToggle = async (id: number) => {
    const row = data.find(
      (item) => item.id === id
    )
    try {
      if (row)
        await updateInternalQuotationResult({
          data: {
            date_to: new Carbon(
              row.date_to
            ).toISOString(),
            date_from: new Carbon(
              row.date_from
            ).toISOString(),
            note: row.note,
            accepted: !row.accepted_at,
          },
          id: id,
        }).unwrap()
      toast.success(
        t(
          'internal_quotations_results:edit.success'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const table = useReactTable({
    columns: columns(
      t,
      onDelete,
      onEdit,
      onToggle
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    manualPagination: true,
    state: {
      columnVisibility: visibility,
      pagination,
    },
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<InternalQuotationResultInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onToggle: (id: number) => void
) => [
  columnBuilder.display({
    id: 'date_from',
    header: t('form:labels.from_to'),
    cell: ({ row }) => (
      <span>
        {new Carbon(
          row.original.date_from
        ).toDateTimeLocal()}{' '}
        -{' '}
        {new Carbon(
          row.original.date_to
        ).toDateTimeLocal()}
      </span>
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('corrected_prices', {
    id: 'corrected_prices',
    header: t('form:labels.corrected_prices'),
    cell: ({ row }) =>
      row.original.corrected_prices
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'accept_user',
    header: t('form:labels.accept_user'),
    cell: ({ row }) =>
      row.original.accept_user
        ? row.original.accept_user.name
        : '-',
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'accepted_at',
    header: t('form:labels.accepted_at'),
    cell: ({ row }) =>
      row.original.accepted_at
        ? new Carbon(
            row.original.accepted_at
          ).toDateTimeLocal()
        : '-',
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATION_DESTROY,
            },
            onEdit: {
              action: () =>
                onEdit(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATION_SAVE,
            },
            onDetails: {
              action: `${row.original.id}`,
              permission:
                PermissionEnum.INTERNAL_QUOTATION_SHOW,
            },
          }),
          {
            icon: faToggleOn,
            onClick: () =>
              onToggle(row.original.id),
            buttonClassName: 'bg-green-500',
            permission:
              PermissionEnum.INTERNAL_QUOTATION_SAVE,
            condition:
              row.original.accepted_at !== null,
            tooltip:
              'internal_quotations_results:tooltips.revoke',
          },
          {
            icon: faToggleOff,
            onClick: () =>
              onToggle(row.original.id),
            buttonClassName: 'bg-red-400',
            permission:
              PermissionEnum.INTERNAL_QUOTATION_SAVE,
            condition:
              row.original.accepted_at === null,
            tooltip:
              'internal_quotations_results:tooltips.accept',
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
