import React, {
  FC,
  useCallback,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import {
  UpdateUserRequestInterface,
  UserInterface,
} from '@/features/users/redux'
import { useGetAgreementsQuery } from '@/features/agreements/redux'
import { Checkbox } from '@/features/components/inputs/checkbox'

const Agreements: FC<{
  data?: UserInterface
}> = ({ data }): React.ReactNode => {
  const { t } = useTranslation(['form'])
  const { control, setValue, watch } =
    useFormContext<UpdateUserRequestInterface>()
  const { data: agreements } =
    useGetAgreementsQuery()
  const watchAgreements = watch('agreements')
  const getName = useCallback(
    (id: number) =>
      agreements?.find(
        (agreement) => agreement.id === id
      )?.name,
    [agreements]
  )

  useEffect(() => {
    if (!agreements) return

    const agreementsData = agreements.map(
      (agreement) => ({
        id: agreement.id,
        accepted:
          data?.agreements?.find(
            (userAgreement) =>
              userAgreement.agreement.id ===
              agreement.id
          )?.accepted || false,
      })
    )

    setValue('agreements', agreementsData)
  }, [agreements, data])

  return (
    <div className={'flex flex-col gap-y-6'}>
      <span className={'font-semibold'}>
        {t('form:labels.agreements')}
      </span>
      <div className={'flex flex-col gap-y-2'}>
        {watchAgreements.map(
          (agreement, index) => (
            <Controller
              key={index}
              render={({ field }) => (
                <Checkbox
                  label={getName(agreement.id)}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
              name={`agreements.${index}.accepted`}
              control={control}
            />
          )
        )}
      </div>
    </div>
  )
}

export { Agreements }
