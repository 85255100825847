import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { Query } from '@/utils/query'
import usePagination from '@/utils/hooks/usePagination'
import { toast } from 'react-toastify'
import {
  useDeleteAdvertiserMutation,
  useGetAdvertisersQuery,
  useUpdateAdvertiserMutation,
} from '@/features/ads/advertisers/redux/advertiserAPI'
import { AdvertiserInterface } from '@/features/ads/advertisers/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { TFunction } from 'i18next'
import { advertiserFormModal } from '@/features/ads/advertisers/resources/components'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'advertisers',
  ])
  const [{ pageIndex, pageSize }, setPagination] =
    usePagination()
  const [data, setData] = useState<
    AdvertiserInterface[]
  >([])
  const [deleteAdvertiser] =
    useDeleteAdvertiserMutation()
  const query = useMemo(
    () =>
      new Query().limit(pageSize).page(pageIndex),
    [pageIndex, pageSize]
  )
  const { data: apiData } =
    useGetAdvertisersQuery(query.url())
  const [updateAdvertiser] =
    useUpdateAdvertiserMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = (id: number) => {
    deleteAdvertiser(id)
      .unwrap()
      .then(() =>
        toast.success(
          t('advertisers:list.deleted')
        )
      )
  }

  const handleEdit = (id: number) => {
    const advertiser = data.find(
      (advertiser) => advertiser.id === id
    )

    if (!advertiser) return

    void advertiserFormModal({
      data: advertiser,
      title: t('advertisers:edit.title'),
    }).then((response) => {
      updateAdvertiser({
        id,
        body: response,
      })
        .unwrap()
        .then(() =>
          toast.success(
            t('advertisers:edit.success')
          )
        )
    })
  }

  const table = useReactTable({
    columns: columns(
      t,
      (id) => handleEdit(id),
      (id) => handleDelete(id)
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    onPaginationChange: setPagination,
    manualPagination: true,
    enableHiding: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<AdvertiserInterface>()

const columns = (
  t: TFunction,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableHiding: true,
  }),
  columnBuilder.accessor('user.name', {
    id: 'user.name',
    header: t('form:labels.user'),
    enableHiding: true,
  }),
  columnBuilder.accessor('manager.name', {
    id: 'manager.name',
    header: t('form:labels.manager'),
    enableHiding: true,
  }),
  columnBuilder.accessor('company_name', {
    id: 'company_name',
    header: t('form:labels.company_name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('nip', {
    id: 'nip',
    header: t('form:labels.nip'),
    enableHiding: true,
  }),
  columnBuilder.accessor('company_address', {
    id: 'company_address',
    header: t('form:labels.company_address'),
    enableHiding: true,
  }),
  columnBuilder.accessor('contact_person', {
    id: 'contact_person',
    header: t('form:labels.contact_person'),
    enableHiding: true,
  }),
  columnBuilder.accessor('contact_phone', {
    id: 'contact_phone',
    header: t('form:labels.contact_phone'),
    enableHiding: true,
  }),
  columnBuilder.accessor('contact_email', {
    id: 'contact_email',
    header: t('form:labels.contact_email'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.ADVERTISER_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.ADVERTISER_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
