import igritAPI from '@/utils/api/igrit'
import {
  GetQuotationApplicationsResponseInterface,
  SaveQuotationApplicationsRequestInterface,
} from '@/features/internalQuotationsApplications/redux/types'
import { setPaginatedCache } from '@/utils/api/rtkHelper'

export const {
  useGetInternalQuotationApplicationsQuery,
  useStoreInternalQuotationApplicationMutation,
  useUpdateInternalQuotationApplicationMutation,
  useDeleteInternalQuotationApplicationMutation,
  useLazyGetInternalQuotationApplicationsQuery,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getInternalQuotationApplications: build.query<
      GetQuotationApplicationsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/internal-quotation-apis?${query}`,
      transformResponse: (
        response: GetQuotationApplicationsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache(
          'InternalQuotationApplication',
          result
        ),
    }),
    storeInternalQuotationApplication:
      build.mutation<
        void,
        SaveQuotationApplicationsRequestInterface
      >({
        query: (body) => ({
          url: '/admin/internal-quotation-apis',
          method: 'POST',
          body,
        }),
        invalidatesTags: [
          'InternalQuotationApplication',
        ],
      }),
    updateInternalQuotationApplication:
      build.mutation<
        void,
        {
          data: SaveQuotationApplicationsRequestInterface
          id: string | number
        }
      >({
        query: ({ data, id }) => ({
          url: `/admin/internal-quotation-apis/${id}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [
          'InternalQuotationApplication',
        ],
      }),
    deleteInternalQuotationApplication:
      build.mutation<void, string | number>({
        query: (id) => ({
          url: `/admin/internal-quotation-apis/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          'InternalQuotationApplication',
        ],
      }),
  }),
})
