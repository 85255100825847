import React, { FC, useEffect } from 'react'
import {
  UpdateUserRequestInterface,
  UserInterface,
  useUpdateUserMutation,
} from '@/features/users/redux'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import { BaseUserValidation } from '@/features/users/resources/rules'
import {
  FormProvider,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Card from '@/features/components/cards'
import { BaseForm } from '@/features/users/resources/components'
import { Button } from '@/features/components/buttons/button'
import { Label } from '@/components'
import Carbon from '@/utils/carbon'
import { toast } from 'react-toastify'

const BaseDataForm: FC<{
  data?: UserInterface
  userId: string | number
}> = ({ data, userId }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new BaseUserValidation(),
    t
  )
  const [updateUser] = useUpdateUserMutation()
  const methods =
    useForm<UpdateUserRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      const { setValue } = methods

      setValue('name', data.name)
      setValue('is_active', data.is_active)
      setValue('email', data.email)
      setValue('is_blocked', data.is_blocked)

      if (data.phone_number) {
        setValue(
          'phone_number',
          data.phone_number.phone_number
        )
        setValue(
          'phone_prefix',
          data.phone_number.prefix
        )
      }
    }
  }, [data])

  const onSubmit = async (
    data: UpdateUserRequestInterface
  ) => {
    try {
      await updateUser({
        data: data,
        userId: userId,
      }).unwrap()
      toast.success(
        t('users:form.base_data.success')
      )
    } catch (error) {
      // empty block
    }
  }

  return (
    <Card>
      <span className={'font-semibold text-lg'}>
        {t('users:form.base_data.title')}
      </span>
      {data && (
        <div
          className={'flex flex-col pt-4 gap-2'}
        >
          <div className={'flex flex-col'}>
            <Label
              label={t('form:labels.created_at')}
            />
            <span>
              {new Carbon(
                data.created_at
              ).toLocaleString()}
            </span>
          </div>
          <div className={'flex flex-col'}>
            <Label
              label={t(
                'form:labels.premium_expires_at'
              )}
            />
            {data.premium_expires_at
              ? new Carbon(
                  data.premium_expires_at
                ).toLocaleString()
              : '-'}
          </div>
        </div>
      )}

      <FormProvider {...methods}>
        <form
          className={'flex flex-col pt-8'}
          onSubmit={methods.handleSubmit(
            onSubmit
          )}
        >
          <BaseForm data={data} />
          <div className={'flex pt-8'}>
            <Button
              variant={'contained'}
              size={'small'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  )
}

export { BaseDataForm }
