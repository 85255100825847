import React, { useEffect, useState } from 'react'
import {
  AdsSlotInterface,
  SaveAdsSlotRequestInterface,
} from '@/features/ads/slots/redux/types'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import { SaveAdsSlotValidation } from './rules/SaveAdsSlotValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Select2OptionInterface } from '@/app/types'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import _ from 'lodash'
import { AdsSlotTypeEnum } from '@/features/ads/slots/redux/enums/AdsSlotTypeEnum'
import { Label } from '@/features/components/inputs/label'
import { MultiValue } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useLazyGetAdsViewsQuery } from '@/features/ads/views/redux/viewAPI'
import { Query } from '@/utils/query'
import { Button } from '@/features/components/buttons/button'

type Props = {
  title: string
  data?: AdsSlotInterface
} & InstanceProps<SaveAdsSlotRequestInterface>

const Form: React.FC<Props> = ({
  title,
  data,
  onResolve,
  onReject,
  isOpen,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveAdsSlotValidation(),
    t
  )
  const { control, handleSubmit, setValue } =
    useForm<SaveAdsSlotRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })
  const [selectedViews, setSelectedViews] =
    useState<Select2OptionInterface[]>([])
  const [getViews] = useLazyGetAdsViewsQuery()

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('type', data.type)

      if (data.views) {
        setValue(
          'view_ids',
          data.views.map((view) => view.id)
        )
        setSelectedViews(
          data.views.map((view) => ({
            label: view.name,
            value: view.id.toString(),
          }))
        )
      }
    }
  }, [data])

  const handleChangeViews = (
    value: MultiValue<Select2OptionInterface>
  ) => {
    setSelectedViews(
      value as Select2OptionInterface[]
    )
    setValue(
      'view_ids',
      value.map((view) => +view.value)
    )
  }

  const handleLoadOptions = async (
    value: string,
    prevOptions: unknown,
    additional?: { page: number | undefined }
  ) => {
    let page = 1

    if (additional?.page) {
      page = additional.page
    }

    try {
      const response = await getViews(
        new Query()
          .page(page)
          .where('name', value)
          .url()
      ).unwrap()

      return {
        options: response.data.map((view) => ({
          label: view.name,
          value: view.id.toString(),
        })),
        hasMore:
          response.pagination.total_pages > page,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      }
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={title}
          onClose={onReject}
        />
        <form
          onSubmit={handleSubmit(onResolve)}
          className={'flex flex-col gap-y-4 pt-8'}
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t('form:labels.name')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'name'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <Select
                label={t('form:labels.type')}
                onChange={(_, value) =>
                  field.onChange(value)
                }
                value={field.value}
              >
                {_.map(
                  AdsSlotTypeEnum,
                  (value, key) => (
                    <Option
                      value={value}
                      key={key}
                    >
                      {value
                        .toUpperCase()
                        .replaceAll('_', ' ')}
                    </Option>
                  )
                )}
              </Select>
            )}
            name={'type'}
            control={control}
          />
          <Controller
            render={({
              fieldState: { error },
            }) => (
              <FormControl
                error={!!error}
                className={'flex flex-col'}
              >
                <Label
                  label={t('form:labels.views')}
                />
                <AsyncPaginate
                  loadOptions={handleLoadOptions}
                  debounceTimeout={500}
                  isMulti
                  value={selectedViews}
                  onChange={handleChangeViews}
                  classNames={{
                    control: () =>
                      '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent !min-h-[42px]',
                    menuList: () =>
                      '!max-h-[200px]',
                  }}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'view_ids'}
            control={control}
          />

          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

const adsSlotFormModal = create(Form)

export { adsSlotFormModal }
