import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Form } from '@/features/posts/categories/resources/components/forms'
import { SaveCategoryPropsInterface } from '@/features/posts/categories/redux/types'
import { useStoreCategoryMutation } from '@/features/posts/categories/redux/categoryAPI'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { buildFormData } from '@/features/posts/categories/resources/components/forms/helper'

export const PostCategoryCreateScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'posts/categories',
    ])
    const [storeCategory] =
      useStoreCategoryMutation()
    const navigate = useNavigate()

    const handleSubmit = (
      data: SaveCategoryPropsInterface
    ) => {
      storeCategory(buildFormData(data))
        .unwrap()
        .then(() => {
          toast.success(
            t('posts/categories:create.success')
          )
          navigate('/posts/categories')
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('posts/categories:create.title')}
        </span>
        <Card>
          <Form onSubmit={handleSubmit} />
        </Card>
      </div>
    )
  }
