import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Table } from './_components/table'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

export const NotificationTemplateListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'notification_templates',
    ])
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    return (
      <div
        className={'flex flex-col gap-y-4 p-4'}
      >
        <div className={'flex justify-end'}>
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t(
                  'notification_templates:list.title'
                )}
              </span>
            </div>
            <Table
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
