import {
  AttributeCategoryInterface,
  SaveAttributeInterface,
} from '@/features/posts/attributeCategories/redux/types'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import SaveAttributeCategoryValidation from './validations/saveAttributeCategoryValidation'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Card from '@/features/components/cards'
import { FormControl } from '@mui/base'
import { Checkbox } from '@/features/components/inputs/checkbox'
import {
  CreatableSelect2OptionInterface,
  Select2OptionInterface,
} from '@/app/types'
import { Query } from '@/utils/query'
import _ from 'lodash'
import { useLazySearchCategoriesQuery } from '@/features/posts/categories/redux/categoryAPI'
import { Label } from '@/features/components/inputs/label'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import AsyncSelect from '@/features/components/inputs/asyncSelect/asyncSelect'
import { useLazySearchAttributeQuery } from '@/features/posts/attributes/redux/attributeAPI'
import { AttributeTypeEnum } from '@/features/posts/attributes/redux/enums/attributeType'
import { v4 as uuid } from 'uuid'
import { Button } from '@/features/components/buttons/button'
import { SingleValue } from 'react-select'
import CreateableSelect from '@/features/components/inputs/asyncSelect/createableSelect'
import {
  explodeValue,
  instanceOfAttribute,
} from '@/features/posts/attributeCategories/redux/helper'
import { useDeleteAttributeCategoryMutation } from '@/features/posts/attributeCategories/redux/attributeCategoryAPI'
import { confirmModal } from '@/features/components/modals/confirm'
import { useHelper } from '@/features/posts/attributeCategories/hooks/useHelper'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'
import {
  ChildrenForm,
  FileInput,
  Tree,
} from './partials'

type Props = {
  onSubmit: (
    data: SaveAttributeInterface
  ) => Promise<boolean>
  data?: AttributeCategoryInterface
  backendErrors?: BackendValidationErrorInterface<SaveAttributeInterface>
}

export const Form: React.FC<Props> = ({
  onSubmit,
  data: apiData,
  backendErrors,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
    'posts/attribute_categories',
  ])
  const {
    schema,
    defaultValues,
    resolveValidationErrors,
  } = useValidation(
    new SaveAttributeCategoryValidation(),
    t
  )
  const [searchCategories] =
    useLazySearchCategoriesQuery()
  const [searchAttributes, { data }] =
    useLazySearchAttributeQuery()
  const [deleteAttributeCategory] =
    useDeleteAttributeCategoryMutation()
  const {
    deleteRecursiveArray,
    transformToForm,
    findInArray,
    assignValue,
  } = useHelper()
  const [
    selectedAttribute,
    setSelectedAttribute,
  ] = useState<Select2OptionInterface | null>()
  const [selectedCategory, setSelectedCategory] =
    useState<Select2OptionInterface | null>()
  const [attributeType, setAttributeType] =
    useState<AttributeTypeEnum>(
      AttributeTypeEnum.STRING
    )
  const isSelectType = useMemo(
    () =>
      attributeType &&
      [
        AttributeTypeEnum.SELECT,
        AttributeTypeEnum.MULTI_SELECT,
      ].includes(attributeType),
    [attributeType]
  )
  const [formContext, setFormContext] = useState<{
    uuid: string
    show: boolean
  }>({
    uuid: '',
    show: false,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    setError,
  } = useForm<SaveAttributeInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const { fields, append, replace } =
    useFieldArray({
      control,
      name: 'values',
    })

  useEffect(() => {
    if (backendErrors) {
      const err = resolveValidationErrors(
        backendErrors
      )

      if (err) {
        _.forEach(err, (value, key) => {
          setError(
            key as keyof SaveAttributeInterface,
            {
              type: 'manual',
              message: value,
            }
          )
        })
      }
    }
  }, [backendErrors])

  useEffect(() => {
    if (
      apiData &&
      apiData.category &&
      apiData.attribute
    ) {
      const transformedData =
        transformToForm(apiData)

      setValue(
        'affects_price',
        transformedData.affects_price
      )
      setValue(
        'attribute_id',
        transformedData.attribute_id
      )
      setValue(
        'category_id',
        transformedData.category_id
      )
      setValue('values', transformedData.values)

      setSelectedCategory({
        label: apiData.category.name,
        value: String(
          transformedData.category_id
        ),
      })

      setSelectedAttribute({
        label: apiData.attribute.name,
        value: String(
          transformedData.attribute_id
        ),
      })

      setAttributeType(apiData.attribute.type)
    }
  }, [apiData])

  const _searchCategory = (
    value: string,
    callback: (
      options: Select2OptionInterface[]
    ) => void
  ) => {
    const query = new Query().where('name', value)

    searchCategories(query.url())
      .unwrap()
      .then((response) => {
        callback(
          response.map((category) => ({
            label: category.name,
            value: String(category.id),
          }))
        )
      })
  }

  const _searchAttribute = (
    value: string,
    callback: (
      options: Select2OptionInterface[]
    ) => void
  ) => {
    const query = new Query().where('name', value)

    searchAttributes(query.url())
      .unwrap()
      .then((response) => {
        callback(
          response.map((attribute) => ({
            label: attribute.name,
            value: String(attribute.id),
          }))
        )
      })
  }

  const searchCategory = _.debounce(
    _searchCategory,
    500
  )
  const searchAttribute = _.debounce(
    _searchAttribute,
    500
  )

  const handleSelectAttribute = (
    value: Select2OptionInterface | null
  ) => {
    if (value && data) {
      const attribute = data.find(
        (attribute) =>
          attribute.id === Number(value.value)
      )

      if (!attribute) return

      setAttributeType(attribute.type)
      setSelectedAttribute(value)
      setValue('attribute_id', attribute.id)
    }
  }

  const watchCategoryId = watch('category_id')

  const handleCreateValue = (
    value: CreatableSelect2OptionInterface[]
  ) => {
    const parentId = uuid()

    if (value.length < fields.length) {
      replace(
        fields.filter((item) =>
          value
            .map(({ value }) => value)
            .includes(item.value)
        )
      )
    } else {
      append(
        explodeValue(
          value.filter(
            ({ __isNew__ }) => __isNew__
          )
        ).map((item) => ({
          value: item,
          uuid: parentId,
        }))
      )
    }
  }

  const handleSelectCategory = (
    value: SingleValue<Select2OptionInterface>
  ) => {
    if (!value) return

    setValue('category_id', Number(value.value))
    setSelectedCategory(value)
  }

  const handleAddClick = useCallback(
    (uuid: string) => {
      setFormContext({
        show: true,
        uuid,
      })
    },
    []
  )

  const handleAddChild = useCallback(
    (data: SaveAttributeInterface) => {
      replace(
        assignValue(
          fields,
          formContext.uuid,
          data
        )
      )
      hideFormContext()
    },
    [
      assignValue,
      fields,
      formContext.uuid,
      replace,
    ]
  )

  const handleDeleteClick = useCallback(
    async (uuid: string) => {
      const element = findInArray(fields, uuid)

      if (element && element._id) {
        try {
          const response = await confirmModal({
            message: t(
              'posts/attribute_categories:confirm.delete_description'
            ),
            type: 'warning',
          })

          if (response) {
            await deleteAttributeCategory({
              id: element._id,
              delete_value:
                !instanceOfAttribute(element),
            })
          }
        } catch (error) {
          return
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      replace(deleteRecursiveArray(fields, uuid))
    },
    [
      deleteRecursiveArray,
      fields,
      findInArray,
      replace,
      t,
    ]
  )

  const handleStore = (
    data: SaveAttributeInterface
  ) => {
    onSubmit(data).then((result) => {
      if (result) {
        reset(defaultValues)
        setSelectedAttribute(null)
        setSelectedCategory(null)
        setAttributeType(AttributeTypeEnum.STRING)
        hideFormContext()
      }
    })
  }

  const hideFormContext = () => {
    setFormContext({
      show: false,
      uuid: '',
    })
  }

  return (
    <div className={'flex flex-col p-4'}>
      <form
        onSubmit={handleSubmit(handleStore)}
        className={'flex flex-col gap-y-4'}
      >
        <Card>
          <div
            className={'flex flex-col gap-y-6'}
          >
            <div
              className={
                'grid lg:grid-cols-3 gap-4'
              }
            >
              <Controller
                control={control}
                name={'category_id'}
                render={() => (
                  <div
                    className={'flex flex-col'}
                  >
                    <Label
                      label={t(
                        'form:labels.category'
                      )}
                    />
                    <AsyncSelect
                      cacheOptions
                      onChange={
                        handleSelectCategory
                      }
                      loadOptions={searchCategory}
                      value={selectedCategory}
                    />
                    <FormHelperText
                      message={
                        errors.category_id
                          ?.message
                      }
                      error={!!errors.category_id}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name={'attribute_id'}
                render={() => (
                  <div
                    className={'flex flex-col'}
                  >
                    <Label
                      label={t(
                        'form:labels.attribute'
                      )}
                    />
                    <AsyncSelect
                      cacheOptions
                      onChange={
                        handleSelectAttribute
                      }
                      loadOptions={
                        searchAttribute
                      }
                      value={selectedAttribute}
                    />
                    <FormHelperText
                      message={
                        errors.attribute_id
                          ?.message
                      }
                      error={
                        !!errors.attribute_id
                      }
                    />
                  </div>
                )}
              />
              {attributeType ===
                AttributeTypeEnum.CERTIFICATE && (
                <FileInput
                  control={control}
                  t={t}
                  errors={errors}
                />
              )}
              {isSelectType && (
                <Controller
                  render={({ field }) => (
                    <div
                      className={'flex flex-col'}
                    >
                      <Label
                        label={t(
                          'form:labels.options'
                        )}
                      />
                      <CreateableSelect
                        value={field.value.map(
                          ({ value }) => ({
                            value,
                            label: value,
                          })
                        )}
                        isMulti
                        onChange={(value) =>
                          handleCreateValue(
                            value as CreatableSelect2OptionInterface[]
                          )
                        }
                      />
                    </div>
                  )}
                  name={'values'}
                  control={control}
                />
              )}
            </div>
            <Controller
              render={({ field }) => (
                <FormControl
                  error={!!errors.affects_price}
                >
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t(
                      'form:labels.affects_price'
                    )}
                    name={field.name}
                  />
                </FormControl>
              )}
              name={'affects_price'}
              control={control}
            />
          </div>
        </Card>
        {isSelectType &&
          fields.length > 0 &&
          !_.isNil(watchCategoryId) && (
            <div
              className={
                'grid 2xl:grid-cols-2 lg:grid-cols-3 gap-4'
              }
            >
              <div
                className={
                  'flex flex-col 2xl:col-span-1 lg:col-span-2'
                }
              >
                <Card className={'w-full'}>
                  {formContext.show &&
                    formContext.uuid && (
                      <ChildrenForm
                        onSubmit={handleAddChild}
                      />
                    )}
                </Card>
              </div>
              <Card>
                <Tree
                  onAddClick={handleAddClick}
                  onDeleteClick={
                    handleDeleteClick
                  }
                  fields={fields}
                />
              </Card>
            </div>
          )}
        <div className={'flex'}>
          <Button
            variant={'contained'}
            type={'submit'}
          >
            {t('form:buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  )
}
