import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveAttributePropsInterface } from '@/features/posts/attributes/redux/types'
import { AttributeTypeEnum } from '@/features/posts/attributes/redux/enums/attributeType'
import { UnitEnum } from '@/app/enums/unitEnum'

export default class SaveAttributeValidation
  implements
    ValidationInterface<SaveAttributePropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveAttributePropsInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required')),
      type: yup
        .mixed<AttributeTypeEnum>()
        .oneOf(Object.values(AttributeTypeEnum))
        .required(t('validation:required')),
      unit: yup
        .mixed<UnitEnum>()
        .nullable()
        .oneOf(Object.values(UnitEnum)),
      is_required: yup
        .boolean()
        .required(t('validation:required')),
      settable_by_user: yup
        .boolean()
        .required(t('validation:required')),
    })
  }

  defaultValues(): SaveAttributePropsInterface {
    return {
      name: '',
      type: AttributeTypeEnum.STRING,
      unit: null,
      is_required: false,
      settable_by_user: false,
    }
  }
}
