import igritAPI from '@/utils/api/igrit'
import {
  GetServiceCategoriesResponseInterface,
  GetServiceConfigListResponseInterface,
  GetServiceConfigsResponseInterface,
  GetServiceResponseInterface,
  GetServicesResponseInterface,
  SaveServiceCategoryRequestInterface,
  SaveServiceConfigRequestInterface,
  SaveServiceRequestInterface,
} from '@/features/services/redux/types'
import { ServiceNameEnum } from '@/features/posts/posts/redux/enums/service'
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useLazyGetServiceConfigsQuery,
  useGetServiceQuery,
  useGetServicesQuery,
  useStoreServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useGetServiceCategoriesQuery,
  useStoreServiceCategoryMutation,
  useUpdateServiceCategoryMutation,
  useDeleteServiceCategoryMutation,
  useStoreServiceConfigMutation,
  useUpdateServiceConfigMutation,
  useDeleteServiceConfigMutation,
  useGetServiceConfigListQuery,
  useLazyGetServicesQuery,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getServiceConfigs: builder.query<
      GetServiceConfigsResponseInterface['data'],
      ServiceNameEnum
    >({
      query: (systemName) =>
        `/admin/services/${systemName}/config`,
      transformResponse: (
        response: GetServiceConfigsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setArrayCache('ServiceConfig', result),
    }),
    getServices: builder.query<
      GetServicesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/services?${query}`,
      transformResponse: (
        response: GetServicesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Service', result),
    }),
    getService: builder.query<
      GetServiceResponseInterface['data'],
      number | string
    >({
      query: (id) => `/admin/services/${id}`,
      transformResponse: (
        response: GetServiceResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('Service', result),
    }),
    storeService: builder.mutation<
      void,
      SaveServiceRequestInterface
    >({
      query: (body) => ({
        url: '/admin/services',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Service'],
    }),
    updateService: builder.mutation<
      void,
      {
        id: number | string
        body: SaveServiceRequestInterface
      }
    >({
      query: ({ id, body }) => ({
        url: `/admin/services/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Service'],
    }),
    deleteService: builder.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/services/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Service'],
    }),
    getServiceCategories: builder.query<
      GetServiceCategoriesResponseInterface['data'],
      { id: string | number; query: string }
    >({
      query: ({ query, id }) =>
        `/admin/services/${id}/categories?${query}`,
      transformResponse: (
        response: GetServiceCategoriesResponseInterface
      ) => response.data,
      providesTags: [
        { type: 'ServiceCategory', id: 'LIST' },
      ],
    }),
    storeServiceCategory: builder.mutation<
      void,
      {
        id: number | string
        body: SaveServiceCategoryRequestInterface
      }
    >({
      query: ({ id, body }) => ({
        url: `/admin/services/${id}/categories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceCategory'],
    }),
    updateServiceCategory: builder.mutation<
      void,
      {
        serviceId: number | string
        id: number | string
        body: SaveServiceCategoryRequestInterface
      }
    >({
      query: ({ serviceId, body, id }) => ({
        url: `/admin/services/${serviceId}/categories/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['ServiceCategory'],
    }),
    deleteServiceCategory: builder.mutation<
      void,
      {
        serviceId: number | string
        id: number | string
      }
    >({
      query: ({ serviceId, id }) => ({
        url: `/admin/services/${serviceId}/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ServiceCategory'],
    }),
    getServiceConfigList: builder.query<
      GetServiceConfigListResponseInterface['data'],
      {
        serviceId: string | number
        query: string
      }
    >({
      query: ({ serviceId, query }) =>
        `/admin/services/${serviceId}/configs?${query}`,
      transformResponse: (
        response: GetServiceConfigListResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache(
          'ServiceConfig',
          result
        ),
    }),
    storeServiceConfig: builder.mutation<
      void,
      {
        serviceId: string | number
        body: SaveServiceConfigRequestInterface
      }
    >({
      query: ({ serviceId, body }) => ({
        url: `/admin/services/${serviceId}/configs`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceConfig'],
    }),
    updateServiceConfig: builder.mutation<
      void,
      {
        serviceId: string | number
        id: string | number
        body: SaveServiceConfigRequestInterface
      }
    >({
      query: ({ serviceId, id, body }) => ({
        url: `/admin/services/${serviceId}/configs/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ServiceConfig'],
    }),
    deleteServiceConfig: builder.mutation<
      void,
      {
        serviceId: string | number
        id: string | number
      }
    >({
      query: ({ serviceId, id }) => ({
        url: `/admin/services/${serviceId}/configs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ServiceConfig'],
    }),
  }),
})
