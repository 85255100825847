import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import usePagination from '@/utils/hooks/usePagination'
import {
  Query,
  SortingState,
} from '@/utils/query'
import {
  useDeleteArticleCommentMutation,
  useGetArticleCommentsQuery,
  useToggleArticleCommentMutation,
} from '@/features/articleComments/redux/ArticleCommentsAPI'
import { ArticleCommentInterface } from '@/features/articleComments/redux/types'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Carbon from '@/utils/carbon'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import {
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): ReactNode => {
  const { t } = useTranslation([
    'form',
    'article_comments',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const [sorting, setSorting] =
    useState<SortingState>([])
  const query = useMemo(
    () =>
      baseQuery
        .includes('article,user')
        .page(pagination.pageIndex),
    [pagination, baseQuery]
  )
  const { data: apiData } =
    useGetArticleCommentsQuery(query.url())
  const [deleteArticle] =
    useDeleteArticleCommentMutation()
  const [toggleArticleComment] =
    useToggleArticleCommentMutation()
  const [data, setData] = useState<
    ArticleCommentInterface[]
  >([])

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = async (
    articleId: string | number,
    commentId: string | number
  ) => {
    try {
      await deleteArticle({
        articleId,
        commentId,
      }).unwrap()
      toast.success(
        t('article_comments:list.deleted')
      )
    } catch (error) {
      //   Empty Block
    }
  }

  const onToggle = async (
    commentId: string | number
  ) => {
    try {
      await toggleArticleComment(
        commentId
      ).unwrap()
      toast.success(
        t('article_comments:list.updated')
      )
    } catch (error) {
      // empty block
    }
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onToggle),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: true,
    enableHiding: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualSorting: true,
    manualPagination: true,
    state: {
      sorting,
      pagination,
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<ArticleCommentInterface>()

const columns = (
  t: TFunction,
  onDelete: (
    articleId: number,
    commentId: number
  ) => void,
  onToggle: (commentId: number) => void
) => [
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.created_at'),
    enableSorting: true,
    cell: ({ row }) =>
      new Carbon(
        row.original.created_at
      ).toDateTimeLocal(),
    enableHiding: true,
  }),
  columnBuilder.accessor('user.name', {
    id: 'user.name',
    header: t('form:labels.user'),
    enableSorting: false,
    cell: ({ row }) =>
      row.original.user.id ? (
        <Link
          className={
            'text-blue-400 hover:text-blue-500 transition duration-300'
          }
          to={`/users/${row.original.user.id}/edit`}
          target={'_blank'}
        >
          {row.original.user.name}
        </Link>
      ) : (
        row.original.user.name
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('content', {
    id: 'content',
    header: t('form:labels.content'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('is_visible', {
    id: 'is_visible',
    header: t('form:labels.is_visible'),
    cell: ({ row }) =>
      row.original.is_visible
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableSorting: false,
    enableHiding: true,
  }),
  columnBuilder.accessor('article.title', {
    id: 'article.title',
    header: t('form:labels.title'),
    cell: ({ row }) => (
      <Link
        className={
          'text-blue-400 hover:text-blue-500 transition duration-300'
        }
        to={`/articles/${row.original.article_id}`}
      >
        {row.original.article.title}
      </Link>
    ),
    enableSorting: false,
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(
                  row.original.article_id,
                  row.original.id
                ),
              permission:
                PermissionEnum.ARTICLE_COMMENT_DESTROY,
            },
          }),
          {
            icon: faToggleOff,
            onClick: () =>
              onToggle(row.original.id),
            buttonClassName: 'bg-red-400',
            permission:
              PermissionEnum.ARTICLE_COMMENT_SAVE,
            condition: row.original.is_visible,
            tooltip:
              'article_comments:tooltips:deactivate',
          },
          {
            icon: faToggleOn,
            onClick: () =>
              onToggle(row.original.id),
            buttonClassName: 'bg-blue-400',
            permission:
              PermissionEnum.ARTICLE_COMMENT_SAVE,
            condition: !row.original.is_visible,
            tooltip:
              'article_comments:tooltips:activate',
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
