import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './_components/table'
import Card from '@/features/components/cards'
import {
  InvoiceItemInterface,
  SaveInvoiceItemRequestInterface,
} from '@/features/invoices/redux/types'
import { Button } from '@/features/components/buttons/button'
import { useStoreInvoiceItemMutation } from '@/features/invoices/redux/invoiceAPI'
import { invoiceItemFormModal } from '@/features/invoices/items/resources/_components/form'
import { toast } from 'react-toastify'

type Props = {
  data: InvoiceItemInterface[]
  invoiceId: string
}

export const InvoiceItemList: React.FC<Props> = ({
  data,
  invoiceId,
}): React.ReactNode => {
  const { t } = useTranslation(['invoices'])
  const [storeInvoiceItem] =
    useStoreInvoiceItemMutation()

  const handleCreate = () => {
    invoiceItemFormModal({
      title: t('invoices:items.create.title'),
    }).then(
      (
        response: SaveInvoiceItemRequestInterface
      ) => {
        storeInvoiceItem({
          invoiceId,
          body: response,
        })
          .unwrap()
          .then(() =>
            toast.success(
              t('invoices:items.create.success')
            )
          )
      }
    )
  }

  return (
    <div className={'flex flex-col gap-y-4'}>
      <Card margin noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium flex justify-between items-center'
            }
          >
            <span>
              {t('invoices:items.list.title')}
            </span>
            <Button
              size={'small'}
              variant={'contained'}
              onClick={handleCreate}
            >
              {t('invoices:items.list.add')}
            </Button>
          </div>
          <Table
            data={data}
            invoiceId={invoiceId}
          />
        </div>
      </Card>
    </div>
  )
}
