import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteRoleMutation,
  useGetRolesQuery,
} from '@/features/roles/redux/roleAPI'
import { RoleInterface } from '@/features/roles/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'roles'])
  const [{ pageIndex, pageSize }, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      new Query().limit(pageSize).page(pageIndex),
    [pageIndex, pageSize]
  )
  const { data: apiData } = useGetRolesQuery(
    query.url()
  )
  const [data, setData] = useState<
    RoleInterface[]
  >([])
  const [deleteRole] = useDeleteRoleMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteRole(id)
      .unwrap()
      .then(() =>
        toast.success(t('roles:list.deleted'))
      )
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      columnVisibility: visibility,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      table={table}
      pagination={apiData?.pagination}
    />
  )
}

const columnBuilder =
  createColumnHelper<RoleInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'type',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/roles/${row.original.id}/edit`,
            permission: PermissionEnum.ROLE_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.ROLE_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
