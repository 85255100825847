import {
  AuditInterface,
  PaginationInterface,
} from '@/app/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Carbon from '@/utils/carbon'
import _, { isObject } from 'lodash'
import { TFunction } from 'i18next'
import Card from '@/features/components/cards'
import { Pagination } from '@/components'

type Props = {
  data: AuditInterface[] | undefined
  pagination?: PaginationInterface
  onPageChange?: (page: number) => void
}

export const AuditTable: React.FC<Props> = ({
  data,
  pagination,
  onPageChange,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'audits',
  ])

  return (
    <Card noPadding className={'overflow-auto'}>
      <table
        className={
          'w-full table-auto text-left text-sm '
        }
      >
        <thead>
          <tr
            className={
              'border-b border-b-neutral-600'
            }
          >
            <td
              className={
                'bg-neutral-100 px-[24px] py-[16px] text-[13px] font-[500] text-gray-50'
              }
            >
              {t('form:labels.event')}
            </td>
            <td
              className={
                'bg-neutral-100 px-[24px] py-[16px] text-[13px] font-[500] text-gray-50'
              }
            >
              {t(
                'form:labels.additional_information'
              )}
            </td>
            <td
              className={
                'bg-neutral-100 px-[24px] py-[16px] text-[13px] font-[500] text-gray-50'
              }
            >
              {t('form:labels.ip_address')}
            </td>
          </tr>
        </thead>
        <tbody
          className={
            'text-s font-medium text-gray-700'
          }
        >
          {data?.map((item, index) => (
            <tr
              key={index}
              className={
                'border-b border-b-neutral-600'
              }
            >
              <td
                className={
                  'flex flex-col gap-y-0.5 px-[24px] py-[12px] text-[14px] text-gray-700'
                }
              >
                <span>
                  {new Carbon()
                    .parse(item.created_at)
                    .format('dd.MM.yyyy HH:mm')}
                </span>
                <span className={'font-semibold'}>
                  {t(
                    'audits:events.' +
                      `${
                        item.event
                      }_${item.auditable_type.toLowerCase()}`
                  )}
                </span>
                {item.user && (
                  <span>{item.user.name}</span>
                )}
              </td>
              <td
                className={
                  'px-[24px] py-[12px] text-[14px] text-gray-700 min-w-[400px]'
                }
              >
                <table className={'table-auto'}>
                  <thead>
                    <tr
                      className={
                        'border-b border-b-neutral-600'
                      }
                    >
                      <td
                        className={
                          'bg-neutral-100 p-1 text-[13px] font-[500] text-gray-50'
                        }
                      >
                        Klucze
                      </td>
                      <td
                        className={
                          'bg-neutral-100 p-1 text-[13px] font-[500] text-gray-50'
                        }
                      >
                        Stare wartości
                      </td>
                      <td
                        className={
                          'bg-neutral-100 p-1 text-[13px] font-[500] text-gray-50'
                        }
                      >
                        Nowe wartości
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(
                      item.new_values
                    ).map((key, index) => (
                      <tr key={index}>
                        <td
                          className={
                            'font-semibold p-1'
                          }
                        >
                          {t(
                            `form:labels.${key}`
                          )}
                        </td>
                        <td
                          className={
                            'min-w-[200px] p-1'
                          }
                        >
                          {resolveValue(
                            item.old_values[key],
                            t
                          )}
                        </td>
                        <td
                          className={
                            'min-w-[200px] p-1'
                          }
                        >
                          {resolveValue(
                            item.new_values[key],
                            t
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td className={'min-w-[150px]'}>
                {item.ip_address}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pagination && onPageChange && (
        <div className={'px-8 py-6'}>
          <Pagination
            onPageChange={(page) =>
              onPageChange(page)
            }
            totalPages={pagination.total_pages}
          />
        </div>
      )}
    </Card>
  )
}

const resolveValue = (
  value: any,
  t: TFunction
): React.ReactNode => {
  if (
    typeof value === 'string' ||
    typeof value === 'number'
  ) {
    return <span>{value}</span>
  }

  if (Array.isArray(value)) {
    return <span>{value.join(', ')}</span>
  }

  if (isObject(value)) {
    return Object.keys(value).map(
      (key, index) => {
        return (
          <div
            className={'flex flex-nowrap gap-x-2'}
            key={index}
          >
            <span>
              {t(`form:labels.${key}`)}:
            </span>
            {resolveValue(_.get(value, key), t)}
          </div>
        )
      }
    )
  }
}
