import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveCategoryPropsInterface } from '@/features/posts/categories/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { UnitEnum } from '@/app/enums/unitEnum'
import _ from 'lodash'

export default class SaveCategoryValidation
  implements
    ValidationInterface<SaveCategoryPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveCategoryPropsInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required')),
      seo_keywords: yup.string().nullable(),
      seo_description: yup.string().nullable(),
      parent_category_id: yup
        .number()
        .nullable()
        .test(
          'is-not-one-of',
          t('validation:unique-category'),
          function (value) {
            if (_.isNil(value)) return true

            const additionalCategoryIds =
              this.parent
                .additional_category_ids || []

            return !additionalCategoryIds.includes(
              value
            )
          }
        ),
      additional_category_ids: yup
        .array()
        .of(yup.number().required())
        .nullable()
        .test(
          'is-not-one-of',
          t('validation:unique-category'),
          function (value) {
            const parentCategoryId =
              this.parent.parent_category_id

            if (
              _.isNil(value) ||
              _.isNil(parentCategoryId)
            )
              return true

            return !value.includes(
              parentCategoryId
            )
          }
        ),
      units: yup
        .array()
        .of(yup.mixed<UnitEnum>().required())
        .required(t('validation:required'))
        .min(1, t('validation:required')),
      icon: yup
        .mixed<File>()
        .nullable()
        .test(
          'file-extension',
          t('validation:file_extension', {
            allowedExtensions:
              'jpg, png, jpeg, svg',
          }),
          function (value) {
            if (_.isNil(value)) return true

            const allowedExtensions = [
              'jpg',
              'png',
              'jpeg',
              'svg',
            ]
            const fileExtension = value.name
              .split('.')
              .pop()

            return allowedExtensions.includes(
              fileExtension || ''
            )
          }
        ),
      description: yup.string().nullable(),
    })
  }

  defaultValues(): SaveCategoryPropsInterface {
    return {
      name: '',
      seo_keywords: '',
      seo_description: '',
      additional_category_ids: [],
      units: [],
      description: '',
    }
  }
}
