import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { UpdateAttributeInterface } from '@/features/posts/posts/redux/types'
import { useTranslation } from 'react-i18next'
import {
  useLazyGetAttributeCategoryQuery,
  useLazySearchAttributeCategoriesQuery,
} from '@/features/posts/attributeCategories/redux/attributeCategoryAPI'
import { Select2OptionInterface } from '@/app/types'
import { Query } from '@/utils/query'
import Modal from '@/features/components/modals/modal'
import { AttributeTypeEnum } from '@/features/posts/attributes/redux/enums/attributeType'
import { AttributeCategoryValueInterface } from '@/features/posts/attributeCategories/redux/types'
import { Input } from '@/features/components/inputs/input'
import { TFunction } from 'i18next'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Label } from '@/features/components/inputs/label'
import _ from 'lodash'
import { SingleValue } from 'react-select'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
  UseFormSetValue,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import AsyncSelect from '@/features/components/inputs/asyncSelect/asyncSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@/features/components/buttons/button'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { UpdateAttributeValidation } from '@/features/posts/posts/resources/views/edit/_components'

type Props = {
  categoryId: number | string
  data?: UpdateAttributeInterface
} & InstanceProps<
  UpdateAttributeInterface,
  unknown
>

type ValuePrefix =
  | 'value'
  | 'value_id'
  | 'value_ids'
type Value = UpdateAttributeInterface[ValuePrefix]
type SelectValues = Pick<
  AttributeCategoryValueInterface,
  'id' | 'value'
>[]

const AssociateAttributeForm: React.FC<Props> = ({
  isOpen,
  onReject,
  onResolve,
  categoryId,
  data,
}): React.ReactNode => {
  const { t } = useTranslation()
  const [
    searchAttributeCategories,
    { data: attributeCategories = [] },
  ] = useLazySearchAttributeCategoriesQuery()
  const { schema, defaultValues } = useValidation(
    new UpdateAttributeValidation(),
    t
  )
  const [
    selectedAttributeCategory,
    setSelectedAttributeCategory,
  ] = useState<Select2OptionInterface>()
  const [fetchAttributeCategory] =
    useLazyGetAttributeCategoryQuery()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<UpdateAttributeInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const watchType = watch('type')
  const watchName = watch('name')
  const watchValue = watch('value')
  const watchValueId = watch('value_id')
  const watchValueIds = watch('value_ids')

  const [availableValues, setAvailableValues] =
    useState<SelectValues>([])

  useEffect(() => {
    if (data) {
      setValue(
        'attribute_category_id',
        data.attribute_category_id
      )
      setValue('type', data.type)
      setValue('name', data.name)
      setValue('human_value', data.human_value)
      setValue('value', data.value)
      setValue('value_id', data.value_id)
      setValue('value_ids', data.value_ids)

      setSelectedAttributeCategory({
        label: data.name,
        value:
          data.attribute_category_id.toString(),
      })

      if (
        data.type === AttributeTypeEnum.SELECT ||
        data.type ===
          AttributeTypeEnum.MULTI_SELECT
      ) {
        fetchAttributeCategory(
          data.attribute_category_id
        )
          .unwrap()
          .then((response) => {
            if (response.values) {
              setAvailableValues(
                response.values.map((value) => ({
                  id: value.id,
                  value: value.value,
                }))
              )
            }
          })
      }
    }
  }, [data])

  const _handleSearchAttributeCategories = (
    value: string,
    callback: (
      options: Select2OptionInterface[]
    ) => void
  ) => {
    const query = new Query()
      .includes(
        'attribute',
        'attributeCategoryValues'
      )
      .where('category_id', categoryId)
      .where('name', value)
      .url()

    searchAttributeCategories(query)
      .unwrap()
      .then((response) =>
        callback(
          response.map((attribute) => ({
            label: attribute.attribute!.name,
            value: attribute.id.toString(),
          }))
        )
      )
  }

  const handleSearchAttributeCategories =
    _.debounce(
      _handleSearchAttributeCategories,
      500
    )

  const handleSelectAttribute = (
    value: SingleValue<Select2OptionInterface>
  ) => {
    if (!value) return

    const attributeCategory =
      attributeCategories.find(
        ({ id }) => id === Number(value.value)
      )

    if (
      !attributeCategory ||
      !attributeCategory.attribute
    )
      return

    setValue(
      'attribute_category_id',
      attributeCategory.id
    )

    setSelectedAttributeCategory({
      label: attributeCategory.attribute.name,
      value: attributeCategory.id.toString(),
    })
    setValue(
      'type',
      attributeCategory.attribute.type
    )
    setValue(
      'name',
      attributeCategory.attribute.name
    )

    if (
      [
        AttributeTypeEnum.SELECT,
        AttributeTypeEnum.MULTI_SELECT,
      ].includes(
        attributeCategory.attribute.type
      ) &&
      attributeCategory.values
    ) {
      setAvailableValues(attributeCategory.values)
    }

    setInitialValue(
      attributeCategory.attribute.type,
      attributeCategory.values,
      setValue
    )
  }

  const onSubmit = (
    data: UpdateAttributeInterface
  ) => {
    onResolve(data)
  }

  const resolveCurrentValue = useMemo(() => {
    switch (watchType) {
      case AttributeTypeEnum.SELECT:
        return watchValueId
      case AttributeTypeEnum.MULTI_SELECT:
        return watchValueIds
      case AttributeTypeEnum.BOOL:
        return Boolean(watchValue)
      default:
        return watchValue
    }
  }, [
    watchType,
    watchValueId,
    watchValueIds,
    watchValue,
  ])

  const resolveSetValue = useCallback(
    (value: Value) => {
      if (
        [
          AttributeTypeEnum.SELECT,
          AttributeTypeEnum.MULTI_SELECT,
        ].includes(watchType) &&
        availableValues.length
      ) {
        switch (watchType) {
          case AttributeTypeEnum.SELECT:
            setValue(
              'human_value',
              availableValues.find(
                ({ id }) => id === value
              )?.value ?? ''
            )
            break
          case AttributeTypeEnum.MULTI_SELECT:
            if (!value) return

            const values = (
              value as string
            ).split(',')

            setValue(
              'human_value',
              availableValues
                .filter(({ id }) =>
                  values.includes(id.toString())
                )
                .map(({ value }) => value)
                .join(',')
            )
        }
      } else {
        setValue('human_value', value ?? '')
      }

      switch (watchType) {
        case AttributeTypeEnum.SELECT:
          setValue('value_id', value as number)
          break
        case AttributeTypeEnum.MULTI_SELECT:
          setValue('value_ids', value as string)
          break
        default:
          setValue('value', value)
      }
    },
    [watchType]
  )

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content size={'w-[800px]'}>
        <Fragment>
          <div
            className={
              'flex w-full flex-row items-center justify-between'
            }
          >
            <span
              className={'text-lg font-semibold'}
            >
              {t(
                'posts/posts:edit.attributes.add.title'
              )}
            </span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={'cursor-pointer'}
            />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={
              'flex flex-col gap-y-4 pt-4'
            }
          >
            <Controller
              render={({ field }) => (
                <div className={'flex flex-col'}>
                  <Label
                    label={t(
                      'form:labels.attribute'
                    )}
                  />
                  <AsyncSelect
                    loadOptions={
                      handleSearchAttributeCategories
                    }
                    onChange={
                      handleSelectAttribute
                    }
                    value={
                      selectedAttributeCategory
                    }
                    cacheOptions
                  />
                </div>
              )}
              name={'attribute_category_id'}
              control={control}
            />
            <div className={'flex flex-col'}>
              {resolveValueForm(
                watchType,
                t,
                watchName,
                resolveCurrentValue,
                resolveSetValue,
                availableValues
              )}
              <FormHelperText
                error={!!errors.value}
                message={errors.value?.message}
              />
            </div>

            <div className={'pt-4 flex'}>
              <Button
                variant={'contained'}
                type={'submit'}
              >
                {t('form:buttons.save')}
              </Button>
            </div>
          </form>
        </Fragment>
      </Modal.Content>
    </Modal>
  )
}

const resolveValueForm = (
  type: AttributeTypeEnum,
  t: TFunction,
  label: string,
  currentValue: Value,
  onChange: (value: Value) => void,
  values?: SelectValues
) => {
  switch (type) {
    case AttributeTypeEnum.PIORIN_FORMAT:
    case AttributeTypeEnum.STRING:
      return (
        <Input
          label={label}
          onChange={(e) =>
            onChange(e.currentTarget.value)
          }
          value={currentValue}
        />
      )
    case AttributeTypeEnum.YEAR_FORMAT:
    case AttributeTypeEnum.INTEGER:
      return (
        <Input
          type={'number'}
          step={'0'}
          onChange={(e) =>
            onChange(e.currentTarget.value)
          }
          value={currentValue}
        />
      )
    case AttributeTypeEnum.FLOAT:
      return (
        <Input
          type={'number'}
          step={'0.01'}
          onChange={(e) =>
            onChange(e.currentTarget.value)
          }
          value={currentValue}
        />
      )
    case AttributeTypeEnum.RANGE:
      return (
        <div className={'flex flex-col gap-y-2'}>
          <Label label={label} />
          <Input
            label={t('form:labels.from')}
            type={'number'}
            value={
              (
                currentValue as {
                  from: string
                  to: string
                }
              ).from
            }
            onChange={(e) =>
              onChange({
                from: e.currentTarget.value,
                to: (
                  currentValue as {
                    from: string
                    to: string
                  }
                ).to,
              })
            }
          />
          <Input
            label={t('form:labels.to')}
            type={'number'}
            value={
              (
                currentValue as {
                  from: string
                  to: string
                }
              ).to
            }
            onChange={(e) =>
              onChange({
                to: e.currentTarget.value,
                from: (
                  currentValue as {
                    from: string
                    to: string
                  }
                ).from,
              })
            }
          />
        </div>
      )
    case AttributeTypeEnum.SELECT:
      return (
        <Select
          label={label}
          value={currentValue as number}
          placeholder={t(
            'form:placeholders.select_value'
          )}
          onChange={(_, value) =>
            onChange(value as number)
          }
        >
          {values?.map((option) => (
            <Option
              key={option.id}
              value={option.id}
            >
              {option.value}
            </Option>
          ))}
        </Select>
      )
    case AttributeTypeEnum.MULTI_SELECT:
      return (
        <Select
          multiple
          placeholder={t(
            'form:placeholders.select_value'
          )}
          label={label}
          onChange={(_, value) =>
            onChange(
              (value as string[]).join(',')
            )
          }
          value={(currentValue as string)
            ?.split(',')
            .map((value) => +value)}
        >
          {values?.map((option, index) => (
            <Option value={option.id} key={index}>
              {option.value}
            </Option>
          ))}
        </Select>
      )
    case AttributeTypeEnum.BOOL:
      return (
        <Checkbox
          checked={!!currentValue}
          label={label}
          onChange={(e) =>
            onChange(e.currentTarget.checked)
          }
        />
      )
    case AttributeTypeEnum.DATE:
      return (
        <Input
          label={label}
          value={currentValue}
          type={'date'}
          onChange={(e) =>
            onChange(e.currentTarget.value)
          }
        />
      )
  }
}

/**
 * Set initial value for attribute
 * @param type
 * @param values
 * @param setValue
 */
const setInitialValue = (
  type: AttributeTypeEnum,
  values: Pick<
    AttributeCategoryValueInterface,
    'id' | 'value'
  >[] = [],
  setValue: UseFormSetValue<UpdateAttributeInterface>
): void => {
  switch (type) {
    case AttributeTypeEnum.CERTIFICATE:
      if (!values.length)
        throw new Error(
          'Values are required for certificate type'
        )

      setValue('value_id', values[0].id)
      setValue('human_value', values[0].value)
      break
    case AttributeTypeEnum.RANGE:
      setValue('value', {
        from: '',
        to: '',
      })
  }
}

export const associateAttributeFormModal = create(
  AssociateAttributeForm
)
