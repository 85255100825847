import React, {
  FC,
  ReactNode,
  useEffect,
} from 'react'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import {
  ProtectionInterface,
  SaveProtectionRequestInterface,
} from '@/features/protections/redux'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import { SaveProtectionValidation } from './rules'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { FormControl } from '@mui/base'
import {
  FormHelperText,
  Label,
  TextInput,
} from '@/components'
import { SearchCategory } from '@/features/components/inputs/asyncSelect/searchCategory'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Button } from '@/features/components/buttons/button'

const Form: FC<
  InstanceProps<SaveProtectionRequestInterface> & {
    action: 'update' | 'create'
    data?: ProtectionInterface
    title: string
  }
> = ({
  isOpen,
  onResolve,
  onReject,
  action,
  data,
  title,
}): ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveProtectionValidation(action),
    t
  )
  const { control, handleSubmit, setValue } =
    useForm<SaveProtectionRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('category_id', data.category_id)
      setValue('is_active', data.is_active)
    }
  }, [data])

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={title}
          onClose={() => onReject()}
        />
        <form
          onSubmit={handleSubmit(onResolve)}
          className={'flex flex-col gap-y-4 pt-4'}
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <TextInput
                  label={t('form:labels.name')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'name'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                className={'flex flex-col'}
                error={!!error}
              >
                <Label
                  label={t(
                    'form:labels.category'
                  )}
                />
                <SearchCategory
                  onChange={(value) =>
                    field.onChange(value?.value)
                  }
                  isMulti={false}
                  defaultValue={field.value}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'category_id'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl error={!!error}>
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                  label={t(
                    'form:labels.is_active'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'is_active'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                className={'flex flex-col'}
                error={!!error}
              >
                <Label
                  label={t('form:labels.file')}
                />
                <input
                  type={'file'}
                  accept={'.pdf'}
                  onChange={(e) => {
                    e.target.files
                      ? field.onChange(
                          e.target.files[0]
                        )
                      : field.onChange(null)
                  }}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'file'}
            control={control}
          />
          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const protectionFormModal = create(Form)
