import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Controller,
  useFormContext,
} from 'react-hook-form'
import {
  AdsInterface,
  StoreCampaignValidationInterface,
  UpdateAdsValidationInterface,
} from '@/features/ads/ads/redux'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { useTranslation } from 'react-i18next'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import Card from '@/features/components/cards'
import { useLazyGetAdvertisersQuery } from '@/features/ads/advertisers/redux/advertiserAPI'
import { useAsyncPaginated } from '@/utils/hooks/useAsyncPaginated'
import { AdvertiserInterface } from '@/features/ads/advertisers/redux/types'
import { Select2OptionInterface } from '@/app/types'
import { SingleValue } from 'react-select'
import { Label } from '@/features/components/inputs/label'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Checkbox } from '@/features/components/inputs/checkbox'

type Props = {
  title: string
  data?: AdsInterface
}

const GeneralInfoForm: React.FC<Props> = ({
  title,
  data,
}) => {
  const { t } = useTranslation([
    'form',
    'validation',
  ])
  const { watch, control, setValue } =
    useFormContext<
      | StoreCampaignValidationInterface
      | UpdateAdsValidationInterface
    >()
  const watchBase = watch('base')
  const prefix = useMemo(
    () => (watchBase ? 'base.' : ''),
    [watchBase]
  )
  const [getAdvertisers] =
    useLazyGetAdvertisersQuery()
  const { handleFetch, classes } =
    useAsyncPaginated<AdvertiserInterface>(
      getAdvertisers,
      'company_name',
      'id',
      'company_name'
    )
  const [
    selectedAdvertiser,
    setSelectedAdvertiser,
  ] = useState<Select2OptionInterface>()

  useEffect(() => {
    if (data && data.advertiser) {
      setSelectedAdvertiser({
        value: data.advertiser.id.toString(),
        label: data.advertiser.company_name,
      })
    }
  }, [data])

  const handleChangeAdvertiser = (
    value: SingleValue<Select2OptionInterface>
  ) => {
    if (value) {
      setSelectedAdvertiser(value)
      setValue(
        `${prefix}advertiser_id`,
        +value.value
      )
    }
  }

  return (
    <Card>
      <div className={'flex flex-col gap-y-4'}>
        <span className={'text-lg font-semibold'}>
          {title}
        </span>
      </div>
      <div
        className={
          'grid lg:grid-cols-4 gap-4 pt-8'
        }
      >
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              className={'lg:col-span-4'}
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.name')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`${prefix}name`}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              className={'lg:col-span-2'}
              {...field}
              error={!!error}
            >
              <Input
                type={'url'}
                label={t(
                  'form:labels.desktop_click_url'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`${prefix}desktop_click_url`}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              className={'lg:col-span-2'}
              {...field}
              error={!!error}
            >
              <Input
                type={'url'}
                label={t(
                  'form:labels.mobile_click_url'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`${prefix}mobile_click_url`}
          control={control}
        />
        <Controller
          render={({ fieldState: { error } }) => (
            <FormControl
              error={!!error}
              className={
                'lg:col-span-2 flex flex-col'
              }
            >
              <Label
                label={t(
                  'form:labels.advertiser'
                )}
              />
              <AsyncPaginate
                loadOptions={handleFetch}
                onChange={handleChangeAdvertiser}
                value={selectedAdvertiser}
                debounceTimeout={500}
                classNames={classes}
              />
            </FormControl>
          )}
          name={`${prefix}advertiser_id`}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'date'}
                label={t(
                  'form:labels.started_at'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`${prefix}started_at`}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'date'}
                label={t('form:labels.ended_at')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={`${prefix}ended_at`}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Checkbox
                label={t(
                  'form:labels.is_anonymous_only'
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            </FormControl>
          )}
          name={`${prefix}is_anonymous_only`}
          control={control}
        />
      </div>
    </Card>
  )
}

export { GeneralInfoForm }
