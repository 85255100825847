import { useFormContext } from 'react-hook-form'
import {
  StoreCampaignValidationInterface,
  UpdateAdsValidationInterface,
} from '@/features/ads/ads/redux'
import { useCallback, useMemo } from 'react'
import { SlotFormPrefix } from './useSlotFormHelper.types'

export const useSlotFormHelper = (
  index?: number
) => {
  const { watch, setValue, control, getValues } =
    useFormContext<
      | StoreCampaignValidationInterface
      | UpdateAdsValidationInterface
    >()

  const watchAdvertisements = watch(
    'advertisements'
  )

  const prefix = useMemo(
    () => getPrefix(!!watchAdvertisements, index),
    [index, watchAdvertisements]
  )

  const selectedViewIds = watch(
    `${prefix}view_ids`
  )
  const categories = watch(`${prefix}categories`)

  const handleChangeViewIds = (ids: number[]) =>
    setValue(`${prefix}view_ids`, ids)

  const initiateAssignCategories = useCallback(
    (viewsForCategories: number[]) => {
      const categories = getValues(
        `${prefix}categories`
      )

      const notAssociatedViewIds =
        viewsForCategories.filter(
          (view) =>
            !categories
              .map((category) => category.view_id)
              .includes(view)
        )

      setValue(`${prefix}categories`, [
        ...categories,
        ...notAssociatedViewIds.map((viewId) => ({
          view_id: viewId,
          category_ids: [],
        })),
      ])
    },
    [prefix]
  )

  return {
    prefix,
    control,
    handleChangeViewIds,
    selectedViewIds,
    categories,
    initiateAssignCategories,
  }
}

export const getPrefix = (
  advertisementsExists: boolean,
  index: number | undefined
): SlotFormPrefix =>
  advertisementsExists && index !== undefined
    ? `advertisements.${index}.`
    : ''
