import React, { FC, ReactNode } from 'react'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import { useTranslation } from 'react-i18next'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { Button } from '@/features/components/buttons/button'

const RedirectOrCreateModal: FC<
  InstanceProps<'list' | 'create'>
> = ({ isOpen, onResolve }): ReactNode => {
  const { t } = useTranslation(['form', 'ads'])

  return (
    <Modal
      open={isOpen}
      onClose={() => onResolve('list')}
    >
      <Modal.Content>
        <ModalTitle
          title={t(
            'ads:utils.where_redirect.title'
          )}
          onClose={() => onResolve('list')}
        />
        <p className={'text-center my-8'}>
          {t('ads:utils.where_redirect.message')}
        </p>
        <div className={'flex gap-x-4 pt-4'}>
          <Button
            variant={'contained'}
            type={'button'}
            onClick={() => onResolve('create')}
            className={'w-full'}
          >
            {t('ads:utils.where_redirect.create')}
          </Button>
          <Button
            variant={'outlined'}
            onClick={() => onResolve('list')}
            className={'w-full'}
          >
            {t('ads:utils.where_redirect.list')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const redirectOrCreateModal = create(
  RedirectOrCreateModal
)
