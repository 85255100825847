import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import {
  useDeleteInternalQuotationApplicationMutation,
  useGetInternalQuotationApplicationsQuery,
  useUpdateInternalQuotationApplicationMutation,
} from '@/features/internalQuotationsApplications/redux/internalQuoatationApplicationsApi'
import { QuotationApplicationInterface } from '@/features/internalQuotationsApplications/redux/types'
import Carbon from '@/utils/carbon'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import {
  codeModal,
  internalQuotationApplicationModal,
} from '@/features/internalQuotationsApplications/resources/components/form'
import { faCode } from '@fortawesome/free-solid-svg-icons'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'internal_quotations_applications',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      baseQuery
        .page(pagination.pageIndex)
        .includes('partners,quotations'),
    [pagination, baseQuery]
  )
  const [data, setData] = useState<
    QuotationApplicationInterface[]
  >([])

  const { data: apiData } =
    useGetInternalQuotationApplicationsQuery(
      query.url()
    )

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const [updateInternalQuotationApplication] =
    useUpdateInternalQuotationApplicationMutation()
  const [deleteInternalQuotationApplication] =
    useDeleteInternalQuotationApplicationMutation()

  const onDelete = async (id: number) => {
    try {
      await deleteInternalQuotationApplication(
        id
      ).unwrap()
      toast.success(
        t(
          'internal_quotations_applications:list.deleted'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const onEdit = async (id: number) => {
    const row = data.find(
      (item) => item.id === id
    )

    try {
      const data =
        await internalQuotationApplicationModal({
          title: t(
            'internal_quotations_applications:edit.title'
          ),
          data: row,
        })
      await updateInternalQuotationApplication({
        data: data,
        id,
      }).unwrap()
      toast.success(
        t(
          'internal_quotations_applications:edit.success'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const onShowCode = async (
    row: QuotationApplicationInterface
  ) => {
    try {
      await codeModal({
        row: row,
      })
    } catch (error) {
      //empty block
    }
  }

  const table = useReactTable({
    columns: columns(
      t,
      onDelete,
      onEdit,
      onShowCode
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableHiding: true,
    enableSorting: false,
    manualPagination: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<QuotationApplicationInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onShowCode: (
    row: QuotationApplicationInterface
  ) => void
) => [
  columnBuilder.accessor('domain', {
    id: 'domain',
    header: t('form:labels.domain'),
    enableHiding: true,
  }),
  columnBuilder.accessor('last_request_at', {
    id: 'last_request_at',
    header: t('form:labels.last_request_at'),
    cell: ({ row }) =>
      new Carbon(
        row.original.last_request_at
      ).toDateTimeLocal(),
    enableHiding: true,
  }),
  columnBuilder.accessor('status', {
    id: 'status',
    header: t('form:labels.status'),
    cell: ({ row }) =>
      t(
        `utils:internal_quotations_applications:status.${row.original.status}`
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('token', {
    id: 'token',
    header: t('form:labels.token'),
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATION_API_DESTROY,
            },
            onEdit: {
              action: () =>
                onEdit(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATION_API_SAVE,
            },
          }),
          {
            icon: faCode,
            onClick: () =>
              onShowCode(row.original),
            buttonClassName: 'bg-blue-300',
            tooltip:
              'internal_quotations_applications:tooltips.code',
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
