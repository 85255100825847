import {
  InternalQuotationCategoryInterface,
  SaveInternalQuotationCategoryInterface,
} from '@/features/internalQuotationCategories/redux/types'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SaveInternalQuotationCategoryValidation from '../validations/SaveInternalQuotationCategoryValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useGetInternalQuotationQuery } from '@/features/internalQuotations/redux/internalQuotationAPI'
import { Button } from '@/features/components/buttons/button'
import { Checkbox } from '@/features/components/inputs/checkbox'
import {
  getForInternalQuotationConversion,
  UnitEnum,
} from '@/app/enums/unitEnum'
import { useLazyGetCategoryQuery } from '@/features/posts/categories/redux/categoryAPI'
import { FormInterface } from '@/app/types'

const Form: React.FC<
  FormInterface<
    SaveInternalQuotationCategoryInterface,
    InternalQuotationCategoryInterface
  >
> = ({
  errors: backendErrors,
  onSubmit,
  data,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const [availableUnits, setAvailableUnits] =
    useState<UnitEnum[]>([])
  const {
    schema,
    defaultValues,
    resolveValidationErrors,
  } = useValidation(
    new SaveInternalQuotationCategoryValidation(),
    t
  )
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<SaveInternalQuotationCategoryInterface>(
    {
      resolver: yupResolver(schema),
      defaultValues,
    }
  )
  const { id } = useParams() as { id: string }
  const { data: quotationData } =
    useGetInternalQuotationQuery(id)
  const [getCategory] = useLazyGetCategoryQuery()

  const watchUnit = watch('unit')
  const watchCategoryId = watch('category_id')
  const convertedUnits = useMemo(
    () =>
      getForInternalQuotationConversion(
        watchUnit
      ),
    [watchUnit]
  )

  useEffect(() => {
    if (data) {
      if (data.description) {
        setValue('description', data.description)
      }

      setValue('order', data.order)
      setValue('name', data.name)
      setValue('category_id', data.category_id)

      setValue('price_from', data.price_from)
      setValue('price_to', data.price_to)
      setValue('unit', data.unit.name)
      setValue('is_active', data.is_active)

      if (data.conversion_unit_from) {
        setValue(
          'conversion_unit_from',
          data.conversion_unit_from
        )
      }
    }
  }, [data, getCategory])

  useEffect(() => {
    if (watchCategoryId) {
      getCategory(watchCategoryId)
        .unwrap()
        .then((response) =>
          setAvailableUnits(response.units)
        )
    }
  }, [getCategory, watchCategoryId])

  useEffect(() => {
    if (backendErrors) {
      const err = resolveValidationErrors(
        backendErrors
      )

      if (err) {
        _.forEach(err, (value, key) => {
          setError(
            key as keyof SaveInternalQuotationCategoryInterface,
            {
              type: 'manual',
              message: value,
            }
          )
        })
      }
    }
  }, [backendErrors])

  return (
    <form
      className={'flex flex-col gap-4'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={'grid grid-cols-3 gap-4'}>
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t('form:labels.name')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'name'}
        />
        <Controller
          control={control}
          name={'order'}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                min={0}
                label={t('form:labels:order')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl error={!!error}>
              <Select
                value={field.value}
                onChange={(_, value) =>
                  field.onChange(value)
                }
                label={t('form:labels.category')}
                placeholder={t(
                  'form:placeholders.select'
                )}
              >
                {quotationData?.categories?.map(
                  (category) => (
                    <Option
                      value={category.id}
                      key={category.id}
                    >
                      {category.name}
                    </Option>
                  )
                )}
              </Select>
            </FormControl>
          )}
          name={'category_id'}
          control={control}
        />
        {availableUnits.length > 0 && (
          <Controller
            control={control}
            render={({ field }) => (
              <FormControl
                {...field}
                error={!!errors[field.name]}
              >
                <Select
                  value={field.value}
                  onChange={(_, value) =>
                    field.onChange(value)
                  }
                  label={t('form:labels.unit')}
                >
                  {_.map(
                    availableUnits,
                    (value, index) => (
                      <Option
                        value={value}
                        key={index}
                      >
                        {t(
                          `utils:units.${value}`
                        )}
                      </Option>
                    )
                  )}
                </Select>
                <FormHelperText
                  message={
                    errors[field.name]?.message
                  }
                />
              </FormControl>
            )}
            name={'unit'}
          />
        )}

        {convertedUnits.length > 0 && (
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Select
                  value={field.value}
                  onChange={(_, value) =>
                    field.onChange(value)
                  }
                  label={t(
                    'form:labels.unit_conversion'
                  )}
                  placeholder={t(
                    'form:placeholders.select'
                  )}
                >
                  {_.map(
                    getForInternalQuotationConversion(
                      watchUnit
                    ),
                    (value, index) => (
                      <Option
                        value={value}
                        key={index}
                      >
                        {t(
                          `utils:units.${value}`
                        )}
                      </Option>
                    )
                  )}
                </Select>
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'conversion_unit_from'}
            control={control}
          />
        )}

        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                min={0}
                label={t(
                  'form:labels.price_from'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'price_from'}
        />
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                type={'number'}
                min={0}
                label={t('form:labels.price_to')}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'price_to'}
        />
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Checkbox
                onChange={field.onChange}
                checked={field.value}
                label={t('form:labels.is_active')}
              />
              <FormHelperText />
            </FormControl>
          )}
          name={'is_active'}
        />
        <Controller
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                multiline
                rows={5}
                label={t(
                  'form:labels.description'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'description'}
        />
      </div>
      <div className={'flex'}>
        <Button
          variant={'contained'}
          type={'submit'}
        >
          {t('form:buttons.save')}
        </Button>
      </div>
    </form>
  )
}

export { Form }
