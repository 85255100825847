import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { PermissionEnum } from '@/features/permissions/redux/types'
import {
  Query,
  SortingState,
} from '@/utils/query'
import usePagination from '@/utils/hooks/usePagination'
import { InvoiceInterface } from '@/features/invoices/redux/types'
import {
  useDeleteInvoiceMutation,
  useGetInvoicesQuery,
} from '@/features/invoices/redux/invoiceAPI'
import Carbon from '@/utils/carbon'
import { invoiceEditFormModal } from '@/features/invoices/resources/views/edit'
import { toast } from 'react-toastify'
import { TFunction } from 'i18next'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [{ pageIndex, pageSize }, setPagination] =
    usePagination()
  const [data, setData] = useState<
    InvoiceInterface[]
  >([])
  const [deleteInvoice] =
    useDeleteInvoiceMutation()
  const [sorting, setSorting] =
    useState<SortingState>([])
  const query = useMemo(
    () =>
      baseQuery
        .limit(pageSize)
        .page(pageIndex)
        .sortBySortState(sorting),
    [baseQuery, pageIndex, pageSize, sorting]
  )
  const { data: apiData } = useGetInvoicesQuery(
    query.url()
  )

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = (id: string) => {
    deleteInvoice(id)
      .unwrap()
      .then(() =>
        toast.success(t('invoices:list.deleted'))
      )
  }

  const handleEdit = (id: string) => {
    void invoiceEditFormModal({
      id,
    })
  }

  const table = useReactTable({
    columns: columns(
      t,
      (id) => handleEdit(id),
      (id) => handleDelete(id)
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    manualSorting: true,
    enableSorting: true,
    enableHiding: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      },
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
      setPagination={setPagination}
    />
  )
}

const columnBuilder =
  createColumnHelper<InvoiceInterface>()

const columns = (
  t: TFunction,
  onEdit: (id: string) => void,
  onDelete: (id: string) => void
) => [
  columnBuilder.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('signature', {
    id: 'signature',
    header: t('form:labels.invoice_signature'),
    enableSorting: true,
    enableHiding: true,
  }),
  columnBuilder.accessor('issue_date', {
    id: 'issue_date',
    header: t('form:labels.issue_date'),
    enableSorting: true,
    cell: ({ row }) => (
      <span>
        {new Carbon(
          row.original.issue_date
        ).format('dd.MM.yyyy')}
      </span>
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    enableSorting: true,
    cell: ({ row }) => (
      <span>
        {t(
          `utils:invoice_types.${row.original.type}`
        )}
      </span>
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('gross_value', {
    id: 'gross_value',
    header: t('form:labels.gross_value'),
    enableSorting: true,
    cell: ({ row }) => (
      <span>
        {Intl.NumberFormat('pl', {
          style: 'currency',
          currency: 'PLN',
        }).format(row.original.gross_value)}
      </span>
    ),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.INVOICE_DESTROY,
            },
            onEdit: {
              action: () =>
                onEdit(row.original.id),
              permission:
                PermissionEnum.INVOICE_SAVE,
            },
            onDetails: {
              action: `/invoices/${row.original.id}`,
              permission:
                PermissionEnum.INVOICE_SHOW,
            },
          }),
          {
            icon: faFilePdf,
            onClick: row.original.url,
            buttonClassName: 'bg-red-500',
            tooltip: t(
              'utils:tooltips.download_pdf'
            ),
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
