import igritAPI from '@/utils/api/igrit'
import { GetArticleCommentsInterface } from '@/features/articleComments/redux/types'
import { setPaginatedCache } from '@/utils/api/rtkHelper'

export const {
  useGetArticleCommentsQuery,
  useDeleteArticleCommentMutation,
  useToggleArticleCommentMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getArticleComments: build.query<
      GetArticleCommentsInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/articles/comments?${query}`,
      transformResponse: (
        response: GetArticleCommentsInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache(
          'ArticleComment',
          result
        ),
    }),

    toggleArticleComment: build.mutation<
      void,
      string | number
    >({
      query: (commentId) => ({
        url: `/admin/articles/comments/${commentId}/toggle-visible`,
        method: 'POST',
      }),
      invalidatesTags: ['ArticleComment'],
    }),

    deleteArticleComment: build.mutation<
      void,
      {
        articleId: string | number
        commentId: string | number
      }
    >({
      query: ({ articleId, commentId }) => ({
        url: `/admin/articles/${articleId}/article-comments/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ArticleComment'],
    }),
  }),
})
