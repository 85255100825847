import { useFormControlContext } from '@mui/base'
import React from 'react'
import clsx from 'clsx'

type Props = {
  message?: string | null
  error?: boolean
  containerClassName?: string
  className?: string
}

export const FormHelperText: React.FC<Props> = (
  props
): React.ReactNode => {
  const formControlContext =
    useFormControlContext()

  const spanClassNames = clsx(
    'text-sm text-red-500 first-letter:capitalize',
    props.className
  )

  if (props.error || formControlContext?.error) {
    return (
      <div
        className={
          props.containerClassName
            ? props.containerClassName
            : 'py-1'
        }
      >
        <span className={spanClassNames}>
          {props.message}
        </span>
      </div>
    )
  }

  return <></>
}
