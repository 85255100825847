import React, { ReactNode, useCallback, useMemo } from 'react'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import { match, Pattern } from 'ts-pattern'
import _ from 'lodash'
import { DropzoneInterface, OnChangeValue } from './Dropzone.types'
import { useTranslation } from 'react-i18next'
import { ButtonLink } from '@/features/components/buttons/button'
import { TFunction } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faX } from '@fortawesome/free-solid-svg-icons'

/**
 * @name Dropzone
 * @description Dropzone component for uploading files
 * If preview not displayed correctly, check the file type and handle it accordingly
 */

const Dropzone = <TMultiple extends boolean>({
  onChange,
  accept,
  isMulti,
  files,
  onDeleteImageClick,
  maxFiles,
  maxSize,
  onRejected,
}: DropzoneInterface<TMultiple>): ReactNode => {
  const { t } = useTranslation([
    'utils',
    'validation',
  ])
  const mapAccept = useMemo(
    () =>
      Object.entries(accept)
        .map(([key, values]) => {
          const ext = values
            .map((value) =>
              value.replace('.', '')
            )
            .join(', ')

          return `${ext}, ${key}`
        })
        .join(','),
    [accept]
  )
  const resolvedErrors = useCallback(
    (fileRejections: FileRejection[]) =>
      onRejected?.(
        resolveRejectionErrors(
          fileRejections,
          t,
          maxFiles,
          maxSize,
          mapAccept
        )
      ),
    [onRejected, t, maxFiles, maxSize, mapAccept]
  )
  const dropzone = useDropzone({
    onDropAccepted: (files: File[]) =>
      isMulti
        ? onChange(
            files as OnChangeValue<TMultiple>
          )
        : onChange(
            files[0] as OnChangeValue<TMultiple>
          ),
    accept: accept,
    multiple: isMulti,
    maxFiles: isMulti ? maxFiles : 1,
    onDropRejected: (fileRejections) =>
      resolvedErrors(fileRejections),
    maxSize,
  })

  return (
    <div
      {...dropzone.getRootProps({
        className: 'dropzone overflow-x-auto',
      })}
    >
      <input {...dropzone.getInputProps()} />
      {files && files.length > 0 ? (
        <div className={'flex gap-8'}>
          {files.map((file, index) => (
            <div
              key={index}
              className={
                'relative h-[110px] w-[140px]'
              }
            >
              {match(file.type)
                .with(
                  Pattern.string.regex(
                    /^image\/.+$/
                  ),
                  () => (
                    <img
                      src={URL.createObjectURL(
                        file
                      )}
                      className={
                        'object-cover absolute h-full w-full inset-0 bg-transparent rounded-md'
                      }
                      alt={'image'}
                    />
                  )
                )
                .with('application/pdf', () => (
                  <ButtonLink
                    variant={'contained'}
                    className={
                      'flex h-full items-center justify-center text-danger'
                    }
                    to={URL.createObjectURL(file)}
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      className={'text-white'}
                    />
                  </ButtonLink>
                ))
                .otherwise(() => null)}
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  onDeleteImageClick?.(index)
                }}
                className={
                  'absolute right-1 top-1 !rounded-full hover:bg-red-700 bg-red-600 w-5 h-5 flex justify-center items-center py-0 px-0'
                }
                type={'button'}
              >
                <FontAwesomeIcon
                  icon={faX}
                  className={'text-white'}
                />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p>{t('utils:dropzone.select_files')}</p>
      )}
    </div>
  )
}

const resolveRejectionErrors = (
  fileRejections: FileRejection[],
  t: TFunction,
  maxFiles: number | undefined,
  maxSize: number | undefined,
  allowedExtensions: string
): Record<string, string> => {
  const errors = fileRejections.map(
    (fileRejection) => {
      return fileRejection.errors.map((error) => {
        const message = match(error.code)
          .with(ErrorCode.TooManyFiles, () =>
            t('validation:max_files', {
              max: maxFiles,
            })
          )
          .with(ErrorCode.FileTooLarge, () =>
            t('validation:file_too_large', {
              max: maxSize! / 1024 / 1024,
            })
          )
          .with(ErrorCode.FileInvalidType, () =>
            t('validation:file_extension', {
              allowedExtensions:
                allowedExtensions,
            })
          )
          .with(ErrorCode.FileTooSmall, () =>
            t('validation:file_too_small')
          )
          .otherwise(() =>
            t('validation:unknown_error')
          )

        return { code: error.code, message }
      })
    }
  )

  const resolvedErrors: Record<string, string> =
    {}

  _.uniqBy(errors.flat(), 'code').forEach(
    (error) => {
      Object.assign(resolvedErrors, {
        [error.code]: error.message,
      })
    }
  )

  return resolvedErrors
}

export { Dropzone }
