import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreInternalQuotationMutation } from '@/features/internalQuotations/redux/internalQuotationAPI'
import { SaveInternalQuotationInterface } from '@/features/internalQuotations/redux/types'
import { Form } from '../../components/form'
import Card from '@/features/components/cards'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'
import { isFetchBaseQueryError } from '@/app/hooks'
import { ErrorResponseInterface, ErrorValidationResponseInterface } from '@/app/types'
import { toast } from 'react-toastify'

const CreateInternalQuotationScreen = (): React.ReactNode => {
  const [storeInternalQuotation] = useStoreInternalQuotationMutation()
  const { t } = useTranslation(['internal_quotations'])
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveInternalQuotationInterface>>()

  const onSubmit = async (data: SaveInternalQuotationInterface) => {
    try {
      await storeInternalQuotation(data).unwrap()
      navigate(`/internal-quotations?`)
      toast.success(t('internal_quotations:create.success'))
      return true
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        const errorData = error as ErrorResponseInterface

        if (errorData.status === 422) {
          const errorData =
            error as ErrorValidationResponseInterface<SaveInternalQuotationInterface>
          setErrors(errorData.data)
        }
      }
      return false
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('internal_quotations:create.title')}
      </span>
      <Card>
        <Form backendErrors={errors} onSubmit={onSubmit} />
      </Card>
    </div>
  )
}

export { CreateInternalQuotationScreen }
