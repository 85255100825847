import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  ArticleInterface,
  SaveArticleRequestInterface,
} from '@/features/articles/redux/types'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SaveArticleValidation from './SaveArticleValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import Card from '@/features/components/cards'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Editor } from '@/features/components/inputs/editor'
import { GalleryEnum } from '@/app/enums/galleryEnum'
import { Label } from '@/features/components/inputs/label'
import { SearchCategory } from '@/features/components/inputs/asyncSelect/searchCategory'

import { Button } from '@/features/components/buttons/button'
import _ from 'lodash'
import { useGetArticleCategoriesQuery } from '@/features/articleCategories/redux/articleCategoryAPI'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { findCategories } from './helpers/findCategories'
import {
  mergeCategories,
  MergedCategoriesType,
} from './helpers/mergeCategories'
import { ArticleStatusEnum } from '@/features/articles/redux/enums/ArticleStatusEnum'
import Carbon from '@/utils/carbon'
import { useFilePreview } from '@/utils/hooks/useFilePreview'
import { SearchArticleGallery } from '@/components/Inputs/SearchArticleGallery/SearchArticleGallery'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'
import { compressImage } from '@/utils/helpers/compressor'

type Props = {
  onSubmit: (
    data: SaveArticleRequestInterface
  ) => void
  data?: ArticleInterface
  backendErrors?: BackendValidationErrorInterface<SaveArticleRequestInterface>
}
const ArticleForm: React.FC<Props> = ({
  data,
  onSubmit,
  backendErrors,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
  ])
  const {
    schema,
    defaultValues,
    resolveValidationErrors,
  } = useValidation(
    new SaveArticleValidation(),
    t
  )
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm<SaveArticleRequestInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const { data: categories = [] } =
    useGetArticleCategoriesQuery()
  const [mergedCategories, setMergedCategories] =
    useState<MergedCategoriesType>([])

  const [
    selectedMergedCategory,
    setSelectedMergedCategory,
  ] = useState<string>()

  const ogImage = watch('og_image')
  const ogImageSrc = useFilePreview(ogImage)

  const mainImage = watch('main_image.file')
  const mainImageSrc = useFilePreview(mainImage)
  const watchStatus = watch('status')

  const handleChangeMergedCategory = (
    value: string
  ) => {
    setSelectedMergedCategory(value)
    const newCategory = mergedCategories.find(
      (mergeCategory) =>
        mergeCategory.id === value
    )
    if (newCategory) {
      setValue(
        'article_category_id',
        newCategory.article_category_id
      )

      if (newCategory.subcategory) {
        setValue(
          'subcategory',
          newCategory.subcategory
        )
      } else {
        setValue('subcategory', '')
      }
    }
  }

  const transformSubmit = async (
    formData: SaveArticleRequestInterface
  ) => {
    const transformedData = formData

    try {
      if (transformedData.main_image?.file) {
        transformedData.main_image.file =
          (await compressImage(
            transformedData.main_image.file
          )) as File
      }

      if (transformedData.og_image) {
        transformedData.og_image =
          (await compressImage(
            transformedData.og_image
          )) as File
      }

      if (!data || !data.og_image) {
        delete transformedData.og_image
      }

      onSubmit(transformedData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (data) {
      setValue('status', data.status)
      setValue('promoted', data.promoted)
      setValue('important', data.important)
      setValue(
        'article_of_day',
        data.article_of_day
      )
      setValue('title', data.title)
      setValue(
        'article_category_id',
        data.article_category_id
      )

      if (data.lead) {
        setValue('lead', data.lead)
      }

      if (data.article_gallery_id) {
        setValue(
          'article_gallery_id',
          data.article_gallery_id
        )
      }

      setValue('content', data.content)

      if (data.seo_description) {
        setValue(
          'seo_description',
          data.seo_description
        )
      }

      if (data.main_image) {
        setValue('main_image', data.main_image)
      }

      if (data.seo_keywords) {
        setValue(
          'seo_keywords',
          data.seo_keywords
        )
      }

      if (data.seo_canonical_url) {
        setValue(
          'seo_canonical_url',
          data.seo_canonical_url
        )
      }

      if (data.og_title) {
        setValue('og_title', data.og_title)
      }

      if (data.og_description) {
        setValue(
          'og_description',
          data.og_description
        )
      }

      setValue('seo_keywords', data.seo_keywords)

      if (data.published_at) {
        setValue(
          'published_at',
          new Carbon(
            data.published_at
          ).toInputWithDateTime()
        )
      }

      if (mergedCategories.length > 0) {
        setValue('category_id', data.category_id)

        const foundMergedCategoryWithSubCategories =
          findCategories(
            mergedCategories,
            data.article_category_id,
            data.subcategory,
            true
          )

        if (
          foundMergedCategoryWithSubCategories
        ) {
          setSelectedMergedCategory(
            foundMergedCategoryWithSubCategories.id
          )
          setValue(
            'subcategory',
            foundMergedCategoryWithSubCategories.subcategory
          )
          return
        }

        const foundMergedCategoryWithoutSubCategories =
          findCategories(
            mergedCategories,
            data.article_category_id
          )
        setSelectedMergedCategory(
          foundMergedCategoryWithoutSubCategories?.id
        )
      }
    }
  }, [data, mergedCategories])

  useEffect(() => {
    if (data && categories) {
      setMergedCategories(
        mergeCategories(categories, false)
      )
    }

    setMergedCategories(
      mergeCategories(categories, true)
    )
  }, [categories])

  const imageSrc = useMemo(() => {
    if (
      data?.article_media.length &&
      data.article_media[0].article_media_files
        .length
    ) {
      const mainImg =
        data.article_media[0].article_media_files.find(
          (media) =>
            media.collection_name === 'default'
        )

      return mainImg?.url
    }
  }, [data, mainImage])

  useEffect(() => {
    if (backendErrors) {
      const err = resolveValidationErrors(
        backendErrors
      )

      if (err) {
        _.forEach(err, (value, key) => {
          setError(
            key as keyof SaveArticleRequestInterface,
            {
              type: 'manual',
              message: value,
            }
          )
        })
      }
    }
  }, [backendErrors])

  return (
    <form
      onSubmit={handleSubmit(transformSubmit)}
      className={'flex flex-col'}
    >
      <div className={'pb-4 lg:hidden'}>
        <Button
          variant={'contained'}
          className={'flex items-center gap-x-2'}
          type={'submit'}
        >
          <span>{t('form:buttons.save')}</span>
        </Button>
      </div>
      <div
        className={'grid lg:grid-cols-6 gap-4'}
      >
        <div className={'lg:col-span-4'}>
          <Card>
            <div className={'grid gap-4'}>
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                  >
                    <Input
                      label={t(
                        'form:labels.title'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'title'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                  >
                    <Input
                      multiline
                      rows={5}
                      label={t(
                        'form:labels.lead'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'lead'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    className={
                      'flex flex-col overflow-hidden'
                    }
                    error={!!error}
                  >
                    <Label
                      label={t(
                        'form:labels.content'
                      )}
                    />
                    <Editor
                      onChange={field.onChange}
                      data={field.value}
                      galleryType={
                        GalleryEnum.ARTICLE
                      }
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'content'}
                control={control}
              />
            </div>
          </Card>
          <div className={'pt-2 hidden lg:block'}>
            <Button
              variant={'contained'}
              className={
                'flex items-center gap-x-2'
              }
              type={'submit'}
            >
              <span>
                {t('form:buttons.save')}
              </span>
            </Button>
          </div>
        </div>
        <div
          className={
            'flex flex-col lg:col-span-2 gap-4'
          }
        >
          <Card>
            <span
              className={'font-semibold text-lg'}
            >
              {t(
                'form:labels.additional_information'
              )}
            </span>
            <div
              className={
                'flex flex-col gap-4 pt-8'
              }
            >
              <Controller
                render={({ field }) => (
                  <Checkbox
                    label={t(
                      'form:labels.promoted'
                    )}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
                name={'promoted'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Checkbox
                    label={t(
                      'form:labels.important'
                    )}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
                name={'important'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Checkbox
                    label={t(
                      'form:labels.article_of_day'
                    )}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
                name={'article_of_day'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl error={!!error}>
                    <Select
                      value={field.value}
                      onChange={(_, value) =>
                        field.onChange(value)
                      }
                      label={t(
                        'form:labels.status'
                      )}
                      name={field.name}
                    >
                      {_.map(
                        ArticleStatusEnum,
                        (value, index) => (
                          <Option
                            value={value}
                            key={index}
                          >
                            {t(
                              `utils:article_status.${value}`
                            )}
                          </Option>
                        )
                      )}
                    </Select>
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'status'}
                control={control}
              />
              {watchStatus ===
                ArticleStatusEnum.PUBLISHED && (
                <Controller
                  render={({ field }) => (
                    <FormControl
                      {...field}
                      error={!!errors[field.name]}
                    >
                      <Input
                        type={'datetime-local'}
                        label={t(
                          'form:labels.published_at'
                        )}
                      />
                      <FormHelperText
                        message={
                          errors[field.name]
                            ?.message
                        }
                      />
                    </FormControl>
                  )}
                  name={'published_at'}
                  control={control}
                />
              )}
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <div
                    className={
                      'flex flex-col gap-y-8'
                    }
                  >
                    <Label
                      label={t(
                        'form:labels.main_image'
                      )}
                    />
                    <input
                      type={'file'}
                      accept='image/png, image/jpeg, image/jpg image/webp'
                      name={field.name}
                      onChange={(event) => {
                        event.target.files
                          ? field.onChange(
                              event.target
                                .files[0]
                            )
                          : field.onChange(null)
                      }}
                    />
                    {(mainImageSrc ||
                      imageSrc) && (
                      <img
                        src={
                          mainImageSrc
                            ? mainImageSrc
                            : imageSrc
                        }
                        alt='preview'
                        className={
                          'max-h-[200px] object-contain pt-2'
                        }
                      />
                    )}
                    <FormHelperText
                      message={error?.message}
                    />
                  </div>
                )}
                name={'main_image.file'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                    disabled={!mainImage}
                  >
                    <Input
                      label={t(
                        'form:labels.title'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'main_image.title'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                    disabled={!mainImage}
                  >
                    <Input
                      label={t(
                        'form:labels.description'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'main_image.description'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Checkbox
                    label={t(
                      'form:labels.watermark'
                    )}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
                name={'main_image.watermark'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl error={!!error}>
                    <Label
                      label={t(
                        'form:labels.category'
                      )}
                    />
                    <SearchCategory
                      onChange={(option) =>
                        field.onChange(
                          option?.value
                        )
                      }
                      isMulti={false}
                      defaultValue={field.value}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'category_id'}
                control={control}
              />

              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl error={!!error}>
                    <Label
                      label={t(
                        'form:labels.article_gallery'
                      )}
                    />
                    <SearchArticleGallery
                      onChange={(option) =>
                        field.onChange(
                          option?.value
                        )
                      }
                      isMulti={false}
                      defaultValue={field.value}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'article_gallery_id'}
                control={control}
              />

              <Select
                onChange={(_, value) =>
                  handleChangeMergedCategory(
                    value as string
                  )
                }
                value={selectedMergedCategory}
                label={t(
                  'form:labels.articles_category'
                )}
              >
                {mergedCategories.map(
                  (mergeCategory) => (
                    <Option
                      key={mergeCategory.id}
                      value={mergeCategory.id}
                    >
                      {mergeCategory.name}
                    </Option>
                  )
                )}
              </Select>
            </div>
          </Card>
          <Card>
            <span
              className={'font-semibold text-lg'}
            >
              {t('form:labels.seo')}
            </span>
            <div
              className={
                'flex flex-col gap-4 pt-8'
              }
            >
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                  >
                    <Input
                      label={t(
                        'form:labels.seo_keywords'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'seo_keywords'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                  >
                    <Input
                      label={t(
                        'form:labels.seo_canonical_url'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'seo_canonical_url'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                  >
                    <Input
                      label={t(
                        'form:labels.seo_description'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'seo_description'}
                control={control}
              />
            </div>
          </Card>
          <Card>
            <span
              className={'font-semibold text-lg'}
            >
              {t('form:labels.og')}
            </span>
            <div
              className={
                'flex flex-col gap-4 pt-8'
              }
            >
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                  >
                    <Input
                      label={t(
                        'form:labels.og_title'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'og_title'}
                control={control}
              />
              <Controller
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <FormControl
                    {...field}
                    error={!!error}
                  >
                    <Input
                      label={t(
                        'form:labels.og_description'
                      )}
                    />
                    <FormHelperText
                      message={error?.message}
                    />
                  </FormControl>
                )}
                name={'og_description'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <div
                    className={
                      'flex flex-col gap-y-8'
                    }
                  >
                    <Label
                      label={t(
                        'form:labels.og_image'
                      )}
                    />
                    <input
                      type={'file'}
                      accept='image/png, image/jpeg, image/jpg image/webp'
                      name={field.name}
                      onChange={(event) => {
                        event.target.files
                          ? field.onChange(
                              event.target
                                .files[0]
                            )
                          : field.onChange(null)
                      }}
                    />
                    {ogImageSrc ||
                    data?.og_image ? (
                      <img
                        src={
                          ogImageSrc ||
                          (data?.og_image as string)
                        }
                        alt='preview'
                        className={
                          'max-h-[200px] object-contain pt-2 '
                        }
                      />
                    ) : null}
                  </div>
                )}
                name={'og_image'}
                control={control}
              />
            </div>
          </Card>
          <div className={'pt-4 lg:hidden mb-24'}>
            <Button
              variant={'contained'}
              className={
                'flex items-center gap-x-2'
              }
              type={'submit'}
            >
              <span>
                {t('form:buttons.save')}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ArticleForm
