import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteBlackListRowMutation,
  useGetBlackListQuery,
  useUpdateBlackListRowMutation,
} from '@/features/blacklist/redux/blacklistAPI'
import { BlackListRowInterface } from '@/features/blacklist/redux/types'
import { blackListFormModal } from '@/features/blacklist/resources/components/form'
import Carbon from '@/utils/carbon'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'blacklist',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination.pageIndex]
  )
  const { data: apiData } = useGetBlackListQuery(
    query.url()
  )
  const [data, setData] = useState<
    BlackListRowInterface[]
  >([])
  const [deleteBlackListRow] =
    useDeleteBlackListRowMutation()
  const [updateBlackListRow] =
    useUpdateBlackListRowMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteBlackListRow(id)
      .unwrap()
      .then(() =>
        toast.success(t('blacklist:list.deleted'))
      )
  }

  const onEdit = (id: number) => {
    const blackListRow = data.find(
      (item) => item.id === id
    )

    blackListFormModal({
      title: t('blacklist:edit.title'),
      data: blackListRow,
    }).then((data) =>
      updateBlackListRow({ data, id })
        .unwrap()
        .then(() =>
          toast.success(
            t('blacklist:edit.success')
          )
        )
    )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<BlackListRowInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('value', {
    id: 'value',
    header: t('form:labels.value'),
    enableHiding: true,
  }),
  columnBuilder.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    cell: ({ row }) =>
      t(
        'utils:blacklist_types.' +
          row.original.type
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('reason', {
    id: 'reason',
    header: t('form:labels.reason'),
    enableHiding: true,
  }),
  columnBuilder.accessor('expires_at', {
    id: 'expires_at',
    header: t('form:labels.expires_at'),
    cell: ({ row }) =>
      row.original.expires_at
        ? new Carbon(
            row.original.expires_at
          ).toLocaleString()
        : '-',
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.BLACKLIST_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.BLACKLIST_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
