import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteInternalQuotationMutation,
  useGetInternalQuotationsQuery,
} from '@/features/internalQuotations/redux/internalQuotationAPI'
import { InternalQuotationInterface } from '@/features/internalQuotations/redux/types'
import Carbon from '@/utils/carbon'
import { Query } from '@/utils/query'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { Form } from './form'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'internal_quotations',
    'utils',
    'validation',
  ])

  const query = useMemo(
    () => baseQuery.url(),
    [baseQuery]
  )

  const { data = [] } =
    useGetInternalQuotationsQuery(query)
  const [deleteInternalQuotation] =
    useDeleteInternalQuotationMutation()

  const onDelete = (id: number) => {
    deleteInternalQuotation(id)
      .unwrap()
      .then(() =>
        toast.success(
          t('internal_quotations:list.deleted')
        )
      )
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    state: {
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<InternalQuotationInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('order', {
    id: 'order',
    header: t('form:labels.order'),
    enableHiding: true,
  }),
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    cell: ({ row }) => (
      <Form
        t={t}
        body={row.original}
        fieldName={'name'}
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'execution_interval_days',
    header: t(
      'form:labels.execution_interval_days'
    ),
    cell: ({ row }) => (
      <Form
        t={t}
        body={row.original}
        fieldName={'execution_interval_days'}
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'description',
    header: t('form:labels.description'),
    cell: ({ row }) => (
      <Form
        t={t}
        body={row.original}
        fieldName={'description'}
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('is_active', {
    id: 'is_active',
    header: t('form:labels.is_active'),
    cell: ({ row }) =>
      row.original.is_active
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('created_at', {
    id: 'created_at',
    header: t('form:labels.created_at'),
    cell: ({ row }) =>
      new Carbon(
        row.original.created_at
      ).toDateTimeLocal(),
    enableHiding: true,
  }),
  columnBuilder.accessor('updated_at', {
    id: 'updated_at',
    header: t('form:labels.updated_at'),
    cell: ({ row }) =>
      new Carbon(
        row.original.updated_at
      ).toDateTimeLocal(),
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATION_DESTROY,
            },
            onEdit: {
              action: `/internal-quotations/${row.original.id}/edit`,
              permission:
                PermissionEnum.INTERNAL_QUOTATION_SAVE,
            },
            onDetails: {
              action: `/internal-quotations/${row.original.id}/categories`,
              permission:
                PermissionEnum.INTERNAL_QUOTATION_SHOW,
              tooltip:
                'internal_quotations:tooltips.categories',
            },
          }),
        ]}
      />
    ),
    enableHiding: true,
  }),
]
