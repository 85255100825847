import { AnyObject, Maybe } from 'yup'
import {
  BackendValidationErrorInterface,
  ValidationErrors,
  ValidationInterface,
} from './types'
import { TFunction } from 'i18next'
import { useCallback, useMemo } from 'react'

const useValidation = <
  T extends Maybe<AnyObject>
>(
  validateClass: ValidationInterface<T>,
  t: TFunction
) => {
  const validation = useMemo(() => {
    return {
      schema: validateClass.rules(t),
      defaultValues:
        validateClass.defaultValues(),
    }
  }, [t, validateClass])

  const resolveValidationErrors = useCallback(
    (
      errors: BackendValidationErrorInterface<T>
    ): ValidationErrors<T> => {
      const validationErrors: ValidationErrors<T> =
        {} as ValidationErrors<T>

      for (const [key, value] of Object.entries(
        errors.errors
      )) {
        validationErrors[key as keyof T] = (
          value as string[]
        )[0]
      }

      return validationErrors
    },
    []
  )

  return {
    schema: validation.schema,
    defaultValues: validation.defaultValues,
    resolveValidationErrors,
  }
}

export default useValidation
