import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { CategoryInterface } from '@/features/posts/categories/redux/types'
import usePagination from '@/utils/hooks/usePagination'
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from '@/features/posts/categories/redux/categoryAPI'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import { isCustomError } from '@/utils/api/rtkHelper'
import { ResponseCodeEnum } from '@/app/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'posts/categories',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      baseQuery
        .includes('parentCategory')
        .page(pagination.pageIndex),
    [pagination, baseQuery]
  )
  const { data: apiData } = useGetCategoriesQuery(
    query.url()
  )
  const [data, setData] = useState<
    CategoryInterface[]
  >([])
  const [deleteCategory] =
    useDeleteCategoryMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteCategory(id)
      .unwrap()
      .then(() =>
        toast.success(
          t('posts/categories:list.deleted')
        )
      )
      .catch((error) => {
        if (
          isCustomError(
            error,
            ResponseCodeEnum.CATEGORY_CAN_NOT_BE_DELETED
          )
        ) {
          toast.error(
            t(
              'posts/categories:list.can_not_be_deleted'
            )
          )
        }
      })
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    enableHiding: true,
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<CategoryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'type',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('seo_keywords', {
    id: 'seo_keywords',
    header: t('form:labels.seo_keywords'),
    enableHiding: true,
  }),
  columnBuilder.accessor('seo_description', {
    id: 'seo_description',
    header: t('form:labels.seo_description'),
    enableHiding: true,
  }),
  columnBuilder.accessor('parent_category.name', {
    id: 'parent_category',
    header: t('form:labels.parent_category'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/posts/categories/${row.original.id}/edit`,
            permission:
              PermissionEnum.CATEGORY_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.CATEGORY_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
