import { PermissionEnum } from '@/features/permissions/redux/types'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePermissions } from '@/utils/hooks/usePermission'
import {
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons'
import { ExternalQuotationInterface } from '@/features/externalQuotations/redux'
import { Tooltip } from '@/components'
import { useTranslation } from 'react-i18next'
import { useId } from 'react'

type Props = {
  onClick: (
    row: ExternalQuotationInterface
  ) => Promise<void>
  row: ExternalQuotationInterface
  isActive: boolean
  permission: PermissionEnum
  tooltip: string
}
const SwitcherIcon: React.FC<Props> = ({
  onClick,
  isActive,
  row,
  permission,
  tooltip,
}): React.ReactNode => {
  const { hasPermission } = usePermissions()
  const { t } = useTranslation()
  if (
    permission === undefined ||
    hasPermission(permission)
  )
    return (
      <Tooltip
        as={'button'}
        onClick={() => onClick(row)}
        content={t(tooltip)}
        place={'bottom'}
        id={useId()}
      >
        <div
          className={clsx(
            'w-6 h-6 rounded flex items-center justify-center',
            {
              'bg-green-500': !isActive,
              'bg-red-500': isActive,
            }
          )}
        >
          <FontAwesomeIcon
            icon={
              isActive ? faToggleOff : faToggleOn
            }
            className={'text-white'}
          />
        </div>
      </Tooltip>
    )
}

export { SwitcherIcon }
