import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import {
  AgreementGroupEnum,
  StoreAgreementRequestInterface,
} from '@/features/agreements'
import { TFunction } from 'i18next'
import {
  AnyObject,
  array,
  boolean,
  mixed,
  object,
  ObjectSchema,
  string,
} from 'yup'

class StoreAgreementValidation
  implements
    ValidationInterface<StoreAgreementRequestInterface>
{
  defaultValues(): StoreAgreementRequestInterface {
    return {
      name: '',
      description: '',
      text_link: '',
      locale: 'pl',
      required: false,
      visible: false,
      file_remove: false,
      groups: [],
    }
  }

  rules(
    t: TFunction
  ): ObjectSchema<
    StoreAgreementRequestInterface,
    AnyObject
  > {
    return object().shape({
      name: string().required(
        t('validation:required')
      ),
      description: string().nullable(),
      text_link: string()
        .nullable()
        .when('file', {
          is: (file: File | undefined) => !!file,
          then: (schema) =>
            schema.required(
              t('validation:required')
            ),
        }),
      required: boolean().required(),
      visible: boolean().required(),
      groups: array()
        .of(
          mixed<AgreementGroupEnum>().required()
        )
        .required(),
      locale: string().required(),
      file: mixed<File>().nullable(),
      file_remove: boolean().required(),
    })
  }
}

export { StoreAgreementValidation }
