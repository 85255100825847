import { useRef } from 'react'
import { Query } from '@/utils/query'
import {
  PaginatedResponseInterface,
  Select2OptionInterface,
} from '@/app/types'

const useAsyncPaginated = <T extends object>(
  fetchResult: Function,
  fieldForSearch: string,
  valuePropertyName: keyof T & string,
  labelPropertyName: keyof T,
  query?: Query
) => {
  const handleFetch = async (
    value: string,
    prevOptions: unknown,
    additional?: { page: number | undefined }
  ) => {
    let page = 1

    try {
      if (additional && additional.page) {
        page = additional.page
      }

      let baseQuery = new Query()

      if (query) {
        baseQuery = query
      }

      const response: PaginatedResponseInterface<T> =
        await fetchResult(
          baseQuery
            .where(fieldForSearch, value)
            .url()
        ).unwrap()

      return {
        options: response.data.map((item) => ({
          label: item[labelPropertyName],
          value: item[valuePropertyName],
        })) as Select2OptionInterface[],
        hasMore:
          response.pagination.total_pages > page,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      }
    }
  }

  const classes = useRef({
    control: () =>
      '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent !min-h-[42px]',
    menuList: () => '!max-h-[200px]',
  }).current

  return {
    handleFetch,
    classes,
  }
}

export { useAsyncPaginated }
