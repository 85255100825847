import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import {
  ProtectionInterface,
  SaveProtectionRequestInterface,
  useDeleteProtectionMutation,
  useGetProtectionsQuery,
  useUpdateProtectionMutation,
} from '@/features/protections/redux'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { protectionFormModal } from './Form'
import { serialize } from 'object-to-formdata'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      new Query()
        .includes('category')
        .page(pagination.pageIndex),
    [pagination]
  )
  const { data: apiData } =
    useGetProtectionsQuery(query.url())
  const [data, setData] = useState<
    ProtectionInterface[]
  >([])
  const [deleteProtection] =
    useDeleteProtectionMutation()
  const [updateProtection] =
    useUpdateProtectionMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteProtection(id)
      .unwrap()
      .then(() =>
        toast.success(
          t('protections:list.deleted')
        )
      )
  }

  const handleEdit = async (id: number) => {
    try {
      const response: SaveProtectionRequestInterface =
        await protectionFormModal({
          title: t('protections:edit.title'),
          action: 'update',
          data: data.find(
            (item) => item.id === id
          ),
        })

      const serializedData = serialize(response, {
        indices: true,
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
      })

      serializedData.append('_method', 'PUT')

      await updateProtection({
        data: serializedData,
        id,
      }).unwrap()
      toast.success(t('protections:edit.success'))
    } catch (error) {
      // empty block
    }
  }

  const table = useReactTable({
    columns: columns(t, onDelete, handleEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<ProtectionInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('category.name', {
    id: 'category',
    header: t('form:labels.category'),
    enableHiding: true,
  }),
  columnBuilder.accessor('is_active', {
    id: 'is_active',
    header: t('form:labels.is_active'),
    cell: ({ row }) =>
      row.original.is_active
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onEdit: {
              action: () =>
                onEdit(row.original.id),
              permission:
                PermissionEnum.PROTECTION_SAVE,
            },
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.PROTECTION_DESTROY,
            },
          }),
          {
            icon: faDownload,
            permission:
              PermissionEnum.PROTECTION_SHOW,
            onClick: () =>
              window.open(row.original.file_url),
            buttonClassName: 'bg-primary-600',
            tooltip:
              'protections:tooltips.download',
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
