import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveAdsViewRequestInterface } from '@/features/ads/views/redux/types'
import { TFunction } from 'i18next'
import * as yup from 'yup'

class SaveAdsViewValidation
  implements
    ValidationInterface<SaveAdsViewRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveAdsViewRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required'))
        .transform((value) => value.trim()),
      route: yup
        .string()
        .required(t('validation:required'))
        .transform((value) => value.trim()),
      is_category_required: yup
        .boolean()
        .required(t('validation:required')),
    })
  }

  defaultValues(): SaveAdsViewRequestInterface {
    return {
      name: '',
      route: '',
      is_category_required: false,
    }
  }
}

export { SaveAdsViewValidation }
