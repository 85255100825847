import igritAPI from '@/utils/api/igrit'
import {
  ArticleGalleryInterface,
  GetArticleGalleriesResponseInterface,
  GetArticleGalleryResponseInterface,
  SaveArticleGalleryInterface,
} from '@/features/articleGalleries/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useDeleteArticleGalleryMutation,
  useGetArticleGalleriesQuery,
  useGetArticleGalleryQuery,
  useStoreArticleGalleryMutation,
  useUpdateArticleGalleryMutation,
  useLazyGetArticleGalleriesQuery,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getArticleGalleries: build.query<
      GetArticleGalleriesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/article-gallery?${query}`,
      transformResponse: (
        response: GetArticleGalleriesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache(
          'ArticleGallery',
          result
        ),
    }),
    getArticleGallery: build.query<
      ArticleGalleryInterface,
      string | number
    >({
      query: (id) =>
        `admin/article-gallery/${id}`,
      transformResponse: (
        response: GetArticleGalleryResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'ArticleGallery',
          result
        ),
    }),
    storeArticleGallery: build.mutation<
      void,
      SaveArticleGalleryInterface
    >({
      query: (data) => ({
        url: '/admin/article-gallery',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ArticleGallery'],
    }),
    updateArticleGallery: build.mutation<
      void,
      {
        id: number | string
        data: SaveArticleGalleryInterface
      }
    >({
      query: ({ data, id }) => ({
        url: `/admin/article-gallery/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ArticleGallery'],
    }),
    deleteArticleGallery: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `admin/article-gallery/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ArticleGallery'],
    }),
  }),
})
