import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteServiceConfigMutation,
  useGetServiceConfigListQuery,
  useUpdateServiceConfigMutation,
} from '@/features/services/redux/serviceAPI'
import { ServiceConfigInterface } from '@/features/services/redux/types'
import { ServiceNameEnum } from '@/features/posts/posts/redux/enums/service'
import { serviceConfigForm } from '@/features/services/resources/views/edit/config/_components/form'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
} from '@/components'

type Props = {
  serviceId: number | string
  serviceName: ServiceNameEnum
}

export const Table: React.FC<Props> = ({
  serviceId,
  serviceName,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination]
  )
  const { data: apiData } =
    useGetServiceConfigListQuery({
      serviceId,
      query: query.url(),
    })
  const [data, setData] = useState<
    ServiceConfigInterface[]
  >([])
  const [deleteServiceConfig] =
    useDeleteServiceConfigMutation()
  const [updateServiceConfig] =
    useUpdateServiceConfigMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteServiceConfig({ serviceId, id })
      .unwrap()
      .then(() =>
        toast.success(
          t('services:config.list.deleted')
        )
      )
  }

  const onEdit = (id: number) => {
    const serviceConfig = data.find(
      (serviceConfig) => serviceConfig.id === id
    )

    if (!serviceConfig || !serviceConfig) return

    serviceConfigForm({
      data: serviceConfig,
      title: t('services:config.edit.title'),
      serviceName,
    }).then((data) => {
      updateServiceConfig({
        serviceId,
        id,
        body: data,
      })
        .unwrap()
        .then(() =>
          toast.success(
            t('services:config.edit.success')
          )
        )
    })
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<ServiceConfigInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('key', {
    id: 'key',
    header: t('form:labels.key'),
    cell: ({ row }) =>
      t(
        `utils:service_config_keys.${row.original.key}`
      ),
  }),
  columnBuilder.accessor('value', {
    id: 'value',
    header: t('form:labels.value'),
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.SERVICE_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.SERVICE_DESTROY,
          },
        })}
      />
    ),
  }),
]
