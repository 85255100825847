import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import { Query } from '@/utils/query'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import {
  useDeleteInternalQuotationResultDetailMutation,
  useGetInternalQuotationResultsDetailsQuery,
} from '@/features/internalQuotationsResultsDetails/redux/internalQuotationsResultsDetailsApi'
import { QuotationResultDetailsInterface } from '@/features/internalQuotationsResultsDetails/redux/types'
import { useParams } from 'react-router-dom'
import { Form } from '@/features/internalQuotationsResultsDetails/resources/components/form'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'internal_quotations_results_details',
    'utils',
  ])
  const query = useMemo(
    () =>
      baseQuery.includes(
        'internalQuotationCategory'
      ),
    [baseQuery]
  )
  const { id } = useParams() as {
    id: string | number
  }
  const { data = [] } =
    useGetInternalQuotationResultsDetailsQuery({
      id: id,
      query: query.url(),
    })

  const [deleteInternalQuotationResultDetail] =
    useDeleteInternalQuotationResultDetailMutation()

  const onDelete = async (resultId: number) => {
    try {
      await deleteInternalQuotationResultDetail({
        quotationId: id,
        resultId: resultId,
      }).unwrap()
      toast.success(
        t(
          'internal_quotations_results_details:list.deleted'
        )
      )
    } catch (error) {
      // empty block
    }
  }

  const table = useReactTable({
    columns: columns(t, onDelete, id),
    getCoreRowModel: getCoreRowModel(),
    data,
    state: {
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<QuotationResultDetailsInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  id: string | number
) => [
  columnBuilder.accessor(
    'internal_quotation_category.name',
    {
      id: 'internal_quotation_category_category.name',
      header: t('form:labels.category'),
      enableHiding: true,
    }
  ),

  columnBuilder.display({
    id: 'final_price',
    header: t('form:labels.price'),
    cell: ({ row }) => (
      <Form
        body={row.original}
        t={t}
        fieldName={'price'}
        permission={
          PermissionEnum.INTERNAL_QUOTATION_SAVE
        }
        quotationId={id}
      />
    ),
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.INTERNAL_QUOTATION_DESTROY,
            },
          }),
        ]}
      />
    ),
    enableHiding: true,
  }),
]
