import React, { FC } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import {
  MultiValue,
  SingleValue,
} from 'react-select'
import { Select2OptionInterface } from '@/app/types'
import { useTranslation } from 'react-i18next'
import { useLazyGetRolesQuery } from '@/features/roles/redux/roleAPI'
import { SearchRolesTypes } from '@/components/Inputs/SearchRoles/SearchRoles.types'
import { useAsyncSelect } from '@/utils/hooks/useAsyncSelectV2'
import { RoleInterface } from '@/features/roles/redux/types'

const SearchRole: FC<SearchRolesTypes> = ({
  onChange,
  isMulti,
  defaultValue,
}): React.ReactNode => {
  const { t } = useTranslation(['form'])
  const [getRoles] = useLazyGetRolesQuery()
  const {
    handleFetch,
    selectedValue,
    setSelectedValue,
    selectProperties,
  } = useAsyncSelect<RoleInterface, 'paginated'>(
    getRoles,
    'paginated',
    'name',
    'id',
    'name',
    defaultValue
  )

  const handleChange = (
    value:
      | MultiValue<Select2OptionInterface>
      | SingleValue<Select2OptionInterface>
  ) => {
    if (isMulti) {
      onChange(
        value as MultiValue<Select2OptionInterface>
      )
      setSelectedValue(
        value as Select2OptionInterface[]
      )
    } else {
      onChange(
        value as SingleValue<Select2OptionInterface>
      )
      setSelectedValue(
        value as Select2OptionInterface
      )
    }
  }

  return (
    <AsyncPaginate
      loadOptions={handleFetch}
      onChange={handleChange}
      value={selectedValue}
      placeholder={t(
        'form:placeholders.search_role'
      )}
      isMulti={isMulti}
      debounceTimeout={500}
      classNames={selectProperties}
    />
  )
}

export { SearchRole }
