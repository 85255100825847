import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { PostSearchInterface } from '@/features/posts/posts/redux/types'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import Carbon from '@/utils/carbon'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { TFunction } from 'i18next'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import {
  useDeletePostMutation,
  useGetPostsQuery,
  useRestorePostMutation,
} from '@/features/posts/posts/redux/postAPI'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { toast } from 'react-toastify'
import { Form, TypeBadge } from './partials'
import {
  faFilePdf,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons'

type Props = {
  query: Query
  onRowSelectionChange?: (
    selectedIds: number[]
  ) => void
  showSelection?: boolean
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  onRowSelectionChange,
  showSelection = true,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
    'posts/posts',
  ])
  const [data, setData] = useState<
    PostSearchInterface[]
  >([])
  const [{ pageIndex, pageSize }, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      baseQuery
        .limit(pageSize)
        .includes(
          'category,unit,user,phoneNumber,attributes'
        )
        .page(pageIndex),
    [pageIndex, pageSize, baseQuery]
  )
  const { data: apiData } = useGetPostsQuery(
    query.url()
  )
  const [deletePost] = useDeletePostMutation()
  const [restorePost] = useRestorePostMutation()
  const [rowSelection, setRowSelection] =
    useState<Record<number, boolean>>({})

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  useEffect(() => {
    if (data.length === 0) {
      if (onRowSelectionChange)
        onRowSelectionChange([])
      setRowSelection({})
      return
    }

    const selectedIds = Object.keys(
      rowSelection
    ).map((key) => data[Number(key)].id)

    if (onRowSelectionChange)
      onRowSelectionChange(selectedIds)
  }, [data, onRowSelectionChange, rowSelection])

  const onDelete = async (id: number) => {
    try {
      await deletePost(id).unwrap()
      toast.success(t('posts/posts:list.deleted'))
    } catch (error) {
      // empty block
    }
  }

  const onRestore = async (id: number) => {
    try {
      await restorePost(id).unwrap()
      toast.success(
        t('posts/posts:list.restored')
      )
    } catch (error) {
      //empty block
    }
  }

  const table = useReactTable({
    columns: columns(
      t,
      onDelete,
      showSelection,
      onRestore
    ),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: (value) => {
      setPagination(value)
      setRowSelection({})
    },
    manualSorting: true,
    enableHiding: true,
    manualPagination: true,
    state: {
      rowSelection,
      pagination: {
        pageIndex,
        pageSize,
      },
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <div className={'flex flex-col gap-y-2'}>
      <BaseTable
        table={table}
        pagination={apiData?.pagination}
        setPagination={setPagination}
      />
    </div>
  )
}

const columnBuilder =
  createColumnHelper<PostSearchInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  showSelection: boolean,
  onRestore: (id: number) => void
) => [
  columnBuilder.display({
    id: 'select',
    header: () => {
      if (showSelection) return <span>#</span>
    },
    enableSorting: false,
    meta: {
      cellClassName: 'text-center',
      columnClassName: 'text-center',
    },
    maxSize: 50,
    cell: ({ row }) => {
      if (showSelection)
        return (
          <input
            type={'checkbox'}
            className={
              'w-[16px] h-[16px] accent-primary'
            }
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            disabled={!row.getCanSelect()}
          />
        )
    },
  }),
  columnBuilder.display({
    id: 'id_date',
    header: () => {
      return (
        <div className={'flex flex-col'}>
          <span>#ID</span>
          <span>
            {t('form:labels.created_at')}
          </span>
        </div>
      )
    },
    cell: ({ row }) => (
      <div className={'flex flex-col'}>
        <span>#{row.original.id}</span>
        {row.original.published_at && (
          <span>
            {new Carbon()
              .parse(row.original.published_at)
              .format('dd.MM.yyyy HH:mm')}
          </span>
        )}
      </div>
    ),
  }),
  columnBuilder.accessor('status', {
    id: 'status',
    header: t('form:labels.status'),
    cell: ({ row }) =>
      t(
        `posts/posts:statuses.${row.original.status}`
      ),
  }),
  columnBuilder.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    cell: ({ row }) => (
      <TypeBadge type={row.original.type} />
    ),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'category',
    header: t('form:labels.category'),
    cell: ({ row }) => (
      <Form
        body={row.original}
        t={t}
        fieldName={'category_id'}
        postId={row.original.id}
        permission={PermissionEnum.POST_SAVE}
        noPermissionValue={
          row.original.category.name
        }
      />
    ),
  }),
  columnBuilder.display({
    id: 'title',
    header: t('form:labels.title'),
    enableHiding: true,
    cell: ({ row }) => (
      <Form
        body={row.original}
        t={t}
        fieldName={'title'}
        postId={row.original.id}
        permission={PermissionEnum.POST_SAVE}
        noPermissionValue={row.original.title}
      />
    ),
  }),
  columnBuilder.accessor('content', {
    id: 'content',
    header: t('form:labels.content'),
    cell: ({ row }) => (
      <span className={'lg:truncate'}>
        {row.original.content.substring(0, 50)}
        {row.original.content.length > 50 &&
          '...'}
      </span>
    ),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'price',
    header: t('form:labels.price'),
    cell: ({ row }) => (
      <Form
        body={row.original}
        t={t}
        fieldName={'price'}
        postId={row.original.id}
        permission={PermissionEnum.POST_SAVE}
        noPermissionValue={
          row.original.price
            ? Intl.NumberFormat('pl', {
                style: 'currency',
                currency: 'PLN',
              }).format(row.original.price)
            : '-'
        }
      />
    ),
    enableHiding: true,
  }),
  columnBuilder.accessor('user.email', {
    id: 'email',
    header: t('form:labels.email'),
    enableHiding: true,
  }),
  columnBuilder.accessor(
    'phoneNumber.phone_number',
    {
      id: 'phone_number',
      header: t('form:labels.phone_number'),
      cell: ({ row }) =>
        row.original.phoneNumber?.phone_number,
      enableHiding: true,
    }
  ),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDetails: {
              action: `${
                import.meta.env
                  .VITE_IGRIT_FRONTEND_URL
              }/ogloszenie/${row.original.slug}`,
              permission:
                PermissionEnum.POST_SHOW,
            },
            onEdit: {
              action: `/posts/${row.original.id}/edit`,
              permission:
                PermissionEnum.POST_SAVE,
            },
            onDelete: {
              action: () =>
                onDelete(row.original.id),
              permission:
                PermissionEnum.POST_DESTROY,
              tooltip: row.original.deleted_at
                ? 'posts/posts:list.tooltip.delete_permanently'
                : 'posts/posts:list.tooltip.delete',
            },
          }),
          {
            icon: faFilePdf,
            buttonClassName: 'ripple-bg-red-600',
            permission: PermissionEnum.POST_SHOW,
            onClick: () =>
              window.open(
                row.original.police_report_url,
                '_blank'
              ),
            tooltip:
              'posts/posts:list.download_report',
          },
          {
            icon: faTrashRestore,
            onClick: () =>
              onRestore(row.original.id),
            permission:
              PermissionEnum.POST_DESTROY,
            buttonClassName: row.original
              .deleted_at
              ? 'bg-green-400'
              : 'hidden',
            tooltip:
              'posts/posts:list.tooltip.restore',
          },
        ]}
      />
    ),
    enableHiding: true,
  }),
]
