import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Button } from '@/features/components/buttons/button'
import { useStoreAdvertiserMutation } from '@/features/ads/advertisers/redux/advertiserAPI'
import { toast } from 'react-toastify'
import { advertiserFormModal } from '@/features/ads/advertisers/resources/components'
import { Table } from './partials'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

export const AdvertiserListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['advertisers'])
    const [storeAdvertiser] =
      useStoreAdvertiserMutation()
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    const handleAdd = () => {
      advertiserFormModal({
        title: t('advertisers:create.title'),
      }).then((response) => {
        storeAdvertiser(response)
          .unwrap()
          .then(() =>
            toast.success(
              t('advertisers:create.success')
            )
          )
      })
    }

    return (
      <div
        className={'flex flex-col p-8 gap-y-4'}
      >
        <div className={'flex justify-end'}>
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium flex-col lg:flex-row lg:justify-between flex gap-y-4 lg:items-center'
              }
            >
              <span>
                {t('advertisers:list.title')}
              </span>
              <Button
                onClick={handleAdd}
                variant={'contained'}
              >
                {t('advertisers:list.create')}
              </Button>
            </div>
            <Table
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
