import {
  Control,
  Controller,
  FieldErrors,
} from 'react-hook-form'
import React from 'react'
import { TFunction } from 'i18next'
import { Label } from '@/features/components/inputs/label'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { StoreAttributeInterface } from '@/features/posts/attributeCategories/redux/types'

type Props = {
  control: Control<StoreAttributeInterface, any>
  t: TFunction
  errors: FieldErrors<StoreAttributeInterface>
}

export const FileInput: React.FC<Props> = ({
  control,
  t,
  errors,
}): React.ReactNode => {
  return (
    <Controller
      render={({ field }) => (
        <div className={'flex flex-col gap-y-1'}>
          <Label label={t('form:labels.image')} />
          <input
            type={'file'}
            accept={'image/*'}
            name={field.name}
            onChange={(e) => {
              e.target.files
                ? field.onChange(
                    e.target.files[0]
                  )
                : field.onChange(null)
            }}
          />
          <FormHelperText
            error={!!errors.image}
            message={errors.image?.message}
          />
        </div>
      )}
      name={'image'}
      control={control}
    />
  )
}
