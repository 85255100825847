import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyGetInvoiceStatisticsQuery } from '@/features/invoices/redux/invoiceAPI'
import { Input } from '@/features/components/inputs/input'
import { InvoiceStatisticsInterface } from '@/features/invoices/redux/types'
import { Query } from '@/utils/query'

type Props = {
  query: Query
}

export const InvoiceStatistics: React.FC<
  Props
> = ({ query }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'invoices',
  ])
  const [getStatistics] =
    useLazyGetInvoiceStatisticsQuery()
  const [statistics, setStatistics] =
    useState<InvoiceStatisticsInterface>({
      count: 0,
      gross_value: 0,
      net_value: 0,
      tax_value: 0,
    })

  useEffect(() => {
    getStatistics(query.url())
      .unwrap()
      .then((response) => {
        setStatistics(response)
      })
  }, [query])

  return (
    <div
      className={
        'flex flex-col lg:flex-row lg:flex-wrap gap-4'
      }
    >
      <Input
        label={t('form:labels.net_value')}
        value={statistics.net_value}
        readOnly
      />
      <Input
        label={t('form:labels.gross_value')}
        value={statistics.gross_value}
        readOnly
      />
      <Input
        label={t('form:labels.invoice_count')}
        value={statistics.count}
        readOnly
      />
    </div>
  )
}
