import { Query } from '@/utils/query'
import { useSelectValue } from '@/utils/hooks/useSelectValue'
import { useCallback, useEffect, useMemo } from 'react'
import { ArrayAsyncSelectStrategy, PaginationAsyncSelectStrategy } from './strategies'
import { DefaultValue, ResponseType, UseAsyncSelectResponse } from './useAsyncSelect.types'
import { match } from 'ts-pattern'

/**
 * @warning This hook is experimental and should be used with caution, as it is not yet fully tested.
 */

const useAsyncSelect = <
  T extends object,
  RType extends ResponseType
>(
  fetchFunction: Function,
  type: ResponseType,
  labelColumnName: keyof T,
  valueColumnName: keyof T,
  fieldForSearch: keyof T & string,
  defaultValue?: DefaultValue,
  isMulti = false,
  query: Query = new Query()
): UseAsyncSelectResponse<
  RType,
  typeof isMulti
> => {
  const [selectedValue, setSelectedValue] =
    useSelectValue<typeof isMulti>()

  const strategy = useMemo(
    () =>
      match(type)
        .with(
          'paginated',
          () =>
            new PaginationAsyncSelectStrategy(
              fetchFunction,
              {
                label: labelColumnName,
                value: valueColumnName,
                search: fieldForSearch,
              },
              isMulti,
              setSelectedValue,
              query
            )
        )
        .with(
          'array',
          () =>
            new ArrayAsyncSelectStrategy(
              fetchFunction,
              {
                label: labelColumnName,
                value: valueColumnName,
                search: fieldForSearch,
              },
              isMulti,
              setSelectedValue,
              query
            )
        )
        .exhaustive(),
    [type]
  )

  useEffect(() => {
    if (!selectedValue && defaultValue) {
      void strategy.handleGetDefaultValue(
        defaultValue
      )
    } else if (selectedValue && !defaultValue) {
      setSelectedValue(null)
    }
  }, [defaultValue, selectedValue, strategy])

  const handleFetch = useCallback(
    strategy.handleFetch,
    [query, strategy]
  )

  const selectProperties = useMemo(
    () => strategy.getProperties(),
    [strategy]
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    selectedValue,
    setSelectedValue,
    handleFetch,
    selectProperties,
  }
}

export { useAsyncSelect }
