import { TFunction } from 'i18next'
import * as yup from 'yup'
import { UpdateIssueRequestInterface } from '@/features/issues/redux/types'
import { IssueStatusEnum } from '@/features/issues/redux/enums/status'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'

export default class UpdateIssueStatusValidation
  implements
    ValidationInterface<UpdateIssueRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    UpdateIssueRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      status: yup
        .mixed<IssueStatusEnum>()
        .required(t('validation:required'))
        .oneOf(
          Object.values(IssueStatusEnum),
          t('validation:invalid')
        ),
    })
  }

  defaultValues(): UpdateIssueRequestInterface {
    return {
      status: IssueStatusEnum.IN_PROGRESS,
    }
  }
}
