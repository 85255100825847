import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveAdressRequestInterface } from '@/features/users/redux'

export default class SaveAdressValidation
  implements
    ValidationInterface<SaveAdressRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveAdressRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t('validation:max', { max: 255 })
        ),
      street: yup
        .string()
        .nullable()
        .max(
          255,
          t('validation:max', { max: 255 })
        ),
      local_number: yup
        .string()
        .nullable()
        .max(
          255,
          t('validation:max', {
            max: 255,
          })
        ),
      house_number: yup
        .string()
        .nullable()
        .max(
          255,
          t('validation:max', {
            max: 255,
          })
        ),
      postal_code: yup
        .string()
        .nullable()
        .max(
          255,
          t('validation:max', {
            max: 255,
          })
        ),
      city: yup
        .string()
        .max(
          255,
          t('validation:max', {
            max: 255,
          })
        )
        .required(),
      state: yup
        .string()
        .nullable()
        .max(
          255,
          t('validation:max', {
            max: 255,
          })
        ),
      country: yup
        .string()
        .nullable()
        .max(
          255,
          t('validation:max', {
            max: 255,
          })
        ),
      is_default: yup.boolean().required(),
      location: yup.object().shape({
        lat: yup
          .number()
          .required(t('validation:required')),
        long: yup
          .number()
          .required(t('validation:required')),
      }),
    })
  }

  defaultValues(): SaveAdressRequestInterface {
    return {
      name: '',
      is_default: false,
      city: '',
      country: '',
      house_number: '',
      local_number: '',
      location: {
        lat: 52.237049,
        long: 21.017532,
      },
      postal_code: '',
      state: '',
      street: '',
    }
  }
}
