import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveFAQCategoryQuestionRequestInterface } from '@/features/faq/questions/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'

export default class SaveCategoryQuestionValidation
  implements
    ValidationInterface<SaveFAQCategoryQuestionRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveFAQCategoryQuestionRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      question: yup
        .string()
        .required(t('validation:required')),
      answer: yup
        .string()
        .required(t('validation:required')),
      category_id: yup
        .number()
        .required(t('validation:required'))
        .notOneOf([0], t('validation:required')),
      related_faq_entry_ids: yup
        .array()
        .of(yup.number().required())
        .required(),
    })
  }

  defaultValues(): SaveFAQCategoryQuestionRequestInterface {
    return {
      question: '',
      answer: '',
      category_id: 0,
      related_faq_entry_ids: [],
    }
  }
}
