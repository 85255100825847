import { useLazyGetAdvertisersQuery } from '@/features/ads/advertisers/redux/advertiserAPI'
import { useAsyncPaginated } from '@/utils/hooks/useAsyncPaginated'
import { AdvertiserInterface } from '@/features/ads/advertisers/redux/types'
import React, { FC } from 'react'
import { SearchAdvertiserTypes } from './SearchAdvertiserTypes'
import { AsyncPaginate } from 'react-select-async-paginate'
import {
  MultiValue,
  SingleValue,
} from 'react-select'
import { Select2OptionInterface } from '@/app/types'
import { useAsyncSelect } from '@/utils/hooks/useAsyncSelect'
import { useTranslation } from 'react-i18next'

const SearchAdvertiser: FC<
  SearchAdvertiserTypes
> = ({
  onChange,
  isMulti,
  defaultValue,
}): React.ReactNode => {
  const { t } = useTranslation(['form'])

  const [getAdvertisers] =
    useLazyGetAdvertisersQuery()
  const { handleFetch, classes } =
    useAsyncPaginated<AdvertiserInterface>(
      getAdvertisers,
      'company_name',
      'id',
      'company_name'
    )
  const { selectedValue, setSelectedValue } =
    useAsyncSelect<AdvertiserInterface>(
      getAdvertisers,
      'company_name',
      defaultValue,
      isMulti
    )

  const handleChange = (
    value:
      | MultiValue<Select2OptionInterface>
      | SingleValue<Select2OptionInterface>
  ) => {
    if (isMulti) {
      onChange(
        value as MultiValue<Select2OptionInterface>
      )
      setSelectedValue(
        value as Select2OptionInterface[]
      )
    } else {
      onChange(
        value as SingleValue<Select2OptionInterface>
      )
      setSelectedValue(
        value as Select2OptionInterface
      )
    }
  }

  return (
    <AsyncPaginate
      loadOptions={handleFetch}
      onChange={handleChange}
      value={selectedValue}
      placeholder={t(
        'form:placeholders.search_advertiser'
      )}
      isMulti={isMulti}
      debounceTimeout={500}
      classNames={classes}
    />
  )
}

export { SearchAdvertiser }
