import igritAPI from '@/utils/api/igrit'
import {
  AssignRoleRequestInterface,
  ChangeCompanyRequestInterface,
  ChangePasswordRequestInterface,
  GetAdressesResponseInterface,
  GetUserLogsResponseInterface,
  GetUserResponseInterface,
  GetUsersResponseInterface,
  SaveAdressRequestInterface,
  SearchUsersResponseInterface,
  StoreUserRequestInterface,
  UpdateUserRequestInterface,
} from '@/features/users/redux/types'
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useLazySearchUsersQuery,
  useStoreUserMutation,
  useGetUserQuery,
  useChangeUserPasswordMutation,
  useAssignRoleMutation,
  useRevokeRoleMutation,
  useStoreAdressMutation,
  useUpdateAdressMutation,
  useUpdateCompanyMutation,
  useDeleteAdressMutation,
  useGetAdressesQuery,
  useGetUserLogsQuery,
  useUpdateUserMutation,
  useGetUsersQuery,
  useSearchUsersQuery,
  useDeleteUserMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<
      GetUsersResponseInterface['data'],
      string
    >({
      query: (query) => `/admin/users/?${query}`,
      transformResponse: (
        response: GetUsersResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('User', result),
    }),
    deleteUser: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    searchUsers: build.query<
      SearchUsersResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/users/search?${query}`,
      transformResponse: (
        response: SearchUsersResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setArrayCache('User', result),
    }),
    storeUser: build.mutation<
      void,
      StoreUserRequestInterface
    >({
      query: (data) => ({
        url: '/admin/users',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: build.mutation<
      void,
      {
        data: UpdateUserRequestInterface
        userId: number | string
      }
    >({
      query: ({ data, userId }) => ({
        url: `/admin/users/${userId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),

    changeUserPassword: build.mutation<
      void,
      {
        id: number | string
        body: ChangePasswordRequestInterface
      }
    >({
      query: ({ id, body }) => ({
        url: `/admin/users/${id}/change-password`,
        method: 'POST',
        body,
      }),
    }),
    assignRole: build.mutation<
      void,
      {
        id: number | string
        body: AssignRoleRequestInterface
      }
    >({
      query: ({ id, body }) => ({
        url: `/admin/users/${id}/roles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: 'User', id },
      ],
    }),
    revokeRole: build.mutation<
      void,
      {
        data: AssignRoleRequestInterface
        id: string | number
      }
    >({
      query: ({ data, id }) => ({
        url: `/admin/users/${id}/roles`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: 'User', id },
      ],
    }),
    getUser: build.query<
      GetUserResponseInterface['data'],
      number | string
    >({
      query: (id) => `/admin/users/${id}`,
      transformResponse: (
        response: GetUserResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('User', result),
    }),
    getAdresses: build.query<
      GetAdressesResponseInterface['data'],
      { query: string; id: string | number }
    >({
      query: ({ query, id }) =>
        `/admin/users/${id}/addresses?${query}`,
      transformResponse: (
        response: GetAdressesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('UserAddress', result),
    }),
    storeAdress: build.mutation<
      void,
      {
        body: SaveAdressRequestInterface
        id: string | number
      }
    >({
      query: ({ body, id }) => ({
        url: `/admin/users/${id}/addresses`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserAddress'],
    }),
    updateAdress: build.mutation<
      void,
      {
        body: SaveAdressRequestInterface
        userId: string | number
        adressId: string | number
      }
    >({
      query: ({ body, userId, adressId }) => ({
        url: `/admin/users/${userId}/addresses/${adressId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UserAddress'],
    }),
    deleteAdress: build.mutation<
      void,
      {
        userId: string | number
        adressId: string | number
      }
    >({
      query: ({ userId, adressId }) => ({
        url: `/admin/users/${userId}/addresses/${adressId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserAddress'],
    }),
    getUserLogs: build.query<
      GetUserLogsResponseInterface['data'],
      { userId: string | number; query: string }
    >({
      query: ({ userId, query }) =>
        `/admin/users/${userId}/login-attempts/?${query}`,
      transformResponse: (
        response: GetUserLogsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('UserLogs', result),
    }),
    updateCompany: build.mutation<
      void,
      {
        data: ChangeCompanyRequestInterface
        userId: string | number
      }
    >({
      query: ({ data, userId }) => ({
        url: `/admin/users/${userId}/company`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Company'],
    }),
  }),
})
