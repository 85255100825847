import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { ChangePasswordInterface } from '@/features/auth/redux/types'

export default class ChangePasswordValidation
  implements
    ValidationInterface<ChangePasswordInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    ChangePasswordInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      old_password: yup
        .string()
        .required(t('validation:required')),
      new_password: yup
        .string()
        .min(
          8,
          t('validation:field_min_length', {
            length: 8,
          })
        )
        .required(t('validation:required'))
        .notOneOf(
          [yup.ref('old_password'), ''],
          t('validation:cannot_be_the_same_as', {
            field: t(
              'form:labels.old_password'
            ).toLowerCase(),
          })
        ),
    })
  }

  defaultValues(): ChangePasswordInterface {
    return {
      old_password: '',
      new_password: '',
    }
  }
}
