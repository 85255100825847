import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { useGetNotificationTemplatesQuery } from '@/features/notificationTemplates/redux/notificationTemplateAPI'
import { NotificationTemplateInterface } from '@/features/notificationTemplates/redux/types'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  visibility,
  setAvailableColumns,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'notification_templates',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      new Query()
        .page(pagination.pageIndex)
        .includes('answers'),
    [pagination.pageIndex]
  )
  const { data: apiData } =
    useGetNotificationTemplatesQuery(query.url())
  const [data, setData] = useState<
    NotificationTemplateInterface[]
  >([])

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const table = useReactTable({
    columns: columns(t),
    getCoreRowModel: getCoreRowModel(),
    data,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    manualPagination: true,
    enableSorting: false,
    enableHiding: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<NotificationTemplateInterface>()

const columns = (t: TFunction) => [
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    cell: ({ row }) =>
      t(
        `utils:notification_templates.names.${row.original.name}`
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    cell: ({ row }) =>
      t(
        `utils:notification_templates.types.${row.original.type}`
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('for', {
    id: 'for',
    header: t('form:labels.for'),
    cell: ({ row }) =>
      t(
        `utils:notification_templates.for.${row.original.for}`
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('can_turn_off', {
    id: 'can_turn_off',
    header: t('form:labels.can_turn_off'),
    cell: ({ row }) =>
      row.original.can_turn_off
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('is_active', {
    id: 'is_active',
    header: t('form:labels.is_active'),
    cell: ({ row }) =>
      row.original.is_active
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/notification-templates/${row.original.id}/edit`,
            permission:
              PermissionEnum.SURVEY_SAVE,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
