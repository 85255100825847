import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AssignRoleRequestInterface,
  useAssignRoleMutation,
  useRevokeRoleMutation,
  UserInterface,
} from '@/features/users/redux'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import useValidation from '@/utils/hooks/useValidation'
import { ChangeRoleValidation } from './rules/ChangeRoleValidation'
import { yupResolver } from '@hookform/resolvers/yup'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import { SearchRole } from '@/components'
import { FormControl } from '@mui/base'
import { Button } from '@/features/components/buttons/button'

const ChangeRoleForm: FC<{
  data?: UserInterface
  id: number | string
}> = ({
  id,
  data: userData,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'users',
  ])
  const [assignRole] = useAssignRoleMutation()
  const [revokeRole] = useRevokeRoleMutation()
  const { schema, defaultValues } = useValidation(
    new ChangeRoleValidation(),
    t
  )
  const methods =
    useForm<AssignRoleRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (userData?.role) {
      methods.setValue(
        'role_id',
        userData.role.id
      )
    }
  }, [userData])

  const onSubmit = async (
    data: AssignRoleRequestInterface
  ) => {
    try {
      await assignRole({
        body: data,
        id,
      }).unwrap()
      toast.success(
        t('users:form.change_role.success')
      )
      methods.reset(defaultValues)
    } catch (error) {
      // empty block
    }
  }

  const handleRemoveRole = async () => {
    try {
      if (userData?.role.id) {
        await revokeRole({
          data: { role_id: userData.role.id },
          id: id,
        }).unwrap()
      }
      toast.success(
        t('users:form.change_role.success')
      )
      methods.reset(defaultValues)
    } catch (error) {
      // empty block
    }
  }

  return (
    <Card>
      <div className={'flex justify-between'}>
        <span className={'font-semibold text-lg'}>
          {t('users:form.change_role.title')}
        </span>
        <Button
          variant={'secondary'}
          size={'small'}
          className={
            'bg-red-600 hover:bg-red-700 text-white'
          }
          onClick={handleRemoveRole}
        >
          {t('form:buttons.delete')}
        </Button>
      </div>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={'flex flex-col gap-4 pt-4'}
      >
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              error={!!error}
              className={'flex flex-col'}
            >
              <SearchRole
                onChange={(value) => {
                  field.onChange(value?.value)
                }}
                isMulti={false}
                defaultValue={field.value}
              />
            </FormControl>
          )}
          name={'role_id'}
          control={methods.control}
        />
        <div className={'flex pt-8'}>
          <Button
            variant={'contained'}
            size={'small'}
            type={'submit'}
          >
            {t('form:buttons.save')}
          </Button>
        </div>
      </form>
    </Card>
  )
}

export { ChangeRoleForm }
