import igritAPI from '@/utils/api/igrit'
import {
  GetAgreementResponseInterface,
  GetAgreementsResponseInterface,
  GetPaginatedAgreementsResponseInterface,
} from '@/features/agreements'
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetAgreementsQuery,
  useGetPaginatedAgreementsQuery,
  useStoreAgreementMutation,
  useDeleteAgreementMutation,
  useUpdateAgreementMutation,
  useGetAgreementQuery,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getAgreements: build.query<
      GetAgreementsResponseInterface['data'],
      void
    >({
      query: () => '/admin/agreements/search',
      transformResponse: (
        response: GetAgreementsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setArrayCache('Agreement', result),
    }),
    getPaginatedAgreements: build.query<
      GetPaginatedAgreementsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/agreements?${query}`,
      transformResponse: (
        response: GetPaginatedAgreementsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Agreement', result),
    }),
    storeAgreement: build.mutation<
      void,
      FormData
    >({
      query: (data) => ({
        url: '/admin/agreements',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['Agreement'],
    }),
    deleteAgreement: build.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/agreements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Agreement'],
    }),
    getAgreement: build.query<
      GetAgreementResponseInterface['data'],
      string | number
    >({
      query: (id) => `/admin/agreements/${id}`,
      transformResponse: (
        response: GetAgreementResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('Agreement', result),
    }),
    updateAgreement: build.mutation<
      void,
      { body: FormData; id: string | number }
    >({
      query: ({ body, id }) => ({
        url: `/admin/agreements/${id}`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['Agreement'],
    }),
  }),
})
