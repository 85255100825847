import React, { useEffect } from 'react'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { TFunction } from 'i18next'
import {
  InternalQuotationInterface,
  SaveInternalQuotationInterface,
} from '@/features/internalQuotations/redux/types'
import { useUpdateInternalQuotationMutation } from '@/features/internalQuotations/redux/internalQuotationAPI'
import { toast } from 'react-toastify'
import { toRequestObject } from '@/features/internalQuotations/resources/views/list/components/form/helper'
import { match } from 'ts-pattern'
import { SaveInternalQuotationValidation } from '@/features/internalQuotations/resources/components'

type Props = {
  body: InternalQuotationInterface
  t: TFunction
  fieldName: keyof SaveInternalQuotationInterface
}

const Form: React.FC<Props> = ({
  body,
  t,
  fieldName,
}): React.ReactNode => {
  const { schema, defaultValues } = useValidation(
    new SaveInternalQuotationValidation(),
    t
  )
  const [updateInternalQuotation, { isLoading }] =
    useUpdateInternalQuotationMutation()

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SaveInternalQuotationInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const _handleFieldValueChange = async (
    data: SaveInternalQuotationInterface
  ) => {
    try {
      await updateInternalQuotation({
        id: body.id,
        data: {
          ...data,
        },
      }).unwrap()
      toast.success(
        t('internal_quotations:edit.success')
      )
    } catch (error) {
      //empty block
    }
  }
  const handleFieldValueChange = _.debounce(
    _handleFieldValueChange,
    1500
  )

  useEffect(() => {
    reset(toRequestObject(body))
  }, [body])

  useEffect(() => {
    const subscription = watch(() => {
      if (Object.keys(errors).length) return

      handleSubmit(handleFieldValueChange)()
    })
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  return (
    <Controller
      render={({
        field,
        fieldState: { error },
      }) => (
        <FormControl {...field} error={!!error}>
          <Input
            type={match(fieldName)
              .with(
                'execution_interval_days',
                () => 'number'
              )
              .otherwise(() => 'text')}
            endAdornment={
              isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                />
              )
            }
          />
          <FormHelperText
            message={error?.message}
          />
        </FormControl>
      )}
      name={fieldName}
      control={control}
    />
  )
}

export { Form }
