import { QuotationApplicationInterface } from '@/features/internalQuotationsApplications/redux/types'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from '@/features/components/modals/modal'
import { Button } from '@/features/components/buttons/button'
import ModalTitle from '@/features/components/modals/ModalTitle'

type Props = {
  row: QuotationApplicationInterface
} & InstanceProps<
  QuotationApplicationInterface,
  unknown
>

const Form: React.FC<Props> = ({
  row,
  isOpen,
  onReject,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'internal_quotations_applications',
  ])
  return (
    <Modal open={isOpen} onClose={() => onReject}>
      <Modal.Content size={'w-[800px]'}>
        <ModalTitle
          title={t(
            'internal_quotations_applications:code.title'
          )}
          onClose={() => onReject()}
        />
        <div
          className={'flex flex-col gap-y-8 pt-4'}
        >
          <div
            className={
              'bg-gray-200 flex flex-col rounded-md p-4'
            }
          >
            <span>
              {`// Proszę podać ID kontenera w jakim
              ma wyświetlać się wykres`}
            </span>
            <span className={'mt-4'}>
              {`
              // Umieść kod na końcu strony przed
              zamknięciem sekcji BODY
              `}
            </span>
            <span>
              {`
              <script type="text/javascript" src="https://igrit.pl/js/bundle.min.js"></script>
              `}
            </span>

            <span className={'mt-4'}>
              {`
              <script>
                $igritChart(document.getElementById('YourContenerID'),
                '${row.token}');
              </script>
              `}
            </span>
          </div>

          <div className={'gap-x-4'}>
            <Button
              variant={'contained'}
              className={
                'w-full ripple-bg-primary-600'
              }
              size={'small'}
              onClick={() => onReject(true)}
            >
              {t('form:buttons.close')}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const codeModal = create(Form)
