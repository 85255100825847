import React from 'react'
import { FAQCategoryInterface } from '@/features/faq/categories/redux/types'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'

type Props = {
  data: FAQCategoryInterface
}

export const Details: React.FC<Props> = ({
  data,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'faq/categories',
  ])

  return (
    <Card>
      <div className={'flex flex-col gap-y-6'}>
        <span className={'text-lg font-medium'}>
          {t('faq/categories:details.details')}
        </span>
        <div className={'flex flex-col gap-y-2'}>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.name')}
            </span>
            <span>{data.name}</span>
          </div>
          <div
            className={'grid grid-cols-2 gap-4'}
          >
            <span className={'font-medium'}>
              {t('form:labels.icon')}
            </span>
            <img
              src={data.media_url || ''}
              alt={data.name}
              className={'size-4'}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}
