import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import {
  InternalQuotationResultInterface,
  SaveInternalQuotationResultRequestInterface,
} from '@/features/internalQuotationsResults/redux/types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SaveInternalQuotationsResultValidation from './validation/SaveInternalQuotationsResultValidation'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Button } from '@/features/components/buttons/button'
import Carbon from '@/utils/carbon'

type Props = {
  data?: InternalQuotationResultInterface
  title: string
} & InstanceProps<
  SaveInternalQuotationResultRequestInterface,
  unknown
>

const Form: React.FC<Props> = ({
  data,
  isOpen,
  title,
  onResolve,
  onReject,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveInternalQuotationsResultValidation(),
    t
  )
  const { control, setValue, handleSubmit } =
    useForm<SaveInternalQuotationResultRequestInterface>(
      {
        resolver: yupResolver(schema),
        defaultValues,
      }
    )

  useEffect(() => {
    if (data) {
      setValue('note', data.note)
      setValue(
        'date_to',
        new Carbon(
          data.date_to
        ).toInputWithDateTime()
      )
      setValue(
        'date_from',
        new Carbon(
          data.date_from
        ).toInputWithDateTime()
      )
    }
  }, [data])

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content size={'w-[500px]'}>
        <div className={'flex flex-col gap-y-6'}>
          <ModalTitle
            title={title}
            onClose={() => onReject()}
          />
          <form
            className={'flex flex-col gap-y-8'}
            onSubmit={handleSubmit(onResolve)}
          >
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    type={'datetime-local'}
                    label={t(
                      'form:labels.started_at'
                    )}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'date_from'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    type={'datetime-local'}
                    label={t(
                      'form:labels.end_at'
                    )}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'date_to'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    multiline={true}
                    rows={5}
                    label={t('form:labels.notes')}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'note'}
              control={control}
            />

            <div className={'flex gap-x-4 pt-4'}>
              <Button
                variant={'contained'}
                type={'submit'}
                className={'w-full'}
              >
                {t('form:buttons.save')}
              </Button>
              <Button
                variant={'outlined'}
                onClick={() => onReject()}
                className={'w-full'}
              >
                {t('form:buttons.cancel')}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const internalQuotationsResultModal =
  create(Form)
