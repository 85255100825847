import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { useNavigate } from 'react-router-dom'
import { useStorePageMutation } from '@/features/pages/redux/pageAPI'
import { SavePageRequestInterface } from '@/features/pages/redux/types'
import { Form } from '@/features/pages/resources/_components/form'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'

export const PageCreateScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['pages'])
    const [storePage] = useStorePageMutation()
    const navigate = useNavigate()

    const handleSubmit = (
      data: SavePageRequestInterface
    ) => {
      const form = serialize(data, {
        booleansAsIntegers: true,
      })

      storePage(form)
        .unwrap()
        .then(() => {
          toast.success(t('pages:create.success'))
          navigate('/pages')
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('pages:create.title')}
        </span>
        <Card margin>
          <Form onSubmit={handleSubmit} />
        </Card>
      </div>
    )
  }
