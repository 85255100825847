import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Search } from '@/features/components/inputs/Search'
import { useSearch } from '@/utils/hooks/useSearch'
import { Table } from './table'
import { Button } from '@/features/components/buttons/button'
import { AdressModal } from '@/features/users/resources/views/edit/partials/Adresses/partials/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { usePermissions } from '@/utils/hooks/usePermission'
import { toast } from 'react-toastify'
import { useStoreAdressMutation } from '@/features/users/redux'

type Props = {
  userId: string | number
}
const AdressForm: React.FC<Props> = ({
  userId,
}): React.ReactNode => {
  const { t } = useTranslation(['users'])
  const { hasPermission } = usePermissions()
  const { query, setQueryParams } = useSearch({
    columnsForSearch: ['adress'],
  })
  const [storeAdress] = useStoreAdressMutation()

  const handleAddAdress = async () => {
    try {
      const data = await AdressModal({
        title: t('users:adresses.create.title'),
      })

      await storeAdress({
        body: data,
        id: userId,
      }).unwrap()
      toast.success(
        t('users:adresses:create.success')
      )
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col gap-y-4'}>
      <div className={'flex flex-row-reverse'}>
        {hasPermission(
          PermissionEnum.USER_ADDRESS_SAVE
        ) && (
          <Button
            variant={'contained'}
            className={
              'flex items-center gap-x-2'
            }
            onClick={handleAddAdress}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className={'text-white'}
            />
            <span>
              {t('users:adresses.list.create')}
            </span>
          </Button>
        )}
      </div>
      <Search onChange={setQueryParams} />
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t('users:adresses.list.title')}
            </span>
          </div>
          <Table userId={userId} query={query} />
        </div>
      </Card>
    </div>
  )
}

export { AdressForm }
