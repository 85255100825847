import {
  FormInterface,
  Select2OptionInterface,
} from '@/app/types'
import {
  CategoryInterface,
  SaveCategoryPropsInterface,
} from '@/features/posts/categories/redux/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SaveCategoryValidation from './validation/saveCategoryValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '@/features/components/inputs/input'
import { FormControl } from '@mui/base'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { useLazySearchCategoriesQuery } from '@/features/posts/categories/redux/categoryAPI'
import { Query } from '@/utils/query'
import { Label } from '@/features/components/inputs/label'
import { AsyncSelect } from '@/features/components/inputs/asyncSelect/asyncSelect'
import _ from 'lodash'
import Select, { MultiValue } from 'react-select'
import useValidation from '@/utils/hooks/useValidation'
import { Button } from '@/features/components/buttons/button'
import { UnitEnum } from '@/app/enums/unitEnum'

export const Form: React.FC<
  FormInterface<
    SaveCategoryPropsInterface,
    CategoryInterface
  >
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveCategoryValidation(),
    t
  )
  const [searchCategories] =
    useLazySearchCategoriesQuery()
  const [
    selectedParentCategory,
    setSelectedParentCategory,
  ] = useState<Select2OptionInterface>()
  const [
    selectedAdditionalCategories,
    setSelectedAdditionalCategories,
  ] =
    useState<MultiValue<Select2OptionInterface>>()

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<SaveCategoryPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('seo_keywords', data.seo_keywords)
      setValue(
        'seo_description',
        data.seo_description
      )
      setValue(
        'parent_category_id',
        data.parent_category_id
      )
      setValue(
        'additional_category_ids',
        data.additional_category_ids
      )
      setValue('units', data.units)
      setValue('description', data.description)

      if (data.parent_category) {
        setSelectedParentCategory({
          label: data.parent_category.name,
          value: String(data.parent_category.id),
        })
      }

      if (data.additional_categories) {
        setSelectedAdditionalCategories(
          data.additional_categories.map(
            (category) => ({
              label: category.name,
              value: String(category.id),
            })
          )
        )
      }
    }
  }, [data, setValue])

  const watchParentId = watch(
    'parent_category_id'
  )
  const watchAdditionalIds = watch(
    'additional_category_ids'
  )

  const _searchParentCategories = (
    value: string,
    callback: (
      options: Select2OptionInterface[]
    ) => void
  ) => {
    const query = new Query().where('name', value)

    if (
      watchAdditionalIds &&
      watchAdditionalIds.length > 0
    ) {
      query.whereNotIn(watchAdditionalIds)
    }

    searchCategories(query.url())
      .unwrap()
      .then((response) => {
        callback(
          response.map((category) => ({
            label: category.name,
            value: String(category.id),
          }))
        )
      })
  }

  const searchParentCategories = _.debounce(
    _searchParentCategories,
    500
  )

  const _searchAdditionalCategories = (
    value: string,
    callback: (
      options: Select2OptionInterface[]
    ) => void
  ) => {
    const query = new Query().where('name', value)

    if (watchParentId) {
      query.whereNotIn([watchParentId])
    }

    searchCategories(query.url())
      .unwrap()
      .then((response) => {
        callback(
          response.map((category) => ({
            label: category.name,
            value: String(category.id),
          }))
        )
      })
  }

  const searchAdditionalCategories = _.debounce(
    _searchAdditionalCategories,
    500
  )

  const handleChangeParentCategory = (
    value: Select2OptionInterface
  ) => {
    setSelectedParentCategory(value)
    setValue('parent_category_id', value?.value)
  }

  const handleChangeAdditionalCategories = (
    value: MultiValue<Select2OptionInterface>
  ) => {
    setSelectedAdditionalCategories(value)
    setValue(
      'additional_category_ids',
      value.map((category) => category.value)
    )
  }

  return (
    <div
      className={
        'flex flex-col gap-y-6 max-w-full'
      }
    >
      <div
        className={
          'grid lg:grid-cols-5 gap-4 max-w-full'
        }
      >
        <Controller
          render={({ field }) => (
            <FormControl
              {...field}
              error={!!errors.name}
            >
              <Input
                name={field.name}
                label={t('form:labels.name')}
                error={!!errors.name}
              />
              <FormHelperText
                message={errors.name?.message}
              />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
        <Controller
          render={({ field }) => (
            <FormControl
              {...field}
              error={!!errors.seo_keywords}
            >
              <Input
                name={field.name}
                label={t(
                  'form:labels.seo_keywords'
                )}
                error={!!errors.seo_keywords}
              />
              <FormHelperText
                message={
                  errors.seo_keywords?.message
                }
              />
            </FormControl>
          )}
          name={'seo_keywords'}
          control={control}
        />
        <Controller
          render={({ field }) => (
            <FormControl
              {...field}
              error={!!errors.seo_description}
            >
              <Input
                name={field.name}
                label={t(
                  'form:labels.seo_description'
                )}
                error={!!errors.seo_description}
              />
              <FormHelperText
                message={
                  errors.seo_description?.message
                }
              />
            </FormControl>
          )}
          name={'seo_description'}
          control={control}
        />
        <Controller
          control={control}
          name={'parent_category_id'}
          render={({ fieldState: { error } }) => (
            <FormControl
              error={!!error}
              className={'flex flex-col'}
            >
              <Label
                label={t(
                  'form:labels.parent_category'
                )}
              />
              <AsyncSelect
                cacheOptions
                value={selectedParentCategory}
                onChange={(value) =>
                  handleChangeParentCategory(
                    value as Select2OptionInterface
                  )
                }
                loadOptions={
                  searchParentCategories
                }
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name={'additional_category_ids'}
          render={({ fieldState: { error } }) => (
            <FormControl
              error={!!error}
              className={'flex flex-col'}
            >
              <Label
                label={t(
                  'form:labels.additional_categories'
                )}
              />
              <AsyncSelect
                isMulti
                cacheOptions
                onChange={
                  handleChangeAdditionalCategories
                }
                value={
                  selectedAdditionalCategories
                }
                loadOptions={
                  searchAdditionalCategories
                }
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              className={
                'flex flex-col lg:col-span-2'
              }
              error={!!error}
            >
              <Label
                label={t('form:labels.units')}
              />
              <Select
                isMulti
                options={_.map(
                  UnitEnum,
                  (value) => ({
                    label: t(
                      `utils:units.${value}`
                    ),
                    value,
                  })
                )}
                name={field.name}
                value={field.value.map(
                  (value) => ({
                    label: t(
                      `utils:units.${value}`
                    ),
                    value,
                  })
                )}
                onChange={(value) =>
                  field.onChange(
                    value.map((val) => val.value)
                  )
                }
                classNames={{
                  control: () =>
                    '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent',
                }}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'units'}
          control={control}
        />
        <Controller
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              className={'lg:col-span-2'}
              error={!!error}
            >
              <Input
                multiline
                rows={10}
                name={field.name}
                label={t(
                  'form:labels.description'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
          name={'description'}
          control={control}
        />
        <Controller
          render={({ field }) => (
            <div
              className={
                'flex flex-col lg:col-span-2 gap-y-1'
              }
            >
              <Label
                label={t('form:labels.icon')}
              />
              <input
                type={'file'}
                className={
                  'max-w-[320px] lg:max-w-full'
                }
                accept={'image/*'}
                name={field.name}
                onChange={(e) => {
                  e.target.files
                    ? field.onChange(
                        e.target.files[0]
                      )
                    : field.onChange(null)
                }}
              />
              <FormHelperText
                error={!!errors.icon}
                message={errors.icon?.message}
              />
            </div>
          )}
          name={'icon'}
          control={control}
        />
      </div>
      <div className={'flex'}>
        <Button
          variant={'contained'}
          onClick={handleSubmit(onSubmit)}
        >
          {t('form:buttons.save')}
        </Button>
      </div>
    </div>
  )
}
