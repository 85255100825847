import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { StoreMassAttributeCategoryValidationInterface } from '@/features/posts/attributeCategories/redux'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'
import { AttributeTypeEnum } from '@/features/posts/attributes/redux/enums/attributeType'

class StoreMassAttributeCategoryValidation
  implements
    ValidationInterface<StoreMassAttributeCategoryValidationInterface>
{
  defaultValues(): StoreMassAttributeCategoryValidationInterface {
    return {
      attribute_id: 0,
      affects_price: false,
      image: null,
      attribute_type: AttributeTypeEnum.SELECT,
      values: [],
    }
  }

  rules(
    t: TFunction
  ): ObjectSchema<
    StoreMassAttributeCategoryValidationInterface,
    AnyObject
  > {
    return yup.object().shape({
      attribute_id: yup
        .number()
        .required(t('validation:required'))
        .notOneOf([0], t('validation:required')),
      affects_price: yup
        .boolean()
        .required(t('validation:required')),
      attribute_type: yup
        .mixed<AttributeTypeEnum>()
        .required(),
      image: yup
        .mixed<File>()
        .nullable()
        .when('attribute_type', {
          is: (val: AttributeTypeEnum) =>
            val === AttributeTypeEnum.CERTIFICATE,
          then: (schema) =>
            schema.required(
              t('validation:required')
            ),
        }),
      values: yup
        .array()
        .of(
          yup.object().shape({
            category_id: yup
              .number()
              .required(t('validation:required')),
            category_name: yup
              .string()
              .required(t('validation:required')),
            value: yup
              .array()
              .of(yup.string().required())
              .nullable()
              .test(
                'required',
                t('validation:required'),
                (value, context) => {
                  const from = context.from

                  if (from && from.length >= 2) {
                    const attributeType: AttributeTypeEnum =
                      from[1].value.attribute_type

                    return [
                      AttributeTypeEnum.MULTI_SELECT,
                      AttributeTypeEnum.SELECT,
                    ].includes(attributeType)
                      ? !!value &&
                          value.length > 0
                      : true
                  }

                  return false
                }
              ),
          })
        )
        .required()
        .when('attribute_type', {
          is: (val: AttributeTypeEnum) =>
            [
              AttributeTypeEnum.MULTI_SELECT,
              AttributeTypeEnum.SELECT,
            ].includes(val),
          then: (schema) =>
            schema.min(
              1,
              t('validation:required')
            ),
        }),
    })
  }
}

export { StoreMassAttributeCategoryValidation }
