import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import * as yup from 'yup'

import { TFunction } from 'i18next'
import { UpdateArticleMediaGalleryInterface } from '@/features/articleMediaGalleries/redux/types'

export default class UpdateArticleMediaGalleryValidation
  implements
    ValidationInterface<UpdateArticleMediaGalleryInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    UpdateArticleMediaGalleryInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      order: yup
        .number()
        .min(
          0,
          t(
            'validation:field_must_be_greater_than',
            { min: 0 }
          )
        )
        .required(t('validation:required')),
      title: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t(
            'validation:field_must_be_less_than',
            { max: 255 }
          )
        ),
      description: yup
        .string()
        .max(
          255,
          t(
            'validation:field_must_be_less_than',
            { max: 255 }
          )
        )
        .nullable(),
    })
  }

  defaultValues(): UpdateArticleMediaGalleryInterface {
    return {
      order: 0,
      title: '',
      description: '',
    }
  }
}
