import React, { useState } from 'react'
import {
  SaveAttributeInterface,
  StoreAttributeInterface,
} from '@/features/posts/attributeCategories/redux/types'
import { useStoreAttributeCategoryMutation } from '@/features/posts/attributeCategories/redux/attributeCategoryAPI'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
  ResponseCodeEnum,
} from '@/app/types'
import { isFetchBaseQueryError } from '@/app/hooks'
import { Form } from '@/features/posts/attributeCategories/resources/components/form/Form'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'

const ns = 'posts/attribute_categories'

export const PostAttributeCategoryCreateScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(ns)
    const [storeAttributeCategory] =
      useStoreAttributeCategoryMutation()
    const [errors, setErrors] =
      useState<
        BackendValidationErrorInterface<SaveAttributeInterface>
      >()

    const handleSubmit = async (
      data: StoreAttributeInterface
    ): Promise<boolean> => {
      const formData = serialize(data, {
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
        indices: true,
      })

      try {
        await storeAttributeCategory(
          formData
        ).unwrap()
        toast.success(t(`${ns}:create.success`))
        return true
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          const errorData =
            error as ErrorResponseInterface

          if (
            errorData.status === 400 &&
            errorData.data &&
            errorData.data.code ===
              ResponseCodeEnum.ATTRIBUTE_CATEGORY_BINDING_EXIST
          ) {
            toast.error(t(`${ns}:binding_exist`))
          }

          if (errorData.status === 422) {
            const errorData =
              error as ErrorValidationResponseInterface<SaveAttributeInterface>
            setErrors(errorData.data)
          }
        }

        return false
      }
    }

    return (
      <Form
        backendErrors={errors}
        onSubmit={handleSubmit}
      />
    )
  }
