import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Search } from '@/features/components/inputs/Search'
import React from 'react'
import { useSearch } from '@/utils/hooks/useSearch'
import { Table } from './partials'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { ButtonLink } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const AgreementListPage = () => {
  const { t } = useTranslation(['agreements'])
  const { setQueryParams, query } = useSearch({
    columnsForSearch: ['name'],
  })
  const { hasPermission } = usePermissions()
  const {
    handleToggleColumn,
    visibilityColumns,
    visibilityColumnsWithLabels,
    handleSetAvailableColumns,
  } = useColumnVisibility()

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <div className={'flex justify-between'}>
        {hasPermission(
          PermissionEnum.AGREEMENT_SAVE
        ) && (
          <ButtonLink
            variant={'contained'}
            className={
              'flex items-center gap-x-2'
            }
            to={'/agreements/create'}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className={'text-white'}
            />
            <span>
              {t('agreements:list.create')}
            </span>
          </ButtonLink>
        )}
        <div className={'flex gap-1'}>
          <Search onChange={setQueryParams} />
          <ColumnVisibility
            toggleColumn={handleToggleColumn}
            columns={visibilityColumnsWithLabels}
          />
        </div>
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t('agreements:list.title')}
            </span>
          </div>
          <Table
            setAvailableColumns={
              handleSetAvailableColumns
            }
            visibility={visibilityColumns}
            baseQuery={query}
          />
        </div>
      </Card>
    </div>
  )
}

export { AgreementListPage }
