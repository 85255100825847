import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { LoginScreen } from '@/features/auth/resources/views/login'
import AuthRoutes from '@/utils/routes/authRoutes'
import 'react-toastify/dist/ReactToastify.css'
import { PrivateRoutes } from '@/utils/routes/privateRoutes'
import { routes } from '@/utils/routes/privateRoutes/routes'
import { Container as ModalContainer } from 'react-modal-promise'
import { Error404 } from '@/features/errors'

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        className={'z-[9999999999]'}
        hideProgressBar={true}
        theme='colored'
      />
      <Routes>
        <Route element={<AuthRoutes />}>
          <Route
            element={<LoginScreen />}
            path={'/login'}
          />
        </Route>
        <Route
          element={
            <PrivateRoutes routes={routes} />
          }
        >
          {routes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={<route.component />}
            />
          ))}
        </Route>
        <Route
          element={<Error404 />}
          path={'/404'}
        />
        <Route
          element={<Error404 />}
          path={'*'}
        />
      </Routes>
      <ModalContainer isAppendIntances />
    </BrowserRouter>
  )
}

export default App
