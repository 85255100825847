import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  UpdateAdsRequestInterface,
  UpdateAdsValidationInterface,
  useGetAdsQuery,
  useUpdateAdsMutation,
} from '@/features/ads/ads/redux'
import useValidation from '@/utils/hooks/useValidation'
import {
  FormProvider,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { UpdateAdsValidation } from '@/features/ads/ads/resources/views/edit/rules/UpdateAdsValidation'
import React, { useEffect } from 'react'
import {
  associateValues,
  GeneralInfoForm,
  SlotForm,
} from '@/features/ads/ads/resources/components'
import { Button } from '@/features/components/buttons/button'
import { omit } from 'lodash'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import { useSearchAdsViewsQuery } from '@/features/ads/views/redux'

const AdsEditScreen = () => {
  const { t } = useTranslation(['ads'])
  const { id } = useParams<{ id: string }>() as {
    id: string
  }
  const { data: ad } = useGetAdsQuery(id)
  const { schema, defaultValues } = useValidation(
    new UpdateAdsValidation(),
    t
  )
  const methods =
    useForm<UpdateAdsValidationInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })
  const { setValue, handleSubmit, watch } =
    methods
  const [
    watchSlotId,
    watchSlotName,
    watchSlotType,
  ] = watch(['slot_id', 'slot_name', 'slot_type'])
  const [updateAds] = useUpdateAdsMutation()
  const { data: adsViews = [] } =
    useSearchAdsViewsQuery()

  useEffect(() => {
    if (ad) {
      associateValues(ad, setValue)
    }
  }, [ad])

  const onSubmit = async (
    data: UpdateAdsValidationInterface
  ) => {
    if (!isValidData(data)) {
      toast.error(t('ads:utils.empty_categories'))
      return
    }

    const transformedData: UpdateAdsRequestInterface =
      {
        ...omit(data, [
          'generate_form',
          'slot_name',
        ]),
        categories: data.categories.map(
          (category) => ({
            ...category,
            category_ids:
              category.category_ids.join(','),
          })
        ),
        view_ids: data.view_ids.join(','),
      }

    if (!transformedData.mobile_click_url) {
      transformedData.mobile_click_url =
        transformedData.desktop_click_url
    }

    try {
      await updateAds({
        body: serialize(transformedData, {
          indices: true,
          nullsAsUndefineds: true,
          booleansAsIntegers: true,
        }),
        id,
      }).unwrap()
      toast.success(t('ads:edit.success'))
    } catch (error) {
      // empty block
    }
  }

  const isValidData = (
    data: UpdateAdsValidationInterface
  ): boolean => {
    const requiredCategories = adsViews.filter(
      (view) =>
        data.categories.some(
          (category) =>
            category.view_id === view.id &&
            category.category_ids.length === 0
        )
    )

    return requiredCategories.length === 0
  }

  return (
    <div className={'p-8'}>
      <FormProvider {...methods}>
        <form
          className={
            'flex flex-col gap-2 mb-14 lg:mb-0'
          }
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={'py-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
          <GeneralInfoForm
            data={ad}
            title={t('ads:edit.title')}
          />
          <SlotForm
            slotType={watchSlotType}
            slotId={watchSlotId}
            slotName={watchSlotName}
          />
        </form>
      </FormProvider>
    </div>
  )
}

export { AdsEditScreen }
