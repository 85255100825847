import igritAPI from '@/utils/api/igrit'
import {
  GetInvoiceResponseInterface,
  GetInvoicesResponseInterface,
  GetInvoiceStatisticsResponseInterface,
  InvoiceInterface,
  SaveInvoiceItemRequestInterface,
  UpdateInvoiceRequestInterface,
} from '@/features/invoices/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetInvoiceStatisticsQuery,
  useLazyGetInvoiceStatisticsQuery,
  useStoreInvoiceItemMutation,
  useUpdateInvoiceItemMutation,
  useDeleteInvoiceItemMutation,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query<
      GetInvoicesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/invoices?${query}`,
      transformResponse: (
        response: GetInvoicesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Invoice', result),
    }),
    getInvoice: builder.query<
      InvoiceInterface,
      string | number
    >({
      query: (id) => `/admin/invoices/${id}`,
      transformResponse: (
        response: GetInvoiceResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('Invoice', result),
    }),
    updateInvoice: builder.mutation<
      void,
      {
        id: number | string
        body: UpdateInvoiceRequestInterface
      }
    >({
      query: ({ body, id }) => ({
        url: `/admin/invoices/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: 'Invoice', id: 'LIST' },
        { type: 'Invoice', id: id },
      ],
    }),
    deleteInvoice: builder.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/invoices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Invoice', id: 'LIST' },
      ],
    }),
    getInvoiceStatistics: builder.query<
      GetInvoiceStatisticsResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/invoices/statistics?${query}`,
      transformResponse: (
        response: GetInvoiceStatisticsResponseInterface
      ) => {
        return {
          net_value: Number(
            response.data.net_value
          ).toFixed(2),
          tax_value: Number(
            response.data.tax_value
          ).toFixed(2),
          gross_value: Number(
            response.data.gross_value
          ).toFixed(2),
          count: Number(
            response.data.count
          ).toFixed(0),
        }
      },
      providesTags: ['InvoiceStatistic'],
    }),
    storeInvoiceItem: builder.mutation<
      void,
      {
        invoiceId: number | string
        body: SaveInvoiceItemRequestInterface
      }
    >({
      query: ({ invoiceId, body }) => ({
        url: `/admin/invoices/${invoiceId}/items`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (
        _,
        error,
        { invoiceId }
      ) => [{ type: 'Invoice', id: invoiceId }],
    }),
    updateInvoiceItem: builder.mutation<
      void,
      {
        id: number | string
        invoiceId: number | string
        body: SaveInvoiceItemRequestInterface
      }
    >({
      query: ({ id, invoiceId, body }) => ({
        url: `/admin/invoices/${invoiceId}/items/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (
        _,
        error,
        { invoiceId }
      ) => [{ type: 'Invoice', id: invoiceId }],
    }),
    deleteInvoiceItem: builder.mutation<
      void,
      {
        id: number | string
        invoiceId: number | string
      }
    >({
      query: ({ id, invoiceId }) => ({
        url: `/admin/invoices/${invoiceId}/items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (
        _,
        error,
        { invoiceId }
      ) => [{ type: 'Invoice', id: invoiceId }],
    }),
  }),
})
