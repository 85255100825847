import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import {
  useDeleteAdsSlotMutation,
  useGetAdsSlotsQuery,
  useUpdateAdsSlotMutation,
} from '@/features/ads/slots/redux'
import { AdsSlotInterface } from '@/features/ads/slots/redux/types'
import { adsSlotFormModal } from '@/features/ads/slots/resources/components'
import {
  ResponseCodeEnum,
  ValidationErrorInterface,
} from '@/app/types'
import _ from 'lodash'
import { isFetchBaseQueryError } from '@/app/hooks'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { isCustomError } from '@/utils/api/rtkHelper'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'ads_slots',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      new Query()
        .page(pagination.pageIndex)
        .includes('views'),
    [pagination]
  )
  const { data: apiData } = useGetAdsSlotsQuery(
    query.url()
  )
  const [data, setData] = useState<
    AdsSlotInterface[]
  >([])
  const [deleteAdsSlot] =
    useDeleteAdsSlotMutation()
  const [updateAdsSlot] =
    useUpdateAdsSlotMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = async (id: number) => {
    try {
      await deleteAdsSlot(id).unwrap()
      toast.success(t('ads_slots:list.deleted'))
    } catch (error) {
      if (
        isCustomError(
          error,
          ResponseCodeEnum.AD_SLOT_CAN_NOT_BE_DELETED
        )
      ) {
        toast.error(
          t('ads_slots:list.can_not_be_deleted')
        )
      }
    }
  }

  const onEdit = (id: number) => {
    const row = data.find(
      (item) => item.id === id
    )

    adsSlotFormModal({
      title: t('ads_slots:edit.title'),
      data: row,
    }).then((data) =>
      updateAdsSlot({ data, id })
        .unwrap()
        .then(() =>
          toast.success(
            t('ads_slots:edit.success')
          )
        )
        .catch((error) => {
          if (
            isFetchBaseQueryError(error) &&
            error.status === 422
          ) {
            const errors =
              error.data as ValidationErrorInterface

            toast.error(() => (
              <ul
                className={
                  'flex flex-col gap-y-1'
                }
              >
                {_.map(
                  errors.errors,
                  (error, key) => (
                    <li key={key}>
                      {error.join(', ')}
                    </li>
                  )
                )}
              </ul>
            ))
          }
        })
    )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    manualPagination: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<AdsSlotInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    cell: ({ row }) =>
      row.original.type
        .toUpperCase()
        .replaceAll('_', ' '),
    enableHiding: true,
  }),
  columnBuilder.accessor('views', {
    id: 'views',
    header: t('form:labels.views'),
    cell: ({ row }) =>
      row.original.views
        ?.map((view) => view.name)
        .join(', '),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission: PermissionEnum.SLOT_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.SLOT_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
