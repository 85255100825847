import { MergedCategoriesType } from './mergeCategories'

export const findCategories = (
  mergedCategories: MergedCategoriesType,
  articleCategoryId: number,
  subcategory?: string | null,
  withSubCategories?: boolean
) =>
  mergedCategories.find((mergedCategory) => {
    if (withSubCategories) {
      return (
        mergedCategory.article_category_id ===
          articleCategoryId &&
        mergedCategory.subcategory === subcategory
      )
    } else {
      return (
        mergedCategory.article_category_id ===
        articleCategoryId
      )
    }
  })
