import igritAPI from '@/utils/api/igrit'
import {
  GetBlackListResponseInterface,
  GetBlackListRowResponseInterface,
  SaveBlackListRowRequestInterface,
} from '@/features/blacklist/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetBlackListQuery,
  useGetBlackListRowQuery,
  useStoreBlackListRowMutation,
  useUpdateBlackListRowMutation,
  useDeleteBlackListRowMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getBlackList: build.query<
      GetBlackListResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/blacklist?${query}`,
      transformResponse: (
        response: GetBlackListResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('BlackList', result),
    }),
    getBlackListRow: build.query<
      GetBlackListRowResponseInterface['data'],
      string | number
    >({
      query: (id) => `/admin/blacklist/${id}`,
      transformResponse: (
        response: GetBlackListRowResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('BlackList', result),
    }),
    storeBlackListRow: build.mutation<
      void,
      SaveBlackListRowRequestInterface
    >({
      query: (data) => ({
        url: '/admin/blacklist',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BlackList'],
    }),
    updateBlackListRow: build.mutation<
      void,
      {
        id: number | string
        data: SaveBlackListRowRequestInterface
      }
    >({
      query: ({ data, id }) => ({
        url: `/admin/blacklist/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['BlackList'],
    }),
    deleteBlackListRow: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/blacklist/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BlackList'],
    }),
  }),
})
