import igritAPI from '@/utils/api/igrit'
import {
  GetAttributeResponseInterface,
  GetAttributesResponseInterface,
  GetSearchAttributesResponseInterface,
  SaveAttributePropsInterface,
} from '@/features/posts/attributes/redux/types'
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useDeleteAttributeMutation,
  useGetAttributeQuery,
  useGetAttributesQuery,
  useStoreAttributeMutation,
  useUpdateAttributeMutation,
  useLazySearchAttributeQuery,
  useSearchAttributeQuery,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAttributes: builder.query<
      GetAttributesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/posts/attributes?${query}`,
      transformResponse: (
        response: GetAttributesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Attribute', result),
    }),
    getAttribute: builder.query<
      GetAttributeResponseInterface['data'],
      string | number
    >({
      query: (id) =>
        `/admin/posts/attributes/${id}`,
      transformResponse: (
        response: GetAttributeResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('Attribute', result),
    }),
    searchAttribute: builder.query<
      GetSearchAttributesResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/posts/attributes/search?${query}`,
      transformResponse: (
        response: GetSearchAttributesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setArrayCache('Attribute', result),
    }),
    storeAttribute: builder.mutation<
      void,
      SaveAttributePropsInterface
    >({
      query: (body) => ({
        url: `/admin/posts/attributes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'Attribute', id: 'LIST' },
      ],
    }),
    updateAttribute: builder.mutation<
      void,
      {
        id: number | string
        body: SaveAttributePropsInterface
      }
    >({
      query: ({ body, id }) => ({
        url: `/admin/posts/attributes/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (
        result,
        error,
        { id }
      ) => [
        { type: 'Attribute', id: 'LIST' },
        { type: 'Attribute', id },
      ],
    }),
    deleteAttribute: builder.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/posts/attributes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Attribute', id: 'LIST' },
      ],
    }),
  }),
})
