import React from 'react'
import { usePermissions } from '@/utils/hooks/usePermission'
import { useTranslation } from 'react-i18next'
import { Table } from './components/table'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { Button } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useStoreInternalQuotationApplicationMutation } from '@/features/internalQuotationsApplications/redux/internalQuoatationApplicationsApi'
import { internalQuotationApplicationModal } from '../../components/form'
import { Search } from '@/features/components/inputs/Search'
import { useSearch } from '@/utils/hooks/useSearch'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const InternalQuotationApplicationListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'internal_quotations_applications',
    ])
    const { hasPermission } = usePermissions()
    const [storeInternalQuotationApplication] =
      useStoreInternalQuotationApplicationMutation()
    const { query, setQueryParams } = useSearch({
      columnsForSearch: [
        'domain',
        'ip',
        'status',
        'id',
      ],
    })
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    const handleAddInternalQuotationApplication =
      async () => {
        try {
          const data =
            await internalQuotationApplicationModal(
              {
                title: t(
                  'internal_quotations_applications:create.title'
                ),
              }
            )
          await storeInternalQuotationApplication(
            data
          ).unwrap()
          toast.success(
            t(
              'internal_quotations_applications:create.success'
            )
          )
        } catch (error) {
          // empty block
        }
      }

    return (
      <div
        className={'flex flex-col gap-y-4 p-4'}
      >
        <div
          className={
            'flex flex-col gap-2 justify-between lg:flex-row'
          }
        >
          {hasPermission(
            PermissionEnum.INTERNAL_QUOTATION_API_SAVE
          ) && (
            <div>
              <Button
                variant={'contained'}
                className={
                  'flex items-center gap-x-2'
                }
                onClick={
                  handleAddInternalQuotationApplication
                }
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={'text-white'}
                />
                <span>
                  {t(
                    'internal_quotations_applications:list.create'
                  )}
                </span>
              </Button>
            </div>
          )}
          <div className={'flex gap-2'}>
            <Search onChange={setQueryParams} />
            <ColumnVisibility
              columns={
                visibilityColumnsWithLabels
              }
              toggleColumn={handleToggleColumn}
            />
          </div>
        </div>

        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t(
                  'internal_quotations_applications:list.title'
                )}
              </span>
            </div>
            <Table
              query={query}
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }

export { InternalQuotationApplicationListScreen }
