import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveServiceRequestInterface } from '@/features/services/redux/types'
import { ServiceNameEnum } from '@/features/posts/posts/redux/enums/service'
import { ServiceUnitEnum } from '@/features/services/redux/enums/serviceUnit'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import _ from 'lodash'

export default class SaveServiceValidation
  implements
    ValidationInterface<SaveServiceRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveServiceRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      system_name: yup
        .mixed<ServiceNameEnum>()
        .required(t('validation:required')),
      description: yup
        .string()
        .required(t('validation:required')),
      price: yup
        .number()
        .typeError(t('validation:required'))
        .required(t('validation:required'))
        .min(
          0.01,
          t('validation:min', { min: 0.01 })
        ),
      points: yup
        .number()
        .typeError(t('validation:required'))
        .nullable()
        .min(1, t('validation:min', { min: 1 }))
        .integer(t('validation:integer'))
        .transform((value) =>
          _.isNaN(value) ? null : value
        ),
      discounted_price: yup
        .number()
        .typeError(t('validation:required'))
        .nullable()
        .min(
          0.01,
          t('validation:min', { min: 0.01 })
        )
        .transform((value) =>
          _.isNaN(value) ? null : value
        ),
      discounted_points: yup
        .number()
        .typeError(t('validation:required'))
        .nullable()
        .min(1, t('validation:min', { min: 1 }))
        .integer(t('validation:integer'))
        .transform((value) =>
          _.isNaN(value) ? null : value
        ),
      quantity: yup
        .number()
        .typeError(t('validation:required'))
        .required(t('validation:required'))
        .min(1, t('validation:min', { min: 1 }))
        .integer(t('validation:integer')),
      standalone: yup
        .boolean()
        .required(t('validation:required')),
      unit: yup
        .mixed<ServiceUnitEnum>()
        .oneOf(Object.values(ServiceUnitEnum))
        .required(t('validation:required')),
    })
  }

  defaultValues(): SaveServiceRequestInterface {
    return {
      system_name: ServiceNameEnum.POST_REFRESH,
      description: null,
      price: '',
      points: '',
      discounted_price: '',
      discounted_points: '',
      quantity: '',
      standalone: false,
      unit: ServiceUnitEnum.DAY,
    }
  }
}
