import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveProtectionRequestInterface } from '@/features/protections/redux'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'

class SaveProtectionValidation
  implements
    ValidationInterface<SaveProtectionRequestInterface>
{
  constructor(
    private action: 'update' | 'create'
  ) {}

  defaultValues(): SaveProtectionRequestInterface {
    return {
      is_active: true,
      name: '',
      category_id: 0,
    }
  }

  rules(
    t: TFunction
  ): ObjectSchema<
    SaveProtectionRequestInterface,
    AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required'))
        .transform((value: string) =>
          value.trim()
        ),
      category_id: yup
        .number()
        .required(t('validation:required'))
        .notOneOf([0], t('validation:required')),
      is_active: yup.boolean().required(),
      file: yup
        .mixed<File>()
        .nullable()
        .when(this.action, {
          is: () => this.action === 'create',
          then: (schema) =>
            schema.required(
              t('validation:required')
            ),
        }),
    })
  }
}

export { SaveProtectionValidation }
