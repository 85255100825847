import React, { Fragment, useEffect } from 'react'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import { toRequestObject } from './helper'
import { match } from 'ts-pattern'
import {
  InternalQuotationCategoryInterface,
  SaveInternalQuotationCategoryInterface,
} from '@/features/internalQuotationCategories/redux/types'
import SaveInternalQuotationCategoryValidation from '../validations/SaveInternalQuotationCategoryValidation'
import { useUpdateInternalQuotationCategoryMutation } from '@/features/internalQuotationCategories/redux/internalQuotationCategoriesAPI'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { usePermissions } from '@/utils/hooks/usePermission'

type Props = {
  body: InternalQuotationCategoryInterface
  t: TFunction
  fieldName: keyof SaveInternalQuotationCategoryInterface
  quotationId: string | number
  permission: PermissionEnum
}

const RealtimeForm: React.FC<Props> = ({
  body,
  t,
  fieldName,
  quotationId,
  permission,
}): React.ReactNode => {
  const { schema, defaultValues } = useValidation(
    new SaveInternalQuotationCategoryValidation(),
    t
  )
  const { hasPermission } = usePermissions()
  const [
    updateInternalQuotationCategory,
    { isLoading },
  ] = useUpdateInternalQuotationCategoryMutation()

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SaveInternalQuotationCategoryInterface>(
    {
      resolver: yupResolver(schema),
      defaultValues,
    }
  )

  const _handleExecutionChange = async (
    data: SaveInternalQuotationCategoryInterface
  ) => {
    try {
      await updateInternalQuotationCategory({
        quotationId: quotationId,
        categoryId: body.id,
        data: {
          ...data,
        },
      }).unwrap()
      toast.success(
        t(
          'internal_quotations_categories:edit.success'
        )
      )
    } catch (error) {
      //empty block
    }
  }
  const handleExecutionChange = _.debounce(
    _handleExecutionChange,
    1500
  )

  useEffect(() => {
    reset(toRequestObject(body))
  }, [body])

  useEffect(() => {
    const subscription = watch(() => {
      if (Object.keys(errors).length) {
        return
      }

      handleSubmit(handleExecutionChange)()
    })
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  if (
    permission === undefined ||
    hasPermission(permission)
  )
    return (
      <Controller
        render={({
          field,
          fieldState: { error },
        }) => (
          <FormControl {...field} error={!!error}>
            <Input
              type={match(fieldName)
                .with(
                  'price_from',
                  () => 'number'
                )
                .otherwise(() => 'text')}
              endAdornment={
                isLoading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                  />
                )
              }
            />
            <FormHelperText
              message={error?.message}
            />
          </FormControl>
        )}
        name={fieldName}
        control={control}
      />
    )

  return (
    <Fragment>{`${body[fieldName]}`}</Fragment>
  )
}

export { RealtimeForm }
