import igritAPI from '@/utils/api/igrit'
import {
  ExternalQuotationInterface,
  GetExternalQuotationListResponseInterface,
  GetExternalQuotationResponseInterface,
  SaveExternalQuotationRequestInterface,
} from './types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetExternalQuotationsQuery,
  useGetExternalQuotationQuery,
  useUpdateExternalQuotationMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getExternalQuotations: build.query<
      GetExternalQuotationListResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/external-quotation-sources?${query}`,
      transformResponse: (
        response: GetExternalQuotationListResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache(
          'ExternalQuotation',
          result
        ),
    }),
    getExternalQuotation: build.query<
      ExternalQuotationInterface,
      string | number
    >({
      query: (id) =>
        `/admin/external-quotation-sources${id}`,
      transformResponse: (
        response: GetExternalQuotationResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'ExternalQuotation',
          result
        ),
    }),
    updateExternalQuotation: build.mutation<
      void,
      {
        id: string | number
        data: SaveExternalQuotationRequestInterface
      }
    >({
      query: ({ data, id }) => ({
        url: `/admin/external-quotation-sources/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ExternalQuotation'],
    }),
  }),
})
