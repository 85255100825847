import igritAPI from '@/utils/api/igrit'
import {
  AdsInterface,
  GetAdsListResponseInterface,
  GetAdsResponseInterface,
} from '@/features/ads/ads/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetAdsListQuery,
  useGetAdsQuery,
  useStoreCampaignMutation,
  useUpdateAdsMutation,
  useDeleteAdsMutation,
  useResetAdsStatisticsMutation,
  useUpdateAdsStatusMutation,
  useDeleteBatchAdsMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getAdsList: build.query<
      GetAdsListResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/advertisements?${query}`,
      transformResponse: (
        response: GetAdsListResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Ads', result),
    }),
    getAds: build.query<
      AdsInterface,
      string | number
    >({
      query: (id) =>
        `/admin/advertisements/${id}`,
      transformResponse: (
        response: GetAdsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache('Ads', result),
    }),
    storeCampaign: build.mutation<void, FormData>(
      {
        query: (body) => ({
          url: '/admin/campaigns',
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        invalidatesTags: ['Ads'],
      }
    ),
    updateAds: build.mutation<
      void,
      { id: number | string; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/admin/advertisements/${id}`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['Ads'],
    }),
    deleteAds: build.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/advertisements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ads'],
    }),
    resetAdsStatistics: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/advertisements/${id}/statistics`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ads'],
    }),
    updateAdsStatus: build.mutation<
      void,
      { status: boolean; id: number | string }
    >({
      query: ({ status, id }) => ({
        url: `/admin/advertisements/${id}/status`,
        method: 'PUT',
        body: { is_active: status },
      }),
      invalidatesTags: ['Ads'],
    }),
    deleteBatchAds: build.mutation<
      void,
      number[]
    >({
      query: (ids) => ({
        url: `/admin/advertisements/batch`,
        method: 'DELETE',
        body: { advertisement_ids: ids },
      }),
      invalidatesTags: ['Ads'],
    }),
  }),
})
