import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteCensorshipMutation,
  useGetCensorshipListQuery,
  useUpdateCensorshipMutation,
} from '@/features/censorship/redux/censorshipAPI'
import { CensorshipInterface } from '@/features/censorship/redux/types'
import { censorshipFormModal } from '@/features/censorship/resources/_components/form'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'censorship',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination.pageIndex]
  )
  const { data: apiData } =
    useGetCensorshipListQuery(query.url())
  const [data, setData] = useState<
    CensorshipInterface[]
  >([])
  const [deleteCensorship] =
    useDeleteCensorshipMutation()
  const [updateCensorship] =
    useUpdateCensorshipMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteCensorship(id)
      .unwrap()
      .then(() =>
        toast.success(
          t('censorship:list.deleted')
        )
      )
  }

  const onEdit = (id: number) => {
    const censorship = data.find(
      (item) => item.id === id
    )

    censorshipFormModal({
      title: t('censorship:edit.title'),
      data: censorship,
    }).then((data) =>
      updateCensorship({ body: data, id })
        .unwrap()
        .then(() =>
          toast.success(
            t('censorship:edit.success')
          )
        )
    )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableHiding: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<CensorshipInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('word', {
    id: 'word',
    header: t('form:labels.word'),
    enableHiding: true,
  }),
  columnBuilder.accessor('description', {
    id: 'description',
    header: t('form:labels.description'),
    enableHiding: true,
  }),
  columnBuilder.accessor(
    'is_enabled_in_comments',
    {
      id: 'is_enabled_in_comments',
      header: t(
        'form:labels.is_enabled_in_comments'
      ),
      cell: ({ row }) =>
        row.original.is_enabled_in_comments
          ? t('form:labels:yes')
          : t('form:labels:no'),
      enableHiding: true,
    }
  ),
  columnBuilder.accessor(
    'with_more_accurate_checking',
    {
      id: 'with_more_accurate_checking',
      header: t(
        'form:labels.with_more_accurate_checking'
      ),
      cell: ({ row }) =>
        row.original.with_more_accurate_checking
          ? t('form:labels:yes')
          : t('form:labels:no'),
      enableHiding: true,
    }
  ),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.CENSORSHIP_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.CENSORSHIP_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
