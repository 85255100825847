import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import { PackageInterface } from '@/features/packages/redux/types'
import {
  useDeletePackageMutation,
  useGetPackagesQuery,
} from '@/features/packages/redux/packageAPI'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination]
  )
  const { data: apiData } = useGetPackagesQuery(
    query.url()
  )
  const [data, setData] = useState<
    PackageInterface[]
  >([])
  const [deletePackage] =
    useDeletePackageMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deletePackage(id)
      .unwrap()
      .then(() =>
        toast.success(t('packages:list.deleted'))
      )
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<PackageInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    cell: ({ row }) =>
      t(
        `utils:package_types.${row.original.type}`
      ),
    enableHiding: true,
  }),
  columnBuilder.accessor('price', {
    id: 'price',
    header: t('form:labels.price'),
    cell: ({ row }) =>
      Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
      }).format(row.original.price),
    enableHiding: true,
  }),
  columnBuilder.accessor('points', {
    id: 'points',
    header: t('form:labels.points'),
    enableHiding: true,
  }),
  columnBuilder.accessor('discounted_price', {
    id: 'discounted_price',
    header: t('form:labels.discounted_price'),
    cell: ({ row }) =>
      row.original.discounted_price
        ? Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'PLN',
          }).format(row.original.price)
        : '-',
    enableHiding: true,
  }),
  columnBuilder.accessor('discounted_points', {
    id: 'discounted_points',
    header: t('form:labels.discounted_points'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/packages/${row.original.id}/edit`,
            permission:
              PermissionEnum.PACKAGE_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.PACKAGE_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
