import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import {
  BlackListRowType,
  BlackListRowTypes,
  SaveBlackListRowRequestInterface,
} from '@/features/blacklist/redux/types'
import Carbon from '@/utils/carbon'

export default class SaveBlackListRowValidation
  implements
    ValidationInterface<SaveBlackListRowRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveBlackListRowRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      value: yup
        .string()
        .required(t('validation.required')),
      type: yup
        .mixed<BlackListRowType>()
        .oneOf(BlackListRowTypes)
        .required(t('validation.required')),
      reason: yup.string().nullable(),
      expires_at: yup
        .string()
        .nullable()
        .transform((value: string) =>
          value
            ? new Carbon()
                .parse(value)
                .toISOString()
            : value
        ),
    })
  }

  defaultValues(): SaveBlackListRowRequestInterface {
    return {
      value: '',
      type: 'email',
      reason: '',
      expires_at: '',
    }
  }
}
