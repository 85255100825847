import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ChangePasswordRequestInterface,
  useChangeUserPasswordMutation,
} from '@/features/users/redux'
import {
  FormProvider,
  useForm,
} from 'react-hook-form'
import useValidation from '@/utils/hooks/useValidation'
import { ChangePasswordValidation } from '@/features/users/resources/rules'
import { yupResolver } from '@hookform/resolvers/yup'
import Card from '@/features/components/cards'
import { PasswordForm } from '@/features/users/resources/components'
import { Button } from '@/features/components/buttons/button'
import { toast } from 'react-toastify'

const ChangePasswordForm: FC<{
  id: number | string
}> = ({ id }): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'users',
  ])
  const [changePassword] =
    useChangeUserPasswordMutation()
  const { schema, defaultValues } = useValidation(
    new ChangePasswordValidation(),
    t
  )
  const methods =
    useForm<ChangePasswordRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  const onSubmit = async (
    data: ChangePasswordRequestInterface
  ) => {
    try {
      await changePassword({
        body: data,
        id,
      }).unwrap()
      toast.success(
        t('users:form.change_password.success')
      )
      methods.reset(defaultValues)
    } catch (error) {
      // empty block
    }
  }

  return (
    <Card>
      <span className={'font-semibold text-lg'}>
        {t('users:form.change_password.title')}
      </span>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(
            onSubmit
          )}
          className={'flex flex-col gap-4 pt-4'}
        >
          <PasswordForm />
          <div className={'flex pt-8'}>
            <Button
              variant={'contained'}
              size={'small'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  )
}

export { ChangePasswordForm }
