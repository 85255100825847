import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { ButtonLink } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './/components/table'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { Search } from '@/features/components/inputs/Search'
import { SearchCategory } from '@/features/components/inputs/asyncSelect/searchCategory'
import { useSearch } from '@/utils/hooks/useSearch'
import { useSearchParams } from 'react-router-dom'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

export const PostCategoryListScreen =
  (): React.ReactNode => {
    const [searchParams] = useSearchParams()
    const { t } = useTranslation([
      'posts/categories',
    ])
    const { query, setQueryParams } = useSearch({
      columnsForSearch: [
        'name',
        'parentCategory.name',
      ],
    })
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()
    const { hasPermission } = usePermissions()

    return (
      <div
        className={'flex flex-col p-8 gap-y-4'}
      >
        <div
          className={
            'flex flex-col lg:flex-row-reverse gap-4'
          }
        >
          {hasPermission(
            PermissionEnum.CATEGORY_SAVE
          ) && (
            <ButtonLink
              variant={'contained'}
              className={
                'flex items-center gap-x-2'
              }
              to={'/posts/categories/create'}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={'text-white'}
              />
              <span>
                {t(
                  'posts/categories:list.create'
                )}
              </span>
            </ButtonLink>
          )}
        </div>
        <div
          className={
            'flex flex-col lg:flex-row lg:justify-between lg:items-center gap-4'
          }
        >
          <div className={'lg:w-[400px]'}>
            <SearchCategory
              defaultValue={searchParams.get(
                'parent_id'
              )}
              onChange={(value) =>
                setQueryParams({
                  parent_id: value?.value,
                })
              }
              isMulti={false}
            />
          </div>
          <div
            className={
              'flex flex-col md:flex-row gap-2'
            }
          >
            <Search onChange={setQueryParams} />
            <ColumnVisibility
              columns={
                visibilityColumnsWithLabels
              }
              toggleColumn={handleToggleColumn}
            />
          </div>
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t('posts/categories:list.title')}
              </span>
            </div>
            <Table
              query={query}
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }
