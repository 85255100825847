import {
  PostInterface,
  UpdatePostDataRequest,
} from '@/features/posts/posts/redux/types'
import { UseFormSetValue } from 'react-hook-form'

export const associateValues = (
  data: PostInterface,
  setValue: UseFormSetValue<UpdatePostDataRequest>
): void => {
  setValue('content', data.content)
  setValue('category_id', data.category.id)
  setValue(
    'is_price_negotiable',
    data.is_price_negotiable
  )
  setValue('price', data.price)
  setValue('quantity', data.quantity)
  setValue('phone_visible', data.phone_visible)
  setValue('email_visible', data.email_visible)
  setValue('status', data.status)
  setValue(
    'whatsapp_available',
    data.whatsapp_available
  )
  setValue(
    'messages_enabled',
    data.messages_enabled
  )
  setValue('title', data.title)

  if (data.unit) {
    // setValue("unit", data.unit.id)
  }

  if (data.quantity_unit) {
    // setValue("quantity_unit", data.quantity_unit.id)
  }
}
