import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './components/table'
import Card from '@/features/components/cards'
import { Search } from '@/features/components/inputs/Search'
import { useSearch } from '@/utils/hooks/useSearch'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const ArticleCommentsListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'article_comments',
    ])

    const { query, setQueryParams } = useSearch({
      columnsForSearch: [
        'content',
        'article.title',
        'article.id',
        'user.name',
        'user.email',
        'guest_user_data->email',
        'guest_user_data->name',
      ],
    })

    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    return (
      <div
        className={'flex flex-col gap-y-4 p-4'}
      >
        <div className={'flex gap-2 justify-end'}>
          <Search onChange={setQueryParams} />
          <ColumnVisibility
            columns={visibilityColumnsWithLabels}
            toggleColumn={handleToggleColumn}
          />
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t('article_comments:list.title')}
              </span>
            </div>
            <Table
              query={query}
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }

export { ArticleCommentsListScreen }
