import igritAPI from '@/utils/api/igrit'
import {
  CensorshipInterface,
  GetCensorshipResponseInterface,
  GetListCensorshipResponseInterface,
  SaveCensorshipRequestInterface,
} from '@/features/censorship/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetCensorshipQuery,
  useStoreCensorshipMutation,
  useUpdateCensorshipMutation,
  useDeleteCensorshipMutation,
  useGetCensorshipListQuery,
} = igritAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCensorshipList: builder.query<
      GetListCensorshipResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/censorship?${query}`,
      transformResponse: (
        response: GetListCensorshipResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Censorship', result),
    }),
    getCensorship: builder.query<
      CensorshipInterface,
      number | string
    >({
      query: (id) => `/admin/censorship/${id}`,
      transformResponse: (
        response: GetCensorshipResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'Censorship',
          result
        ),
    }),
    storeCensorship: builder.mutation<
      void,
      SaveCensorshipRequestInterface
    >({
      query: (body) => ({
        url: '/admin/censorship',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'Censorship', id: 'LIST' },
      ],
    }),
    updateCensorship: builder.mutation<
      void,
      {
        id: number | string
        body: SaveCensorshipRequestInterface
      }
    >({
      query: ({ id, body }) => ({
        url: `/admin/censorship/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: 'Censorship', id: 'LIST' },
        { type: 'Censorship', id },
      ],
    }),
    deleteCensorship: builder.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/censorship/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Censorship', id: 'LIST' },
      ],
    }),
  }),
})
