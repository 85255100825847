import { TFunction } from 'i18next'
import * as yup from 'yup'
import { UpdateAttributeInterface } from '@/features/posts/posts/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { AttributeTypeEnum } from '@/features/posts/attributes/redux/enums/attributeType'
import _ from 'lodash'

class UpdateAttributeValidation
  implements
    ValidationInterface<UpdateAttributeInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    UpdateAttributeInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      attribute_category_id: yup
        .number()
        .required(),
      type: yup
        .mixed<AttributeTypeEnum>()
        .required(),
      value: yup
        .mixed<
          | string
          | number
          | boolean
          | { from: string; to: string }
        >()
        .nullable()
        .when('type', {
          is: (type: AttributeTypeEnum) =>
            ![
              AttributeTypeEnum.SELECT,
              AttributeTypeEnum.MULTI_SELECT,
              AttributeTypeEnum.CERTIFICATE,
            ].includes(type),
          then: (yup) =>
            yup.required(
              t('validation:required')
            ),
        })
        .test(
          'is-valid',
          t('validation:wrong_format'),
          (
            value,
            { createError, path, parent }
          ) => {
            const type =
              parent.type as AttributeTypeEnum

            if (
              type ===
              AttributeTypeEnum.PIORIN_FORMAT
            ) {
              const formatValidation = new RegExp(
                /^PL-\d{2}\/\d{2}\/\d{4}$/
              ).test(value as string)

              if (!formatValidation) {
                return createError({
                  path,
                  message: t(
                    'validation:invalid_format',
                    {
                      format: 'PL-XX/XX/XXXX',
                    }
                  ),
                })
              }

              return true
            }

            if (
              type ===
              AttributeTypeEnum.YEAR_FORMAT
            ) {
              return (
                new RegExp(/^\d{4}$/).test(
                  value as string
                ) &&
                Number(value) >= 1900 &&
                Number(value) <= 2100
              )
            }

            if (
              type === AttributeTypeEnum.RANGE
            ) {
              return (
                typeof value === 'object' &&
                !_.isNil(value) &&
                Number(value.from) <=
                  Number(value.to)
              )
            }

            return true
          }
        ),
      value_ids: yup
        .string()
        .nullable()
        .when('type', {
          is: (value: AttributeTypeEnum) =>
            value ===
            AttributeTypeEnum.MULTI_SELECT,
          then: (yup) =>
            yup.required(
              t('validation:required')
            ),
        }),
      value_id: yup
        .number()
        .nullable()
        .when('type', {
          is: (value: AttributeTypeEnum) =>
            value === AttributeTypeEnum.SELECT ||
            value ===
              AttributeTypeEnum.CERTIFICATE,
          then: (yup) =>
            yup.required(
              t('validation:required')
            ),
        }),
      name: yup.string().required(),
      human_value: yup
        .mixed<
          | string
          | number
          | boolean
          | { from: string; to: string }
        >()
        .required(),
    })
  }

  defaultValues(): UpdateAttributeInterface {
    return {
      attribute_category_id: 0,
      name: '',
      human_value: '',
      type: AttributeTypeEnum.STRING,
    }
  }
}

export { UpdateAttributeValidation }
