import { TFunction } from 'i18next'
import * as yup from 'yup'
import { UpdatePostDataRequest } from '@/features/posts/posts/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { UnitEnum } from '@/app/enums/unitEnum'
import { PostTypeEnum } from '@/features/posts/posts/redux/enums/type'
import { UpdateAttributeValidation } from '@/features/posts/posts/resources/views/edit/_components'
import { PostStatusEnum } from '@/features/posts/posts/redux/enums/status'

class UpdatePostValidation
  implements
    ValidationInterface<UpdatePostDataRequest>
{
  attributeValidation =
    new UpdateAttributeValidation()

  rules(
    t: TFunction
  ): yup.ObjectSchema<
    UpdatePostDataRequest,
    yup.AnyObject
  > {
    return yup.object().shape({
      category_id: yup
        .number()
        .required(t('validation:required')),
      is_price_negotiable: yup
        .boolean()
        .required(),
      price: yup
        .number()
        .nullable()
        .min(
          0.01,
          t('validation:min', { min: 0.01 })
        )
        .transform((value) =>
          Number.isNaN(value) ? null : value
        )
        .test(
          'max_decimals',
          t('validation:max_decimals', {
            max: 2,
          }),
          (value) => {
            if (
              value === null ||
              value === undefined
            )
              return true
            const stringValue = value.toString()
            const decimalIndex =
              stringValue.indexOf('.')
            if (decimalIndex !== -1) {
              const decimalPlaces =
                stringValue.length -
                decimalIndex -
                1
              return decimalPlaces <= 2
            }
            return true
          }
        ),
      content: yup
        .string()
        .required(t('validation:required'))
        .min(
          20,
          t('validation:field_min_length', {
            length: 20,
          })
        ),
      unit: yup.mixed<UnitEnum>().nullable(),
      title: yup
        .string()
        .required(t('validation:required')),
      quantity: yup
        .number()
        .nullable()
        .min(1, t('validation:min', { min: 1 })),
      quantity_unit: yup
        .mixed<UnitEnum>()
        .nullable(),
      email_visible: yup.boolean().required(),
      phone_visible: yup.boolean().required(),
      whatsapp_available: yup
        .boolean()
        .required(),
      messages_enabled: yup.boolean().required(),
      archived: yup.boolean().required(),
      type: yup.mixed<PostTypeEnum>().required(),
      location_name: yup
        .string()
        .required(t('validation:required')),
      location: yup.object().shape({
        lat: yup
          .number()
          .required(t('validation:required')),
        long: yup
          .number()
          .required(t('validation:required')),
      }),
      attributes: yup
        .array()
        .of(this.attributeValidation.rules(t))
        .nullable(),
      status: yup
        .mixed<PostStatusEnum>()
        .required(t('validation:required')),
    })
  }

  defaultValues(): UpdatePostDataRequest {
    return {
      location_name: '',
      location: {
        lat: 0,
        long: 0,
      },
      category_id: 0,
      title: '',
      price: 0,
      is_price_negotiable: false,
      content: '',
      quantity: 0,
      archived: false,
      email_visible: false,
      phone_visible: false,
      whatsapp_available: false,
      messages_enabled: false,
      type: PostTypeEnum.SELL,
      status: PostStatusEnum.BLOCKED,
    }
  }
}

export { UpdatePostValidation }
