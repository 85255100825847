import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Search } from '@/features/components/inputs/Search'
import React, { useRef } from 'react'
import { useSearch } from '@/utils/hooks/useSearch'
import {
  accountingReportStoreModal,
  Table,
} from './partials'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { Button } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  ColumnVisibility,
  FilterBuilder,
  Filters,
  useColumnVisibility,
  useFilters,
} from '@/components'
import { Transition } from 'react-transition-group'

const AccountingReportListPage = () => {
  const { t } = useTranslation([
    'accounting_reports',
  ])
  const { setQueryParams, query } = useSearch({
    columnsForSearch: [
      'creator.name',
      'creator.email',
      'sender.name',
      'sender.email',
      'refresher.name',
      'refresher.email',
    ],
  })
  const { hasPermission } = usePermissions()
  const {
    handleToggleColumn,
    visibilityColumns,
    visibilityColumnsWithLabels,
    handleSetAvailableColumns,
  } = useColumnVisibility()
  const filters = useRef(filterBuilder).current
  const { ref, TriggerFilters, showFilters } =
    useFilters()

  const handleCreateReport = async () => {
    await accountingReportStoreModal()
  }

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <Transition
        nodeRef={ref}
        unmountOnExit={true}
        in={showFilters}
        timeout={200}
        classNames={'transition-height'}
      >
        <Filters
          filterBuilder={filters}
          onSubmit={setQueryParams}
        />
      </Transition>
      <div
        className={
          'flex flex-col gap-4 lg:flex-row lg:justify-between'
        }
      >
        <div className={'flex gap-1'}>
          {hasPermission(
            PermissionEnum.ACCOUNTING_REPORT_SAVE
          ) && (
            <Button
              variant={'contained'}
              className={
                'flex items-center gap-x-2'
              }
              onClick={handleCreateReport}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={'text-white'}
              />
              <span>
                {t(
                  'accounting_reports:list.create'
                )}
              </span>
            </Button>
          )}
          <TriggerFilters />
        </div>
        <div className={'flex gap-1'}>
          <Search onChange={setQueryParams} />
          <ColumnVisibility
            toggleColumn={handleToggleColumn}
            columns={visibilityColumnsWithLabels}
          />
        </div>
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t('accounting_reports:list.title')}
            </span>
          </div>
          <Table
            setAvailableColumns={
              handleSetAvailableColumns
            }
            visibility={visibilityColumns}
            baseQuery={query}
          />
        </div>
      </Card>
    </div>
  )
}

const filterBuilder = new FilterBuilder()
  .addFilter({
    field: 'sent',
    component: 'select',
    props: {
      label: 'form:labels.only_sent',
    },
    optionsProps: {
      options: [
        {
          label: 'form:labels.yes',
          value: 1,
        },
        {
          label: 'form:labels.no',
          value: 0,
        },
      ],
      translate: true,
    },
  })
  .addFilter({
    field: 'date_from',
    component: 'date',
    props: {
      label: 'form:labels.date_from',
    },
  })
  .addFilter({
    field: 'date_to',
    component: 'date',
    props: {
      label: 'form:labels.date_to',
    },
  })

export { AccountingReportListPage }
