import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SaveArticleRequestInterface } from '@/features/articles/redux/types'
import Form from '@/features/articles/resources/components/Form/Form'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import { useStoreArticleMutation } from '@/features/articles/redux/articlesAPI'
import { useNavigate } from 'react-router-dom'
import { isFetchBaseQueryError } from '@/app/hooks'
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
} from '@/app/types'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'

const ArticleCreateScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'articles',
      'utils',
    ])
    const [storeArticle] =
      useStoreArticleMutation()
    const navigate = useNavigate()
    const [errors, setErrors] =
      useState<
        BackendValidationErrorInterface<SaveArticleRequestInterface>
      >()

    const handleSubmit = async (
      data: SaveArticleRequestInterface
    ) => {
      const formData = serialize(data, {
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
        indices: true,
      })

      try {
        await storeArticle(formData).unwrap()
        toast.success(
          t('articles:create.success')
        )
        navigate('/articles')
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          const errorData =
            error as ErrorResponseInterface

          if (errorData.status === 422) {
            const errorData =
              error as ErrorValidationResponseInterface<SaveArticleRequestInterface>
            setErrors(errorData.data)
          }
        }
      }
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('articles:create.title')}
        </span>
        <Form
          onSubmit={handleSubmit}
          backendErrors={errors}
        />
      </div>
    )
  }

export { ArticleCreateScreen }
