import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  AdressForm,
  BaseDataForm,
  ChangePasswordForm,
  ChangeRoleForm,
  UserLogs,
} from './partials'
import { useGetUserQuery } from '@/features/users/redux'
import { ChangeCompanyForm } from './partials/Company'
import { PostsForUser } from '@/features/users/resources/views/edit/Posts'

const UserEditScreen = (): React.ReactNode => {
  const { id } = useParams<{ id: string }>() as {
    id: string
  }
  const { t } = useTranslation(['users'])
  const { data } = useGetUserQuery(id)

  return (
    <div className={'flex flex-col gap-y-6 p-4'}>
      <span className={'text-2xl font-semibold'}>
        {t('users:edit.title')} - {data?.name}
      </span>
      <div
        className={'grid lg:grid-cols-2 gap-4'}
      >
        <div
          className={'col-span-2 xl:col-span-1'}
        >
          <BaseDataForm data={data} userId={id} />
        </div>

        <div
          className={
            'flex flex-col gap-4 col-span-2 xl:col-span-1'
          }
        >
          <div className={'flex flex-col gap-4'}>
            <ChangePasswordForm id={id} />
          </div>
          <div className={'flex flex-col gap-4'}>
            <ChangeRoleForm data={data} id={id} />
          </div>
        </div>

        <div
          className={
            'flex flex-col gap-4 col-span-2 xl:col-span-1'
          }
        >
          <AdressForm userId={id} />
        </div>
        <div
          className={
            'flex flex-col gap-4 col-span-2 xl:col-span-1'
          }
        >
          <UserLogs userId={id} />
        </div>
        <div
          className={
            'flex flex-col gap-4 col-span-2 xl:col-span-1'
          }
        >
          <ChangeCompanyForm
            data={data}
            userId={id}
          />
        </div>
      </div>
      <PostsForUser />
    </div>
  )
}

export { UserEditScreen }
