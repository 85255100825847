import {
  QuotationApplicationInterface,
  SaveQuotationApplicationsRequestInterface,
} from '@/features/internalQuotationsApplications/redux/types'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { Button } from '@/features/components/buttons/button'
import { FormControl } from '@mui/base'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import _ from 'lodash'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Input } from '@/features/components/inputs/input'
import {
  QuotationApplicationStatusEnum,
  QuotationApplicationTypeEnum,
} from '@/features/internalQuotationsApplications/redux/enums'
import SaveQuotationApplicationValidation from './validations/SaveQuotationApplicationValidation'

type Props = {
  data?: QuotationApplicationInterface
  title: string
} & InstanceProps<
  SaveQuotationApplicationsRequestInterface,
  unknown
>

const Form: React.FC<Props> = ({
  data,
  isOpen,
  onReject,
  onResolve,
  title,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveQuotationApplicationValidation(),
    t
  )

  const { control, setValue, handleSubmit } =
    useForm<SaveQuotationApplicationsRequestInterface>(
      {
        resolver: yupResolver(schema),
        defaultValues,
      }
    )

  useEffect(() => {
    if (data) {
      setValue('domain', data.domain)
      setValue('status', data.status)
      setValue('type', data.type)
    }
  }, [data])

  return (
    <Modal open={isOpen} onClose={() => onReject}>
      <Modal.Content size={'w-[500px]'}>
        <div className={'flex flex-col gap-y-6'}>
          <ModalTitle
            title={title}
            onClose={onReject}
          />
          <form
            className={'flex flex-col gap-y-8'}
            onSubmit={handleSubmit(onResolve)}
          >
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <Input
                    label={t(
                      'form:labels.domain'
                    )}
                  ></Input>
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'domain'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl error={!!error}>
                  <Select
                    value={field.value}
                    onChange={(_, value) =>
                      field.onChange(value)
                    }
                    label={t(
                      'form:labels.status'
                    )}
                  >
                    {_.map(
                      QuotationApplicationStatusEnum,
                      (value, index) => (
                        <Option
                          value={value}
                          key={index}
                        >
                          {t(
                            `utils:internal_quotations_applications.status.${value}`
                          )}
                        </Option>
                      )
                    )}
                  </Select>
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'status'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl error={!!error}>
                  <Select
                    value={field.value}
                    onChange={(_, value) =>
                      field.onChange(value)
                    }
                    label={t(
                      'form:labels.application_type'
                    )}
                  >
                    {_.map(
                      QuotationApplicationTypeEnum,
                      (value, index) => (
                        <Option
                          value={value}
                          key={index}
                        >
                          {t(
                            `utils:internal_quotations_applications.types.${value}`
                          )}
                        </Option>
                      )
                    )}
                  </Select>
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'type'}
              control={control}
            />
            <div className={'flex gap-x-4 pt-4'}>
              <Button
                variant={'contained'}
                type={'submit'}
                className={'w-full'}
              >
                {t('form:buttons.save')}
              </Button>
              <Button
                variant={'outlined'}
                onClick={() => onReject()}
                className={'w-full'}
              >
                {t('form:buttons.cancel')}
              </Button>
            </div>
          </form>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const internalQuotationApplicationModal =
  create(Form)
