import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { SaveInternalQuotationInterface } from "@/features/internalQuotations/redux/types"
import { Form } from "../../components/form"
import Card from "@/features/components/cards"
import {
  useGetInternalQuotationQuery,
  useUpdateInternalQuotationMutation,
} from "@/features/internalQuotations/redux/internalQuotationAPI"
import { isFetchBaseQueryError } from "@/app/hooks"
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
} from "@/app/types"
import { BackendValidationErrorInterface } from "@/utils/hooks/useValidation/types"

const EditInternalQuotationScreen = (): React.ReactNode => {
  const { id } = useParams() as { id: string }
  const [updateInternalQuotation] = useUpdateInternalQuotationMutation()
  const { data } = useGetInternalQuotationQuery(id)
  const { t } = useTranslation(["internal_quotations"])
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveInternalQuotationInterface>>()

  const onSubmit = async (data: SaveInternalQuotationInterface) => {
    try {
      await updateInternalQuotation({ data, id }).unwrap()
      toast.success(t("internal_quotations:edit.success"))
      navigate(`/internal-quotations?`)
      return true
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        const errorData = error as ErrorResponseInterface

        if (errorData.status === 422) {
          const errorData =
            error as ErrorValidationResponseInterface<SaveInternalQuotationInterface>
          setErrors(errorData.data)
        }
      }
      return false
    }
  }
  return (
    <div className={"flex flex-col p-4 gap-y-8"}>
      <span className={"text-2xl font-medium"}>
        {t("internal_quotations:edit.title")}
      </span>
      <Card>
        <Form backendErrors={errors} onSubmit={onSubmit} data={data} />
      </Card>
    </div>
  )
}

export { EditInternalQuotationScreen }
