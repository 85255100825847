import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { Button } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './_components/table'
import { useStoreServiceCategoryMutation } from '@/features/services/redux/serviceAPI'
import { serviceCategoryForm } from '../_components/form'
import { toast } from 'react-toastify'

type Props = {
  serviceId: number | string
}

export const ServiceCategoryList: React.FC<
  Props
> = ({ serviceId }): React.ReactNode => {
  const { t } = useTranslation('services')
  const [storeServiceCategory] =
    useStoreServiceCategoryMutation()

  const handleStoreServiceCategory = () => {
    serviceCategoryForm({
      title: t(
        'services:categories.create.title'
      ),
    }).then((data) => {
      storeServiceCategory({
        id: serviceId,
        body: data,
      })
        .unwrap()
        .then(() =>
          toast.success(
            t(
              'services:categories.create.success'
            )
          )
        )
    })
  }

  return (
    <div className={'flex flex-col gap-y-4'}>
      <div className={'flex flex-row-reverse'}>
        <Button
          variant={'contained'}
          className={'flex items-center gap-x-2'}
          onClick={handleStoreServiceCategory}
        >
          <FontAwesomeIcon
            icon={faPlus}
            className={'text-white'}
          />
          <span>
            {t('services:categories.list.create')}
          </span>
        </Button>
      </div>
      <Card margin noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t(
                'services:categories.list.title'
              )}
            </span>
          </div>
          <Table serviceId={serviceId} />
        </div>
      </Card>
    </div>
  )
}
