import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  PostUserInterface,
  UpdatePostUserRequestInterface,
} from '@/features/posts/posts/redux/types'
import { Form } from './form/index'
import { useUpdatePostUserMutation } from '@/features/posts/posts/redux/postAPI'
import { toast } from 'react-toastify'
import { AddressList } from './addresses'
import { AgreementList } from './agreements'

type Props = {
  postId: number | string
  data: PostUserInterface
}

export const PostUser: React.FC<Props> = ({
  postId,
  data,
}): React.ReactNode => {
  const { t } = useTranslation(['posts/posts'])
  const { id } = data
  const [updateUser] = useUpdatePostUserMutation()

  const handleUpdate = (
    data: UpdatePostUserRequestInterface
  ) => {
    updateUser({ postId, id, body: data })
      .unwrap()
      .then(() =>
        toast.success(
          t('posts/posts:edit.users.edit.success')
        )
      )
  }

  return (
    <div className={'grid lg:grid-cols-2 gap-4'}>
      <Form data={data} onSubmit={handleUpdate} />
      <AddressList addresses={data.addresses} />
      <AgreementList
        agreements={data.agreements}
      />
    </div>
  )
}
