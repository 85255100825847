import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  useGetAttributeCategoryQuery,
  useUpdateAttributeCategoryMutation,
} from '@/features/posts/attributeCategories/redux/attributeCategoryAPI'
import { Form } from '@/features/posts/attributeCategories/resources/components/form/Form'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import { isFetchBaseQueryError } from '@/app/hooks'
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
  ResponseCodeEnum,
} from '@/app/types'
import { SaveAttributeInterface } from '@/features/posts/attributeCategories/redux/types'
import { useHelper } from '@/features/posts/attributeCategories/hooks/useHelper'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'

const ns = 'posts/attribute_categories'

export const PostAttributeCategoryEditScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([ns])
    const { id } = useParams() as { id: string }
    const { data } =
      useGetAttributeCategoryQuery(id)
    const [updateAttributeCategory] =
      useUpdateAttributeCategoryMutation()
    const { transformToUpdate } = useHelper()
    const [errors, setErrors] =
      useState<
        BackendValidationErrorInterface<SaveAttributeInterface>
      >()

    const handleSubmit = async (
      data: SaveAttributeInterface
    ): Promise<boolean> => {
      const formData = serialize(
        transformToUpdate(data),
        {
          nullsAsUndefineds: true,
          booleansAsIntegers: true,
          indices: true,
        }
      )

      formData.append('_method', 'PUT')

      try {
        await updateAttributeCategory({
          id,
          body: formData,
        }).unwrap()
        toast.success(t(`${ns}:edit.success`))
        return true
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          const errorData =
            error as ErrorResponseInterface

          if (
            errorData.status === 400 &&
            errorData.data &&
            errorData.data.code ===
              ResponseCodeEnum.ATTRIBUTE_CATEGORY_BINDING_EXIST
          ) {
            toast.error(t(`${ns}:binding_exist`))
          }

          if (errorData.status === 422) {
            const errorData =
              error as ErrorValidationResponseInterface<SaveAttributeInterface>
            setErrors(errorData.data)
          }
        }
      }

      return false
    }

    return (
      <Form
        backendErrors={errors}
        onSubmit={handleSubmit}
        data={data}
      />
    )
  }
