import React from 'react'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { useTranslation } from 'react-i18next'
import { Button } from '@/features/components/buttons/button'

const OpenInBlankModal: React.FC<
  InstanceProps<boolean>
> = ({ isOpen, onReject, onResolve }) => {
  const { t } = useTranslation(['utils'])

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={t('utils:open_in_blank.title')}
          onClose={() => onReject()}
        />
        <div
          className={'flex flex-col gap-y-8 pt-4'}
        >
          <span>
            {t('utils:open_in_blank.message')}
          </span>
          <div className={'flex gap-x-4'}>
            <Button
              variant={'contained'}
              className={
                'w-full ripple-bg-primary-600'
              }
              size={'small'}
              onClick={() => onResolve(true)}
            >
              {t('form:labels.yes')}
            </Button>
            <Button
              variant={'contained'}
              className={
                'w-full !ripple-bg-red-600'
              }
              size={'small'}
              onClick={() => onResolve(false)}
            >
              {t('form:labels.no')}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const openInBlank = create(
  OpenInBlankModal
)
