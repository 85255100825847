import igritAPI from '@/utils/api/igrit'
import {
  GetInternalQuotationResultListResponseInterface,
  GetInternalQuotationResultResponseInterface,
  InternalQuotationResultInterface,
  SaveInternalQuotationResultRequestInterface,
} from './types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useDeleteInternalQuotationResultMutation,
  useGetInternalQuotationResultsQuery,
  useGetInternalQuotationResultQuery,
  useUpdateInternalQuotationResultMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getInternalQuotationResults: build.query<
      GetInternalQuotationResultListResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/internal-quotation-results?${query}`,
      transformResponse: (
        response: GetInternalQuotationResultListResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache(
          'InternalQuotationResult',
          result
        ),
    }),
    getInternalQuotationResult: build.query<
      InternalQuotationResultInterface,
      string | number
    >({
      query: (id) =>
        `/admin/internal-quotation-results/${id}`,
      transformResponse: (
        response: GetInternalQuotationResultResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'InternalQuotationResult',
          result
        ),
    }),
    updateInternalQuotationResult: build.mutation<
      void,
      {
        id: string | number
        data: SaveInternalQuotationResultRequestInterface
      }
    >({
      query: ({ data, id }) => ({
        url: `/admin/internal-quotation-results/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [
        'InternalQuotationResult',
      ],
    }),
    deleteInternalQuotationResult: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/internal-quotation-results/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'InternalQuotationResult',
      ],
    }),
  }),
})
