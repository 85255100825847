import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useStoreServiceMutation } from '@/features/services/redux/serviceAPI'
import { SaveServiceRequestInterface } from '@/features/services/redux/types'
import { Form } from '@/features/services/resources/_components/form'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'
import { isFetchBaseQueryError } from '@/app/hooks'
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
} from '@/app/types'

export const ServiceCreateScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['services'])
    const [storeService] =
      useStoreServiceMutation()
    const navigate = useNavigate()
    const [errors, setErrors] =
      useState<
        BackendValidationErrorInterface<SaveServiceRequestInterface>
      >()

    const handleSubmit = async (
      data: SaveServiceRequestInterface
    ) => {
      try {
        await storeService(data).unwrap()
        toast.success(
          t('services:create.success')
        )
        navigate('/services')
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          const errorData =
            error as ErrorResponseInterface

          if (errorData.status === 422) {
            const errorData =
              error as ErrorValidationResponseInterface<SaveServiceRequestInterface>
            setErrors(errorData.data)
          }
        }
      }
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('services:create.title')}
        </span>
        <Card>
          <Form
            errors={errors}
            onSubmit={handleSubmit}
          />
        </Card>
      </div>
    )
  }
