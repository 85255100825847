import { FC, Fragment, ReactNode } from 'react'
import { useFormControlContext } from '@mui/base'
import { FormHelperTextTypes } from './FormHelperText.types'
import clsx from 'clsx'

const FormHelperText: FC<FormHelperTextTypes> = ({
  error,
  message,
  className,
  ...rest
}): ReactNode => {
  const formControlContext =
    useFormControlContext()

  const classNames = clsx(
    'text-sm text-red-500 first-letter:capitalize py-1',
    className
  )

  if (error || formControlContext?.error) {
    return (
      <div className={classNames} {...rest}>
        <span>{message}</span>
      </div>
    )
  }

  return <Fragment></Fragment>
}

export { FormHelperText }
