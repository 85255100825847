import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from '@/features/categoryBundles/resources/components'
import Card from '@/features/components/cards'
import {
  useGetBundleCategoryQuery,
  useUpdateBundleCategoryMutation,
} from '@/features/categoryBundles/redux/categoryBundleAPI'
import { SaveBundleCategoryRequestInterface } from '@/features/categoryBundles/redux/types'
import { toast } from 'react-toastify'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'

const EditCategoryBundleScreen =
  (): React.ReactNode => {
    const { id } = useParams() as { id: string }
    const { t } = useTranslation([
      'bundle_categories',
      'utils',
    ])
    const [updateBundleCategory] =
      useUpdateBundleCategoryMutation()
    const { data } = useGetBundleCategoryQuery(id)
    const navigate = useNavigate()

    const handleSubmit = async (
      data: SaveBundleCategoryRequestInterface
    ) => {
      try {
        await updateBundleCategory({
          data,
          id,
        }).unwrap()
        toast.success(
          t('bundle_categories:edit.success')
        )
        navigate('/bundle-categories')
      } catch (error) {
        toast.error(
          t('utils:errors.something_went_wrong')
        )
      }
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('bundle_categories:edit.title')}
        </span>
        <Card margin>
          <Form
            onSubmit={handleSubmit}
            data={data}
          />
        </Card>
      </div>
    )
  }

export { EditCategoryBundleScreen }
