import igritAPI from '@/utils/api/igrit'
import {
  ArticleCategoryInterface,
  GetArticleCategoriesResponseInterface,
  GetArticleCategoryResponseInterface,
  SaveArticleCategoryRequestInterface,
} from '@/features/articleCategories/redux/types'
import {
  setArrayCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetArticleCategoriesQuery,
  useGetArticleCategoryQuery,
  useStoreArticleCategoryMutation,
  useUpdateArticleCategoryMutation,
  useDeleteArticleCategoryMutation,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getArticleCategories: build.query<
      GetArticleCategoriesResponseInterface['data'],
      void
    >({
      query: () => `/admin/article-categories`,
      transformResponse: (
        response: GetArticleCategoriesResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setArrayCache('ArticleCategory', result),
    }),
    getArticleCategory: build.query<
      ArticleCategoryInterface,
      string | number
    >({
      query: (id) =>
        `/admin/article-categories/${id}`,
      transformResponse: (
        response: GetArticleCategoryResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'ArticleCategory',
          result
        ),
    }),
    storeArticleCategory: build.mutation<
      void,
      SaveArticleCategoryRequestInterface
    >({
      query: (data) => ({
        url: '/admin/article-categories',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ArticleCategory'],
    }),
    updateArticleCategory: build.mutation<
      void,
      {
        id: number | string
        data: SaveArticleCategoryRequestInterface
      }
    >({
      query: ({ id, data }) => ({
        url: `/admin/article-categories/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ArticleCategory'],
    }),
    deleteArticleCategory: build.mutation<
      void,
      string | number
    >({
      query: (id) => ({
        url: `/admin/article-categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ArticleCategory'],
    }),
  }),
})
