import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

export const supportedLanguages = ['pl']

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pl',
    supportedLngs: supportedLanguages,
    returnNull: false,
    load: 'languageOnly',
    debug: import.meta.env.DEV,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
      format: (value: string, format) => {
        if (format === 'uppercase')
          return value.toUpperCase()
        if (format === 'lowercase')
          return value.toLowerCase()
        if (format === 'firstUpperCase') {
          return (
            value.charAt(0).toUpperCase() +
            value.slice(1)
          )
        }
        return value
      },
    },
  })

export default i18n
