import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { ButtonLink } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { Table } from './_components/table'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const RolesListScreen = (): React.ReactNode => {
  const { t } = useTranslation(['roles'])
  const { hasPermission } = usePermissions()
  const {
    handleToggleColumn,
    handleSetAvailableColumns,
    visibilityColumnsWithLabels,
    visibilityColumns,
  } = useColumnVisibility()

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <div
        className={
          'flex flex-col gap-2 justify-between lg:flex-row'
        }
      >
        {hasPermission(
          PermissionEnum.ROLE_SAVE
        ) && (
          <ButtonLink
            variant={'contained'}
            className={
              'flex items-center gap-x-2'
            }
            to={'/roles/create'}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className={'text-white'}
            />
            <span>{t('roles:list.create')}</span>
          </ButtonLink>
        )}
        <ColumnVisibility
          columns={visibilityColumnsWithLabels}
          toggleColumn={(id: string) =>
            handleToggleColumn(id)
          }
        />
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>{t('roles:list.title')}</span>
          </div>
          <Table
            visibility={visibilityColumns}
            setAvailableColumns={
              handleSetAvailableColumns
            }
          />
        </div>
      </Card>
    </div>
  )
}

export { RolesListScreen }
