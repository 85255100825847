import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useStoreFAQCategoryQuestionMutation } from '@/features/faq/questions/redux/faqCategoryQuestionAPI'
import { SaveFAQCategoryQuestionRequestInterface } from '@/features/faq/questions/redux/types'
import { Form } from '@/features/faq/questions/resources/_components/form'

export const FAQCategoryQuestionsCreateScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'faq/questions',
    ])
    const { category_id } = useParams<{
      category_id: string
    }>() as {
      category_id: string
    }
    const [storeQuestion] =
      useStoreFAQCategoryQuestionMutation()
    const navigate = useNavigate()

    const handleSubmit = (
      data: SaveFAQCategoryQuestionRequestInterface
    ) => {
      storeQuestion(data)
        .unwrap()
        .then(() => {
          toast.success(
            t('faq/questions:create.success')
          )
          navigate(
            `/faq/categories/${category_id}`
          )
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('faq/questions:create.title')}
        </span>
        <Card>
          <Form
            params={{ category_id }}
            onSubmit={handleSubmit}
          />
        </Card>
      </div>
    )
  }
