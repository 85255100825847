import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveInternalQuotationInterface } from '@/features/internalQuotations/redux/types'
import Carbon from '@/utils/carbon'

export default class SaveInternalQuotationValidation
  implements
    ValidationInterface<SaveInternalQuotationInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveInternalQuotationInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t('validation:max', { max: 255 })
        ),
      is_active: yup
        .boolean()
        .required(t('validation:required')),
      start_at: yup
        .string()
        .nullable()
        .when('is_active', {
          is: (isActive: boolean) => isActive,
          then: (yup) =>
            yup.required(
              t('validation:required')
            ),
        })
        .transform((value) => {
          return value
            ? new Carbon(value).toISOString()
            : value
        }),
      execution_interval_days: yup
        .number()
        .min(0, t('validation:min', { min: 0 }))
        .typeError(t('validation:required'))
        .required(t('validation:required')),
      order: yup
        .number()
        .required('validation:required'),
      description: yup.string().nullable(),
      category_id: yup
        .number()
        .min(0, t('validation:min', { min: 0 }))
        .required(t('validation:required')),
    })
  }

  defaultValues(): SaveInternalQuotationInterface {
    return {
      name: '',
      is_active: false,
      execution_interval_days: 0,
      order: 0,
      description: '',
      category_id: 0,
      start_at: '',
    }
  }
}
