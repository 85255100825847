import { SearchUserProps } from './SearchUser.types'
import React from 'react'
import { useLazySearchUsersQuery } from '@/features/users/redux/userAPI'
import { useAsyncSelect } from '@/utils/hooks/useAsyncSelect'
import { UserInterface } from '@/features/users/redux/types'
import AsyncSelect from '@/features/components/inputs/asyncSelect/asyncSelect'
import { Select2OptionInterface } from '@/app/types'
import { MultiValue, SingleValue } from 'react-select'
import { Query } from '@/utils/query'
import _, { isArray } from 'lodash'

const SearchUser: React.FC<SearchUserProps> = ({
  defaultValue,
  onChange,
  isMulti,
  query: _query,
}): React.ReactNode => {
  const [searchUser] = useLazySearchUsersQuery()
  const { selectedValue, setSelectedValue } = useAsyncSelect<UserInterface>(
    searchUser,
    'name',
    defaultValue,
    isMulti
  )

  const handleChange = (
    value:
      | MultiValue<Select2OptionInterface>
      | SingleValue<Select2OptionInterface>
  ) => {
    if (isMulti) {
      setSelectedValue(value as Select2OptionInterface[])
      onChange(value as Select2OptionInterface[])
    } else {
      setSelectedValue(value as Select2OptionInterface)
      onChange(value as Select2OptionInterface)
    }
  }

  const _handleSearchUser = (
    value: string,
    callback: (options: Select2OptionInterface[]) => void
  ) => {
    const query = _query ? _query : new Query()

    query.where('name', value)

    if (defaultValue && !isArray(defaultValue)) query.whereNotIn([defaultValue])

    searchUser(query.url())
      .unwrap()
      .then((response) =>
        callback(
          response.map((user) => ({
            label: user.name,
            value: user.id.toString(),
          }))
        )
      )
  }

  const handleSearchUser = _.debounce(_handleSearchUser, 500)

  return (
    <AsyncSelect
      defaultValue={selectedValue}
      onChange={handleChange}
      isMulti={isMulti}
      loadOptions={handleSearchUser}
    />
  )
}

export { SearchUser }
