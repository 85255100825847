import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AttributeTypeEnum } from '@/features/posts/attributes/redux/enums/attributeType'
import { v4 as uuid } from 'uuid'
import { Select2OptionInterface } from '@/app/types'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { Label } from '@/features/components/inputs/label'
import AsyncSelect from '@/features/components/inputs/asyncSelect/asyncSelect'
import { Query } from '@/utils/query'
import _ from 'lodash'
import { useLazySearchAttributeQuery } from '@/features/posts/attributes/redux/attributeAPI'
import {
  MultiValue,
  SingleValue,
} from 'react-select'
import { Button } from '@/features/components/buttons/button'
import { explodeValue } from '@/features/posts/attributeCategories/redux/helper'
import { StoreAttributeInterface } from '@/features/posts/attributeCategories/redux/types'
import CreateableSelect from '@/features/components/inputs/asyncSelect/createableSelect'
import { z } from 'zod'
import { toast } from 'react-toastify'
import { Checkbox } from '@/features/components/inputs/checkbox'

type Props = {
  onSubmit: (
    data: StoreAttributeInterface
  ) => void
}

type AttributeType =
  | AttributeTypeEnum.SELECT
  | AttributeTypeEnum.MULTI_SELECT

export const ChildrenForm: React.FC<Props> = ({
  onSubmit,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [attributeType, setAttributeType] =
    useState<AttributeType>(
      AttributeTypeEnum.SELECT
    )
  const [form, setForm] =
    useState<StoreAttributeInterface>({
      uuid: uuid(),
      affects_price: false,
      attribute_id: 0,
      values: [],
    })
  const [searchAttributes] =
    useLazySearchAttributeQuery()

  const handleChangeAttribute = (
    value: SingleValue<Select2OptionInterface>
  ) => {
    setForm((prev) => ({
      ...prev,
      attribute_id: value
        ? Number(value.value)
        : null,
    }))
  }

  const handleChangeValues = (
    values: MultiValue<Select2OptionInterface>
  ) => {
    setForm((prev) => ({
      ...prev,
      values: explodeValue(values).map(
        (value) => ({
          uuid: uuid(),
          value,
        })
      ),
    }))
  }

  const _searchAttribute = (
    value: string,
    callback: (
      options: Select2OptionInterface[]
    ) => void
  ) => {
    const query = new Query()
      .where('name', value)
      .where('type', attributeType)

    searchAttributes(query.url())
      .unwrap()
      .then((response) => {
        callback(
          response.map((attribute) => ({
            label: attribute.name,
            value: String(attribute.id),
          }))
        )
      })
  }

  const searchAttribute = _.debounce(
    _searchAttribute,
    500
  )

  const handleSubmit = () => {
    const validation = z.object({
      attribute_id: z
        .number()
        .nonnegative()
        .refine(
          (value) => value !== 0,
          t(
            'posts/attribute_categories:create.attribute_required'
          )
        ),
      values: z
        .array(
          z.object({
            uuid: z.string(),
            value: z.string(),
          })
        )
        .min(
          1,
          t(
            'posts/attribute_categories:create.value_required'
          )
        ),
    })

    const result = validation.safeParse(form)

    if (result.success) {
      onSubmit(form)
      setForm((prev) => ({
        ...prev,
        uuid: uuid(),
        values: [],
      }))
      return
    }

    toast.error(() => {
      return (
        <div className={'flex flex-col'}>
          {result.error.errors.map(
            (error, index) => (
              <span key={index}>
                {error.message}
              </span>
            )
          )}
        </div>
      )
    })
  }

  return (
    <div className={'flex flex-col gap-y-4'}>
      <Select
        placeholder={t(
          'form:placeholders.select_attribute_type'
        )}
        label={t('form:labels.attribute_type')}
        onChange={(_, value) =>
          setAttributeType(value as AttributeType)
        }
      >
        {[
          AttributeTypeEnum.SELECT,
          AttributeTypeEnum.MULTI_SELECT,
        ].map((value, index) => (
          <Option value={value} key={index}>
            {t(`utils:attribute_types.${value}`)}
          </Option>
        ))}
      </Select>
      <div
        className={'grid lg:grid-cols-2 gap-4'}
      >
        <div className={'flex flex-col'}>
          <Label
            label={t('form:labels.attribute')}
          />
          <AsyncSelect
            cacheOptions
            onChange={handleChangeAttribute}
            loadOptions={searchAttribute}
          />
        </div>
        <div className={'flex flex-col'}>
          <Label
            label={t('form:labels.options')}
          />
          <CreateableSelect
            isMulti
            value={form.values.map(
              ({ value }) => ({
                value,
                label: value,
              })
            )}
            onChange={handleChangeValues}
          />
        </div>
        <Checkbox
          onChange={(event) =>
            setForm((prev) => ({
              ...prev,
              affects_price:
                !!event.currentTarget?.checked,
            }))
          }
          checked={form.affects_price}
          label={t('form:labels.affects_price')}
          name={'affects_price'}
        />
      </div>
      <div className={'flex'}>
        <Button
          variant={'contained'}
          onClick={handleSubmit}
        >
          {t('form:buttons.add')}
        </Button>
      </div>
    </div>
  )
}
