import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SaveArticleRequestInterface } from '@/features/articles/redux/types'
import Form from '@/features/articles/resources/components/Form/Form'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useGetArticleQuery,
  useUpdateArticleMutation,
} from '@/features/articles/redux/articlesAPI'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/types'
import { isFetchBaseQueryError } from '@/app/hooks'
import {
  ErrorResponseInterface,
  ErrorValidationResponseInterface,
} from '@/app/types'
import { ButtonLink } from '@/features/components/buttons/button'

const ArticleEditScreen = (): React.ReactNode => {
  const { t } = useTranslation([
    'articles',
    'utils',
  ])
  const { id } = useParams() as { id: string }
  const [updateArticle] =
    useUpdateArticleMutation()
  const { data } = useGetArticleQuery(id)
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<
      BackendValidationErrorInterface<SaveArticleRequestInterface>
    >()

  const handleSubmit = async (
    data: SaveArticleRequestInterface
  ) => {
    const formData = serialize(data, {
      nullsAsUndefineds: true,
      booleansAsIntegers: true,
      indices: true,
    })

    formData.append('_method', 'PUT')

    try {
      await updateArticle({
        data: formData,
        articleId: id,
      }).unwrap()
      toast.success(t('articles:update.success'))
      navigate('/articles')
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        const errorData =
          error as ErrorResponseInterface

        if (errorData.status === 422) {
          const errorData =
            error as ErrorValidationResponseInterface<SaveArticleRequestInterface>
          setErrors(errorData.data)
        }
      }
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <div
        className={
          'w-full flex gap-8 flex-col md:flex-row md:justify-between'
        }
      >
        <span className={'text-2xl font-medium'}>
          {t('articles:update.title')}
        </span>
        <ButtonLink
          to={`${
            import.meta.env
              .VITE_IGRIT_FRONTEND_URL
          }/article/${id}`}
          variant={'contained'}
        >
          {t('articles:show_in_portal')}
        </ButtonLink>
      </div>
      <Form
        onSubmit={handleSubmit}
        data={data}
        backendErrors={errors}
      />
    </div>
  )
}

export { ArticleEditScreen }
