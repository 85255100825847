import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveArticleCategoryValidationInterface } from '@/features/articleCategories/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'

export default class SaveArticleCategoryValidation
  implements
    ValidationInterface<SaveArticleCategoryValidationInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveArticleCategoryValidationInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required')),
      active: yup
        .boolean()
        .required(t('validation:required')),
      subcategories: yup
        .array()
        .of(
          yup
            .string()
            .required(t('validation:required'))
        )
        .required(t('validation:required'))
        .default([]),
    })
  }

  defaultValues(): SaveArticleCategoryValidationInterface {
    return {
      name: '',
      active: true,
      subcategories: [],
    }
  }
}
