import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faEdit,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { ReactNode } from 'react'

export type Action = {
  icon: IconDefinition | ReactNode
  onClick: (() => void) | string
  permission?: PermissionEnum
  buttonClassName?: string
  condition?: boolean
  confirm?: {
    message: string
  }
  tooltip?: string
}

export type ActionStackProps = {
  actions: Action[]
}

export type BaseActionStackActionType = {
  action: (() => void) | string
  permission: PermissionEnum
  tooltip?: string
  show?: boolean
}

export const baseActionStack = ({
  onDelete,
  onEdit,
  onDetails,
}: {
  onDelete?: BaseActionStackActionType
  onEdit?: BaseActionStackActionType
  onDetails?: BaseActionStackActionType
}): Action[] => [
  ...(onDetails
    ? [
        {
          icon: faEye,
          permission: onDetails.permission,
          onClick: onDetails.action,
          buttonClassName: 'bg-primary-600',
          tooltip:
            onDetails.tooltip ||
            'utils:tooltips.details',
          condition: onDetails.show,
        },
      ]
    : []),
  ...(onEdit
    ? [
        {
          icon: faEdit,
          permission: onEdit.permission,
          onClick: onEdit.action,
          buttonClassName: 'bg-yellow-500',
          tooltip:
            onEdit.tooltip ||
            'utils:tooltips.edit',
          condition: onEdit.show,
        },
      ]
    : []),
  ...(onDelete
    ? [
        {
          icon: faTrash,
          permission: onDelete.permission,
          onClick: onDelete.action,
          buttonClassName: 'bg-red-500',
          confirm: {
            message:
              'utils:confirmations.delete_record.message',
          },
          tooltip:
            onDelete.tooltip ||
            'utils:tooltips.delete',
          condition: onDelete.show,
        },
      ]
    : []),
]
