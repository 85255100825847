import { useTranslation } from 'react-i18next'

type Props = {
  date: string
}
const PublishedBadge: React.FC<Props> = ({
  date,
}) => {
  const { t } = useTranslation(['form'])

  return (
    <div
      className={
        'flex flex-col gap-0.5 p-2 bg-[#3c763d] justify-center items-center text-gray-100 w-52 rounded-md'
      }
    >
      <span>
        {t('form:labels.publish_planed_at')}
      </span>
      <span>{date}</span>
    </div>
  )
}

export { PublishedBadge }
