import React, { useEffect } from 'react'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import {
  SaveServiceConfigRequestInterface,
  ServiceConfigInterface,
} from '@/features/services/redux/types'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SaveServiceConfigValidation from './validations/SaveServiceConfigValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Button } from '@/features/components/buttons/button'
import { ServiceNameEnum } from '@/features/posts/posts/redux/enums/service'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { getServiceConfigKeyForServiceName } from '@/app/enums/serviceConfigKeyEnum'

type Props = {
  title: string
  data?: ServiceConfigInterface
  serviceName: ServiceNameEnum
} & InstanceProps<SaveServiceConfigRequestInterface>

const Form: React.FC<Props> = ({
  title,
  onResolve,
  onReject,
  isOpen,
  data,
  serviceName,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveServiceConfigValidation(serviceName),
    t
  )
  const { control, setValue, handleSubmit } =
    useForm<SaveServiceConfigRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      setValue('key', data.key)
      setValue('value', data.value)
    }
  }, [data])

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={title}
          onClose={() => onReject()}
        />
        <form
          onSubmit={handleSubmit(onResolve)}
          className={'flex flex-col gap-y-4 pt-8'}
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                error={!!error}
                className={'flex flex-col'}
              >
                <Select
                  value={field.value}
                  onChange={(_, value) =>
                    field.onChange(value)
                  }
                  label={t('form:labels.key')}
                >
                  {getServiceConfigKeyForServiceName(
                    serviceName
                  ).map((key, index) => (
                    <Option
                      value={key}
                      key={index}
                    >
                      {t(
                        `utils:service_config_keys.${key}`
                      )}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            name={'key'}
            control={control}
          />
          {serviceName ===
            ServiceNameEnum.MARK && (
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  {...field}
                >
                  <Input
                    label={t('form:labels.value')}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'value'}
              control={control}
            />
          )}
          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const serviceConfigForm = create(Form)
