import axios from 'axios'
import { url } from '@/utils/api/igrit'
import { store } from '@/app/store'
import { GalleryEnum } from '@/app/enums/galleryEnum'
import { compressImage } from '@/utils/helpers/compressor'

export class UploadAdapter {
  constructor(
    private loader: any,
    private type: GalleryEnum
  ) {}

  upload() {
    return this.loader.file.then(
      async (file: File) => {
        const { token } = store.getState().auth

        try {
          const compressedImage =
            await compressImage(file)

          const resposne = await axios.post(
            `${url}/admin/gallery/${this.type}`,
            compressedImage,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': file.type,
              },
            }
          )

          return {
            default: resposne.data.data,
          }
        } catch (error) {
          console.error(error)
        }
      }
    )
  }
}
