import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { ChangePasswordRequestInterface } from '@/features/users/redux'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'

class ChangePasswordValidation
  implements
    ValidationInterface<ChangePasswordRequestInterface>
{
  defaultValues(): ChangePasswordRequestInterface {
    return {
      password: '',
      password_confirmation: '',
    }
  }

  rules(
    t: TFunction
  ): ObjectSchema<
    ChangePasswordRequestInterface,
    AnyObject
  > {
    return yup.object().shape({
      password: yup
        .string()
        .required(t('validation:required')),
      password_confirmation: yup
        .string()
        .required(t('validation:required'))
        .oneOf(
          [yup.ref('password'), ''],
          t('validation:password_confirmation')
        ),
    })
  }
}

export { ChangePasswordValidation }
