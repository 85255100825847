import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MassAction, Table } from './components'
import Card from '@/features/components/cards'
import { AllowedMassActionType } from '@/features/posts/posts/redux/types'
import { useManagePostsMutation } from '@/features/posts/posts/redux/postAPI'
import { toast } from 'react-toastify'
import { useSearch } from '@/utils/hooks/useSearch'
import { Search } from '@/features/components/inputs/Search'
import { Transition } from 'react-transition-group'
import {
  ColumnVisibility,
  FilterBuilder,
  Filters,
  SearchUser,
  useColumnVisibility,
  useFilters,
} from '@/components'
import { PostStatusEnum } from '@/features/posts/posts/redux/enums/status'
import { Label } from '@/features/components/inputs/label'
import { SearchCategory } from '@/features/components/inputs/asyncSelect/searchCategory'

export const PostsListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation(['posts/posts'])
    const [rowSelection, setRowSelection] =
      useState<number[]>([])
    const [managePosts] = useManagePostsMutation()
    const { query, setQueryParams } = useSearch({
      columnsForSearch: [
        'title',
        'content',
        'phoneNumber.phone_number',
        'user.email',
      ],
    })
    const { ref, TriggerFilters, showFilters } =
      useFilters()
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()

    const handleAction = (
      action: AllowedMassActionType,
      categoryId?: number
    ) => {
      if (
        action === 'switch_category' &&
        !categoryId
      )
        return

      managePosts({
        action,
        ids: rowSelection,
        category_id: categoryId,
      })
        .unwrap()
        .then(() =>
          toast.success(
            t('posts/posts:mass_actions.success')
          )
        )
    }

    return (
      <div
        className={
          'flex flex-col p-4 lg:p-8 gap-y-4'
        }
      >
        <Transition
          nodeRef={ref}
          unmountOnExit={true}
          in={showFilters}
          timeout={200}
          classNames={'transition-height'}
        >
          <Filters
            filterBuilder={builder}
            onSubmit={setQueryParams}
          />
        </Transition>
        <div
          className={
            'flex flex-col w-full gap-4 lg:flex-row lg:justify-between lg:items-center'
          }
        >
          <TriggerFilters />
          <div className={'flex gap-2'}>
            <Search onChange={setQueryParams} />
            <ColumnVisibility
              columns={
                visibilityColumnsWithLabels
              }
              toggleColumn={handleToggleColumn}
            />
          </div>
        </div>
        <div
          className={
            'flex w-full flex-col gap-2 lg:flex-row lg:justify-between md:max-w-1/3 lg:max-w-1/4'
          }
        >
          <MassAction onConfirm={handleAction} />
        </div>
        <div className={'flex gap-x-2'}></div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t('posts/posts:list.title')}
              </span>
            </div>
            <Table
              onRowSelectionChange={
                setRowSelection
              }
              query={query}
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }

const builder = new FilterBuilder()
  .addFilter({
    field: 'status',
    component: 'select',
    props: {
      placeholder:
        'form:placeholders.select_status',
      label: 'form:labels.status',
    },
    optionsProps: {
      translate: true,
      options: [
        {
          value: PostStatusEnum.PUBLISHED,
          label: 'form:labels.published',
        },
        {
          value: PostStatusEnum.ARCHIVED,
          label: 'form:labels.archived',
        },
        {
          value: PostStatusEnum.BLOCKED,
          label: 'form:labels.blocked',
        },
      ],
    },
  })
  .addFilter({
    field: 'id',
    component: 'number',
    props: {
      label: 'form:labels.id',
    },
  })
  .addFilter({
    field: 'user_id',
    component: 'custom',
    props: {
      label: 'form:labels.user',
    },
    customComponent: ({ onChange, label }) => (
      <div className={'flex flex-col'}>
        {label && <Label label={label} />}
        <SearchUser
          isMulti={false}
          onChange={onChange}
        />
      </div>
    ),
  })
  .addFilter({
    field: 'category_id',
    component: 'custom',
    props: {
      label: 'form:labels.category',
    },
    customComponent: ({ onChange, label }) => (
      <div className={'flex flex-col'}>
        {label && <Label label={label} />}
        <SearchCategory
          isMulti={false}
          onChange={onChange}
        />
      </div>
    ),
  })
  .addFilter({
    field: 'title',
    component: 'input',
    props: {
      label: 'form:labels.title',
    },
  })
  .addFilter({
    field: 'blockade',
    component: 'select',
    props: {
      label: 'form:labels.blockade',
    },
    optionsProps: {
      translate: true,
      options: [
        {
          value: '1',
          label: 'form:labels.block',
        },
        {
          value: '0',
          label: 'form:labels.unblock',
        },
      ],
    },
  })
  .addFilter({
    field: 'trashed',
    component: 'select',
    props: {
      label: 'form:labels.trashed_only',
    },
    optionsProps: {
      translate: true,
      options: [
        {
          value: '1',
          label: 'form:labels.yes',
        },
      ],
    },
  })
  .addFilter({
    field: 'price_from',
    component: 'number',
    props: {
      label: 'form:labels.price_from',
    },
  })
  .addFilter({
    field: 'price_to',
    component: 'number',
    props: {
      label: 'form:labels.price_to',
    },
  })
  .addFilter({
    field: 'content',
    component: 'input',
    props: {
      label: 'form:labels.content',
    },
  })
  .addFilter({
    field: 'created_after',
    component: 'date',
    props: {
      label: 'form:labels.date_from',
    },
  })
  .addFilter({
    field: 'created_before',
    component: 'date',
    props: {
      label: 'form:labels.date_to',
    },
  })
