import {
  InternalQuotationInterface,
  SaveInternalQuotationInterface,
} from '@/features/internalQuotations/redux/types'

export const toRequestObject = (
  row: InternalQuotationInterface
): SaveInternalQuotationInterface => ({
  is_active: row.is_active,
  name: row.name,
  order: row.order,
  description: row.description,
  execution_interval_days:
    row.execution_interval_days,
  start_at: row.start_at,
  category_id: row.category_id,
})
