import igritAPI from '@/utils/api/igrit'
import {
  AdvertiserInterface,
  GetAdvertiserResponseInterface,
  GetAdvertisersResponseInterface,
  SaveAdvertiserRequestInterface,
} from '@/features/ads/advertisers/redux/types'
import {
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetAdvertisersQuery,
  useGetAdvertiserQuery,
  useStoreAdvertiserMutation,
  useUpdateAdvertiserMutation,
  useDeleteAdvertiserMutation,
  useLazyGetAdvertisersQuery,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getAdvertisers: build.query<
      GetAdvertisersResponseInterface['data'],
      string
    >({
      query: (query) =>
        `/admin/advertisers?${query}`,
      transformResponse: (
        response: GetAdvertisersResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setPaginatedCache('Advertiser', result),
    }),
    getAdvertiser: build.query<
      AdvertiserInterface,
      string | number
    >({
      query: (id) => `/admin/advertisers/${id}`,
      transformResponse: (
        response: GetAdvertiserResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setSingleObjectCache(
          'Advertiser',
          result
        ),
    }),
    storeAdvertiser: build.mutation<
      void,
      SaveAdvertiserRequestInterface
    >({
      query: (body) => ({
        url: '/admin/advertisers',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Advertiser'],
    }),
    updateAdvertiser: build.mutation<
      void,
      {
        id: number | string
        body: SaveAdvertiserRequestInterface
      }
    >({
      query: ({ body, id }) => ({
        url: `/admin/advertisers/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Advertiser'],
    }),
    deleteAdvertiser: build.mutation<
      void,
      number | string
    >({
      query: (id) => ({
        url: `/admin/advertisers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Advertiser'],
    }),
  }),
})
