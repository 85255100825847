import React from 'react'

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const AuthLayout = (props: Props) => {
  return (
    <div
      className={
        'flex h-full w-full items-center justify-center text-gray-700 bg-white'
      }
    >
      <div className={'flex w-[424px] flex-col'}>
        <span
          className={
            'text-center text-4xl font-medium mb-12'
          }
        >
          {import.meta.env.VITE_APP_NAME}
        </span>
        {props.children}
      </div>
    </div>
  )
}

export default AuthLayout
