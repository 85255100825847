import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useStoreFAQCategoryMutation } from '@/features/faq/categories/redux/faqCategoryAPI'
import { SaveFAQCategoryRequestInterface } from '@/features/faq/categories/redux/types'
import { buildForm } from '@/features/faq/categories/resources/_components/form/helper'
import { Form } from '@/features/faq/categories/resources/_components/form'

export const FAQCategoriesCreateScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'faq/categories',
    ])
    const [storeCategory] =
      useStoreFAQCategoryMutation()
    const navigate = useNavigate()

    const handleSubmit = (
      data: SaveFAQCategoryRequestInterface
    ) => {
      storeCategory(buildForm(data))
        .unwrap()
        .then(() => {
          toast.success(
            t('faq/categories:create.success')
          )
          navigate('/faq/categories')
        })
    }

    return (
      <div
        className={'flex flex-col p-4 gap-y-8'}
      >
        <span className={'text-2xl font-medium'}>
          {t('faq/categories:create.title')}
        </span>
        <Card>
          <Form onSubmit={handleSubmit} />
        </Card>
      </div>
    )
  }
