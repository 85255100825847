import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import { isCustomError } from '@/utils/api/rtkHelper'
import { ResponseCodeEnum } from '@/app/types'
import { AttributeCategoryListInterface } from '@/features/posts/attributeCategories/redux/types'
import {
  useDeleteAttributeCategoryMutation,
  useGetAttributeCategoryListQuery,
} from '@/features/posts/attributeCategories/redux/attributeCategoryAPI'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

const ns = 'posts/attribute_categories'

type Props = {
  query: Query
} & VisibleColumnsInterface

export const Table: React.FC<Props> = ({
  query: baseQuery,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    ns,
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      baseQuery
        .includes('category', 'attribute')
        .page(pagination.pageIndex),
    [pagination, baseQuery]
  )
  const { data: apiData } =
    useGetAttributeCategoryListQuery(query.url())
  const [data, setData] = useState<
    AttributeCategoryListInterface[]
  >([])
  const [deleteAttribute] =
    useDeleteAttributeCategoryMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteAttribute({ id, delete_value: false })
      .unwrap()
      .then(() =>
        toast.success(t(`${ns}:list.deleted`))
      )
      .catch((error) => {
        if (
          isCustomError(
            error,
            ResponseCodeEnum.ATTRIBUTE_CAN_NOT_BE_DELETED
          )
        ) {
          toast.error(
            t(`${ns}:list.can_not_be_deleted`)
          )
        }
      })
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableHiding: true,
    state: {
      columnVisibility: visibility,
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<AttributeCategoryListInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void
) => [
  columnBuilder.accessor('attribute.name', {
    id: 'name',
    header: t('form:labels.attribute_name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('category.name', {
    id: 'type',
    header: t('form:labels.category_name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('affects_price', {
    id: 'affects_price',
    header: t('form:labels.affects_price'),
    cell: ({ row }) =>
      row.original.affects_price
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('has_parent', {
    id: 'has_parent',
    header: t('form:labels.has_parent'),
    cell: ({ row }) =>
      row.original.has_parent
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.accessor('has_children', {
    id: 'has_children',
    header: t('form:labels.has_children'),
    cell: ({ row }) =>
      row.original.has_children
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: `/posts/attribute-categories/${row.original.id}/edit`,
            permission:
              PermissionEnum.ATTRIBUTE_CATEGORY_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.ATTRIBUTE_CATEGORY_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
