import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { UpdatePostUserRequestInterface } from '@/features/posts/posts/redux/types'
import { UserTypeEnum } from '@/features/users/redux/enums/typeEnum'

export default class UpdatePostUserValidation
  implements
    ValidationInterface<UpdatePostUserRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    UpdatePostUserRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required')),
      email: yup
        .string()
        .required(t('validation:required'))
        .email(t('validation:email_invalid')),
      contact_email: yup
        .string()
        .nullable()
        .email(t('validation:email_invalid')),
      type: yup
        .mixed<UserTypeEnum>()
        .oneOf(Object.values(UserTypeEnum))
        .required(t('validation:required')),
      phone: yup
        .object()
        .shape({
          prefix: yup
            .string()
            .required(t('validation:required')),
          number: yup
            .string()
            .required(t('validation:required'))
            .length(
              9,
              t('validation:invalid_length', {
                length: 9,
              })
            ),
        })
        .required(t('validation:required')),
      description: yup.string().nullable(),
    })
  }

  defaultValues(): UpdatePostUserRequestInterface {
    return {
      name: '',
      email: '',
      contact_email: '',
      type: UserTypeEnum.PRIVATE_USER,
      phone: {
        prefix: '',
        number: '',
      },
      description: '',
    }
  }
}
