import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteAdsViewMutation,
  useGetAdsViewsQuery,
  useUpdateAdsViewMutation,
} from '@/features/ads/views/redux/viewAPI'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { AdsViewInterface } from '@/features/ads/views/redux/types'
import { adsViewFormModal } from '@/features/ads/views/resources/components'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'
import { isCustomError } from '@/utils/api/rtkHelper'
import { ResponseCodeEnum } from '@/app/types'

export const Table: React.FC<
  VisibleColumnsInterface
> = ({
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'ads_views',
    'utils',
  ])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () => new Query().page(pagination.pageIndex),
    [pagination]
  )
  const { data: apiData } = useGetAdsViewsQuery(
    query.url()
  )
  const [data, setData] = useState<
    AdsViewInterface[]
  >([])
  const [deleteAdsView] =
    useDeleteAdsViewMutation()
  const [updateAdsView] =
    useUpdateAdsViewMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = async (id: number) => {
    try {
      await deleteAdsView(id).unwrap()
      toast.success(t('ads_views:list.deleted'))
    } catch (error) {
      if (
        isCustomError(
          error,
          ResponseCodeEnum.AD_VIEW_CAN_NOT_BE_DELETED
        )
      ) {
        toast.error(
          t('ads_views:list.can_not_be_deleted')
        )
      }
    }
  }

  const onEdit = (id: number) => {
    const row = data.find(
      (item) => item.id === id
    )

    adsViewFormModal({
      title: t('ads_views:edit.title'),
      data: row,
    }).then((data) =>
      updateAdsView({ data, id })
        .unwrap()
        .then(() =>
          toast.success(
            t('ads_views:edit.success')
          )
        )
    )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableHiding: true,
    enableSorting: false,
    manualPagination: true,
    state: {
      pagination,
      columnVisibility: visibility,
    },
    onPaginationChange: setPagination,
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<AdsViewInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
    enableHiding: true,
  }),
  columnBuilder.accessor('route', {
    id: 'route',
    header: t('form:labels.route'),
    enableHiding: true,
  }),
  columnBuilder.accessor('is_category_required', {
    id: 'is_category_required',
    header: t('form:labels.is_category_required'),
    cell: ({ row }) =>
      row.original.is_category_required
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission: PermissionEnum.VIEW_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.VIEW_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
