import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useGetCategoryTreeQuery } from '@/features/posts/categories/redux/categoryAPI'
import TreeView, {
  INode,
  ITreeViewOnNodeSelectProps,
} from 'react-accessible-treeview'
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils'
import clsx from 'clsx'
import { CheckboxIcon } from './Checkbox'

type Props = {
  onChange: (ids: number[]) => void
  selectedIds: number[]
}

const Tree: React.FC<Props> = ({
  onChange,
  selectedIds,
}): React.ReactNode => {
  const { data: apiData } =
    useGetCategoryTreeQuery()
  const [data, setData] = useState<
    INode<IFlatMetadata>[]
  >([
    {
      id: 0,
      name: '',
      parent: null,
      children: [],
    },
  ])
  const isReady = useMemo(() => {
    const availableNodeIds = data.map(
      (item) => item.id
    )

    return selectedIds.every((id) =>
      availableNodeIds.includes(id)
    )
  }, [selectedIds, data])

  useEffect(() => {
    if (apiData) {
      const base = apiData.map((item) => {
        return {
          id: item.id,
          name: item.name,
          parent: 0,
          children: apiData
            .filter(
              (child) =>
                child.parent_id === item.id
            )
            .map((child) => child.id),
        }
      })

      setData([
        {
          id: 0,
          name: '',
          parent: null,
          children: apiData
            .filter(
              (item) => item.parent_id === null
            )
            .map((item) => item.id),
        },
        ...base,
      ])
    }
  }, [apiData])

  const onSelect = (
    props: ITreeViewOnNodeSelectProps
  ) => {
    if (!props.treeState) return
    const selectedIds = Array.from(
      props.treeState.selectedIds
    ).map((id) => +id)
    onChange(selectedIds)
  }

  return (
    <div className={'flex flex-col'}>
      {!isReady ? (
        <div>Loading...</div>
      ) : (
        <TreeView
          data={data}
          multiSelect
          propagateSelect
          propagateSelectUpwards
          selectedIds={selectedIds}
          togglableSelect
          onSelect={onSelect}
          nodeRenderer={({
            element,
            getNodeProps,
            level,
            handleExpand,
            handleSelect,
            isHalfSelected,
            isSelected,
          }) => (
            <div
              {...getNodeProps({
                onClick: handleExpand,
              })}
              style={{
                paddingLeft: `${
                  (level - 1) * 20
                }px`,
              }}
              autoFocus={false}
              className={clsx(
                `flex items-center gap-x-2`,
                {
                  'cursor-pointer':
                    element.children.length,
                }
              )}
            >
              <CheckboxIcon
                onClick={(e) => {
                  handleSelect(e)
                  e.stopPropagation()
                }}
                variant={
                  isHalfSelected
                    ? 'some'
                    : isSelected
                    ? 'all'
                    : 'none'
                }
              />
              <span>{element.name}</span>
            </div>
          )}
        />
      )}
    </div>
  )
}

export { Tree }
