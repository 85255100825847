import { Filter } from './FilterBuilder.types'

class FilterBuilder {
  private filters: Filter[] = []

  addFilter(filter: Filter): this {
    if (
      this.filters.some(
        (f) => f.field === filter.field
      )
    ) {
      throw new Error(
        `Filter with field ${filter.field} already exists`
      )
    }

    this.filters.push(filter)

    return this
  }

  getFilters(): Filter[] {
    return this.filters
  }

  removeFilter(field: string): this {
    this.filters = this.filters.filter(
      (f) => f.field !== field
    )

    return this
  }
}

export { FilterBuilder }
