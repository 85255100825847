import { TFunction } from 'i18next'
import * as yup from 'yup'
import { UpdateInvoiceRequestInterface } from '@/features/invoices/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'

export default class UpdateInvoiceValidation
  implements
    ValidationInterface<UpdateInvoiceRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    UpdateInvoiceRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      issue_date: yup
        .string()
        .required(t('validation:required'))
        .test(
          'is-date',
          t('validation:date_invalid'),
          (value) => {
            return value
              ? !isNaN(Date.parse(value))
              : true
          }
        ),
      sale_date: yup
        .string()
        .required(t('validation:required'))
        .test(
          'is-date',
          t('validation:date_invalid'),
          (value) => {
            return value
              ? !isNaN(Date.parse(value))
              : true
          }
        ),
      buyer: yup.object().shape({
        company_name: yup
          .string()
          .required(t('validation:required')),
        nip: yup
          .string()
          .required(t('validation:required'))
          .length(
            10,
            t('validation:equal', { length: 10 })
          ),
        street: yup
          .string()
          .required(t('validation:required')),
        postal_code: yup
          .string()
          .required(t('validation:required')),
        city: yup
          .string()
          .required(t('validation:required')),
        property_number: yup
          .string()
          .required(t('validation:required')),
        apartment_number: yup.string().nullable(),
      }),
    })
  }

  defaultValues(): UpdateInvoiceRequestInterface {
    return {
      issue_date: '',
      sale_date: '',
      buyer: {
        company_name: '',
        nip: '',
        street: '',
        postal_code: '',
        city: '',
        property_number: '',
        apartment_number: null,
      },
    }
  }
}
