import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { toast } from 'react-toastify'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { SaveRoleRequestInterface } from '@/features/roles/redux/types'
import { Form } from '@/features/roles/resources/_components/form'
import {
  useGetRoleQuery,
  useUpdateRoleMutation,
} from '@/features/roles/redux/roleAPI'

const RolesEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(['roles'])
  const { id } = useParams<{ id: string }>() as {
    id: string
  }
  const [updateRole] = useUpdateRoleMutation()
  const { data } = useGetRoleQuery(id)
  const navigate = useNavigate()

  const handleSubmit = (
    data: SaveRoleRequestInterface
  ) => {
    updateRole({ id, body: data })
      .unwrap()
      .then(() => {
        toast.success(t('roles:edit.success'))
        navigate('/roles')
      })
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('roles:edit.title')}
      </span>
      <Card>
        <Form
          data={data}
          onSubmit={handleSubmit}
        />
      </Card>
    </div>
  )
}

export { RolesEditScreen }
