import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { ButtonLink } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './partials'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { useSearch } from '@/utils/hooks/useSearch'
import { Search } from '@/features/components/inputs/Search'
import {
  ColumnVisibility,
  FilterBuilder,
  Filters,
  useColumnVisibility,
  useFilters,
} from '@/components'
import { Transition } from 'react-transition-group'
import { Label } from '@/features/components/inputs/label'
import {
  SearchRole,
  SearchUser,
} from '@/components/Inputs'

const UsersListScreen = (): React.ReactNode => {
  const { t } = useTranslation(['users', 'utils'])
  const { hasPermission } = usePermissions()
  const { query, setQueryParams } = useSearch({
    columnsForSearch: [
      'name',
      'email',
      'phoneNumber.phone_number',
    ],
  })
  const {
    handleToggleColumn,
    handleSetAvailableColumns,
    visibilityColumnsWithLabels,
    visibilityColumns,
  } = useColumnVisibility()

  const { ref, TriggerFilters, showFilters } =
    useFilters()

  return (
    <div className={'flex flex-col gap-y-4 p-4'}>
      <div
        className={
          'flex flex-col gap-2 justify-between lg:flex-row'
        }
      >
        {hasPermission(
          PermissionEnum.USER_SAVE
        ) && (
          <div>
            <ButtonLink
              variant={'contained'}
              className={
                'flex items-center gap-x-2'
              }
              to={'/users/create'}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={'text-white'}
              />
              <span>
                {t('users:list.create')}
              </span>
            </ButtonLink>
          </div>
        )}
        <ColumnVisibility
          columns={visibilityColumnsWithLabels}
          toggleColumn={handleToggleColumn}
        />
      </div>
      <Transition
        nodeRef={ref}
        unmountOnExit={true}
        in={showFilters}
        timeout={200}
        classNames={'transition-height'}
      >
        <Filters
          filterBuilder={builder}
          onSubmit={setQueryParams}
        />
      </Transition>
      <div
        className={
          'flex flex-col lg:flex-row gap-y-4 lg:justify-between lg:items-center'
        }
      >
        <TriggerFilters />
        <Search onChange={setQueryParams} />
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>{t('users:list.title')}</span>
          </div>
          <Table
            query={query}
            visibility={visibilityColumns}
            setAvailableColumns={
              handleSetAvailableColumns
            }
          />
        </div>
      </Card>
    </div>
  )
}

const builder = new FilterBuilder()
  .addFilter({
    field: 'id',
    component: 'custom',
    props: {
      label: 'form:labels.user',
    },
    customComponent: ({ onChange, label }) => (
      <div className={'flex flex-col'}>
        {label && <Label label={label} />}
        <SearchUser
          isMulti={false}
          onChange={onChange}
        />
      </div>
    ),
  })
  .addFilter({
    field: 'role_id',
    component: 'custom',
    props: {
      label: 'form:labels.role',
    },
    customComponent: ({ onChange, label }) => (
      <div className={'flex flex-col'}>
        {label && <Label label={label} />}
        <SearchRole
          isMulti={false}
          onChange={onChange}
        />
      </div>
    ),
  })
  .addFilter({
    field: 'trashed',
    component: 'select',
    props: {
      placeholder: 'form:placeholders.select',
      label: 'form:labels.trashed',
    },
    optionsProps: {
      translate: true,
      options: [
        {
          value: 'only',
          label: 'form:labels.only_trashed',
        },
        {
          value: 'with',
          label: 'form:labels.with_trashed',
        },
      ],
    },
  })
  .addFilter({
    field: 'is_active',
    component: 'select',
    props: {
      placeholder:
        'form:placeholders.select_status',
      label: 'form:labels.status',
    },
    optionsProps: {
      translate: true,
      options: [
        {
          value: '1',
          label: 'form:labels.active',
        },
        {
          value: '0',
          label: 'form:labels.inactive',
        },
      ],
    },
  })
  .addFilter({
    field: 'is_blocked',
    component: 'select',
    props: {
      placeholder: 'form:placeholders.select',
      label: 'form:labels.is_blocked',
    },
    optionsProps: {
      translate: true,
      options: [
        {
          value: '1',
          label: 'form:labels.yes',
        },
        {
          value: '0',
          label: 'form:labels.no',
        },
      ],
    },
  })
  .addFilter({
    field: 'is_premium',
    component: 'select',
    props: {
      placeholder: 'form:placeholders.select',
      label: 'form:labels.is_premium',
    },
    optionsProps: {
      translate: true,
      options: [
        {
          value: '1',
          label: 'form:labels.yes',
        },
        {
          value: '0',
          label: 'form:labels.no',
        },
      ],
    },
  })

export { UsersListScreen }
