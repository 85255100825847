import React, {
  FC,
  ReactNode,
  useEffect,
} from 'react'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import { useTranslation } from 'react-i18next'
import {
  useGetAgreementQuery,
  useUpdateAgreementMutation,
} from '@/features/agreements'
import useValidation from '@/utils/hooks/useValidation'
import { UpdateAgreementValidation } from './rules'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { omit } from 'lodash'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { FormControl } from '@mui/base'
import {
  FormHelperText,
  Label,
  TextInput,
} from '@/components'
import Select from 'react-select'
import { AgreementGroupEnum } from '@/features/agreements/redux/enums'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Button } from '@/features/components/buttons/button'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'

const Form: FC<
  { id: number } & InstanceProps<void>
> = ({
  id,
  isOpen,
  onReject,
  onResolve,
}): ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'agreements',
    'validation',
  ])
  const { data } = useGetAgreementQuery(id)
  const { schema, defaultValues } = useValidation(
    new UpdateAgreementValidation(),
    t
  )
  const { handleSubmit, control, reset } =
    useForm<typeof defaultValues>({
      defaultValues,
      resolver: yupResolver(schema),
    })
  const [updateAgreement] =
    useUpdateAgreementMutation()

  useEffect(() => {
    if (data) {
      reset({
        ...omit(data, [
          'id',
          'file_url',
          'content',
          'agreement_groups',
        ]),
        groups: data.agreement_groups.map(
          (a) => a.name
        ),
      })
    }
  }, [data])

  const onSubmit = async (
    data: typeof defaultValues
  ) => {
    try {
      await updateAgreement({
        body: serialize(
          {
            ...data,
            groups: data.groups.map((group) => ({
              name: group,
            })),
          },
          {
            indices: true,
            booleansAsIntegers: true,
          }
        ),
        id,
      }).unwrap()
      toast.success(t('agreements:edit.success'))
      onResolve()
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={t('agreements:edit.title')}
          onClose={() => onReject()}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={'flex flex-col gap-4 pt-4'}
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <TextInput
                  label={t('form:labels.name')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'name'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <TextInput
                  multiline
                  rows={5}
                  label={t(
                    'form:labels.description'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'description'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <TextInput
                  label={t(
                    'form:labels.text_link'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'text_link'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                className={'flex flex-col'}
                error={!!error}
              >
                <Label
                  label={t(
                    'form:labels.agreement_group'
                  )}
                />
                <Select
                  onChange={(value) =>
                    field.onChange(
                      value.map((v) => v.value)
                    )
                  }
                  value={field.value.map((v) => ({
                    value: v,
                    label: t(
                      `utils:agreement_groups.${v}`
                    ),
                  }))}
                  options={Object.values(
                    AgreementGroupEnum
                  ).map((value) => ({
                    value,
                    label: t(
                      `utils:agreement_groups.${value}`
                    ),
                  }))}
                  isMulti
                  placeholder={t(
                    'form:placeholders.select_groups'
                  )}
                  classNames={{
                    control: () =>
                      '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent !min-h-[42px]',
                    menuList: () =>
                      '!max-h-[200px]',
                  }}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'groups'}
            control={control}
          />
          <div
            className={'flex flex-col gap-1 pt-4'}
          >
            <Controller
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                  label={t(
                    'form:labels.required'
                  )}
                />
              )}
              name={'required'}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                  label={t('form:labels.visible')}
                />
              )}
              name={'visible'}
              control={control}
            />
          </div>
          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const agreementEditModal = create(Form)
