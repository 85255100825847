import {
  ArticleGalleryInterface,
  SaveArticleGalleryInterface,
} from '@/features/articleGalleries/redux/types'
import {
  create,
  InstanceProps,
} from 'react-modal-promise'
import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import SaveArticleGalleryValidation from './validations/SaveArticleGalleryValidation'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import Modal from '@/features/components/modals/modal'
import ModalTitle from '@/features/components/modals/ModalTitle'
import { FormControl } from '@mui/base'
import { Input } from '@/features/components/inputs/input'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Button } from '@/features/components/buttons/button'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { ArticleGalleryStatusEnum } from '@/features/articleGalleries/redux/enums/ArticleGalleryStatusEnum'
import _ from 'lodash'

type Props = {
  data?: ArticleGalleryInterface
  title: string
} & InstanceProps<SaveArticleGalleryInterface>

const Form: React.FC<Props> = ({
  data,
  isOpen,
  onReject,
  onResolve,
  title,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'utils',
    'validation',
  ])
  const { schema, defaultValues } = useValidation(
    new SaveArticleGalleryValidation(),
    t
  )
  const { control, setValue, handleSubmit } =
    useForm<SaveArticleGalleryInterface>({
      resolver: yupResolver(schema),
      defaultValues: defaultValues,
    })

  useEffect(() => {
    if (data) {
      setValue('title', data.title)
      setValue('description', data.description)
      setValue('status', data.status)
    }
  }, [data])

  return (
    <Modal
      open={isOpen}
      onClose={() => onReject()}
    >
      <Modal.Content>
        <ModalTitle
          title={title}
          onClose={onReject}
        />

        <form
          onSubmit={handleSubmit(onResolve)}
          className={'flex flex-col gap-y-2 pt-8'}
        >
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t('form:labels.title')}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'title'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl
                {...field}
                error={!!error}
              >
                <Input
                  label={t(
                    'form:labels.description'
                  )}
                />
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'description'}
            control={control}
          />
          <Controller
            render={({
              field,
              fieldState: { error },
            }) => (
              <FormControl error={!!error}>
                <Select
                  value={field.value}
                  onChange={(_, value) =>
                    field.onChange(value)
                  }
                  label={t('form:labels.status')}
                >
                  {_.map(
                    ArticleGalleryStatusEnum,
                    (value, index) => (
                      <Option
                        value={value}
                        key={index}
                      >
                        {t(
                          `utils:article_gallery_statuses.${value}`
                        )}
                      </Option>
                    )
                  )}
                </Select>
                <FormHelperText
                  message={error?.message}
                />
              </FormControl>
            )}
            name={'status'}
            control={control}
          />
          <div className={'flex gap-x-4 pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
              className={'w-full'}
            >
              {t('form:buttons.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => onReject()}
              className={'w-full'}
            >
              {t('form:buttons.cancel')}
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

export const articleGalleriesModal = create(Form)
