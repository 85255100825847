import {
  forwardRef,
  useCallback,
  useMemo,
} from 'react'
import {
  Input,
  InputOwnerState,
  useFormControlContext,
} from '@mui/base'
import { Label } from '../Label'
import { TextInputProps } from './TextInput.types'
import clsx from 'clsx'

const TextInput = forwardRef<
  HTMLDivElement,
  TextInputProps
>((props, ref) => {
  const formControlContext =
    useFormControlContext()
  const {
    inputProps,
    containerProps,
    label,
    ...rest
  } = props

  const containerClassName = clsx(
    'flex w-full flex-col gap-y-0.5',
    props.containerProps?.className
  )

  const inputClassName = useCallback(
    (inputOwnerState: InputOwnerState) =>
      clsx(
        'flex-grow lg:max-w-full pr-1 bg-white outline-none placeholder-gray-950 w-full',
        {
          'pr-1':
            !!inputOwnerState.startAdornment,
        }
      ),
    []
  )

  const rootClassName = useMemo(
    () =>
      clsx(
        'bg-white flex items-center border border-neutral-700 rounded-md focus:ring-1 focus:ring-primary focus:border-transparent px-4 py-2',
        {
          'border-red-500':
            formControlContext?.error,
        }
      ),
    [formControlContext?.error]
  )

  return (
    <div
      {...containerProps}
      className={containerClassName}
    >
      {label && <Label label={label} />}
      <Input
        ref={ref}
        {...rest}
        slotProps={{
          input: (props) => ({
            className: inputClassName(props),
            ...inputProps,
          }),
          root: { className: rootClassName },
        }}
      />
    </div>
  )
})

TextInput.displayName = 'TextInput'

export { TextInput }
