import React, {
  forwardRef,
  HTMLProps,
} from 'react'
import clsx from 'clsx'

const Card = forwardRef(
  (
    props: HTMLProps<HTMLDivElement> & {
      noPadding?: boolean
      margin?: boolean
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const {
      className,
      noPadding = false,
      ...rest
    } = props

    const classes = clsx(
      className,
      'py-4.3 px-2 md:px-6 border border-neutral-600 shadow-sm rounded-md bg-white',
      {
        '!p-0': noPadding,
        'mb-32 lg:mb-8': props.margin,
      }
    )

    return (
      <div
        ref={ref}
        className={classes}
        {...rest}
      />
    )
  }
)

Card.displayName = 'Card'

export default Card
