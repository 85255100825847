import React, { useMemo } from 'react'
import Card from '@/features/components/cards'
import { useTranslation } from 'react-i18next'
import TreeView from 'react-accessible-treeview'
import clsx from 'clsx'
import { CategoryTreeInterface } from '@/features/posts/categories/redux/types'

type Props = {
  tree: CategoryTreeInterface[]
}

export const Tree: React.FC<Props> = ({
  tree,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'posts/categories',
  ])
  const data = useMemo(() => {
    const builtTree = tree.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        parent: 0,
        children: tree
          .filter(
            (child) => child.parent_id === item.id
          )
          .map((child) => child.id),
      }
    })

    return [
      {
        id: 0,
        name: '',
        parent: null,
        children: tree
          .filter(
            (item) => item.parent_id === null
          )
          .map((item) => item.id),
      },
      ...builtTree,
    ]
  }, [tree])

  const images = useMemo(() => {
    const imagesObject: Record<
      number,
      string | null
    > = {}

    tree.forEach((item) => {
      imagesObject[item.id] = item.icon_url
    })

    return imagesObject
  }, [tree])

  return (
    <Card>
      <div className={'flex flex-col gap-y-6'}>
        <span className={'text-lg font-medium'}>
          {t('posts/categories:details.tree')}
        </span>
        <div>
          <TreeView
            data={data}
            nodeRenderer={({
              element,
              getNodeProps,
              level,
              handleSelect,
            }) => (
              <div
                {...getNodeProps()}
                style={{
                  paddingLeft: `${
                    (level - 1) * 40
                  }px`,
                }}
                className={clsx(
                  `flex items-center gap-x-2`,
                  {
                    'cursor-pointer':
                      element.children.length,
                  }
                )}
              >
                {images[element.id as number] && (
                  <img
                    src={
                      images[
                        element.id as number
                      ] as string
                    }
                    className={'size-4'}
                    alt={''}
                  />
                )}
                <span>{element.name}</span>
              </div>
            )}
          />
        </div>
      </div>
    </Card>
  )
}
