import React, { useState } from 'react'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { useTranslation } from 'react-i18next'
import { Button } from '@/features/components/buttons/button'
import { AllowedMassActionType } from '@/features/posts/posts/redux/types'
import { confirmModal } from '@/features/components/modals/confirm'
import { Select2OptionInterface } from '@/app/types'
import { SearchCategory } from '@/features/components/inputs/asyncSelect/searchCategory'
import { match } from 'ts-pattern'

type Props = {
  onConfirm: (
    type: AllowedMassActionType,
    categorId?: number
  ) => void
}

export const MassAction: React.FC<Props> = ({
  onConfirm,
}): React.ReactNode => {
  const [action, setSelectAction] =
    useState<AllowedMassActionType>()
  const { hasPermission } = usePermissions()
  const { t } = useTranslation(['posts/posts'])
  const [selectedCategory, setSelectedCategory] =
    useState<Select2OptionInterface | null>(null)

  const handleClick = () => {
    match(action)
      .with('delete', (action) =>
        confirmModal({
          message: t(
            'posts/posts:mass_actions.delete_confirm'
          ),
          type: 'warning',
        }).then(() => onConfirm(action))
      )
      .with(
        'enable_price_negotiations',
        (action) =>
          confirmModal({
            message: t(
              'posts/posts:mass_actions.enable_price_negotiations'
            ),
            type: 'warning',
          }).then(() => onConfirm(action))
      )
      .with('block', (action) =>
        confirmModal({
          message: t(
            'posts/posts:mass_actions.block_confirm'
          ),
          type: 'warning',
        }).then(() => onConfirm(action))
      )
      .with('switch_category', (action) => {
        if (!selectedCategory) {
          return
        }

        confirmModal({
          message: t(
            'posts/posts:mass_actions.switch_confirm'
          ),
          type: 'warning',
        }).then(() =>
          onConfirm(
            action,
            Number(selectedCategory.value)
          )
        )
      })
  }

  return (
    <div
      className={
        'flex gap-2 flex-col lg:flex-row items-center w-full lg:max-w-fit'
      }
    >
      <div
        className={'w-full flex flex-col gap-4'}
      >
        <Select
          buttonClassNames={
            'bg-white !min-w-fit lg:w-[320px]'
          }
          placeholder={t(
            'posts/posts:mass_actions.select_action'
          )}
          value={action}
          onChange={(_, value) =>
            setSelectAction(
              value as AllowedMassActionType
            )
          }
        >
          {hasPermission(
            PermissionEnum.POST_DESTROY
          ) && (
            <Option value={'delete'}>
              {t(
                'posts/posts:mass_actions.delete'
              )}
            </Option>
          )}
          {hasPermission(
            PermissionEnum.POST_SAVE
          ) && (
            <Option
              value={'enable_price_negotiations'}
            >
              {t(
                'posts/posts:mass_actions.set_negotianable'
              )}
            </Option>
          )}
          {hasPermission(
            PermissionEnum.POST_SAVE
          ) && (
            <Option value={'block'}>
              {t(
                'posts/posts:mass_actions.block'
              )}
            </Option>
          )}
          {hasPermission(
            PermissionEnum.POST_SAVE
          ) && (
            <Option value={'switch_category'}>
              {t(
                'posts/posts:mass_actions.switch_category'
              )}
            </Option>
          )}
        </Select>
        {action === 'switch_category' && (
          <SearchCategory
            onChange={setSelectedCategory}
            isMulti={false}
          />
        )}
      </div>
      <div className={'flex w-full'}>
        <Button
          size={'small'}
          variant={'contained'}
          onClick={handleClick}
        >
          {t('posts/posts:mass_actions.apply')}
        </Button>
      </div>
    </div>
  )
}
