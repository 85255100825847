import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveBundleCategoryRequestInterface } from '@/features/categoryBundles/redux/types'

class SaveCategoryBundleValidation
  implements
    ValidationInterface<SaveBundleCategoryRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveBundleCategoryRequestInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required')),
      is_active: yup
        .boolean()
        .required(t('validation:required')),
      category_ids: yup
        .array()
        .of(yup.number().required())
        .required(),
    })
  }

  defaultValues(): SaveBundleCategoryRequestInterface {
    return {
      name: '',
      is_active: false,
      category_ids: [],
    }
  }
}

export { SaveCategoryBundleValidation }
