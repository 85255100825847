import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { FormHelperText } from '@/features/components/inputs/formHelperText'
import { Input } from '@/features/components/inputs/input'
import { Button } from '@/features/components/buttons/button'
import useValidation from '@/utils/hooks/useValidation'
import { ChangePasswordInterface } from '@/features/auth/redux/types'
import _ from 'lodash'
import ChangePasswordValidation from '../validations/changePasswordValidation'
import { FormInterface } from '@/app/types'

const Form: React.FC<
  FormInterface<ChangePasswordInterface>
> = ({
  onSubmit,
  errors: backendErrors,
}): React.ReactNode => {
  const { t } = useTranslation([
    'auth',
    'form',
    'validation',
  ])
  const {
    schema,
    defaultValues,
    resolveValidationErrors,
  } = useValidation(
    new ChangePasswordValidation(),
    t
  )

  const { control, handleSubmit, setError } =
    useForm({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (backendErrors) {
      const err = resolveValidationErrors(
        backendErrors
      )

      if (err) {
        _.forEach(err, (value, key) => {
          setError(
            key as keyof ChangePasswordInterface,
            {
              type: 'manual',
              message: value,
            }
          )
        })
      }
    }
  }, [backendErrors])

  return (
    <form
      className={'flex flex-col gap-y-4'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className={'grid lg:grid-cols-2 gap-4'}
      >
        <Controller
          name={'old_password'}
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t(
                  'form:labels.old_password'
                )}
                name={field.name}
                type={'password'}
                placeholder={t(
                  'form:placeholders.password'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name={'new_password'}
          control={control}
          render={({
            field,
            fieldState: { error },
          }) => (
            <FormControl
              {...field}
              error={!!error}
            >
              <Input
                label={t(
                  'form:labels.new_password'
                )}
                type={'password'}
                name={field.name}
                placeholder={t(
                  'form:placeholders.password'
                )}
              />
              <FormHelperText
                message={error?.message}
              />
            </FormControl>
          )}
        />
      </div>
      <div className={'pt-8'}>
        <Button
          variant={'contained'}
          type={'submit'}
        >
          {t('auth:change_password.button')}
        </Button>
      </div>
    </form>
  )
}
export { Form }
