import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import usePagination from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import {
  useDeleteServiceCategoryMutation,
  useGetServiceCategoriesQuery,
  useUpdateServiceCategoryMutation,
} from '@/features/services/redux/serviceAPI'
import { ServiceCategoryInterface } from '@/features/services/redux/types'
import { serviceCategoryForm } from '@/features/services/resources/views/edit/categories/_components/form'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
} from '@/components'

type Props = {
  serviceId: number | string
}

export const Table: React.FC<Props> = ({
  serviceId,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [pagination, setPagination] =
    usePagination()
  const query = useMemo(
    () =>
      new Query()
        .page(pagination.pageIndex)
        .includes('category'),
    [pagination]
  )
  const { data: apiData } =
    useGetServiceCategoriesQuery({
      id: serviceId,
      query: query.url(),
    })
  const [data, setData] = useState<
    ServiceCategoryInterface[]
  >([])
  const [deleteServiceCategory] =
    useDeleteServiceCategoryMutation()
  const [updateServiceCategory] =
    useUpdateServiceCategoryMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = (id: number) => {
    deleteServiceCategory({ serviceId, id })
      .unwrap()
      .then(() =>
        toast.success(
          t('services:categories.list.deleted')
        )
      )
  }

  const onEdit = (id: number) => {
    const serviceCategory = data.find(
      (serviceCategory) =>
        serviceCategory.id === id
    )

    if (
      !serviceCategory ||
      !serviceCategory.category
    )
      return

    serviceCategoryForm({
      data: serviceCategory,
      title: t('services:categories.edit.title'),
    }).then((data) => {
      updateServiceCategory({
        serviceId,
        id,
        body: data,
      })
        .unwrap()
        .then(() =>
          toast.success(
            t('services:categories.edit.success')
          )
        )
    })
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <BaseTable
      pagination={apiData?.pagination}
      table={table}
    />
  )
}

const columnBuilder =
  createColumnHelper<ServiceCategoryInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('category.name', {
    id: 'category.name',
    header: t('form:labels.category_name'),
  }),
  columnBuilder.accessor('price', {
    id: 'price',
    header: t('form:labels.price'),
    cell: ({ row }) =>
      Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
      }).format(row.original.price),
  }),
  columnBuilder.accessor('points', {
    id: 'points',
    header: t('form:labels.points'),
  }),
  columnBuilder.accessor('discounted_price', {
    id: 'discounted_price',
    header: t('form:labels.discounted_price'),
    cell: ({ row }) =>
      row.original.discounted_price
        ? Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'PLN',
          }).format(row.original.discounted_price)
        : '-',
  }),
  columnBuilder.accessor('discounted_points', {
    id: 'discounted_points',
    header: t('form:labels.discounted_points'),
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.SERVICE_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.SERVICE_DESTROY,
          },
        })}
      />
    ),
  }),
]
