import { Select2OptionInterface } from '@/app/types'
import { Query } from '@/utils/query'
import { useLazySearchCategoriesQuery } from '@/features/posts/categories/redux/categoryAPI'
import _, { isArray } from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  MultiValue,
  SingleValue,
} from 'react-select'
import AsyncSelect from './asyncSelect'
import { useTranslation } from 'react-i18next'
import { match } from 'ts-pattern'
import { CategoryInterface } from '@/features/posts/categories/redux/types'
import {
  isSelect2OptionInterface,
  isSelect2OptionInterfaceArray,
} from '@/utils/helpers/matchers'

type Props<TMultiple = boolean> =
  TMultiple extends true
    ? {
        defaultValue?:
          | number[]
          | string[]
          | null
          | Select2OptionInterface[]
          | Select2OptionInterface
        onChange: (
          value: MultiValue<Select2OptionInterface>
        ) => void
        isMulti: TMultiple
      }
    : {
        defaultValue?:
          | number
          | string
          | null
          | Select2OptionInterface[]
          | Select2OptionInterface
        onChange: (
          value: SingleValue<Select2OptionInterface>
        ) => void
        isMulti: TMultiple
      }

type SelectProps = Props & {
  query?: Query
}

export const SearchCategory: React.FC<
  SelectProps
> = ({
  defaultValue,
  onChange,
  isMulti,
  query: _query,
}): React.ReactNode => {
  const [searchCategories] =
    useLazySearchCategoriesQuery()
  const { t } = useTranslation(['form'])
  const [selectedCategory, setSelectedCategory] =
    useState<
      | Select2OptionInterface
      | Select2OptionInterface[]
      | null
    >()

  useEffect(() => {
    if (defaultValue) {
      if (
        isSelect2OptionInterface(defaultValue) ||
        isSelect2OptionInterfaceArray(
          defaultValue
        )
      ) {
        setSelectedCategory(defaultValue)
        return
      }

      const ids = isArray(defaultValue)
        ? defaultValue.join(',')
        : defaultValue

      searchCategories(
        new Query().where('id', ids).url()
      )
        .unwrap()
        .then((response) => {
          const value = match(isMulti)
            .with(false, () => {
              const category = response[0]
              return {
                label: category.name,
                value: category.id.toString(),
              }
            })
            .with(true, () =>
              transformToSelect2Option(response)
            )
            .otherwise(() => null)

          setSelectedCategory(value)
        })
    } else {
      setSelectedCategory(null)
    }
  }, [defaultValue])

  const transformToSelect2Option = (
    response: Pick<
      CategoryInterface,
      'id' | 'name'
    >[]
  ) =>
    response.map((category) => ({
      label: category.name,
      value: category.id.toString(),
    }))

  const handleChange = (
    value:
      | SingleValue<Select2OptionInterface>
      | MultiValue<Select2OptionInterface>
  ) => {
    if (isMulti) {
      setSelectedCategory(
        value as Select2OptionInterface[]
      )
    } else {
      setSelectedCategory(
        value as SingleValue<Select2OptionInterface>
      )
    }

    if (isMulti) {
      onChange(
        value as MultiValue<Select2OptionInterface>
      )
    } else {
      onChange(
        value as SingleValue<Select2OptionInterface>
      )
    }
  }

  const _handleSearchCategory = (
    value: string,
    callback: (
      options: Select2OptionInterface[]
    ) => void
  ) => {
    const query = _query ? _query : new Query()

    query.where('name', value)

    if (
      defaultValue &&
      !isArray(defaultValue) &&
      !isSelect2OptionInterface(defaultValue)
    )
      query.whereNotIn([defaultValue])

    searchCategories(query.url())
      .unwrap()
      .then((response) =>
        callback(
          transformToSelect2Option(response)
        )
      )
  }

  const handleSearchCategory = _.debounce(
    _handleSearchCategory,
    500
  )

  return (
    <AsyncSelect
      isMulti={isMulti}
      placeholder={t(
        'form:placeholders.type_to_search_category'
      )}
      isClearable={true}
      cacheOptions
      onChange={handleChange}
      value={selectedCategory}
      loadOptions={handleSearchCategory}
    />
  )
}
