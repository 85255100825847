import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { SaveInternalQuotationCategoryInterface } from '@/features/internalQuotationCategories/redux/types'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { UnitEnum } from '@/app/enums/unitEnum'

export default class SaveInternalQuotationCategoryValidation
  implements
    ValidationInterface<SaveInternalQuotationCategoryInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<
    SaveInternalQuotationCategoryInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('validation:required'))
        .max(
          255,
          t('validation:max', { max: 255 })
        ),
      order: yup
        .number()
        .required()
        .min(0, t('validation:min', { min: 0 })),
      category_id: yup
        .number()
        .required('validation:required')
        .min(0, t('validation:min', { min: 0 })),
      price_from: yup
        .number()
        .min(0, t('validation:min', { min: 0 }))
        .required('validation:required'),
      price_to: yup
        .number()
        .min(0, t('validation:min', { min: 0 }))
        .moreThan(
          yup.ref('price_from'),
          (value) =>
            t(
              'validation:field_must_be_greater_than',
              {
                min: value.more,
              }
            )
        )
        .required('validation:required'),
      unit: yup
        .mixed<UnitEnum>()
        .oneOf(Object.values(UnitEnum))
        .required('validation:required'),
      description: yup
        .string()
        .nullable()
        .max(
          500,
          t('validation:max', { max: 500 })
        ),
      is_active: yup.boolean().required(),
      conversion_unit_from: yup
        .mixed<UnitEnum>()
        .oneOf(Object.values(UnitEnum))
        .nullable(),
    })
  }

  defaultValues(): SaveInternalQuotationCategoryInterface {
    return {
      name: '',
      order: 0,
      category_id: 0,
      price_from: 0,
      price_to: 0,
      unit: UnitEnum.METER,
      is_active: false,
      conversion_unit_from: null,
      description: '',
    }
  }
}
