import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { toast } from 'react-toastify'
import { ArticleMediaInterface } from '@/features/articleMediaGalleries/redux/types'
import {
  useDeleteArticleMediaGalleryMutation,
  useGetArticleMediaGalleryQuery,
  useUpdateArticleMediaGalleryMutation,
} from '@/features/articleMediaGalleries/redux/articleMediaGalleryAPI'
import { editArticleMediaGalleryModal } from '@/features/articleMediaGalleries/resources/views/edit'
import {
  ActionStack,
  baseActionStack,
  BaseTable,
  VisibleColumnsInterface,
} from '@/components'

type Props = {
  articleGalleryId: string
} & VisibleColumnsInterface
export const Table: React.FC<Props> = ({
  articleGalleryId,
  setAvailableColumns,
  visibility,
}): React.ReactNode => {
  const { t } = useTranslation([
    'form',
    'article_media_gallery',
    'utils',
  ])
  const { data = [] } =
    useGetArticleMediaGalleryQuery(
      articleGalleryId
    )
  const [deleteArticleMediaGallery] =
    useDeleteArticleMediaGalleryMutation()
  const [updateArticleMediaGallery] =
    useUpdateArticleMediaGalleryMutation()

  const onDelete = (articleMediaId: number) => {
    deleteArticleMediaGallery({
      articleGalleryId,
      articleMediaId,
    })
      .unwrap()
      .then(() =>
        toast.success(
          t('article_media_gallery:list.deleted')
        )
      )
  }

  const onEdit = (articleMediaId: number) => {
    const row = data.find(
      (item) => item.id === articleMediaId
    )

    editArticleMediaGalleryModal({
      title: t(
        'article_media_gallery:edit.title'
      ),
      data: row,
    }).then((data) =>
      updateArticleMediaGallery({
        data,
        articleGalleryId,
        articleMediaId,
      })
        .unwrap()
        .then(() =>
          toast.success(
            t(
              'article_media_gallery:edit.success'
            )
          )
        )
    )
  }

  const table = useReactTable({
    columns: columns(t, onDelete, onEdit),
    getCoreRowModel: getCoreRowModel(),
    data,
    enableSorting: false,
    enableHiding: true,
    state: {
      columnVisibility: visibility,
    },
  })

  useEffect(() => {
    setAvailableColumns(table)
  }, [table.getVisibleFlatColumns])

  return <BaseTable table={table} />
}

const columnBuilder =
  createColumnHelper<ArticleMediaInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('order', {
    id: 'order',
    header: t('form:labels.order'),
    enableHiding: true,
  }),
  columnBuilder.display({
    id: 'image',
    header: t('form:labels.image'),
    cell: ({ row }) => {
      const defaultPhoto =
        row.original.article_media_files.find(
          (article) =>
            article.collection_name === 'default'
        )?.url

      return (
        <img
          src={defaultPhoto}
          alt={''}
          className={'max-w-[100px] max-h-[80px]'}
        />
      )
    },
    enableHiding: true,
  }),
  columnBuilder.accessor('title', {
    id: 'title',
    header: t('form:labels.title'),
    cell: ({ row }) => row.original.title,
    enableHiding: true,
  }),
  columnBuilder.accessor('description', {
    id: 'description',
    header: t('form:labels.description'),
    enableHiding: true,
  }),
  columnBuilder.accessor('has_watermark', {
    id: 'watermark',
    header: t('form:labels.watermark'),
    cell: ({ row }) =>
      row.original.has_watermark
        ? t('form:labels.yes')
        : t('form:labels.no'),
    enableHiding: true,
  }),

  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onEdit: {
            action: () => onEdit(row.original.id),
            permission:
              PermissionEnum.ARTICLE_GALLERY_SAVE,
          },
          onDelete: {
            action: () =>
              onDelete(row.original.id),
            permission:
              PermissionEnum.ARTICLE_GALLERY_DESTROY,
          },
        })}
      />
    ),
    enableHiding: true,
  }),
]
