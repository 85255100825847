import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { Button } from '@/features/components/buttons/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Card from '@/features/components/cards'
import {
  protectionFormModal,
  Table,
} from './partials'
import {
  SaveProtectionRequestInterface,
  useStoreProtectionMutation,
} from '@/features/protections/redux'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const ProtectionListScreen = (): ReactNode => {
  const { t } = useTranslation(['protections'])
  const { hasPermission } = usePermissions()
  const [storeProtection] =
    useStoreProtectionMutation()
  const {
    handleToggleColumn,
    handleSetAvailableColumns,
    visibilityColumnsWithLabels,
    visibilityColumns,
  } = useColumnVisibility()

  const handleCreate = async () => {
    try {
      const data: SaveProtectionRequestInterface =
        await protectionFormModal({
          action: 'create',
          title: t('protections:create.title'),
        })

      const serializedData = serialize(data, {
        indices: true,
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
      })

      await storeProtection(
        serializedData
      ).unwrap()

      toast.success(
        t('protections:create.success')
      )
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <div
        className={
          'flex flex-col gap-2 justify-between lg:flex-row'
        }
      >
        {hasPermission(
          PermissionEnum.PROTECTION_SAVE
        ) && (
          <div>
            <Button
              variant={'contained'}
              className={
                'flex items-center gap-x-2'
              }
              onClick={handleCreate}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className={'text-white'}
              />
              <span>
                {t('protections:list.create')}
              </span>
            </Button>
          </div>
        )}
        <ColumnVisibility
          columns={visibilityColumnsWithLabels}
          toggleColumn={(id: string) =>
            handleToggleColumn(id)
          }
        />
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>
              {t('protections:list.title')}
            </span>
          </div>
          <Table
            visibility={visibilityColumns}
            setAvailableColumns={
              handleSetAvailableColumns
            }
          />
        </div>
      </Card>
    </div>
  )
}

export { ProtectionListScreen }
