import { AbstractAsyncSelectStrategy } from './AbstractAsyncSelectStrategy'
import { isArray } from 'lodash'
import {
  HandleFetchResponse,
  NonNullableDefaultValue,
} from '../useAsyncSelect.types'

class ArrayAsyncSelectStrategy<
  T extends object,
  TMultiple extends boolean
> extends AbstractAsyncSelectStrategy<
  T,
  TMultiple
> {
  getProperties(): Record<
    string,
    string | Function
  > {
    return {}
  }

  handleFetch = async (
    searchValue: string
  ): Promise<HandleFetchResponse<'array'>> => {
    const { label, value, search } =
      this.columnFields

    try {
      const response: T[] =
        await this.fetchFunction(
          this.prepareQuery()
            .where(search, searchValue)
            .url()
        ).unwrap()

      return response.map((item) => ({
        label: item[label] as string,
        value: item[value] as string,
      }))
    } catch (error) {
      return []
    }
  }

  handleGetDefaultValue = async (
    defaultValue: NonNullableDefaultValue
  ) => {
    const { label, value } = this.columnFields

    try {
      const response: T[] =
        await this.fetchFunction(
          this.prepareQuery()
            .where(
              this.columnFields.value.toString(),
              isArray(defaultValue)
                ? defaultValue.join(',')
                : defaultValue
            )
            .url()
        ).unwrap()

      if (this.isMulti) {
        this.setValue(
          response.map((item: T) => ({
            label: item[label] as string,
            value: item[value] as string,
          }))
        )
      } else if (
        response.length &&
        !this.isMulti
      ) {
        const item = response[0]

        if (!item) return

        this.setValue({
          label: item[label] as string,
          value: item[value] as string,
        })
      }
    } catch (error) {
      return
    }
  }
}

export { ArrayAsyncSelectStrategy }
