import { useSelectValue } from '@/utils/hooks/useSelectValue'
import { useEffect } from 'react'

export const useAsyncSelect = <
  T extends { id: number }
>(
  fetchFunction: Function,
  labelColumnName: keyof T,
  defaultValue?:
    | string
    | number
    | string[]
    | number[]
    | null,
  isMulti = false
) => {
  const [selectedValue, setSelectedValue] =
    useSelectValue<typeof isMulti>()

  useEffect(() => {
    if (defaultValue) {
      fetchFunction(defaultValue)
        .unwrap()
        .then((response: T[]) => {
          if (isMulti) {
            setSelectedValue(
              response.map((item) => ({
                label: item[
                  labelColumnName
                ] as string,
                value: item.id.toString(),
              }))
            )
          } else if (
            response.length &&
            !isMulti
          ) {
            const item = response[0]

            setSelectedValue({
              label: item[
                labelColumnName
              ] as string,
              value: item.id.toString(),
            })
          }
        })
    }
  }, [defaultValue])

  return { selectedValue, setSelectedValue }
}
