import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { UpdateSettingRequestInterface } from '@/features/settings'
import { TFunction } from 'i18next'
import {
  AnyObject,
  mixed,
  object,
  ObjectSchema,
  string,
} from 'yup'

class UpdateSettingValidation
  implements
    ValidationInterface<UpdateSettingRequestInterface>
{
  defaultValues(): UpdateSettingRequestInterface {
    return {
      value: '',
      description: '',
    }
  }

  rules(
    t: TFunction
  ): ObjectSchema<
    UpdateSettingRequestInterface,
    AnyObject
  > {
    return object().shape({
      value: mixed<
        string | number | boolean
      >().required(t('validation:required')),
      description: string().nullable(),
    })
  }
}

export { UpdateSettingValidation }
