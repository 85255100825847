import React, {
  type ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import {
  Option as BaseOption,
  type OptionProps,
  Select as BaseSelect,
  type SelectProps,
  type SelectRootSlotProps,
} from '@mui/base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { Label } from './label'
import clsx from 'clsx'

type Props<
  TValue extends string | number,
  Multiple extends boolean
> = {
  label?: string
  fullWidth?: boolean
  buttonClassNames?: string
  size?: 'sm' | 'medium'
} & SelectProps<TValue, Multiple>

const Select = forwardRef(function Select<
  TValue extends string | number,
  Multiple extends boolean
>(
  props: Props<TValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {
    label,
    fullWidth,
    buttonClassNames,
    size,
    ...rest
  } = props
  const id = _.uniqueId('select_')
  const [width, setWidth] = useState('320px')

  const buttonClassName = clsx(
    'flex flex-row disabled:opacity-50 justify-between py-2 px-3 items-center border border-neutral-700 rounded-md lg:min-w-fit min-h-[36px] transition-all duration-200 ease-in-out',
    {
      'w-full': fullWidth,
    },
    buttonClassNames
  )

  useEffect(() => {
    const button = document.getElementById(id)

    if (button) {
      setWidth(`${button.offsetWidth}px`)
    }
  }, [id])

  const listBoxClassName = clsx(
    'bg-white border animate-fade-down animation-duration-[80ms] border-neutral-700 shadow-sm mt-2 rounded overflow-auto max-h-[300px]'
  )

  return (
    <div className={'flex flex-col w-full'}>
      {label && <Label label={label} />}
      <BaseSelect
        slotProps={{
          root: {
            className: buttonClassName,
            id,
          },
          listbox: {
            className: listBoxClassName,
            style: {
              width,
            },
          },
          popper: {
            className: 'z-[99999]',
          },
        }}
        ref={ref}
        slots={{
          root: Button,
        }}
        {...rest}
      />
    </div>
  )
})

const Button = forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { ownerState, ...other } = props

  return (
    <button type={'button'} {...other} ref={ref}>
      {other.children}
      <FontAwesomeIcon
        className={'text-neutral-200'}
        icon={faChevronDown}
      />
    </button>
  )
})

export const Option = (
  props: OptionProps<
    string | number | boolean | null | undefined
  >
) => {
  const classes = clsx(
    props.className,
    'cursor-pointer hover:bg-gray-200 p-2',
    {
      'bg-gray-900': props.disabled,
    }
  )

  return (
    <BaseOption
      data-value={props.value}
      {...props}
      className={classes}
    />
  )
}

export default Select
