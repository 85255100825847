import {
  PostSearchInterface,
  UpdatePostDataRequest,
} from '@/features/posts/posts/redux/types'

export const toRequestObject = (
  row: PostSearchInterface
): UpdatePostDataRequest => ({
  location_name: row.location_name,
  location: row.location,
  category_id: row.category.id,
  title: row.title,
  price: row.price,
  is_price_negotiable: row.is_price_negotiable,
  content: row.content,
  quantity: row.quantity,
  archived: !!row.archived_at,
  email_visible: row.email_visible,
  phone_visible: row.phone_visible,
  whatsapp_available: row.whatsapp_available,
  messages_enabled: row.messages_enabled,
  status: row.status,
  type: row.type,
})
