import igritAPI from '@/utils/api/igrit'
import {
  GetSettingsResponseInterface,
  SettingInterface,
  UpdateSettingRequestInterface,
} from './types'
import { setArrayCache } from '@/utils/api/rtkHelper'

export const {
  useUpdateSettingsMutation,
  useGetSettingsQuery,
} = igritAPI.injectEndpoints({
  endpoints: (build) => ({
    getSettings: build.query<
      SettingInterface[],
      void
    >({
      query: () => '/admin/settings',
      transformResponse: (
        response: GetSettingsResponseInterface
      ) => response.data,
      providesTags: (result) =>
        setArrayCache('Setting', result),
    }),
    updateSettings: build.mutation<
      void,
      {
        data: UpdateSettingRequestInterface
        id: number | string
      }
    >({
      query: ({ data, id }) => ({
        url: `/admin/settings/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Setting'],
    }),
  }),
})
