import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  useGetNotificationTemplateQuery,
  useUpdateNotificationTemplateMutation,
} from '@/features/notificationTemplates/redux/notificationTemplateAPI'
import useValidation from '@/utils/hooks/useValidation'
import UpdateNotificationTemplateValidation from './validations/updateNotificationTemplateValidation'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { UpdateNotificationTemplateRequestInterface } from '@/features/notificationTemplates/redux/types'
import { yupResolver } from '@hookform/resolvers/yup'
import Card from '@/features/components/cards'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import Select, {
  Option,
} from '@/features/components/inputs/select'
import { AvailableLocales } from '@/app/types'
import { NotificationTemplateTypeEnum } from '@/features/notificationTemplates/redux/enums/types'
import { DataForm } from '@/features/notificationTemplates/resources/views/edit/_components/dataForm'
import { Button } from '@/features/components/buttons/button'
import { toast } from 'react-toastify'

export const NotificationTemplateEditScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'notification_templates',
      'form',
      'utils',
      'validation',
    ])
    const { id } = useParams() as { id: string }
    const { data } =
      useGetNotificationTemplateQuery(id)
    const [updateNotification] =
      useUpdateNotificationTemplateMutation()
    const { schema, defaultValues } =
      useValidation(
        new UpdateNotificationTemplateValidation(),
        t
      )
    const methods =
      useForm<UpdateNotificationTemplateRequestInterface>(
        {
          resolver: yupResolver(schema),
          defaultValues,
        }
      )

    const {
      control,
      setValue,
      formState: { errors },
    } = methods

    useEffect(() => {
      if (data) {
        setValue('type', data.type)
        setValue('data', data.data)
      }
    }, [data])

    const dataForm = useFieldArray({
      control,
      name: 'data',
    })

    const onSubmit = (
      data: UpdateNotificationTemplateRequestInterface
    ) => {
      const transformedData = {
        ...data,
        data: data.data.map((item) =>
          _.omitBy(item, _.isNil)
        ),
      }

      updateNotification({
        id,
        body: transformedData as UpdateNotificationTemplateRequestInterface,
      })
        .unwrap()
        .then(() =>
          toast.success(
            t(
              'notification_templates:edit.success'
            )
          )
        )
    }

    return (
      <div
        className={
          'flex flex-col gap-y-6 p-4 pb-8'
        }
      >
        <div className={'flex'}>
          <h3 className={'text-2xl font-medium'}>
            {t(
              'notification_templates:edit.title'
            )}
          </h3>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(
              onSubmit
            )}
          >
            <Card>
              <div
                className={
                  'flex flex-col gap-y-4'
                }
              >
                <Controller
                  render={({ field }) => (
                    <FormControl
                      className={'max-w-[300px]'}
                      error={
                        !!_.get(errors, 'type')
                      }
                    >
                      <Select
                        value={field.value}
                        onChange={(_, value) =>
                          field.onChange(value)
                        }
                        label={t(
                          'form:labels.type'
                        )}
                      >
                        {_.map(
                          NotificationTemplateTypeEnum,
                          (type, key) => (
                            <Option
                              value={type}
                              key={key}
                            >
                              {t(
                                'utils:notification_templates.types.' +
                                  type
                              )}
                            </Option>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                  name={'type'}
                  control={control}
                />
              </div>
            </Card>
            {dataForm.fields.map(
              (field, index) => (
                <div
                  className={
                    'flex flex-col gap-y-4'
                  }
                  key={field.id}
                >
                  <div
                    className={'max-w-[300px]'}
                  >
                    <Controller
                      render={({ field }) => (
                        <FormControl
                          error={
                            !!_.get(
                              errors,
                              `data.${index}.locale`
                            )
                          }
                        >
                          <Select
                            buttonClassNames={
                              'bg-white'
                            }
                            value={field.value}
                            onChange={(
                              _,
                              value
                            ) =>
                              field.onChange(
                                value
                              )
                            }
                            label={t(
                              'form:labels.locale'
                            )}
                          >
                            {_.map(
                              AvailableLocales,
                              (locale, key) => (
                                <Option
                                  value={locale}
                                  key={key}
                                >
                                  {t(
                                    'utils:locales.' +
                                      locale
                                  )}
                                </Option>
                              )
                            )}
                          </Select>
                        </FormControl>
                      )}
                      name={`data.${index}.locale`}
                      control={control}
                    />
                  </div>
                  <Card key={field.id}>
                    <DataForm
                      currentIndex={index}
                      form={methods}
                      type={
                        NotificationTemplateTypeEnum.EMAIL
                      }
                    />
                    <DataForm
                      currentIndex={index}
                      form={methods}
                      type={
                        NotificationTemplateTypeEnum.SMS
                      }
                    />
                  </Card>
                </div>
              )
            )}
            <div
              className={
                'flex py-4 lg:py-0 mb-16 lg:mb-0'
              }
            >
              <Button
                variant={'contained'}
                type={'submit'}
              >
                {t('form:buttons.save')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    )
  }
