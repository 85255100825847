import { ValidationInterface } from '@/utils/hooks/useValidation/types'
import { AssignRoleRequestInterface } from '@/features/users/redux'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import { AnyObject, ObjectSchema } from 'yup'

class ChangeRoleValidation
  implements
    ValidationInterface<AssignRoleRequestInterface>
{
  defaultValues(): AssignRoleRequestInterface {
    return {
      role_id: 0,
    }
  }

  rules(
    t: TFunction
  ): ObjectSchema<
    AssignRoleRequestInterface,
    AnyObject
  > {
    return yup.object().shape({
      role_id: yup
        .number()
        .required(t('validation:required')),
    })
  }
}

export { ChangeRoleValidation }
