import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@/features/components/cards'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/types'
import { Table } from './components/table'
import { Search } from '@/features/components/inputs/Search'
import { Button } from '@/features/components/buttons/button'
import { useSearch } from '@/utils/hooks/useSearch'
import { useParams } from 'react-router-dom'
import { useNavigate } from '@/utils/hooks/useNavigate'
import {
  ColumnVisibility,
  useColumnVisibility,
} from '@/components'

const InternalQuotationCategoriesListScreen =
  (): React.ReactNode => {
    const { t } = useTranslation([
      'internal_quotations_categories',
    ])
    const { hasPermission } = usePermissions()
    const { query, setQueryParams } = useSearch({
      columnsForSearch: ['name', 'category.name'],
    })
    const {
      handleToggleColumn,
      handleSetAvailableColumns,
      visibilityColumnsWithLabels,
      visibilityColumns,
    } = useColumnVisibility()
    const { id } = useParams() as { id: string }
    const { navigateTo } = useNavigate()

    return (
      <div
        className={'flex flex-col gap-y-4 p-4'}
      >
        <div
          className={
            'flex gap-4 flex-col md:justify-between md:flex-row'
          }
        >
          {hasPermission(
            PermissionEnum.INTERNAL_QUOTATION_SAVE
          ) && (
            <div>
              <Button
                variant={'contained'}
                className={
                  'flex items-center gap-x-2'
                }
                onClick={() =>
                  navigateTo(
                    `/internal-quotations/${id}/categories/create`
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={'text-white'}
                />
                <span>
                  {t(
                    'internal_quotations_categories:list.create'
                  )}
                </span>
              </Button>
            </div>
          )}
          <div
            className={
              'flex flex-col gap-2 justify-end md:flex-row'
            }
          >
            <Search onChange={setQueryParams} />
            <ColumnVisibility
              columns={
                visibilityColumnsWithLabels
              }
              toggleColumn={handleToggleColumn}
            />
          </div>
        </div>
        <Card noPadding>
          <div className={'flex flex-col w-full'}>
            <div
              className={
                'border-b border-neutral-600 px-6 py-4 text-l font-medium'
              }
            >
              <span>
                {t(
                  'internal_quotations_categories:list.title'
                )}
              </span>
            </div>
            <Table
              quotationId={id}
              query={query}
              visibility={visibilityColumns}
              setAvailableColumns={
                handleSetAvailableColumns
              }
            />
          </div>
        </Card>
      </div>
    )
  }

export { InternalQuotationCategoriesListScreen }
